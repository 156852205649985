import { useBaseMutation } from 'api/hooks/base/useBaseMutation';
import { useBaseSearchQuery } from 'api/hooks/base/useBaseSearchQuery';
import { useTranslationText } from 'locale';
import {
  IContact,
  IOperationSearchContactsInput,
  ISendContactInput,
  IOperationGetContactInput,
  ISearchScheduleListInput,
} from 'models/Contact';
import { IError } from 'models/Models';
import { ISortDirection } from 'models/types';
import { contactMutations, contactQueries } from 'api/graphql/contact';
import { useEffect, useMemo } from 'react';
import { useBaseGetQuery } from 'api/hooks/base/useBaseGetQuery';

export const useGetContact = (id?: string) => {
  const { getError } = useTranslationText();

  const query = useBaseGetQuery<IOperationGetContactInput, IContact>({
    fetchPolicy: 'cache-and-network',
    query: contactQueries.GET,
  });

  const errorHanle = useMemo(() => {
    if (!query.loading && (query.error || !query.item)) {
      return { error: getError('SYSTEM_ERROR') };
    }

    return null;
  }, [query.error, query.loading]);

  useEffect(() => {
    if (id) {
      query.getQuery({ id });
    }
  }, [id]);

  return {
    loading: query.loading,
    error: errorHanle?.error,
    contact: query.item,
  };
};

export const useSearchSchedule = () => {
  const searchScheduleResult = useBaseSearchQuery<ISearchScheduleListInput, IContact>({
    fetchPolicy: 'cache-and-network',
    query: contactQueries.SEARCH_SCHEDULE_LIST,
  });

  return {
    loading: searchScheduleResult.loading,
    error: searchScheduleResult.error,
    totalItems: searchScheduleResult.totalItems,
    schedule: searchScheduleResult.items,
    onSearchSchedule: searchScheduleResult.onSearch,
  };
};

export const useSendContact = () => {
  const { getError } = useTranslationText();
  const { mutation, loading } = useBaseMutation<ISendContactInput, boolean>({
    mutation: contactMutations.CREATE,
  });

  async function onSendContact(input: ISendContactInput): Promise<{ error?: IError; isSent?: boolean }> {
    const { error, data } = await mutation(input);
    if (error) return { error: getError('CONTACT_SEND_ERROR') };
    return { isSent: data };
  }

  return {
    loading,
    onSendContact,
  };
};

export const useOperationSearchContacts = () => {
  const query = useBaseSearchQuery<IOperationSearchContactsInput, IContact>({
    defaulInput: {
      page: 1,
      sortDirection: ISortDirection.Asc,
      limit: 30,
    } as IOperationSearchContactsInput,
    fetchPolicy: 'cache-and-network',
    query: contactQueries.SEARCH,
  });

  /**
   * Call api
   */
  useEffect(() => {
    query.onSearch(query.input);
  }, []);

  return {
    loading: query.loading,
    error: query.error,
    query: query.input,
    page: query.page,
    hasMore: query.hasMore,
    totalItems: query.totalItems,
    totalPages: query.totalPages,
    limit: query.limit,
    contacts: query.items,
    onChangePage: query.onChangePage,
    onChangeLimit: query.onChangeLimit,
    onSearchContacts: query.onSearch,
    onLoadMoreContacts: query.onLoadMore,
    onDeleteContactsInList: query.onDeleteItem,
  };
};
