import React from 'react';
import { useTranslationText } from 'locale';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { AppColors } from 'helpers';
import { Div, HorizontalView, VerticalView } from 'vendors/Wrapper';
import { Text } from 'vendors/Text';
import Table from 'components/common/components/Table';
import { SVGIcon } from 'vendors/Icon';
import { IReport } from 'models/Report';
import { Confirm } from 'components/common/components/Modals/ConfirmModal';
import { Button } from 'vendors/Button';
import { dateUtil } from 'helpers/date.utils';

export function columns(
  startIndex: number,
  translation: any,
  onDetails: (id: string) => void,
  onDelete: (id: string) => void,
) {
  const reportItems = [
    {
      label: translation('report.reasons.rule'),
      value: 'reportRule',
    },
    {
      label: translation('report.reasons.spam'),
      value: 'reportSpam',
    },
    {
      label: translation('report.reasons.trouble'),
      value: 'reportTrouble',
    },
    {
      label: translation('report.reasons.sex'),
      value: 'reportSexContent',
    },
    {
      label: translation('report.reasons.violence'),
      value: 'reportViolence',
    },
    {
      label: translation('report.reasons.other'),
      value: 'reportOther',
    },
  ];

  const tableColumns: Array<ColumnDescription<IReport>> = [
    {
      dataField: 'id',
      text: 'No',
      headerStyle: () => {
        return { width: '10%', textAlign: 'left', whiteSpace: 'unset', padding: '10px' };
      },
      formatter: (_, row: IReport, index) => {
        return (
          <a
            href={`/cms/report/${row.id}`}
            color={AppColors.PRIMARY}
            onClick={(event) => {
              event.preventDefault();
              onDetails(row.id);
            }}
          >
            <HorizontalView fullWidth alignContent={'centerBetween'} padding={'10px'} flexWrap={'nowrap'}>
              {startIndex + index + 1}
              <Div width={'30px'}>
                <SVGIcon size={'20px'} name={'arrow-right'} color={AppColors.PRIMARY} />
              </Div>
            </HorizontalView>
          </a>
        );
      },
    },

    {
      dataField: 'title',
      text: translation('report.reasonType'),
      headerStyle: () => {
        return { width: '20%', textAlign: 'left', padding: '10px' };
      },
      formatter: (_, row: IReport) => {
        return (
          <HorizontalView fullWidth alignContent={'centerBetween'} padding={'10px'} flexWrap={'nowrap'}>
            {reportItems.find((item) => item.value === row.reasonType)?.label || ''}
          </HorizontalView>
        );
      },
    },
    {
      dataField: 'content',
      text: translation('report.content'),
      headerStyle: () => {
        return { width: '40%', textAlign: 'left', padding: '10px' };
      },
      formatter: (_, row: IReport) => {
        return (
          <HorizontalView
            fullWidth
            alignContent={'centerBetween'}
            padding={'10px'}
            flexWrap={'nowrap'}
            overflow={'hidden'}
          >
            <Text>{row.content ? `${row.content.substring(0, 100)}` : ''}</Text>
          </HorizontalView>
        );
      },
    },
    {
      dataField: 'createdTime',
      text: '',
      headerStyle: () => {
        return { width: '20%', textAlign: 'left', padding: '10px' };
      },
      formatter: (_, row: IReport) => {
        return (
          <HorizontalView fullWidth alignContent={'center'} padding={'10px'} flexWrap={'nowrap'}>
            <Text>{dateUtil.dateFormat(row.createdTime || '', 'YYYY/MM/DD HH:mm')}</Text>
          </HorizontalView>
        );
      },
    },
    {
      dataField: 'menu',
      csvExport: false,
      text: '',
      headerStyle: () => {
        return { width: '10%', maxWidth: '80px', textAlign: 'center', padding: '10px' };
      },
      // eslint-disable-next-line react/display-name
      formatter: (_, row) => {
        return (
          <HorizontalView fullWidth alignContent={'centerAround'} padding={'5px'}>
            <Confirm
              title={'home.category.alertDelete.message'}
              leftText={'home.category.alertDelete.close'}
              rightText={'home.category.alertDelete.delete'}
              onConfirm={() => {
                onDelete(row.id);
              }}
            >
              <Button
                width={'35px'}
                height={'35px'}
                preixIcon={'trash'}
                borderRadius={'4px'}
                color={AppColors.WHITE}
                background={AppColors.RED}
              />
            </Confirm>
          </HorizontalView>
        );
      },
    },
  ];

  return tableColumns;
}

interface ReportWebTableProps {
  loading: boolean;
  reports: IReport[];
  page: number;
  rowPerPage: number;
  totalPages: number;
  onDetails: (id: string) => void;
  onDelete: (id: string) => void;
}

export const ReportWebTable = (props: ReportWebTableProps) => {
  const { translation } = useTranslationText();

  return (
    <VerticalView fullWidth minWidth={'800px'}>
      <Table
        loading={props.loading}
        keyField={'id'}
        data={props.reports}
        bordered={false}
        striped={true}
        hover={true}
        columns={columns((props.page - 1) * props.rowPerPage, translation, props.onDetails, props.onDelete)}
      />
    </VerticalView>
  );
};
