import * as queries from './queries';
import * as mutations from './mutations';

export const downloadQueries = {
  ...queries,
};

export const downloadMutations = {
  ...mutations,
};
