import { useSearchComment } from 'api/hooks/comment/useSearchComment';
import { useGetVideo } from 'api/hooks/video/useVideoApi';
import { VideoDetailComponent } from 'components/user-interface/video-detail';
import React from 'react';
import { useParams } from 'react-router-dom';

export const VideoDetailContainer = () => {
  const { videoId } = useParams();
  const { video, getVideo, loading: videoLoading } = useGetVideo();
  const { comments, loading: commentLoading, searchCommnents } = useSearchComment();

  React.useEffect(() => {
    if (videoId) {
      getVideo({ id: videoId });
      searchCommnents({
        videoId: videoId,
        limit: 10,
        page: 1,
      });
    }
  }, []);

  return <VideoDetailComponent loading={videoLoading || commentLoading} video={video} comments={comments} />;
};
