import { useRequestDownloadFile } from 'api/hooks/download/useRequestDownloadFile';
import { useGetCustomerContent } from 'api/hooks/home/useHomeApi';
import { EmployerComponent } from 'components/user-interface/employer';
import React from 'react';

export const EmployerContainer = () => {
  const { customerPage, loading } = useGetCustomerContent();
  const [sent, setSent] = React.useState(false);
  const { requestDownload, loading: requestLoading } = useRequestDownloadFile();

  return (
    <EmployerComponent
      requestLoading={requestLoading}
      loading={loading}
      videos={customerPage?.videos || []}
      news={customerPage?.articles || []}
      slideImages={customerPage?.slideImages}
      features={customerPage?.features}
      onSendLinkDownload={async (name, email) => {
        if (customerPage && customerPage.fileLinkDownload) {
          const result = await requestDownload({ name, email, documentLink: customerPage.fileLinkDownload });
          if (!result.error) {
            setSent(true);
          }
        }
      }}
      sendSuccess={sent}
    />
  );
};

export default EmployerContainer;
