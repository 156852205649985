import gql from 'graphql-tag';

export const CREATE = gql`
  mutation createTransaction($input: CreateTransactionInput!) {
    createTransaction(input: $input)
  }
`;
export const CREATE_INVOICE = gql`
  mutation createInvoice($input: CreateInvoiceInput!) {
    createInvoice(input: $input) {
      pdfLink
    }
  }
`;
