import BusinessRegisterContainer from 'containers/user-container/customer-register';
import NotFoundContainer from 'containers/user-container/not-found';
import React from 'react';
import { Routes, Route } from 'react-router';

export const BusinessRegisterRoutes = () => {
  return (
    <Routes>
      <Route index element={<BusinessRegisterContainer />} />
      <Route path="/*" element={<NotFoundContainer />} />
    </Routes>
  );
};
