import CMSHelpCenterListContainer from 'containers/cms-containers/help-center/list';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CMSHelpCenterDetailsContainer from 'containers/cms-containers/help-center/detail';

export const HelpCenterRoutes = () => {
  return (
    <Routes>
      <Route index element={<CMSHelpCenterListContainer />} />
      <Route path="/:helpId" element={<CMSHelpCenterDetailsContainer />} />
    </Routes>
  );
};
