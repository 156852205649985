import { userQueries } from 'api/graphql/user';
import { ErrorCodes } from 'helpers/const';
import { useTranslationText } from 'locale';
import { IUser } from 'models/User';
import { useMemo } from 'react';
import { useBaseGetQuery } from '../base/useBaseGetQuery';

export const useGetProfile = () => {
  const { getError } = useTranslationText();
  const result = useBaseGetQuery<{ userId: string }, IUser>({
    fetchPolicy: 'cache-and-network',
    query: userQueries.GET_PROFILE,
  });
  const errorHanle = useMemo(() => {
    if (!result.loading && result.error) {
      switch (result.error.code) {
        case ErrorCodes.ERROR_CODE_ITEM_NOT_EXIST:
          return { error: getError('User_IS_NOT_EXIST') };
        default:
          return { error: getError('SYSTEM_ERROR') };
      }
    }
    return null;
  }, [result.error]);

  return {
    loading: result.loading,
    error: errorHanle,
    user: result.item,
    onGetProfile: result.getQuery,
  };
};
