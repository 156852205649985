import React from 'react';
import styled from 'styled-components';
import { Text } from '../Text';
import { AppColors } from '../../helpers/index';
import { useTranslationText } from 'locale';

const InputDefault = styled.input<InputProps>`
  outline: none;
  ${(props) => {
    const color = props.color || 'black';
    const height = props.height || '40px';
    const width = props.width || '100%';
    const border = `border: ${
      props.border || `1px ${props.borderStyle || 'solid'} ${props.borderColor || AppColors.BORDER} `
    };`;

    return `
      color : ${color};
      height: ${height};
      width: ${width};
      border-width:1px;
      padding-left:5px;
      padding-right:5px;
      ${props.borderRadius ? `border-radius: ${props.borderRadius};` : ''}
      ${border}
      ${props.minWidth ? `minWidth: ${props.minWidth};` : ''}
      ${props.noBorder ? 'border: 0px solid transparent ;' : ''}
      ${props.fontSize ? `font-size: ${props.fontSize};` : '13px'};
      ${props.fontWeight ? `font-weight: ${props.fontWeight};` : ''}
      ${props.background ? `background: ${props.background};` : ''}
      ${props.paddingLeft ? `padding-left: ${props.paddingLeft};` : ''}
      ${props.paddingRight ? `padding-right: ${props.paddingRight};` : ''}
      ${props.borderBottom ? `border-bottom: ${props.borderBottom};` : ''}
      ${props.borderColor ? `border-color: ${props.borderColor};` : ''}
    `;
  }}
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const InputText = styled.div`
  margin-bottom: 6px;
  font-weight: 400;
`;

const MustLabel = styled.span`
  display: inline-block;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1;
  border-radius: 3px;
  color: red;
  margin-left: 2px;
`;

const ErrorMessage = styled.div`
  margin-top: 5px;
  color: red;
  font-size: 10px;
`;

const MaxLength = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 10px;
`;

const InputContent = styled.div``;

export type InputProps = {
  id?: string;
  isPreview?: boolean;
  showMaxLenght?: boolean;
  maxLenght?: number;
  hide?: boolean;
  value?: any | null;
  style?: React.CSSProperties;
  label?: string;
  name?: string;
  required?: boolean;
  borderRadius?: string;
  placeholder?: string;
  color?: string;
  allowClear?: boolean;
  hasFeedback?: boolean;
  disabled?: boolean;
  error?: string;
  noBorder?: boolean;
  border?: string;
  fontSize?: string;
  height?: string;
  width?: string;
  minWidth?: string;
  paddingLeft?: string;
  paddingRight?: string;
  background?: string;
  borderBottom?: string;
  borderColor?: string;
  fontWeight?: 'bold' | 'normal';
  type?: 'text' | 'password' | 'number' | 'textarea';
  borderStyle?: 'solid' | 'dashed';
  onBlur?: () => void;
  onTouchStart?: () => void;
  onFocus?: () => void;
  onPressEnter?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Input = (props: InputProps) => {
  const { translation } = useTranslationText();
  if (props.hide) {
    return <></>;
  }

  let value = '';
  if (typeof props.value !== 'undefined' && props.value !== null) {
    value = props.value;
  }

  const style = JSON.parse(JSON.stringify(props));
  style.onPressEnter && delete style.onPressEnter;

  if (!style.fontSize) {
    style.fontSize = '13px';
  }

  return (
    <InputWrapper style={props.style || {}}>
      {props.label && (
        <InputText>
          <Text color={props.color || 'black'} fontWeight={500} fontSize={props.fontSize || '13px'}>
            {translation(props.label)} {props.required && <MustLabel>{'(*)'}</MustLabel>}
          </Text>
        </InputText>
      )}

      <InputContent>
        {props.isPreview && (
          <Text fontSize={style.fontSize} color={props.color || 'black'}>
            {props.value}
          </Text>
        )}
        {!props.isPreview && (
          <InputDefault
            {...style}
            color={props.color || 'black'}
            id={props.id}
            name={props.name}
            type={props.type || 'text'}
            placeholder={props.placeholder ? translation(props.placeholder) : ''}
            style={{ minHeight: 36 }}
            onChange={(e) => {
              if (props.maxLenght) {
                if (e.target.value.length > props.maxLenght) {
                  return;
                }
              }
              props.onChange && props.onChange(e);
            }}
            onBlur={props.onBlur}
            disabled={props.disabled}
            onTouchStart={props.onTouchStart}
            onFocus={props.onFocus}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                props.onPressEnter && props.onPressEnter();
              }
            }}
            value={value}
          />
        )}

        {!props.isPreview && props.error && <ErrorMessage>{`※ ${translation(props.error)}`}</ErrorMessage>}
      </InputContent>
      {props.maxLenght && props.showMaxLenght && (
        <MaxLength>
          <Text color={props.color || 'black'} fontSize={'10px'}>
            ({value.length}/{props.maxLenght})
          </Text>
        </MaxLength>
      )}
    </InputWrapper>
  );
};
