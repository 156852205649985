import { useMutation } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { IError } from 'models/Models';
import { useTranslationText } from 'locale';

type UseBaseMutation = {
  mutation: DocumentNode;
};

export function useBaseMutation<TMutation = any, TData = any>(props: UseBaseMutation) {
  const { getError } = useTranslationText();
  const [mutation, { loading }] = useMutation(props.mutation);

  async function mutationData(input?: TMutation): Promise<{ error?: IError; data?: TData }> {
    try {
      const { data, errors } = await mutation(input ? { variables: { input } } : undefined);
      if (errors?.length) {
        const error = JSON.parse(JSON.stringify(errors[0]));
        if (!error.code) {
          return { error: getError('SYSTEM_ERROR') };
        }
        return { error };
      }

      if (!data) {
        return { error: getError('SYSTEM_ERROR') };
      }

      return { data };
    } catch (errors) {
      const { graphQLErrors } = errors as any;
      const error = graphQLErrors?.length
        ? JSON.parse(JSON.stringify(graphQLErrors[0]))
        : { code: 500, message: 'ServerError' };

      return { error: error } as any;
    }
  }

  return {
    /**
     * Loading api
     */
    loading,
    /**
     * Fn Update or Delete data
     */
    mutation: mutationData,
  };
}
