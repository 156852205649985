export const margin = {
  size2: 2,
  size3: 3,
  size4: 4,
  size5: 5,
  size10: 10,
  size15: 15,
  size20: 20,
  size25: 25,
  size30: 30,
};
export const padding = {
  size2: 2,
  size3: 3,
  size4: 4,
  size5: 5,
  size10: 10,
  size15: 15,
  size20: 20,
  size25: 25,
  size30: 30,
};

export const font = {
  size2: 2,
  size3: 3,
  size4: 4,
  size5: 5,
  size10: 10,
  size12: 12,
  size13: 13,
  size14: 14,
  size15: 15,
  size16: 16,
  size18: 18,
  size20: 20,
  size25: 25,
  size30: 30,
};
