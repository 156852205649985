import React from 'react';
import { Formik, FormikProps } from 'formik';
import { useTranslationText } from 'locale';
import { useAppSetting } from 'hooks/useAppSetting';
import { AppColors, AppConst } from 'helpers';
import { Div, HorizontalView, VerticalView } from 'vendors/Wrapper';
import { Modal } from 'vendors/Modal';
import { Text } from 'vendors/Text';
import { ISendMultipleEmailInput } from 'models/types';
import { Input } from 'vendors/Input';
import { ArticleEditorInput } from 'vendors/ArticleEditor';
import { Button } from 'vendors/Button';
import { emailValidator } from 'validators/email';

type PropsType = {
  open: boolean;
  emails?: string[];
  sendAll?: boolean;
  sendingEmail: boolean;
  onSend: (values: ISendMultipleEmailInput) => void;
  onClose: () => void;
};

export const SendMailModal = (props: PropsType) => {
  const ref = React.useRef<FormikProps<ISendMultipleEmailInput>>(null);
  const { translation } = useTranslationText();
  const { appLayout, appTheme, appSize } = useAppSetting();

  return (
    <HorizontalView>
      <Modal
        title={translation('emailModal.title')}
        open={props.open}
        height={appLayout.isMobile ? '100vh' : 'auto'}
        zIndex={AppConst.zIndex.header + 60}
        onClose={() => {
          props.onClose();
        }}
        headerBackground={appTheme.sidebar.bg}
        closeOnBackDropClick
      >
        <HorizontalView minWidth={'300px'} fullWidthHeight alignContent={'topCenter'}>
          <Formik
            innerRef={ref}
            initialValues={{ subject: '', body: '', isAll: props.sendAll || false, to: props.emails || [] }}
            validationSchema={emailValidator}
            onSubmit={(values) => {
              props.onSend(values);
            }}
          >
            {({ errors, values, handleChange, setFieldValue }) => {
              return (
                <VerticalView
                  width={appLayout.isMobile ? '100vw' : '800px'}
                  padding={'20px'}
                  overflowY={'scroll'}
                  maxHeight="90vh"
                  flexWrap="nowrap"
                >
                  <HorizontalView fullWidth paddingBottom="20px">
                    <Div padding="2px" marginRight="10px">
                      <Text fontSize={appSize.h3} fontWeight={500}>
                        {translation('emailModal.sendTo')}
                      </Text>
                    </Div>
                    {props.sendAll && (
                      <Div padding="2px">
                        <Text fontSize={appSize.h3} fontWeight={600} color={AppColors.RED}>
                          {translation('emailModal.all')}
                        </Text>
                      </Div>
                    )}
                    {props.emails &&
                      props.emails.length > 0 &&
                      !props.sendAll &&
                      props.emails.map((item, index) => {
                        return (
                          <Div
                            key={index}
                            padding="2px 8px"
                            margin="2px"
                            border={`1px solid ${AppColors.GRAY_LIGHT}`}
                            borderRadius="15px"
                          >
                            <Text fontSize={appSize.h4} color={AppColors.GRAY_DARK}>
                              {item}
                            </Text>
                          </Div>
                        );
                      })}
                  </HorizontalView>
                  <HorizontalView fullWidth paddingBottom="20px">
                    <Input
                      required
                      name="subject"
                      type="text"
                      label={'emailModal.subject'}
                      fontSize={appSize.h3}
                      placeholder={'emailModal.subject'}
                      value={values.subject}
                      onChange={handleChange}
                      error={errors.subject}
                    />
                  </HorizontalView>
                  <HorizontalView fullWidth paddingBottom="20px">
                    <ArticleEditorInput
                      required
                      label={'emailModal.content'}
                      placeholder={'emailModal.content'}
                      defaultValue={values.body}
                      name={'body'}
                      width="100%"
                      height="500px"
                      onChange={(content) => {
                        setFieldValue('body', content);
                      }}
                      error={errors.body}
                    />
                  </HorizontalView>
                  <HorizontalView fullWidth alignContent="center">
                    <Button
                      title={props.sendAll ? 'emailModal.btnSendAll' : 'emailModal.btnSend'}
                      preixIcon={'email'}
                      borderRadius={'4px'}
                      loading={props.sendingEmail}
                      type="submit"
                      padding="10px 15px"
                      fontSize={appSize.h3}
                      fontWeight={600}
                      marginTop={'20px'}
                      background={props.sendAll ? AppColors.BLUE_BOLD : AppColors.BLUE_GREENY}
                      color={AppColors.WHITE}
                      onClick={() => {
                        if (ref.current) {
                          ref.current.handleSubmit();
                        }
                      }}
                    />
                  </HorizontalView>
                </VerticalView>
              );
            }}
          </Formik>
        </HorizontalView>
      </Modal>
    </HorizontalView>
  );
};
