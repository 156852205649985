import gql from 'graphql-tag';

export const CREATE = gql`
  mutation createComment($input: CreateCommentInput!) {
    createComment(input: $input) {
      id
    }
  }
`;

export const DELETE = gql`
  mutation deleteComment($input: DeleteCommentInput!) {
    deleteComment(input: $input)
  }
`;
