import { CreateBusinessContainer } from 'containers/cms-containers/customer/create';
import { CompanyDetailContainer } from 'containers/cms-containers/customer/detail';
import CompanyListContainer from 'containers/cms-containers/customer/list';
import React from 'react';
import { Routes, Route } from 'react-router';

export const CMSCustomerCompaniesRoute = () => {
  return (
    <Routes>
      <Route index element={<CompanyListContainer />} />
      <Route path="/create" element={<CreateBusinessContainer />} />
      <Route path="/detail/:companyId" element={<CompanyDetailContainer />} />
    </Routes>
  );
};
