import { LoadingOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { AppColors } from 'helpers';
import { VerticalView } from '../Wrapper';

export type LoadingProps = {
  size?: string;
  color?: string;
  loading?: boolean;
  height?: string;
  overlay?: boolean;
};

const OverflowMask = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100900;
  opacity: 0.2;
  background: #111;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  animation: ${rotate} 2s linear infinite;
  color: ${AppColors.NIGHT_BLUE};
  opacity: 1;
`;

const ContentLoadingWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  min-height: 20px;
`;

const LoadingMask = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100900;
  background: transparent;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.3;
  background: #000;
`;

export const Loading = (props: LoadingProps) => {
  if (props.loading) {
    return (
      <LoadingMask>
        {props.overlay && <Overlay />}
        <Spinner>
          <LoadingOutlined style={{ fontSize: 40 }} />
        </Spinner>
      </LoadingMask>
    );
  } else {
    return <></>;
  }
};

export const ContentLoading = (props: LoadingProps) => {
  if (props.loading) {
    return (
      <ContentLoadingWrapper style={{ height: props.height || '100%' }}>
        <LoadingMask>
          {props.overlay && <Overlay />}
          <Spinner>
            <LoadingOutlined style={{ fontSize: props.size || 40, color: props.color || 'white' }} />
          </Spinner>
        </LoadingMask>
      </ContentLoadingWrapper>
    );
  } else {
    return <></>;
  }
};

export type UploadFileLoadingProps = {
  loading?: boolean;
  withText?: string;
  value: number;
};

export const UploadFileLoading = (props: UploadFileLoadingProps) => {
  if (props.loading) {
    return (
      <VerticalView
        position={'fixed'}
        top={'0'}
        left={'0'}
        right={'0'}
        bottom={'0'}
        alignContent={'center'}
        zIndex={1000}
      >
        <OverflowMask />
        <VerticalView
          width={'200px'}
          height={'200px'}
          borderRadius={'10px'}
          background={'white'}
          alignContent={'center'}
          zIndex={100901}
          padding={'30px'}
        >
          <CircularProgressbar value={props.value} text={props.withText} strokeWidth={5} />
        </VerticalView>
      </VerticalView>
    );
  } else {
    return null;
  }
};
