import React from 'react';
import { Div, HorizontalView } from 'vendors/Wrapper';
import { FlatList } from 'vendors/FlatList';
import { Text } from 'vendors/Text';
import { Tag } from 'components/common/components/Tag';

export type ITag = {
  value: string;
  label: string;
  isSelect: boolean;
};

export const TagSelect = (props: {
  label: string;
  type: string;
  values: ITag[];
  setValues: (values: ITag[]) => void;
  onSelectTags: (tags: string[], type: string) => void;
}) => {
  return (
    <HorizontalView fullWidth flexWrap={'nowrap'} alignContent={'centerLeft'}>
      <Div width={'150px'} alignContent={'centerLeft'}>
        <Text fontSize={'20px'} fontWeight={500}>
          {props.label}
        </Text>
      </Div>
      <HorizontalView width={'calc(100% - 150px)'} alignContent={'centerLeft'}>
        <FlatList
          values={props.values}
          direction={'column'}
          renderItem={(tag: ITag, index: number) => {
            return (
              <Div key={index} opacity={tag.isSelect ? 1 : 0.2} paddingTop={'3px'} paddingBottom={'3px'}>
                <Tag
                  value={tag.value}
                  label={tag.label}
                  isSelect={tag.isSelect}
                  onClick={() => {
                    const _type = props.values.map((t) => {
                      if (t.value === tag.value) {
                        return { ...t, isSelect: !tag.isSelect };
                      }
                      return t;
                    });
                    props.setValues(_type);
                    props.onSelectTags &&
                      props.onSelectTags(_type.filter((t) => t.isSelect)?.map((t) => t.value) || [], props.type);
                  }}
                />
              </Div>
            );
          }}
        />
      </HorizontalView>
    </HorizontalView>
  );
};
