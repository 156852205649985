import { CmsCategoryCreatePage } from 'containers/cms-containers/categories/update-category';
import React from 'react';
import { Routes, Route } from 'react-router';
import { CmsCategorySearchPage } from '../../containers/cms-containers/categories/search-category';

export const CreateCategoryRouter = () => {
  return (
    <Routes>
      <Route path="/:categoryId" element={<CmsCategoryCreatePage />} />
      <Route index element={<CmsCategorySearchPage />} />
    </Routes>
  );
};
