import { userMutations } from 'api/graphql/user';
import { ICompany, IRegisterBusinessAccountInput } from 'models/Company';
import { IError } from 'models/Models';
import { useBaseMutation } from '../base/useBaseMutation';

export const useCreateBussinessAccount = () => {
  const { mutation, loading } = useBaseMutation<IRegisterBusinessAccountInput, ICompany>({
    mutation: userMutations.REGIST_BUSINESS_USER,
  });
  async function onNewAccount(input: IRegisterBusinessAccountInput): Promise<{ error?: IError; account?: ICompany }> {
    const { data, error } = await mutation(input);

    return { account: data, error: error };
  }
  return {
    loading,
    registBussinessAccount: onNewAccount,
  };
};
