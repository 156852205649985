import React from 'react';
import styled from 'styled-components';
import { AcceptFileType, UploadComponent } from 'vendors/Upload';
import { Div, HorizontalView, VerticalView } from 'vendors/Wrapper';
import { Loading, ContentLoading, UploadFileLoading } from 'vendors/Loading';
import { IMedia } from 'models/Media';
import { CropImageModal, CropRatio } from '../Modals/CropImageModal';
import { FlatList } from 'vendors/FlatList';
import { MediaCard } from './MediaCard';
import { useMediaWidth } from './hooks/useMediaWidth';
import { MediaSearchBar } from './MediaSearchBar';
import { useTranslationText } from 'locale';
import { useMediaUpload } from './hooks/useMediaUpload';
import { useMediaType } from './hooks/useMediaType';
import { useDeleteMedia } from 'api/hooks/media/useMediaApi';
import { useSession } from 'stores/userStore';
import { useSearchMedias } from './hooks/useSearchMedias';
import { SegmentControl, SegmentItem } from 'vendors/Segment';
import { AppColors } from 'helpers';
import { PaginationBar } from '../Pagination/PaginationBar';
import { VideoPlayerModal } from './VideoPlayerModal';

export const UploadView = styled.div`
  width: 100%;
  position: relative;
  background: ${AppColors.GRAY_LIGHT3};
`;

type MediaViewProps = {
  accept: AcceptFileType;
  isModal?: boolean;
  ratios?: CropRatio[];
  onSelect?: (url: string) => void;
  role?: 'upload' | 'full' | 'view';
  maxImageWidth?: number;
};

export const MediaView = (props: MediaViewProps) => {
  const { session } = useSession();
  const [playVideoUrl, setPlayVideoUrl] = React.useState<string | null>(null);
  const { getTypes, translation } = useTranslationText();
  const { mediaboxRef, mediaWidth } = useMediaWidth();
  const { mediaType, setMediaType } = useMediaType(props.accept);
  const { mediaDelete, reloadAfterDeleted, loading: deleteLoading } = useDeleteMedia();
  const {
    loading: searchLoading,
    items,
    searchParam,
    totalPages,
    page,
    setSearchParam,
    onSearchMedia,
    onChangePage,
  } = useSearchMedias(mediaType, props.ratios);
  const {
    progress,
    files,
    loading: uploadLoading,
    reloadAfterUpload,
    openCropView,
    onSelectFiles,
    setFiles,
    setOpenCropView,
    onCrop,
  } = useMediaUpload(session);

  const mediaTypeList: SegmentItem[] = getTypes('mediaGroup');

  React.useEffect(() => {
    if (reloadAfterDeleted) {
      onSearchMedia();
    }
    if (reloadAfterUpload) {
      setSearchParam({ ...searchParam, page: 1 });
    }
  }, [reloadAfterDeleted, reloadAfterUpload]);

  const onSearch = (keyword: string) => {
    const _query = { ...searchParam };
    _query.keyword = keyword;
    setSearchParam({ ..._query, page: 1 });
  };

  const onSelect = (media: IMedia) => {
    /* eslint-disable react/prop-types */
    if (!props.onSelect) {
      media.mimetype === 'video' && setPlayVideoUrl(media.url);
      return;
    }
    props.onSelect(media.url);
  };

  const renderItem = (media: IMedia) => {
    return (
      <Div key={media.id}>
        <MediaCard
          width={mediaWidth}
          media={media}
          onDelete={() => mediaDelete(media.id)}
          onSelect={props.onSelect || media.mimetype === 'video' ? onSelect : undefined}
        />
      </Div>
    );
  };

  return (
    <VerticalView fullWidth ref={mediaboxRef} alignContent={'topCenter'} marginTop={'20px'}>
      <Loading loading={deleteLoading} />
      <UploadFileLoading
        loading={uploadLoading}
        value={progress.length > 0 ? +progress : 0}
        withText={`${progress === '100.00' ? translation('processing') : `${progress}%`}`}
      />
      <UploadView>
        <UploadComponent
          title={'Media'}
          accept={mediaType as AcceptFileType}
          reset={files.length === 0}
          hideUploadButton
          isImportLoading={uploadLoading}
          onSelect={onSelectFiles}
        />
      </UploadView>

      <HorizontalView fullWidth alignContent={'centerBetween'} padding={'20px'}>
        <Div>
          {props.accept === 'image,audio,video,application' && mediaType && (
            <SegmentControl
              value={mediaType}
              items={mediaTypeList}
              background={AppColors.BLUE}
              color={AppColors.WHITE}
              colorSelect={AppColors.BLACK}
              tintColor={AppColors.GRAY_LIGHT}
              onSelect={(index) => {
                if (mediaTypeList[index].value === searchParam.type) {
                  return;
                }
                setMediaType(mediaTypeList[index].value);
              }}
            />
          )}
        </Div>
        <MediaSearchBar placeholder={translation('keyword')} onSearch={onSearch} />
      </HorizontalView>

      {searchLoading && <ContentLoading loading height={'200px'} color={'gray'} />}
      {!searchLoading && <FlatList alignContent={'topCenter'} values={items || []} renderItem={renderItem} />}
      <HorizontalView fullWidth alignContent={'centerRight'}>
        {!searchLoading && (
          <Div marginTop={'20px'} paddingBottom={'20px'}>
            <PaginationBar totalPages={totalPages} currentPage={page} onChangePage={onChangePage} />
          </Div>
        )}
      </HorizontalView>

      <CropImageModal
        ratios={props.ratios}
        open={openCropView}
        file={files.length > 0 ? files[0] : null}
        maxImageWidth={props.maxImageWidth}
        onClose={() => {
          setFiles([]);
          setOpenCropView(false);
        }}
        onCrop={onCrop}
      />
      <VideoPlayerModal url={playVideoUrl || ''} open={Boolean(playVideoUrl)} onClose={() => setPlayVideoUrl(null)} />
    </VerticalView>
  );
};
