import React from 'react';
import { HorizontalView, VerticalView } from 'vendors/Wrapper';
import { TableWrapper } from 'vendors/CommonParts';
import { BaseComponent } from 'components/common/components/BaseComponent';
import { WebTableView } from '../parts/WebTableView';
import { CmsSearchUserBar } from '../parts/CmsSearchUserBar';
import { PageTitle } from 'components/common/components/PageTitle';
import { PaginationBar } from 'components/common/components/Pagination/PaginationBar';
import { IUser } from 'models/User';
import { useNavigate } from 'react-router-dom';
import { useTranslationText } from 'locale';
import { ISendMultipleEmailInput, ISendMultipleEmailOutput } from 'models/types';
import { openNotification } from 'vendors/Notification';
import { SendMailModal } from 'components/common/components/Email/EmailModal';

interface UserListProps {
  loading: boolean;
  deleteLoading: boolean;
  totalPages: number;
  currentPage: number;
  totalItems: number;
  companies: IUser[];
  onDelete: (userId: string) => void;
  onDetails: (userId: string) => void;
  onSearch: (keyword: string) => void;
  onChangePage: (page: number) => void;
  onChangeLimit: (page: number) => void;
  onSelectTags: (tags: string[]) => void;
  onFilterMemberStatus: (status?: boolean) => void;
  sendingEmail: boolean;
  mailResult?: ISendMultipleEmailOutput;
  onSendEmail: (input: ISendMultipleEmailInput) => void;
}

export const CompanyListComponent = (props: UserListProps) => {
  const navigate = useNavigate();
  const [checkedMail, setCheckedMail] = React.useState([] as string[]);
  const [sendMail, setSendMail] = React.useState(false);
  const [openSendMail, setOpenSendMail] = React.useState(false);
  const [sendAll, setSendAll] = React.useState(false);
  const { translation } = useTranslationText();

  const onCheckUncheck = (mail: string) => {
    const m = checkedMail;
    if (checkedMail.includes(mail)) {
      const index = checkedMail.indexOf(mail);
      m.splice(index, 1);
    } else {
      m.push(mail);
    }
    setCheckedMail(m);
    setSendMail(checkedMail.length > 0);
  };

  React.useEffect(() => {
    if (props.mailResult) {
      if (props.mailResult.failedEmail) {
        openNotification({
          type: 'error',
          message: translation('emailModal.sendFailed'),
          description: translation('emailModal.sendFailedMessage'),
        });
        if (sendAll) setSendAll(false);
        setCheckedMail(props.mailResult.failedEmail);
      } else {
        openNotification({
          type: 'success',
          message: translation('emailModal.sent'),
        });
        setOpenSendMail(!openSendMail);
        setSendAll(false);
      }
    }
  }, [props.mailResult]);

  return (
    <BaseComponent>
      <VerticalView fullWidth alignContent={'center'}>
        <PageTitle title={'usersSearch.customerHeader'} />
        <CmsSearchUserBar
          totalItems={props.totalItems}
          onSearch={(keyword) => props.onSearch(keyword)}
          onCreate={() => navigate('/cms/customer/create')}
          onChangeLimit={props.onChangeLimit}
          createLoading={false}
          onSelectTags={props.onSelectTags}
          sendMailButton={sendMail}
          onFilterMemberStatus={props.onFilterMemberStatus}
          onOpenSendMail={() => {
            setOpenSendMail(!openSendMail);
          }}
          onOpenSendMailAll={() => {
            setSendAll(true);
            setOpenSendMail(!openSendMail);
          }}
        />
        <TableWrapper>
          <WebTableView
            loading={props.loading}
            users={props.companies}
            onDelete={props.onDelete}
            onDetails={props.onDetails}
            checkedMail={checkedMail}
            onCheck={onCheckUncheck}
          />
        </TableWrapper>

        {props.totalPages && !props.loading && (
          <HorizontalView fullWidth alignContent={'centerRight'}>
            <PaginationBar
              totalPages={props.totalPages}
              currentPage={props.currentPage}
              onChangePage={props.onChangePage}
            />
          </HorizontalView>
        )}
        <SendMailModal
          open={openSendMail}
          sendAll={sendAll}
          emails={checkedMail}
          sendingEmail={props.sendingEmail}
          onSend={props.onSendEmail}
          onClose={() => {
            setOpenSendMail(!openSendMail);
            setSendAll(false);
          }}
        />
      </VerticalView>
    </BaseComponent>
  );
};

export default React.memo(CompanyListComponent);
