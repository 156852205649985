import gql from 'graphql-tag';

export const SEARCH = gql`
  query searchDownloadRecords($input: SearchDownloadRecordsInput!) {
    searchDownloadRecords(input: $input) {
      totalItems
      items {
        id
        name
        email
        documentLink
        updatedTime
        createdTime
      }
    }
  }
`;
