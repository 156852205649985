import gql from 'graphql-tag';

export const REQUEST_DOWNLOAD = gql`
  mutation requestDownloadFile($input: RequestDownloadFileInput!) {
    requestDownloadFile(input: $input)
  }
`;

export const DELETE = gql`
  mutation deleteDownloadRecord($input: DeleteDownloadRecordInput!) {
    deleteDownloadRecord(input: $input)
  }
`;
