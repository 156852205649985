import React from 'react';
import { Div, VerticalView } from 'vendors/Wrapper';
import { TitleWrapper } from 'vendors/CommonWrappers';
import { AppColors } from 'helpers';
import { useTranslationText } from 'locale';

type PageTitleProps = {
  color?: string;
  size?: string;
  title: string;
};
export const PageTitle = (props: PageTitleProps) => {
  const { translation } = useTranslationText();
  return (
    <Div fullWidth alignContent={'center'}>
      <VerticalView fullWidth alignContent={'center'}>
        <TitleWrapper size={props.size || '40px'} color={props.color || AppColors.DARK} align={'center'}>
          {translation(props.title)}
        </TitleWrapper>
      </VerticalView>
    </Div>
  );
};
