import React, { useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { AppColors, AppConst } from 'helpers';
import { useTranslationText } from 'locale';
import { useAppSetting } from 'hooks/useAppSetting';
import { Modal } from 'vendors/Modal';
import { HorizontalView, VerticalView } from 'vendors/Wrapper';
import { Input } from 'vendors/Input';
import { Button } from 'vendors/Button';
import { IMusic } from 'models/Music';
import MediaModal from 'components/common/components/Modals/MediaModal';
import { Label } from 'vendors/Label';
import { AudioPlayer } from 'vendors/Audio';
import { musicValidator } from 'validators/musicValidator';
import { MustLabel } from 'vendors/CommonWrappers';
import { RadioSelect } from 'vendors/Radio';
import { ImagePicker } from 'components/common/components/ImagePicker/ImagePicker';

interface Props {
  open: boolean;
  loading: boolean;
  music: IMusic | null;
  onChange: (music: IMusic) => void;
  onClose: () => void;
}

export const MusicModal = (props: Props) => {
  const { translation, getTypes } = useTranslationText();
  const { appTheme, appLayout } = useAppSetting();
  const [openMedia, setOpenMedia] = useState(false);
  const ref = React.useRef<FormikProps<IMusic>>(null);

  React.useEffect(() => {
    if (ref && ref.current && props.open) {
      if (props.music) {
        ref.current.setValues(props.music);
      } else {
        ref.current.setValues({} as IMusic);
      }
    }
  }, [props.music, props.open]);

  if (!props.open) {
    return null;
  }

  return (
    <Modal
      title={translation('home.category.header')}
      open={props.open}
      headerBackground={appTheme.sidebar.bg}
      height={appLayout.isMobile ? '100vh' : 'auto'}
      zIndex={AppConst.zIndex.header + 60}
      onClose={() => {
        props.onClose();
      }}
      closeOnBackDropClick
    >
      <HorizontalView
        width={appLayout.isMobile ? '100vw' : '600px'}
        fullHeight
        alignContent={'topCenter'}
        marginBottom={'10px'}
      >
        <Formik
          innerRef={ref}
          validationSchema={musicValidator}
          initialValues={{} as IMusic}
          onSubmit={(values: IMusic) => {
            props.onChange(values);
          }}
        >
          {({ values, handleChange, setFieldValue, submitCount, errors }) => {
            return (
              <VerticalView fullWidth alignContent={'centerLeft'} position={'relative'} padding={'20px'}>
                <VerticalView fullWidth paddingBottom={'20px'}>
                  <ImagePicker
                    required
                    maxImageWidth={256}
                    label={'musicPage.thumbnail'}
                    ratios={['1x1']}
                    width={'100%'}
                    height={'150px'}
                    value={values.thumbnail || ''}
                    onSelect={(url) => setFieldValue('thumbnail', url)}
                    error={submitCount ? errors.thumbnail : undefined}
                  />
                </VerticalView>
                {props.music && (
                  <VerticalView fullWidth paddingBottom={'20px'} alignContent={'centerLeft'}>
                    <RadioSelect
                      fontSize={'13px'}
                      defaultValue={{ value: values.status === 'active' }}
                      label={`musicPage.status`}
                      options={getTypes('segmentPublic')}
                      onChange={async (e) => {
                        setFieldValue('status', e?.value ? 'active' : 'inactive');
                      }}
                    />
                  </VerticalView>
                )}

                <VerticalView fullWidth paddingBottom={'20px'}>
                  <Input
                    required
                    id={'name'}
                    type={'text'}
                    value={values.name}
                    label={'musicPage.name'}
                    placeholder={'musicPage.name'}
                    error={submitCount ? errors.name : undefined}
                    onChange={handleChange}
                  />
                </VerticalView>
                <VerticalView fullWidth paddingBottom={'20px'}>
                  <Input
                    required
                    id={'authorName'}
                    type={'text'}
                    value={values.authorName}
                    label={'musicPage.author'}
                    placeholder={'musicPage.author'}
                    error={submitCount ? errors.authorName : undefined}
                    onChange={handleChange}
                  />
                </VerticalView>
                <VerticalView fullWidth paddingBottom={'20px'} alignContent={'centerLeft'}>
                  <HorizontalView>
                    <Label label="musicPage.mp3" />
                    <MustLabel>(*)</MustLabel>
                  </HorizontalView>

                  <HorizontalView fullWidth alignContent={'centerBetween'}>
                    {values.url?.length && (
                      <HorizontalView width="calc(100% - 70px)">
                        <AudioPlayer url={values.url} />
                      </HorizontalView>
                    )}
                    {submitCount > 0 && errors.url && <MustLabel>{translation(errors.url)}</MustLabel>}
                    <Button
                      title={values.url?.length ? 'musicPage.change' : 'musicPage.add'}
                      width={'60px'}
                      height={'40px'}
                      borderRadius={'10px'}
                      background={AppColors.GRASSY_GREEN}
                      color={AppColors.WHITE}
                      onClick={() => setOpenMedia(true)}
                    />
                  </HorizontalView>
                </VerticalView>
                <MediaModal
                  open={openMedia}
                  accept={'audio'}
                  onClose={() => setOpenMedia(false)}
                  onSelect={(url) => {
                    setOpenMedia(false);
                    setFieldValue('url', url);
                  }}
                />
                <HorizontalView fullWidth paddingTop={'10px'}>
                  <Button
                    disabled={props.loading}
                    loading={props.loading}
                    title={`${props.music ? 'save' : 'create'}`}
                    width={'100%'}
                    height={'45px'}
                    borderRadius={'10px'}
                    background={AppColors.ORANGE}
                    color={AppColors.WHITE}
                    onClick={() => {
                      if (ref.current) {
                        ref.current.handleSubmit();
                      }
                    }}
                  />
                </HorizontalView>
              </VerticalView>
            );
          }}
        </Formik>
      </HorizontalView>
    </Modal>
  );
};
