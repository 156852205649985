import { membershipMutations } from 'api/graphql/membership';
import { useBaseMutation } from 'api/hooks/base/useBaseMutation';
import { useTranslationText } from 'locale';
import { IMembership, IVerifyMembershipInput } from 'models/Membership';

export const useVerifyMembership = () => {
  const { getError } = useTranslationText();
  const { mutation, loading } = useBaseMutation<IVerifyMembershipInput, { verifyMembership: IMembership }>({
    mutation: membershipMutations.VERIFY_MEMBERSHIP,
  });

  async function onVerifyMembership(params: IVerifyMembershipInput) {
    const result = await mutation(params);
    if (result.error) {
      return { ...result, error: getError('ERROR_VERIFY_MEMBERSHIP') };
    }
    return result;
  }

  return {
    loading,
    onVerifyMembership,
  };
};
