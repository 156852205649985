import { Button } from 'vendors/Button';
import { AppColors, AppConst } from 'helpers';
import { useAppSetting } from 'hooks/useAppSetting';
import { useTranslationText } from 'locale';
import React from 'react';
import { Modal } from 'vendors/Modal';
import { SelectDropdown, SelectItem } from 'vendors/Select';
import { Div, VerticalView } from 'vendors/Wrapper';
import { TextAreaInput } from 'vendors/TextAreaInput';

type Props = {
  open: boolean;
  loading: boolean;
  status: string;
  statusTypes: string;
  onUpdate: (values: { status: string; reason: string }) => void;
  onClose: () => void;
};

export const ChangeStatusModal = (props: Props) => {
  const { translation, getTypes } = useTranslationText();
  const { appLayout, appTheme, appSize } = useAppSetting();
  const accStatus = getTypes<SelectItem[]>(props.statusTypes);
  const [status, setStatus] = React.useState(props.status);
  const [reason, setReason] = React.useState('');

  React.useEffect(() => {
    setStatus(props.status);
  }, [props.status]);

  return (
    <VerticalView>
      <Modal
        title={translation('changeStatus.title')}
        open={props.open}
        height={appLayout.isMobile ? '100vh' : 'auto'}
        zIndex={AppConst.zIndex.header + 60}
        onClose={() => {
          props.onClose();
        }}
        headerBackground={appTheme.sidebar.bg}
        closeOnBackDropClick
      >
        <VerticalView minWidth={'500px'} fullWidthHeight alignContent={'topCenter'}>
          <Div fullWidth padding="20px">
            <SelectDropdown
              value={status}
              options={accStatus}
              onChange={(value) => {
                setStatus(value);
              }}
            />
          </Div>
          <Div fullWidth padding="20px">
            <TextAreaInput
              width="100%"
              height="50px"
              label="changeStatus.reason"
              value={reason}
              onChange={(input) => {
                setReason(input.target.value);
              }}
            />
          </Div>
          <Div fullWidth alignContent="center" padding="20px">
            <Button
              title="changeStatus.update"
              preixIcon="save"
              loading={props.loading}
              borderRadius={'4px'}
              padding="10px 15px"
              fontSize={appSize.h3}
              fontWeight={600}
              background={AppColors.RED}
              color={'white'}
              onClick={() => {
                props.onUpdate({ status: status, reason: reason });
                props.onClose();
              }}
            />
          </Div>
        </VerticalView>
      </Modal>
    </VerticalView>
  );
};
