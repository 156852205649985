import React from 'react';
import styled from 'styled-components';
import { Div, HorizontalView } from 'vendors/Wrapper';
import { Button } from 'vendors/Button';
import { AppColors } from 'helpers';
import { VerticalView } from 'vendors/CommonWrappers';
import { FlatList } from 'vendors/FlatList';
import { useTranslationText } from 'locale';
import { Text } from 'vendors/Text';
import { Tag } from 'components/common/components/Tag';
import { IOptionType } from 'models/IOptionType';
import { Label } from 'vendors/Label';
import { SearchKeywordInput } from 'components/common/components/Input/SearchKeywordInput';

export const SearchWrapper = styled.div`
  border: 1px solid ${AppColors.BORDER};
  border-radius: 5px;
  overflow: hidden;
  width: 300px;
  margin-right: 20px;
`;

type SearchBarProps = {
  loading?: boolean;
  isServicePage: boolean;
  totalItems: number;
  onSearch: (params: string) => void;
  onChangeLimit: (limit: number) => void;
  onSelectTags: (tags: string[], type: string) => void;
  onCreate: () => void;
};

type Tag = {
  value: string;
  label: string;
  isSelect: boolean;
};

const ArticleType = (props: { onSelectTags?: (tags: string[], type: string) => void }) => {
  const { translation } = useTranslationText();
  const [type, setType] = React.useState<Tag[]>([]);

  React.useEffect(() => {
    setType([
      {
        value: 'active',
        label: translation('statusCollection.public'),
        isSelect: true,
      },
      {
        value: 'inactive',
        label: translation('statusCollection.unpublic'),
        isSelect: true,
      },
    ]);
  }, []);

  return (
    <HorizontalView fullWidth flexWrap={'nowrap'} alignContent={'centerLeft'}>
      <Div width={'170px'} alignContent={'centerLeft'}>
        <Text fontSize={'20px'}>{translation('status')}</Text>
      </Div>
      <FlatList
        values={type}
        direction={'column'}
        renderItem={(tag: Tag, index: number) => {
          return (
            <Div key={index} opacity={tag.isSelect ? 1 : 0.2} paddingTop={'3px'} paddingBottom={'3px'}>
              <Tag
                value={tag.value}
                label={tag.label}
                onClick={() => {
                  const _type = type.map((t) => {
                    if (t.value === tag.value) {
                      return { ...t, isSelect: !tag.isSelect };
                    }
                    return t;
                  });
                  setType(_type);
                  props.onSelectTags &&
                    props.onSelectTags(_type.filter((t) => t.isSelect)?.map((t) => t.value) || [], 'status');
                }}
              />
            </Div>
          );
        }}
      />
    </HorizontalView>
  );
};

const Categories = (props: { onSelectTags?: (tags: string[], type: string) => void }) => {
  const { translation, getTypes } = useTranslationText();
  const [tags, setTags] = React.useState<Tag[]>([]);
  const articleCategories: IOptionType[] = getTypes('articleCategories');
  React.useEffect(() => {
    setTags(
      articleCategories.map((category) => {
        return { ...category, isSelect: true } as Tag;
      }),
    );
  }, []);

  return (
    <HorizontalView fullWidth flexWrap={'nowrap'} alignContent={'centerLeft'} paddingTop={'10px'}>
      <Div width={'170px'} alignContent={'centerLeft'}>
        <Text fontSize={'20px'}>{translation('category')}</Text>
      </Div>
      <FlatList
        values={tags}
        direction={'column'}
        renderItem={(tag: Tag, index: number) => {
          return (
            <Div key={index} opacity={tag.isSelect ? 1 : 0.2} paddingTop={'3px'} paddingBottom={'3px'}>
              <Tag
                value={tag.value}
                label={tag.label}
                onClick={() => {
                  const _tags = tags.map((t) => {
                    if (t.value === tag.value) {
                      return { ...t, isSelect: !tag.isSelect };
                    }
                    return t;
                  });
                  setTags(_tags);
                  props.onSelectTags &&
                    props.onSelectTags(_tags.filter((t) => t.isSelect)?.map((t) => t.value) || [], 'categories');
                }}
              />
            </Div>
          );
        }}
      />
    </HorizontalView>
  );
};

export const CmsSearchArticleBar = (props: SearchBarProps) => {
  return (
    <>
      <VerticalView
        width={'100%'}
        padding={'20px'}
        background={AppColors.GRAY_LIGHT3}
        borderRadius={'5px'}
        border={`1px solid ${AppColors.GRAY_LIGHT}`}
        marginTop={'10px'}
      >
        <VerticalView fullWidth marginBottom={'20px'} alignContent={'centerLeft'}>
          <VerticalView paddingTop={'10px'} paddingBottom={'10px'}>
            <ArticleType onSelectTags={props.onSelectTags} />
            {!props.isServicePage && <Categories onSelectTags={props.onSelectTags} />}
          </VerticalView>
          <HorizontalView fullWidth alignContent={'centerLeft'} paddingTop={'20px'}>
            <SearchKeywordInput onSearch={props.onSearch} />
          </HorizontalView>
        </VerticalView>
      </VerticalView>
      <HorizontalView fullWidth alignContent={'centerBetween'} paddingTop={'20px'} paddingBottom={'20px'}>
        <Button
          loading={props.loading}
          title={'new'}
          height={'40px'}
          minWidth={'130px'}
          preixIcon={'plus'}
          background={AppColors.RED2}
          color={AppColors.WHITE}
          borderRadius={'5px'}
          onClick={props.onCreate}
        />
        <HorizontalView>
          <Text paddingRight={'20px'}>
            <Label label={'all'} />
            {` ${props.totalItems} `}
            <Label label={'item'} />
          </Text>
          {/* <SelectLimit onChangeLimit={props.onChangeLimit} /> */}
        </HorizontalView>
      </HorizontalView>
    </>
  );
};
