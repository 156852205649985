import React, { useState } from 'react';
import { useTranslationText } from 'locale';
import { useNavigate } from 'react-router';
import { openNotification } from 'vendors/Notification';
import { ProductCategory } from 'helpers/const';
import { CMSCategorySearchComponent } from '../../../../components/cms-interface/categories/search-category';
import { useCreateCategory, useDeleteCategory, useOperationSearchCategory } from 'api/hooks/category/useCategoryApi';
import { ICategory, ICreateCategoryInput } from 'models/Category';

export const CmsCategorySearchPage = () => {
  const navigate = useNavigate();
  const { translation } = useTranslationText();
  const [showCreate, setShowCreate] = useState(false);
  const {
    onSearchCategories,
    onChangeLimit,
    onChangePage,
    onDeleteCategoryInList,
    query,
    totalItems,
    categories,
    limit,
    page,
    totalPages,
    loading,
  } = useOperationSearchCategory();
  const { onCreateCategory, loading: createLoading } = useCreateCategory();
  const { onDeleteCategory, loading: deleteLoading } = useDeleteCategory();

  const onSearchBy = (tags: string[], type: string) => {
    if (type === 'categories') {
      const defaultSearch = [ProductCategory.Information, ProductCategory.News, ProductCategory.Notification];
      const categories = tags.length ? tags : defaultSearch;
      onSearchCategories({ ...query, ...{ [type]: categories } });
    } else {
      onSearchCategories({ ...query, ...{ [type]: tags } });
    }
  };
  const onCreate = async (input: ICreateCategoryInput) => {
    const { category, error } = await onCreateCategory(input);
    if (category) {
      navigate(`/cms/categories/${category.id}`);
    } else {
      openNotification({
        type: 'error',
        message: error?.title,
        description: error?.message,
      });
    }
  };
  const onDelete = async (id: string) => {
    const result = await onDeleteCategory({ id });
    if (result.category) {
      onDeleteCategoryInList({ id } as ICategory);
      openNotification({
        type: 'success',
        message: translation('message.delete'),
        description: translation('message.success'),
      });
    } else {
      openNotification({
        type: 'error',
        message: result.error?.title,
        description: result.error?.message,
      });
    }
  };

  const onDetails = (id: string) => {
    navigate(`/cms/categories/${id}`);
  };

  const onSearchKeyword = (keyword: string) => {
    onSearchCategories({ ...query, keyword });
  };

  return (
    <CMSCategorySearchComponent
      loading={loading}
      limit={limit}
      page={page}
      isServicePage={false}
      createLoading={createLoading}
      deleteLoading={deleteLoading}
      category={categories}
      currentPage={page}
      showCreate={showCreate}
      setShowCreate={setShowCreate}
      totalItems={totalItems}
      totalPages={totalPages}
      onSearch={onSearchKeyword}
      onSearchBy={onSearchBy}
      onDetails={onDetails}
      onCreate={onCreate}
      onDelete={onDelete}
      onChangeLimit={onChangeLimit}
      onChangePage={onChangePage}
    />
  );
};
