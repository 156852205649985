import i18n from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import { ja } from './ja';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import { IError, ISuccess } from 'models/Models';

export function initI18next() {
  i18n.use(initReactI18next).init({
    react: {
      useSuspense: false,
    },
    lng: 'ja',
    resources: {
      ja: {
        translation: ja,
      },
    },
    interpolation: {
      format: function (value, format) {
        if (value instanceof Date) {
          dayjs.locale(i18n.language);
          return dayjs(value).format(format);
        }
        return value;
      },
    },
  });
}

export const useTranslationText = () => {
  const { t } = useTranslation();

  function getMessage(code: string, type: 'error' | 'success'): IError | ISuccess {
    const path = type === 'success' ? `success.${code}` : `errors.${code}`;
    if (`${path}.message` !== t(`${path}.message`)) {
      return t(`${path}`, { returnObjects: true });
    } else {
      return { code: code, message: code } as IError;
    }
  }

  function getError(errorPath: string) {
    return t(`errors.${errorPath}`, { returnObjects: true }) as IError;
  }

  function getObject(path: string): { [key: string]: unknown } {
    return t(`global.${path}`, { returnObjects: true });
  }

  function translation(path: string): string {
    if (t(`global.${path}`) === `global.${path}`) console.log(`TRANSLATION ERRROR ${path}`);
    return t(`global.${path}`);
  }

  function getLang(): string {
    return i18n.language || 'ja';
  }

  function getTypes<tData>(path: string): tData {
    return t(`types.${path}`, { returnObjects: true }) as any;
  }

  return {
    translation: translation,
    getMessage: getMessage,
    getTypes: getTypes,
    getLanguage: getLang,
    getObject,
    getError,
  };
};
