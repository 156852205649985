import gql from 'graphql-tag';

export const PARSE_CSV = gql`
  mutation parseCSV($files: [Upload!]!, $table: String!) {
    parseCSV(files: $files, table: $table) {
      id
      title
    }
  }
`;

export const UPLOAD_MEDIA = gql`
  mutation uploadMedia($files: [Upload!]!) {
    uploadMedia(files: $files) {
      paths {
        id
        originalName
        mimetype
        src
        storagePath
      }
    }
  }
`;

export const DELETE_MEDIA = gql`
  mutation deleteMedia($input: DeleteMediaInput!) {
    deleteMedia(input: $input)
  }
`;
