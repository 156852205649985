import { Regex } from 'helpers/const';
import * as yup from 'yup';

export const createUserValidatorSchema = yup.object().shape({
  email: yup.string().matches(new RegExp(Regex.EMAIL), 'validator.emailInvalid').required('validator.requiredEmail'),
  password: yup
    .string()
    .min(8, 'validator.passwordMin')
    .max(20, 'validator.passwordMax')
    .matches(new RegExp(Regex.PASSWORD), 'validator.passwordInvalid')
    .required('validator.passwordRequired'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), undefined], 'validator.passwordNotMatch')
    .required('validator.passwordConfirmRequired'),
});

export const resetPasswordValidatorSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, 'validator.passwordMin')
    .max(20, 'validator.passwordMax')
    .matches(new RegExp(Regex.PASSWORD), 'validator.passwordInvalid')
    .required('validator.requiredPassword'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), undefined], 'validator.passwordNotMatch')
    .required('validator.requirePasswordConfirm'),
});

export const updateUserValidatorSchema = yup.object().shape({
  name: yup
    .string()
    .min(2, 'validator.nameMinMax')
    .max(16, 'validator.nameMinMax')
    .required('validator.requiredName')
    .nullable(),
});

export const changeIdentotyValidatorSchema = yup.object().shape({
  identityId: yup
    .string()
    .matches(new RegExp(Regex.IDENTITY_ID), 'validator.invalidIdentity')
    .min(4, 'validator.invalidIdentity')
    .max(30, 'validator.invalidIdentity')
    .required('validator.invalidIdentity'),
});

export const updateHelpValidator = yup.object().shape({
  forRole: yup.string().nullable().required('validator.requiredForRole'),
  category: yup.string().required('validator.requiredCategory').nullable(),
  title: yup.string().required('validator.requiredTitle').nullable(),
  content: yup.string().required('validator.requiredContent').nullable(),
});
