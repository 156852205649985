import React from 'react';
import { BaseComponent } from 'components/common/components/BaseComponent';
import { Div, HorizontalView, VerticalView } from 'vendors/Wrapper';
import { MessageBox } from 'components/common/components/MessageBox';
import { IMessage } from 'models/Models';
import { Label } from 'vendors/Label';
import { Image } from 'vendors/Image';
import { IMembership } from 'models/Membership';
import { useSession } from 'stores/userStore';
import { Text } from 'vendors/Text';
import dayjs from 'dayjs';
import { ButtonOutline } from 'components/common/components/Button/ButtonOutline';
import { PrimaryButton } from 'components/common/components/Button/PrimaryButton';
import { useNavigate } from 'react-router-dom';

type PropsType = {
  membership?: IMembership;
  loading: boolean;
  error?: IMessage;
};

export const CheckoutSuccessComponent = (props: PropsType) => {
  const { session } = useSession();
  const navigate = useNavigate();
  return (
    <BaseComponent contentLoading={props.loading}>
      <VerticalView fullWidthHeight padding="20px" alignContent="topCenter">
        {props.error && <MessageBox title={props.error?.title} message={props.error.message} />}
        {props.membership && (
          <VerticalView fullWidth alignContent="centerLeft" maxWidth="600px" position="relative">
            <Div position="absolute" left="-20px" top="-20px">
              <HorizontalView>
                <Image width="60px" src="/images/membership.png" />
              </HorizontalView>
            </Div>
            <VerticalView fullWidth alignContent="centerLeft" border="1px solid #ccc" padding="20px">
              <Label
                width="100%"
                marginTop="10px"
                textAlign="center"
                fontWeight={'bold'}
                label="checkout.membershipInfor"
              />
              <Div fullWidth height="1px" background="gray" opacity={0.2} marginTop="10px" />
              <Text marginTop="20px" fontWeight={'bold'}>
                {session.profile?.name}様
              </Text>
              {props.membership.isTrialPeriod && (
                <VerticalView fullWidth alignContent="centerLeft">
                  <Text>
                    Wakwork有料メンバーシップの試用期間は{' '}
                    <strong>{dayjs(props.membership.expiresDate).format('YYYY/MM/DD')}</strong>
                    までとなっております。
                  </Text>
                  <Text>
                    キャンセルなさらない限り、メンバーシップは自動的に継続され、お試し期間の終了する
                    <strong>{dayjs(props.membership.expiresDate).format('YYYY年MM月DD日 HH:mm')}</strong>
                    (日本時間帯)に月額料金が課金されます。
                  </Text>
                  <Text>
                    お試し期間の終了前にサブスクリプションをキャンセルされた場合、ご利用料金は発生いたしません。
                  </Text>
                </VerticalView>
              )}
              {!props.membership.isTrialPeriod && (
                <VerticalView fullWidth alignContent="centerLeft">
                  <Text>
                    以下の「{props.membership.localizedPlanName || props.membership.planName}
                    」を購入いただき、ありがとうございます。
                  </Text>
                  <Text>プラン名：{props.membership.localizedPlanName || props.membership.planName}</Text>
                  <Text>注文額：{props.membership.localizedPlanPrice || props.membership.planPrice}円</Text>
                  <Text>受領日：{dayjs(props.membership.purchaseDate).format('YYYY/MM/DD HH:mm')}</Text>
                  <Text>次の支払い日：{dayjs(props.membership.expiresDate).format('YYYY/MM/DD HH:mm')}</Text>
                  <Text>キャンセルなさらない限り、メンバーシップは自動的に更新されます。</Text>
                </VerticalView>
              )}

              {/* <Div fullWidth height="1px" background="gray" opacity={0.2} marginTop="20px" /> */}
              <HorizontalView fullWidth alignContent="centerAround" marginTop="20px">
                <ButtonOutline width="200px" title="checkout.gohome" marginTop="10px" onClick={() => navigate('/')} />
                <PrimaryButton
                  width="200px"
                  title="checkout.myProfile"
                  marginTop="10px"
                  onClick={() => navigate('/user/me')}
                />
              </HorizontalView>
            </VerticalView>
          </VerticalView>
        )}
      </VerticalView>
    </BaseComponent>
  );
};
