import React from 'react';
import { HorizontalView, VerticalView } from 'vendors/Wrapper';
import { Image } from 'vendors/Image';
import { Text } from 'vendors/Text';
import { dateUtil } from 'helpers/date.utils';

interface ConversationViewProps {
  title?: string | null;
  image: string;
  time: string;
  lastMessage?: string;
  isSeen?: boolean;
  onDetails: () => void;
}

export const ConversationView = (props: ConversationViewProps) => {
  return (
    <VerticalView fullWidth onClick={props.onDetails}>
      <HorizontalView fullWidth alignContent={'topLeft'}>
        <HorizontalView alignContent={'centerLeft'} borderRadius={'50%'} overflow={'hidden'}>
          <Image src={props.image} width={'40px'} height={'40px'} objectFit={'cover'} />
        </HorizontalView>
        <VerticalView width={'calc(100% - 50px)'} alignContent={'topLeft'} paddingLeft={'10px'}>
          <Text fontWeight={props.isSeen ? 'normal' : 'bold'} fontSize={'13px'}>
            {props.title}
          </Text>
          {Boolean(props.lastMessage?.length) && (
            <Text
              width={'95%'}
              fontSize={'13px'}
              textAlign={'left'}
              display={'inline-block'}
              textOverflow={'ellipsis'}
              whiteSpace={'nowrap'}
              overflow={'hidden'}
              fontWeight={props.isSeen ? 'normal' : 'bold'}
            >
              {props.lastMessage}
            </Text>
          )}
          <Text fontWeight={props.isSeen ? 'normal' : 'bold'} fontSize={'11px'}>
            {dateUtil.dateFormat(props.time, 'MM/DD HH:mm')}
          </Text>
        </VerticalView>
      </HorizontalView>
    </VerticalView>
  );
};
