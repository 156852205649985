import React from 'react';
import NewsComponent from 'components/user-interface/articles/article-search';
import { useSearchArticles } from 'api/hooks/article/useArticleApi';

export const ArticleListPage = () => {
  const { loading, articles, onLoadMoreArticles, hasMore, onSearchArticles } = useSearchArticles();

  return (
    <NewsComponent
      loading={loading}
      hasLoadMore={hasMore}
      articles={articles}
      onLoadMore={onLoadMoreArticles}
      onChangeCategory={(cate) => {
        onSearchArticles({
          page: 1,
          limit: 10,
          categories: [cate],
        });
      }}
    />
  );
};
