import ConversationsContainer from 'containers/cms-containers/chat/pages/room';
import React from 'react';
import { Routes, Route } from 'react-router';

export const ChatRouter = () => {
  return (
    <Routes>
      <Route path="/*" element={<ConversationsContainer />} />
    </Routes>
  );
};
