import * as mutations from './mutations';
import * as queries from './queries';

export const companyMutations = {
  ...mutations,
};

export const companyQueries = {
  ...queries,
};
