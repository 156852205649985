export const global = {
  copied: 'コピー',
  cancel: 'キャンセル',
  signout: 'サインアウト',
  signIn: 'ログイン',
  no: 'なし',
  helpTitle: 'ヘルプ',
  helpCenter: 'ヘルプセンター',
  helpMessage: 'お手伝いしましょうか？',
  searchResult: '検索ヒット数',
  success: '成功しました',
  error: '失敗しました',
  public: {
    login: {
      title: 'ログイン',
      email: 'メールアドレス',
      password: 'パスワード',
      resetPassword: 'パスワードをお忘れの方はこちら',
      forgetPassword: 'パスワードをお忘れですか？',
      register: 'アカウントをお持ちでない方は',
      registerMember: '新しいメンバーアカウントを作成する',
      registerAuthor: '新しい著者アカウントを作成する',
      button: 'こちら',
      loginButton: 'ログイン',
      saveAuto: 'ログイン情報を記憶',
      LoginLink: 'ログインこちら',
    },
    register: {
      code: '郵便番号は 7 文字です',
      title: '法人のアカウントを作成する',
      authorTitle: '新規著者登録こちら',
      verify: 'アカウントの確認',
      completedMessage:
        'ご登録いただきましたメールアドレスへ会員登録を完了するためのURLをお送りしました。お手数ですが、メールのご確認をお願いいたします。',
      email: 'メールアドレス',
      password: 'パスワード',
      confirmPassword: '確認パスワード',
      button: 'こちら',
      registButton: '登録する',
      backToLogin: 'ログインに戻る',
      gohome: 'トップページへ移動する',
      success: 'アカウント登録が成功しました',
      step1: '登録情報',
      step2: 'アカウント認証',
      companyRepresentativeName: '代表者',
      postalCode: '郵便番号',
      stateOrCityName: '都道府県',
      buildingNameAndRoomName: '番地、丁・町村、建物名・部屋番号',
      telephone: '電話番号',
      bussinessContent: '事業内容',
      companyDescription: 'PR',
      companyName: '会社名',
      companyFoundation: '設立',
      businessContent: '事業内容',
      emailExist: 'メールが存在しております。もう一度確認ください。',
    },
    about: {
      header: '会社概要',
    },
    serviceList: {
      header: '事業内容',
      description:
        '当社は、日本とベトナムの架け橋となって健康食品の貿易、ビジネス支援、広告代行などのサービスを提供します。',
    },
    contact: {
      contactDetails: '連絡先の詳細',
      contactHeader: 'お問い合わせ',
      contactName: 'お名前',
      contactEmail: 'メール',
      contactTime: '予約時間',
      contactPhone: '電話番号',
      contactSubject: '件名',
      contactContent: '内容',
      contactDirect: '予約時間',
      sendContact: '送る',
      sendContactSuccess: '送りました。',
      sendContactError: 'エラーが発生しました。もう一度やり直してください。',
      contactMessage: 'お問い合わせいただきありがとうございます. 登録日時までにご連絡いたします。',
      contactMessage1: '※ 全ての時間帯に「×」が表示されている場合は、一度ページを再読み込みしてください',
      contactMessage2: '※ 解決しない場合は「contact@global-medipharm.jp」までご連絡ください。',
      contactMessage3: '下記カレンダーから、ご希望の時間帯をクリックしてください。所要時間は25分を予定しております。',
      contactMessage4: '※ 通常、ご連絡頂いてから2営業日以内にご返信します',
      contactMessage5: 'ご不明な点などございましたら、弊社サポートセンターまでお問い合わせください。',
      contactPolicyMessage: '個人情報の取り扱いに同意の上、送信しますか？',
      contactInvalidContent: '入力内容に不備があります。再度ご確認ください。',
      contactSentMessage: 'このたびは、お問い合わせいただき誠にありがとうございます。',
      contactSentMessage2:
        'お問い合わせいただいた内容につきましては後ほど担当者よりご案内を差し上げます。今しばらくお待ちくださませ。',
      contactSentMessage3: 'なお、内容によりご返信にお時間をいただく場合がございます。あらかじめご了承ください。',
    },
    articleDetails: {
      header: '記事詳細',
      description: '',
    },
    articlesList: {
      header: '記事',
      searchTitle: '記事検索',
      description: '記事、テクノロジーに関する情報の更新',
      news: {
        header: 'NEWS',
        subHeader: 'お知らせ',
        seeMore: 'お知らせ一覧',
        newsTab1: '通知',
        newsTab2: '求人者向け',
        newsTab3: '求職者向け',
      },
    },
    privacy: {
      header: '使用条件',
      title: '使用条件',
    },
  },
  home: {
    category: {
      id: 'ID',
      name: 'カテゴリー名',
      list: 'カテゴリー一覧',
      header: 'カテゴリー',
      icon: 'アイコン',
      title: 'タイトル',
      description: '説明',
      create: 'カテゴリー作成',
      edit: '保存する',
      alertDelete: {
        message: 'カテゴリーの削除を実施してもよろしですか？',
        close: 'キャンセル',
        delete: '削除',
      },
    },
  },
  userList: {
    addNew: 'ユーザーを追加する',
    header: 'ユーザー管理',
    description: 'ユーザー一覧',
    title: '会員のお客様',
    searchbar: {
      title: 'ユーザー検索',
      keyword: 'キーワード',
    },
    alertDelete: {
      message: 'ユーザーの削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
  },
  accountStatus: {
    mailVerified: 'あなたのメールアドレスは確認済みです',
    active: '検証済み',
    blocked: 'ブロック',
    inactive: '検証なし',
    restrict: '制限',
    suspended: '停止',
    not_approved: '拒否',
  },
  usersSearch: {
    header: 'ユーザー一覧',
    customerHeader: '企業一覧',
    avatar: 'アバター',
    information: '情報',
    email: 'メール',
    name: '名前',
    isLocked: '無効',
    isVerified: '検証',
    role: '権限',
    member: 'メンバー',
    status: '承認',
    searchbar: {
      title: '投稿検索',
      keyword: 'キーワード',
      condition: '検索条件クリア',
      buttonSearch: '検索',
    },
    alertDelete: {
      message: 'アカウントの削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
  },
  createUser: {
    title: '新規アカウント',
    email: 'メールアドレス',
    password: 'パスワード',
    confirmPassword: '確認パスワード',
    create: '作成する',
    role: '権限',
  },
  common: {
    nodata: 'データなし',
    topMenu: {
      notification: '新着情報',
      about: '会社概要',
      services: '事業内容',
      articles: '記事',
      supplement: 'サプリメント',
      cosmetic: '化粧品',
      news: 'ニュース',
    },
  },
  modals: {
    confirmLogout: {
      title: 'アカウントからサインアウトしますか？',
      close: 'いいえ',
      confirm: 'サインアウト',
    },
    confirmDelete: {
      message: 'データの削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
  },
  searchContacts: {
    all: '全',
    item: '件',
    name: 'お名前',
    email: 'メール',
    time: '予約時間',
    phone: '電話番号',
    subject: '件名',
    content: '内容',
    information: '情報',
    contactDirect: '予約時間',
  },
  searchArticles: {
    category: 'カテゴリー',
    header: '投稿一覧',
    headerService: 'サービス一覧',
    title: 'タイトル',
    description: '説明',
    public: '公開',
    content: '内容',
    preview: 'プレビュー',
    thumbnail: 'サムネイル',
    cover: '表紙画像',
    status: 'スターテス',
    date: '時間',
    information: '情報',
    searchbar: {
      title: '投稿検索',
      keyword: 'キーワード',
      condition: '検索条件クリア',
      buttonSearch: '検索',
    },
    alertDelete: {
      message: '投稿の削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
  },
  editArticle: {
    category: 'カテゴリー',
    header: '投稿',
    title: 'タイトル',
    description: '説明',
    public: '公開',
    content: '内容',
    preview: 'プレビュー',
    thumbnail: 'サムネイル',
    cover: '表紙画像',
    status: 'スターテス',
  },
  statusCollection: {
    all: 'すべて',
    new: '新しい',
    inReview: '',
    pending: '承認待ち',
    approved: '承認済み',
    public: '公開',
    unpublic: '非公開',
    reject: '差し戻し',
  },
  mediaCenter: {
    title: 'メディアセンター',
    mediaList: 'メディアリスト',
  },
  cropImageModal: {
    title: 'クロップ画像',
    selectRatio: '選択してアップロード',
  },
  media: {
    title: 'メディア',
    message: '選択する',
    deleteAlert: {
      message: 'メディアの削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
  },
  company: {
    privacy: '個人情報保護方針',
    header: '会社情報の編集',
    profile: 'プロフィール',
    companyName: '会社名',
    foundation: '設立',
    androidAppUrl: 'ANDROID URL',
    iosAppUrl: 'IOS URL',
    representatorName: '代表者',
    tax: '資本金',
    postalCode: '郵便番号',
    city: '都道府県',
    streetAddress: '番地、丁・町村',
    building: '建物名・部屋番号',
    phone: '携帯電話番号',
    homePhone: '電話番号',
    email: 'メール',
    bussinessContent: '事業内容',
    mapUrl: '地図のリンク',
    description: 'PR',
    save: '保存する',
    copyRight: 'CopyRight',
    create: '企業プロフィール作成',
    termOfService: '使用条件',
    comercialLaw: '特定商取引法に基づく表記',
  },
  message: {
    create: 'データ作成',
    update: 'データ更新',
    delete: 'データ削除',
    error: '失敗しました',
    success: '成功しました',
    errorMessage1: 'エラーが発生しました。もう一度やり直してください',
  },
  invalidDataAlert: {
    message: 'データが無効です。入力したフィールドを確認してください',
    close: '閉じる',
  },
  validator: {
    emailInvalid: '無効なメールアドレス',
    passwordRequired: 'パスワードは必須項目です。',
    passwordConfirmRequired: '確認パスワードは必須項目です。',
    passwordMin: 'パスワードは8文字以上で入力してください。',
    passwordMax: 'パスワードは20文字以内で入力してください。',
    passwordInvalid: 'パスワードは半角英数記号(英数混合必須)で入力してください。',
    passwordNotMatch: '２つのパスワードが一致していません。',
    requiredEmail: 'メールアドレスは必須項目です。',
    invalidEmail: 'メールアドレスを正しく入力してください。',
    requiredForRole: '役割は必須項目です。',
    requiredSeoName: 'seonameは必須項目です。',
    requiredCategory: 'カテゴリーは必須項目です。',
    requiredThumbnail: 'サムネイルは必須項目です。',
    requiredTitle: 'タイトルは必須項目です。',
    requiredContent: '内容は必須項目です。',
    nameMinMax: '名前は2〜16文字で入力してください',
    requiredName: '名前は必須項目です。',
    requiredId: 'IDは必須項目です。',
    requiredImage: '画像は必須項目です。',
    requiredAddress: '住所は必須項目です。',
    requiredGender: '性別は必須項目です。',
    requiredDescription: '説明は必須項目です。',
    requiredSubject: 'タイトルは必須項目です。',
    requiredPhone: '電話番号は必須項目です。',
    requiredAgree: '送信確認のチェックを選択してください。',
    requiredDatetime: '予約時間が必要です。',
    requiredType: 'タイプが必要です。',
    requiredTarget: 'ターゲットが必要です。',
    requiredDuration: '期間は必須項目です。',
    requiredBanner: 'バナーは必須項目です。',
    requiredPrice: '価格は必須項目です。',
    requiredPriceId: '価格IDは必須項目です。',
    requiredCourseDuration: '数日は必須項目です。',
    requiredCourseDurationMin: '数日は1日以上で入力してください',
    requiredLesson: 'レッスンは必須項目です。',
    requiredSkill: 'スキルは必須項目です。',
    requiredSyllabus: 'シラバスは必須項目です。',
    requiredChapter: 'チャプターは必須項目です。',
    requiredAnswer: '回答は必須項目です。',
    requiredQuestion: '質問は必須項目です。',
    requiredAudio: 'Audioは必須項目です。',
    requiredCheckedAnswer: '正しい答えを選択してください',
    requiredExamTime: 'テスト時間は必須項目です。',
    requiredCourse: 'コースは必須項目です。',
    requiredSection: 'セクションは必須項目です。',
    requiredExamLesson: 'レッスンを選択してください (最初にコースを選択する必要があります)',
    articleTitleMinMax: 'タイトルは5〜60文字で入力してください',
    articleDescriptionMinMax: 'タイトルは10〜120文字で入力してください',
    articleContentMinMax: 'タイトルは10〜文字で入力してください',
    requiredAuthorName: '著者名は必須項目です。',
    requiredMp3: 'MP3は必須項目です。',
    requiredIdentity: 'ニックネームは必須項目です。',
    invalidIdentity:
      'ニックネームは4〜30文字(アルファベット英語,「.」または「-」で区切る」) で入力してください.　礼：maruko.aichi.12',
    companyNameRequired: '会社名は必須項目です。',
    companyFoundationRequired: '設立は必須項目です。',
    postalCodeRequired: '郵便番号は必須項目です。',
    companyRepresentativeName: '代表者は必須項目です。',
    stateOrCityNameRequired: '都道府県は必須項目です。',
    businessContentRequired: '仕事内容は必須項目です。',
  },

  userDetails: {
    nickname: 'ニックネーム',
    changeNickname: '変更',
    invoice: '領収書',
    changeNicknameSuccess: '変更しました',
    information: '配送先情報',
    account: 'アカウント',
    title: 'アカウント情報',
    email: 'メール',
    name: '名前',
    gender: '性別',
    birthday: 'お誕生日',
    address: '住所',
    isLocked: '無効',
    isVerified: '検証',
    role: '権限',
    changePass: 'パスワードの変更',
    changePassFinished: 'パスワードの変更が完了しました。引き続きサービスをお使いください',
    save: '変更する',
    details: '詳細',
    addCourse: 'コースを追加する',
    isAuthorVerified: '検証著者',
    deleteCourseMsg: 'コースの削除を実施してもよろしですか？',
    totalFollowing: 'フォロー中',
    totalFollowers: 'フォロワー',
    avatar: '写真',
    status: '承認',
    plan: 'プラン',
    planName: 'プラン名',
    price: '値段',
    date: '期限',
    billing: '課金',
    billingmanager: '課金管理',
    nextPayment: '次の更新',
    membershipInfor: 'メンバーシップ情報',
    usingGooglePayment: 'Google Playのお支払いを使用しています。',
    seeDetails: 'Google Play のお支払い方法を追加、削除、編集する方法',
    resetPasswordModal: {
      header: 'パスワードのリセット',
      password: '新パスワード',
      confirmPassword: '新パスワード (確認用)',
      resetPassword: 'リセットする',
    },
    alertChangeNickname: {
      message: 'ニックネームの変更を実施してもよろしですか？',
      close: 'キャンセル',
      change: '変更',
    },
  },
  membership: {
    membership: 'メンバーシップ',
    membershipIsExpired: '期限切れ',
    nextPayment: '次の更新',
    extendPlan: '期限を延ばす',
    changePlan: 'プランの変更',
    totalDownload: '全てダウンロード',
    from: 'から',
    to: 'まで',
    addPlan: 'プランの追加',
    noPlan: 'なし',
    expireDate: '期限',
    cancel: 'キャンセル',
    cancelSubcription: 'キャンセル',
    price: '値段',
    date: '期限',
    plan: 'プラン',
    freeTrial: '無料試用',
    packageId: 'プランID',
    isFreeTrial: '無料試用',
    extendSuccess: '成功しました',
    extendError: '失敗しました',
    changeSuccess: '成功しました',
    changeError: '失敗しました',
    pagekageNotFound: 'プランIDが無効',
    cancelSuccess: 'プランのキャンセルが成功しました。',
    alertCancel: {
      message: 'プランのキャンセルを実施してもよろしですか？',
      close: '閉じる',
      ok: '実施する',
    },
  },
  page404: {
    title: '',
    goHome: 'ホームへ戻る',
    description: '申し訳ありません。お探しのページが見つかりません。',
  },
  ja: '日本語',
  vi: 'ベトナム語',
  en: '英語',
  membershipWarning:
    '有料メンバーシップではないため、求人動画作成・公開ができません。既に公開済みの全ての動画が非公開になります。引継ぎご利用を希望する場合、メンバーシッププランをご購入ください。',
  privacyPolicy: '使用条件',
  keyword: 'キーワードを入力します',
  close: '閉じる',
  new: '作成する',
  delete: '削除',
  edit: '編集',
  type: '種類',
  item: '件',
  all: 'すべて',
  search: '検索する',
  status: 'スターテス',
  category: 'カテゴリー',
  contactList: 'コンタクトマネージャー',
  save: '保存',
  back: '戻る',
  dateType: 'YYYY/MM/DD',
  pagination: 'ページ',
  details: '詳細',
  services: 'サービスの強み',
  seeMore: '続きを見る',
  forEmployer: '企業の方はこちら',
  forEmployee: '求職者はこちら',
  super_admin: '管理者',
  user: 'メンバー',
  customer: '企業',
  alertDeleteHelp: 'このヘルプを削除しますか？',
  logo: 'ロゴー',
  cover: 'カバー',
  seoname: 'seoname',
  helpFor: 'ヘルプのために',
  contactDetails: 'コンタクトの詳細',
  publicTitle: '公衆',
  helpEdit: 'ヘルプの編集',
  language: '言語',
  blockedReason: 'ブロックされた理由',
  employerPage: {
    newVideosEmployee: '新着求職者のビデオ',
    merit: '動画で求人を出すメリット',
    serviceDetails: 'サービスについて詳しく知りたい方',
    plan: '自社に合ったプランを知りたい方',
    btnDownload: '資料を請求',
    btnPlan: 'プランを問い合わせる',
  },
  employeePage: {
    newVideosEmployer: '新着求人者のビデオ',
  },
  changePassword: {
    title: 'パスワードの変更',
    newPassword: '新しいパスワード',
    confirmNewPassword: '確認パスワード',
    save: '変更する',
  },
  changeStatus: {
    button: 'ステータス',
    title: 'ステータスを変更する',
    update: '承認',
    reason: '理由',
    success: 'ステータスを変更しました',
    error: 'ステータスの変更は失敗しました',
  },
  videoPage: {
    header: '動画検索',
    userId: 'ユーザーID',
    userName: 'ユーザー名',
    createdTime: 'アップロード時間',
    name: 'ビデオ名',
    description: '説明',
    detail: 'ビデオ詳細',
    content: '仕事内容',
    salary: '給料',
    salaryPayType: '給与支払いタイプ',
    salaryPayTypeValue: {
      hour: '時給',
      day: '日給',
      month: '月給',
      percent: '歩合給',
    },
    employmentType: '雇用形態',
    employmentTypeValue: {
      manager: '役員',
      fulltime: '正社員',
      contract: '契約社員',
      temporary: '派遣社員',
      parttime: 'アルバイト・パート',
      subcontracting: '業務委託',
      other: 'その他',
    },
    workLocation: '勤務地',
    workTime: '勤務時間',
    category: '職種: ',
    expireDate: '有効期間',
    type: {
      title: '種類',
      video: 'ビデオ',
      job: '仕事',
    },
    status: {
      title: 'ステータス',
      active: '公開',
      inactive: '非公開',
      blocked: 'ブロック',
      pending: '検証待ち',
      reject: '拒否',
    },
  },
  musicPage: {
    header: '音楽リスト',
    thumbnail: 'サムネイル',
    name: '曲名',
    status: 'ステータス',
    author: '著者',
    create: '作成する',
    save: '保存する',
    url: 'URL',
    mp3: 'MP3',
    change: '変更',
    add: '追加',
    createSuccess: '曲を追加しました',
    deleteSuccess: '曲を削除しました',
    updateSuccess: '曲を保存しました',
    alertDelete: {
      message: '曲の削除を実施してもよろしですか？',
      close: 'キャンセル',
      delete: '削除',
    },
  },
  emailModal: {
    title: 'メール送り',
    btnSend: '送信',
    btnSendAll: '全員送信',
    sendTo: '宛先',
    all: '全員',
    subject: '件名',
    content: '内容',
    sent: 'メールを送信しました',
    sendFailed: '送信できないメールが発生しました。',
    sendFailedMessage: '再送信しますか？',
    btnSendAgain: '再送信',
  },
  downloadAppModal: {
    title: 'アプリを使用するともっと便利',
  },
  chat: {
    send: '送る',
    meessagePlaceholder: 'メッセージを入力してください',
    noMessage: 'メッセージはありません',
    noRoomChat: 'チャットはありません',
  },
  revenue: {
    buyDate: '購入日',
    title: '収入統計',
    total: '総収入',
    date: '日付',
    download: 'ダウンロード',
    userName: 'ユーザー名',
    price: '金閣',
    platform: 'プラットフォーム',
    receipt: '領収書',
  },
  yen: '円',
  watchVideo: '動画を見る',
  comment: 'コメント',
  create: '作成',
  processing: '処理中',
  newsTitle: '記事',
  sharePost: '投稿を共有してください',
  updateSuccess: '更新完了',
  setting: {
    header: 'ホームページ設定',
    images: 'トップイメージ',
    homePage: 'ホームページ',
    employerPage: '求人者のページ',
    employeePage: '求職者のページ',
    downloadLink: '資料ダウンロード',
    link: 'https://',
    feature: '特殊',
    addFeature: '特殊を追加する',
    featureTitle: 'タイトル',
    featureDescription: '説明',
  },
  productPage: {
    header: 'プラン',
    name: 'プラン名',
    platform: 'プラットフォーム',
    type: 'タイプ',
    description: '説明',
    price: '価格（円）',
    packageId: 'ID',
    alertDelete: 'プランの削除を実施してもよろしですか？',
    deleteSuccess: 'プランを削除しました',
    createSuccess: 'プランを追加しました',
    updateSuccess: 'プランを変更しました',
  },
  verifyAccount: {
    title: 'アカウントの確認',
    message: 'アカウントの確認に成功しました',
    login: 'ログイン',
    error: 'アカウントの確認に失敗しました',
  },
  resetPassword: {
    title: 'パスワードのリセット',
    completed: 'パスワードのリセットが完了しました。 メールでチェックしてください',
    email: 'メールアドレス',
    button: 'こちら',
    reset: 'リセットする',
    home: 'トップページ',
    hasAccount: 'アカウントをお持ち方は',
    noAccount: 'アカウントをお持ちでない方は',
    password: '新しいパスワード',
    confirmPassword: '新しいパスワードを再入力してください',
  },
  plan: {
    title: 'WAKWORKのプラン',
    description: '自分に最適なプランを選択する',
    choosePlan: 'プランを選択する',
    yourPlan: 'あなたのプラン',
    basic: {
      title: '基本プラン',
      price: '1ヵ月19,130円',
      features: ['1ヶ月お試し', '自動的に1ヶ月毎更新', '無制限のアップロード動画'],
    },
    medium: {
      title: 'ミディアムプラン',
      price: '3ヵ月54,800円',
      features: ['1週間お試し', '自動的に3ヶ月毎更新', '無制限のアップロード動画'],
    },
    pro: {
      title: 'プロプラン',
      price: '3ヵ月64,800円',
      features: ['1ヶ月お試し', '自動的に3ヶ月毎更新', '無制限のアップロード動画'],
    },
  },
  checkout: {
    title: 'チェックアウト',
    waiting: 'しばらくお待ちください',
    membershipInfor: 'メンバーシップ情報',
    gohome: 'トップページ',
    myProfile: 'プロフィールページ',
    successMessage: {
      title: 'プロプラン',
      message: '',
    },
  },
  report: {
    header: '報告',
    title: 'タイトル',
    type: 'タイプ',
    ReportUser: 'ユーザー',
    ReportVideo: 'ビデオ',
    reasonType: '理由',
    reporter: 'リポーター',
    detail: '報告詳細',
    reportUser: 'ユーザー',
    content: '内容',
    reportedUser: '報告された人',
    reportedVideo: '報告されたビデオ',
    reasons: {
      rule: 'ポリシー違反',
      spam: 'スパム',
      trouble: '問題',
      sex: 'ヌードまたは不健康な活動',
      violence: '暴力',
      other: 'その他',
    },
  },
  createdTime: '作成時間',
  businessRegister: 'ビジネスアカウントを作る',
  accountDelete: {
    title: '非アクティブ化中',
    message: 'アカウントは現在非アクティブ化中です',
    timeLeft: '残り時間',
  },
  downloadDocModal: {
    title: '資料ダウンロード',
    description: 'ダウンロードのリンクはメールで送りますので、\nお名前とメールアドレスを入力してください',
    btnDownload: '送る',
    successMessage: 'リンクを送りました',
  },
  docDownloaded: {
    header: '資料ダウンロード',
    deleteMessage: '削除しますか',
  },
};
