import gql from 'graphql-tag';

export const CREATE = gql`
  mutation createVideo($input: CreateVideoInput!) {
    createVideo(input: $input) {
      id
      type
      status
    }
  }
`;

export const DELETE = gql`
  mutation deleteVideo($input: DeleteVideoInput!) {
    deleteVideo(input: $input)
  }
`;

export const UPDATE = gql`
  mutation updateVideo($input: UpdateVideoInput!) {
    updateVideo(input: $input) {
      id
    }
  }
`;

export const CHANGE_STATUS = gql`
  mutation changeVideoStatus($input: ChangeVideoStatusInput!) {
    changeVideoStatus(input: $input)
  }
`;
