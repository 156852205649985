import React from 'react';
import { ChattingComponent } from 'components/cms-interface/chat/chatting';
import { IUser } from 'models/User';
import { useChatting } from '../../hooks/useChatting';

export const ChattingContainer = () => {
  const { messages, hasMore, loading, onLoadEarlier, onSend } = useChatting();

  return (
    <ChattingComponent
      hasMore={hasMore}
      isLoadMore={Boolean(messages?.length && loading)}
      loading={Boolean(!messages?.length && loading)}
      auth={{} as IUser}
      messages={messages}
      onSend={onSend}
      onVisiableInView={() => {}}
      onLoadEarlier={onLoadEarlier}
    />
  );
};

export default ChattingContainer;
