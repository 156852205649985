import { useCreateBusiness } from 'api/hooks/business/useBusinessApi';
import { CreateCompanyComponent } from 'components/cms-interface/customer/create';
import { useTranslationText } from 'locale';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { openNotification } from 'vendors/Notification';

export const CreateBusinessContainer = () => {
  const { createBusinessAccount, loading } = useCreateBusiness();
  const navigate = useNavigate();
  const { translation } = useTranslationText();

  return (
    <CreateCompanyComponent
      loading={loading}
      onCreate={async (input) => {
        const result = await createBusinessAccount(input);

        if (!result.error) {
          navigate(`/cms/customer/${result.user?.id}`);
        } else {
          openNotification({
            type: 'error',
            message: result.error?.title || translation('message.create'),
            description: result.error?.message || translation('message.error'),
          });
        }
      }}
    />
  );
};
