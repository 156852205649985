import React from 'react';
import styled from 'styled-components';
import { HorizontalView } from '../Wrapper';
import { Text } from '../Text';

const SegmentWrapper = styled.div<{ background?: string; borderRadius?: string }>`
  overflow: hidden;
  background: ${(props) => (props.background ? props.background : 'transparent')};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '0px')};
`;

const ItemWrapper = styled.div<{ background?: string }>`
  background: ${(props) => (props.background ? props.background : 'white')};
  border: 1px solid ${(props) => (props.background ? props.background : 'white')};
  min-width: 100px;
  min-height: 40px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`;

export type SegmentItem = {
  label: string;
  value: string;
};

export type SegmentProps = {
  items: SegmentItem[];
  background: string;
  color: string;
  colorSelect: string;
  tintColor: string;
  value?: string;
  borderRadius?: string;
  onSelect?: (index: number) => void;
};

export const SegmentControl = (props: SegmentProps) => {
  const [itemSelected, setItemSelected] = React.useState(0);

  React.useEffect(() => {
    if (props.value) {
      props.items.forEach((item, index) => {
        if (item.value === props.value) {
          setItemSelected(index);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  return (
    <SegmentWrapper background={props.background} borderRadius={props.borderRadius}>
      <HorizontalView width={'100%'} height={'100%'} alignContent={'centerLeft'}>
        {props.items.map((item, index) => {
          return (
            <ItemWrapper
              key={index}
              background={itemSelected === index ? props.tintColor : 'transparent'}
              onClick={(e) => {
                e.preventDefault();
                setItemSelected(index);
                props.onSelect && props.onSelect(index);
              }}
            >
              <Text fontSize={'13px'} fontWeight={600} color={itemSelected === index ? props.colorSelect : props.color}>
                {item.label}
              </Text>
            </ItemWrapper>
          );
        })}
      </HorizontalView>
    </SegmentWrapper>
  );
};
