import { useSocket } from 'context/SocketContext';
import {
  IChatMessage,
  IChatMessageFilter,
  IRoom,
  IRoomFilter,
  SocketEvent,
} from 'containers/cms-containers/chat/type/Chat';
import { useCallback, useContext } from 'react';
import { ChatContext } from '../context';
import { useSession } from 'stores/userStore';
import { IError } from 'models/Models';

export const useChatManager = () => {
  const { socket } = useSocket();
  const { session } = useSession();
  const { state, dispatch } = useContext(ChatContext);

  /**
   * Join chatting screen
   */
  const onJoinRoom = useCallback(
    (roomId: string) => {
      const room = state.roomList.rooms.find((room) => room.id === roomId);
      if (room) {
        dispatch({ type: 'join-room', room: room });
      } else {
        socket.emit(SocketEvent.JoinRoom, { id: roomId }, (data: { error: IError; room: IRoom }) => {
          dispatch({ type: 'join-room', room: data.room });
        });
      }
    },
    [session, state],
  );

  /**
   * Load load messages earliers after joined room
   */
  const loadMessages = (filter: IChatMessageFilter) => {
    dispatch({ type: 'loading-messages', loading: true });
    socket.emit(SocketEvent.MessagesEarliers, filter, (data: { totalItems: number; items: IChatMessage[] }) => {
      dispatch({ type: 'add-messages-earliers', messages: data.items, totalMessages: data.totalItems });
      dispatch({ type: 'loading-messages', loading: false });
    });
  };

  /**
   * Load rooms list
   */
  const onLoadRooms = (filter: IRoomFilter) => {
    socket.emit(SocketEvent.SearchRooms, filter, (data: { totalItems: number; items: IRoom[] }) => {
      dispatch({ type: 'add-rooms', rooms: data.items, totalRoms: data.totalItems });
    });
  };

  /**
   * Leave chatting screen
   */
  const onLeaveRoom = (roomId: string) => {
    socket.emit(SocketEvent.LeaveRoom, { id: roomId });
    dispatch({ type: 'leave-room', roomId: roomId });
  };

  /**
   * User send a message
   */
  const onSendMessage = useCallback(
    (messageSend: IChatMessage, roomId: string, callback: (message: IChatMessage | null) => void) => {
      socket.emit(
        SocketEvent.SendMessage,
        { ...messageSend, roomId },
        (data: { error: unknown; message: IChatMessage }) => {
          if (data.message) {
            dispatch({ type: 'add-message', message: data.message });
          }
          callback(data.message);
        },
      );
    },
    [session],
  );

  const onLoadMessagesEarliers = useCallback(() => {
    if (state.currentRoom && state.currentRoom.room) {
      const messages = state.currentRoom.messages;
      loadMessages({
        roomId: state.currentRoom.room.id,
        limit: state.currentRoom.limit,
        lastMessageTime: messages[state.currentRoom.messages.length - 1]?.createdTime,
      });
    }
  }, [state.currentRoom]);

  return { state, dispatch, onJoinRoom, onLeaveRoom, onSendMessage, onLoadMessagesEarliers, loadMessages, onLoadRooms };
};
