import { AppColors, AppConst } from 'helpers';
import { useAppSetting } from 'hooks/useAppSetting';
import { useTranslationText } from 'locale';
import React from 'react';
import { Modal } from 'vendors/Modal';
import { Div, HorizontalView, VerticalView } from 'vendors/Wrapper';
import { Image } from 'vendors/Image';
import { Button } from 'vendors/Button';
import { useGetAppLink } from 'api/hooks/home/useGetAppLink';

type Props = {
  open: boolean;
  onClose: () => void;
  onDetail: () => void;
};

export const DownloadAppModal = (props: Props) => {
  const { translation } = useTranslationText();
  const { appLayout } = useAppSetting();
  const { iosLink, androidLink } = useGetAppLink();

  return (
    <Div>
      <Modal
        title={translation('downloadAppModal.title')}
        open={props.open}
        zIndex={AppConst.zIndex.header + 60}
        onClose={() => {
          props.onClose();
        }}
        headerBackground={AppColors.PRIMARY}
        closeOnBackDropClick
      >
        <VerticalView
          minWidth={appLayout.isMobile ? '300px' : '500px'}
          fullWidthHeight
          alignContent={'topCenter'}
          position="relative"
          padding="30px"
        >
          <VerticalView fullWidth position={'relative'} alignContent={'center'} zIndex={1}>
            <HorizontalView fullWidth>
              <Div width={'100%'} alignContent={'center'}>
                <VerticalView>
                  <HorizontalView
                    flexWrap={'nowrap'}
                    onClick={() => {
                      if (iosLink.length) {
                        window.open(iosLink);
                      }
                    }}
                  >
                    <Image
                      width={appLayout.isMobile ? '200px' : '250px'}
                      src={'/apple-store.png'}
                      objectFit={'contain'}
                    />
                  </HorizontalView>

                  <HorizontalView
                    marginTop={appLayout.isMobile ? '10px' : '25px'}
                    flexWrap={'nowrap'}
                    onClick={() => {
                      if (androidLink.length) {
                        window.open(androidLink);
                      }
                    }}
                  >
                    <Image
                      width={appLayout.isMobile ? '200px' : '250px'}
                      src={'/google-play.png'}
                      objectFit={'contain'}
                    />
                  </HorizontalView>
                  <Button
                    marginTop={appLayout.isMobile ? '15px' : '25px'}
                    title={'watchVideo'}
                    padding={appLayout.isMobile ? '10px 30px' : '20px 30px'}
                    fontSize={'20px'}
                    fontWeight={600}
                    background={AppColors.PRIMARY}
                    color={AppColors.WHITE}
                    borderRadius={'12px'}
                    onClick={() => props.onDetail()}
                  />
                </VerticalView>
              </Div>
            </HorizontalView>
          </VerticalView>
        </VerticalView>
      </Modal>
    </Div>
  );
};
