import React from 'react';
import { Div, HorizontalView } from 'vendors/Wrapper';
import { Input } from 'vendors/Input';
import { PrimaryButton } from 'components/common/components/Button/PrimaryButton';

type SearchBarProps = {
  onSearch: (params: string) => void;
};

export const SearchKeywordInput = (props: SearchBarProps) => {
  const [keyword, setKeyword] = React.useState('');
  return (
    <HorizontalView fullWidth alignContent={'centerLeft'} flexWrap={'nowrap'}>
      <Div fullWidth minWidth={'150px'} maxWidth={'350px'} paddingRight={'20px'}>
        <Input
          width={'100%'}
          height={'50px'}
          placeholder={'keyword'}
          value={keyword}
          borderRadius={'5px'}
          onChange={(e) => {
            setKeyword(e.target.value);
          }}
          onPressEnter={() => {
            props.onSearch(keyword);
          }}
        />
      </Div>
      <Div>
        <PrimaryButton
          width="120px"
          icon={'search'}
          title={'search'}
          onClick={() => {
            props.onSearch(keyword);
          }}
        />
      </Div>
    </HorizontalView>
  );
};
