import gql from 'graphql-tag';

export const CREATE = gql`
  mutation operationCreateCategory($input: OperationCreateCategoryInput!) {
    operationCreateCategory(input: $input) {
      id
      locales {
        lang
        name
      }
    }
  }
`;
export const UPDATE = gql`
  mutation operationUpdateCategory($input: OperationUpdateCategoryInput!) {
    operationUpdateCategory(input: $input) {
      id
      status
      locales {
        lang
        name
      }
    }
  }
`;
export const DELETE = gql`
  mutation operationDeleteCategory($input: OperationDeleteCategoryInput!) {
    operationDeleteCategory(input: $input)
  }
`;
