import React from 'react';
import Table from 'components/common/components/Table';
import { useTranslationText } from 'locale';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { AppColors } from 'helpers';
import { Div, HorizontalView, VerticalView } from 'vendors/Wrapper';
import { SVGIcon } from 'vendors/Icon';
import { ICategory, ICreateCategoryInput } from 'models/Category';
import { Button } from 'vendors/Button';
import { Confirm } from 'components/common/components/Modals/ConfirmModal';
import { getCategoryNameByLang } from 'helpers/common.utils';
import { Label } from 'vendors/Label';

export function columns(
  startIndex: number,
  translation: (path: string) => string,
  onDelete: (id: string) => void,
  onDetails: (id: string) => void,
) {
  const tableColumns: Array<ColumnDescription<ICategory>> = [
    {
      dataField: 'id',
      text: 'ID',
      headerStyle: () => {
        return { width: '25%', textAlign: 'left', whiteSpace: 'unset', padding: '10px' };
      },
      formatter: (_, row: ICategory) => {
        return (
          <a
            href={`/cms/categories/${row.id}`}
            color={AppColors.PRIMARY}
            onClick={(event) => {
              event.preventDefault();
              onDetails(row.id);
            }}
          >
            <HorizontalView fullWidth alignContent={'centerBetween'} padding={'10px'} flexWrap={'nowrap'}>
              {row.id}
              <Div width={'30px'}>
                <SVGIcon size={'20px'} name={'arrow-right'} color={AppColors.PRIMARY} />
              </Div>
            </HorizontalView>
          </a>
        );
      },
    },

    {
      dataField: 'locales',
      text: translation('home.category.header'),
      headerStyle: () => {
        return { width: '70%', textAlign: 'left', padding: '10px' };
      },
      formatter: (_, row: ICategory) => {
        return (
          <HorizontalView fullWidth alignContent={'centerBetween'} padding={'10px'} flexWrap={'nowrap'}>
            {getCategoryNameByLang(row)}
            <Div width={'30px'}></Div>
          </HorizontalView>
        );
      },
    },
    {
      dataField: 'status',
      text: translation('searchArticles.public'),
      headerStyle: () => {
        return { width: '10%', textAlign: 'center', padding: '10px' };
      },
      // eslint-disable-next-line react/display-name
      formatter: (_, row: ICategory) => {
        return (
          <HorizontalView fullWidth alignContent={'center'}>
            <Label
              color={row.status == 'active' ? AppColors.GRASSY_GREEN : AppColors.RED}
              label={row.status == 'active' ? 'statusCollection.public' : 'statusCollection.unpublic'}
            />
          </HorizontalView>
        );
      },
    },
    {
      dataField: 'menu',
      csvExport: false,
      text: '',
      headerStyle: () => {
        return { width: '10%', maxWidth: '80px', textAlign: 'center', padding: '10px' };
      },
      // eslint-disable-next-line react/display-name
      formatter: (_, row) => {
        return (
          <HorizontalView fullWidth alignContent={'centerAround'} padding={'5px'}>
            <Confirm
              title={'home.category.alertDelete.message'}
              leftText={'home.category.alertDelete.close'}
              rightText={'home.category.alertDelete.delete'}
              onConfirm={() => {
                onDelete(row.id);
              }}
            >
              <Button
                width={'35px'}
                height={'35px'}
                preixIcon={'trash'}
                borderRadius={'4px'}
                color={AppColors.WHITE}
                background={AppColors.RED}
              />
            </Confirm>
          </HorizontalView>
        );
      },
    },
  ];

  return tableColumns;
}

interface TableProps {
  page: number;
  rowPerPage: number;
  loading: boolean;
  category: ICategory[];
  onCreate?: (data: ICreateCategoryInput) => void;
  onDetails: (id: string) => void;
  onDelete: (id: string) => void;
}

export const WebTableView = (props: TableProps) => {
  const { translation } = useTranslationText();

  return (
    <VerticalView fullWidth minWidth={'1200px'}>
      <Table
        loading={props.loading}
        keyField={'id'}
        data={props.category ? props.category : []}
        bordered={true}
        striped={true}
        hover={true}
        condensed={true}
        columns={columns((props.page - 1) * props.rowPerPage, translation, props.onDelete, props.onDetails)}
      />
    </VerticalView>
  );
};
