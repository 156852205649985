import { ProductPage } from 'containers/cms-containers/product';
import React from 'react';
import { Routes, Route } from 'react-router';
import { NotFoundContainer } from '../../containers/user-container/not-found/index';

export const ProductRouter = () => {
  return (
    <Routes>
      <Route index element={<ProductPage />} />
      <Route path="*" element={<NotFoundContainer />} />
    </Routes>
  );
};
