import { BaseComponent } from 'components/common/components/BaseComponent';
import { PromotionPreview } from 'components/common/components/Promotion/PromotionView';
import { VideoPreviewSection } from 'components/common/components/VideoPreviewSection';
import { useTranslationText } from 'locale';
import { IArticleShort } from 'models/Article';
import { IVideoShort } from 'models/Video';
import React from 'react';
import { VerticalView } from 'vendors/Wrapper';
import { HomeArticlePreview } from '../home/parts/HomeArticlePreview';

type PropsType = {
  loading: boolean;
  videos?: IVideoShort[];
  news?: IArticleShort[];
  slideImages?: string[];
};

export const EmployeeComponent = (props: PropsType) => {
  const { translation } = useTranslationText();

  return (
    <BaseComponent scrollToTop contentLoading={props.loading}>
      <VerticalView fullWidth>
        <PromotionPreview
          slidePictures={props.slideImages}
          title={translation('forEmployee')}
          thumbnail={'images/app_src.png'}
        />

        {props.videos && props.videos.length > 0 && (
          <VideoPreviewSection
            loading={props.loading}
            videoTabs={[{ title: 'employeePage.newVideosEmployer', videos: props.videos }]}
          />
        )}
        {props.news && <HomeArticlePreview header={'common.topMenu.news'} seeMore={'seeMore'} article={props.news} />}
      </VerticalView>
    </BaseComponent>
  );
};

export default React.memo(EmployeeComponent);
