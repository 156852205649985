import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslationText } from 'locale';
import { AppColors } from 'helpers';
import { useDebouncedCallback } from 'use-debounce/lib';
import { useAppSetting } from 'hooks/useAppSetting';
import { HorizontalView, VerticalView } from 'vendors/Wrapper';
import { Input } from 'vendors/Input';
import { SVGIcon } from 'vendors/Icon';
import { AnimationView } from 'vendors/Animation';
import { CardWrapper } from 'vendors/CommonWrappers';
import { Hr } from 'vendors/CommonParts';
import { Text } from 'vendors/Text';
import { ISearchHelpsInput } from 'models/Help';
import { useNavigate } from 'react-router-dom';
import { useSearchHelp } from 'api/hooks/help-center/useSearchHelp';

const RowWrapper = styled.div`
  width: 100%;
  cursor: pointer;
  border-bottom: 1px solid ${AppColors.GRAY_LIGHT};
`;

interface HelpSearchBarProps {
  lang?: string;
  maxWidth?: string;
  height?: number;
}

export const HelpSearchBar = React.memo((props: HelpSearchBarProps) => {
  const navigate = useNavigate();
  const { appLayout, appSize } = useAppSetting();
  const { translation } = useTranslationText();
  const { help, onSearchHelp } = useSearchHelp();
  const [keyword, setKeyword] = React.useState('');
  const [openResult, setOpenResult] = React.useState(false);

  const searchDebounced = useDebouncedCallback(async (keyword: string) => {
    onSearchHelp({
      lang: props.lang || 'ja',
      limit: 100,
      keyword: keyword,
      page: 1,
    } as ISearchHelpsInput);
  }, 300);

  const helpResults = useMemo(() => {
    if (!keyword.length) {
      return [];
    }
    return help || [];
  }, [help, keyword]);

  return (
    <HorizontalView
      width={'100%'}
      maxWidth={props.maxWidth || (appLayout.isMobile ? '90%' : '600px')}
      alignContent={'topCenter'}
      position={'relative'}
    >
      <HorizontalView
        width={'100vw'}
        height={'100vh'}
        position={'fixed'}
        display={openResult ? 'flex' : 'none'}
        onClick={() => setOpenResult(false)}
      />

      <HorizontalView fullWidth position={'relative'}>
        <Input
          placeholder={'keyword'}
          value={keyword}
          paddingLeft={'20px'}
          paddingRight={'40px'}
          width={'100%'}
          height={props.height ? `${props.height}px` : '50px'}
          borderRadius={props.height ? `${props.height / 2}px` : '25px'}
          border={'1px solid #cccccc'}
          onChange={(e) => {
            searchDebounced.callback(e.target.value);
            setKeyword(e.target.value);
          }}
          onFocus={() => {
            setOpenResult(true);
          }}
        />
        <HorizontalView position={'absolute'} right={'10px'}>
          <SVGIcon size={'25px'} name={'search'} color={AppColors.GRAY_LIGHT} />
        </HorizontalView>
      </HorizontalView>

      <HorizontalView
        fullWidth
        position={'absolute'}
        marginTop={`${props.height ? props.height + 20 : 60}px`}
        zIndex={10000}
      >
        {openResult && keyword.length > 0 && (
          <AnimationView width={'100%'}>
            <CardWrapper width={'100%'} style={{ boxShadow: 'rgb(0 0 0 / 15%)' }}>
              <VerticalView
                maxHeight={'350px'}
                overflowY={'auto'}
                flexWrap={'nowrap'}
                overflowX={'hidden'}
                alignContent={'topLeft'}
              >
                {Boolean(helpResults?.length) && (
                  <VerticalView alignContent={'centerLeft'} fullWidth>
                    <Text fontSize={'20px'} fontWeight={600} textAlign={'center'} marginBottom={'10px'}>
                      {translation('searchResult')}
                    </Text>
                    <Text></Text>
                    <Hr />
                  </VerticalView>
                )}

                {helpResults.map((h, index) => {
                  return (
                    <RowWrapper
                      key={index}
                      onClick={(e) => {
                        e.preventDefault();
                        const id = h.id;
                        setOpenResult(false);
                        setKeyword('');
                        navigate(`/helps/${id}?lang=${props.lang}`);
                      }}
                    >
                      <VerticalView fullWidth alignContent={'centerLeft'} paddingTop={'10px'}>
                        <Text fontSize={appSize.h4} marginBottom={'10px'}>
                          {h.title}
                        </Text>
                      </VerticalView>
                    </RowWrapper>
                  );
                })}
              </VerticalView>
            </CardWrapper>
          </AnimationView>
        )}
      </HorizontalView>
    </HorizontalView>
  );
});
