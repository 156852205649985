import { helpMutations } from 'api/graphql/help-center';
import { IUpdateHelpInput } from 'models/Help';
import { IError } from 'models/Models';
import { useBaseMutation } from '../base/useBaseMutation';

export const useUpdateHelp = () => {
  const { mutation, loading } = useBaseMutation<IUpdateHelpInput, boolean>({
    mutation: helpMutations.UPDATE,
  });

  async function onUpdateHelp(input: IUpdateHelpInput): Promise<{ error?: IError; success?: boolean }> {
    const { data, error } = await mutation(input);
    if (error) return { error };

    return { success: data };
  }

  return {
    loading,
    onUpdateHelp,
  };
};
