import EmployerContainer from 'containers/user-container/employer';
import React from 'react';
import { Routes, Route } from 'react-router';

export const EmployerPageRouter = () => {
  return (
    <Routes>
      <Route index element={<EmployerContainer />} />
    </Routes>
  );
};
