import React from 'react';
import { BaseComponent } from 'components/common/components/BaseComponent';
import { Div, HorizontalView, VerticalView } from 'vendors/Wrapper';
import { Loading } from 'vendors/Loading';
import { IUser } from 'models/User';
import { useAppSetting } from 'hooks/useAppSetting';
import { Label } from 'vendors/Label';
import { FlatList } from 'vendors/FlatList';
import { PlanCard } from './parts/Plan';
import { IProduct } from 'models/Transaction';

type PropsType = {
  loading: boolean;
  sourceLoading: boolean;
  user?: IUser;
  isSuccess?: boolean;
  products: IProduct[];
  onChoosePlan: (product: IProduct) => void;
};

export const PlanComponent = (props: PropsType) => {
  const { appLayout } = useAppSetting();

  return (
    <BaseComponent contentLoading={props.loading}>
      <VerticalView fullWidth padding="40px" alignContent="topCenter">
        <Label label="plan.title" fontSize="30px" fontWeight={'bold'} />
        <Label label="plan.description" color="gray" fontSize="20px" fontWeight={300} marginTop={'20px'} />

        <HorizontalView fullWidth alignContent="center" paddingTop="30px">
          <FlatList
            alignContent="center"
            values={props.products}
            renderItem={(product: IProduct, index) => {
              return (
                <Div width={appLayout.isMobile ? '90vw' : '400px'} maxWidth={'400px'} padding="15px" key={index}>
                  <PlanCard
                    priceId={product.id}
                    size={index == 1 ? 'large' : 'normal'}
                    width="100%"
                    title={product.name}
                    price={`${product.price}`}
                    description={product.description}
                    onChoose={() => props.onChoosePlan(product)}
                  />
                </Div>
              );
            }}
          />
        </HorizontalView>
        <Loading loading={props.loading} />
        <Loading loading={props.sourceLoading} overlay />
      </VerticalView>
    </BaseComponent>
  );
};
