import React from 'react';
import { Button } from 'vendors/Button';
import { useAppSetting } from 'hooks/useAppSetting';
import { Div } from 'vendors/Wrapper';
import { Image } from 'vendors/Image';

type CustomProps = {
  onClick?: () => void;
};
export const BackButton2 = (props: CustomProps) => {
  const { appTheme } = useAppSetting();
  const [color, setColor] = React.useState(appTheme.base.base);
  const [bg, setBg] = React.useState('/BackIcon2.ico');

  return (
    <Div position={'relative'} paddingLeft={'10px'}>
      <Div
        top={'0'}
        left={'0'}
        transition={'children 0.3s'}
        onClick={props.onClick}
        onMouseEnter={() => {
          setBg('/BackIcon.png');
          setColor(appTheme.base.base);
        }}
        onMouseLeave={() => {
          setBg('/BackIcon2.ico');
          setColor(appTheme.base.base);
        }}
      >
        <Image width={'25px'} src={bg} />
        <Button title={'back'} fontSize={'16px'} borderRadius={'5px'} height={'40px'} color={color} />
      </Div>
    </Div>
  );
};
