import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { AppColors } from 'helpers';
import { Button } from 'vendors/Button';
import { ContentLoading } from 'vendors/Loading';
import { Text } from 'vendors/Text';
import { HorizontalView, VerticalView } from 'vendors/Wrapper';
import { CardWrapper } from 'vendors/CommonWrappers';
import { useWindowDimensions } from 'vendors/Hooks/useWindow/useWindowDimensions';

const widthResolution = [2048, 1024, 720, 512, 256, 128];

function getResizedCanvas(canvas: HTMLCanvasElement, newWidth: number, newHeight: number) {
  const tmpCanvas = document.createElement('canvas');
  tmpCanvas.width = newWidth;
  tmpCanvas.height = newHeight;

  const ctx = tmpCanvas.getContext('2d')!;
  ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, newWidth, newHeight);

  return tmpCanvas;
}

const blobToFile = (theBlob: Blob, fileName: string): File => {
  return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type });
};

async function generateFile(
  width: number,
  height: number,
  canvas: HTMLCanvasElement | null,
  crop: ReactCrop.Crop | null,
  fileName: string,
  result: (file: File | null) => void,
) {
  if (!crop || !canvas) {
    result(null);
    return null;
  }
  const newCanvas = getResizedCanvas(canvas, width, height);

  newCanvas.toBlob(
    (blob) => {
      blob ? result(blobToFile(blob, fileName)) : result(null);
    },
    'image/png',
    1,
  );
}

type CropImageProp = {
  aspect: { label: string; value: number };
  file: File | null;
  maxImageWidth?: number;
  onCrop: (file: File | null, ratio: string) => void;
};

export const CropImage = (props: CropImageProp) => {
  const { isMobile } = useWindowDimensions();
  const [loading, setLoading] = useState(true);
  const [rendering, setRendering] = useState(false);
  const [upImg, setUpImg] = useState<string | ArrayBuffer | null>();
  const [width, setWidth] = useState(512);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const reactCropRef = useRef<ReactCrop>(null);
  const [crop, setCrop] = useState<ReactCrop.Crop | undefined>({ unit: '%', width: 100, aspect: 4 / 3 });
  const [completedCrop, setCompletedCrop] = useState<ReactCrop.Crop | null>(null);

  React.useEffect(() => {
    if (props.file) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(props.file);
      return reader.removeEventListener('load', () => setUpImg(reader.result));
    }
  }, [props.file]);

  React.useEffect(() => {
    if (props.aspect) {
      setCrop({ unit: '%', width: 100, aspect: props.aspect.value });
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [props.aspect]);

  const onLoad = useCallback((img: any) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image: any = imgRef.current;
    const canvas: HTMLCanvasElement = previewCanvasRef.current;
    const crop: ReactCrop.Crop = completedCrop;

    if (!image || !crop.width || !crop.height) {
      return;
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;

    if (!ctx) {
      return;
    }

    ctx.imageSmoothingQuality = 'high';
    ctx.drawImage(
      image,
      (crop.x || 0) * scaleX,
      (crop.y || 0) * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );

    // ctx.setTransform()
  }, [completedCrop, width]);

  return (
    <VerticalView fullWidth minHeight={'100px'} position={'relative'}>
      {!loading && (
        <HorizontalView fullWidth alignContent={'centerAround'}>
          <HorizontalView width={isMobile ? '100%' : '30%'} minWidth={'200px'}>
            <ReactCrop
              ref={reactCropRef}
              src={upImg as string}
              onImageLoaded={onLoad}
              crop={crop}
              onChange={(c) => setCrop(c)}
              onComplete={(c) => setCompletedCrop(c)}
            />
          </HorizontalView>
          <HorizontalView
            width={isMobile ? '100%' : '30%'}
            minWidth={'200px'}
            marginTop={isMobile ? '20px' : '0px'}
            padding={'20px'}
          >
            <CardWrapper width={'auto'} borderRadius={'5px'}>
              <canvas
                ref={previewCanvasRef}
                // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                style={{
                  width: Math.round(completedCrop?.width ?? 0),
                  height: Math.round(completedCrop?.height ?? 0),
                }}
              />
            </CardWrapper>
          </HorizontalView>
          <VerticalView width={isMobile ? '80%' : '30%'} alignContent={'center'} paddingTop={'20px'}>
            <Text fontSize={'30px'} fontWeight={600}>
              {props.aspect.label}
            </Text>
            <VerticalView
              width={'150px'}
              borderRadius={'5px'}
              background={AppColors.GRAY_LIGHT}
              marginTop={'10px'}
              marginBottom={'20px'}
              overflow={'hidden'}
            >
              {widthResolution.map((item, index) => {
                if (!props.maxImageWidth || item <= props.maxImageWidth) {
                  return (
                    <Button
                      key={index}
                      width={'100%'}
                      color={width === item ? AppColors.WHITE : AppColors.BLACK}
                      background={width === item ? AppColors.DARK : AppColors.GRAY_LIGHT}
                      padding={'10px'}
                      onClick={() => {
                        setWidth(item);
                      }}
                    >{`${item}x${(item / props.aspect.value).toFixed(0)}`}</Button>
                  );
                }
              })}
            </VerticalView>
            <Button
              loading={rendering}
              preixIcon={'file'}
              title={'cropImageModal.selectRatio'}
              color={AppColors.WHITE}
              background={AppColors.BLUE}
              padding={'10px'}
              borderRadius={'5px'}
              minWidth={'50px'}
              onClick={() => {
                setRendering(true);
                generateFile(
                  width,
                  width / props.aspect.value,
                  previewCanvasRef.current,
                  completedCrop,
                  `${props.file?.name || 'image'}`,
                  async (file) => {
                    setRendering(false);
                    props.onCrop(file, props.aspect.label);
                  },
                );
              }}
            />
          </VerticalView>
        </HorizontalView>
      )}
      {loading && (
        <HorizontalView fullWidthHeight height={'500px'} position={'absolute'} alignContent={'center'}>
          <ContentLoading loading color={AppColors.BLUE} />
        </HorizontalView>
      )}
    </VerticalView>
  );
};
