import React from 'react';
import { Div, Body, VerticalView } from 'vendors/Wrapper';
import { TitleWrapper, DescriptionWrapper } from 'vendors/CommonWrappers';
import { AppColors } from 'helpers';

type PageBodyHeaderProps = {
  title: string;
  description?: string | null;
};
export const PageBodyHeader = (props: PageBodyHeaderProps) => {
  return (
    <Div fullWidth background={AppColors.PAGE_HEADER} alignContent={'centerLeft'} padding={'10px'}>
      <Body>
        <VerticalView fullWidth alignContent={'centerLeft'}>
          {props.title && (
            <TitleWrapper size={'35px'} color={AppColors.WHITE}>
              {props.title}
            </TitleWrapper>
          )}

          {props.description && <DescriptionWrapper color={AppColors.WHITE}> {props.description}</DescriptionWrapper>}
        </VerticalView>
      </Body>
    </Div>
  );
};
