import { BaseComponent } from 'components/common/components/BaseComponent';
import React from 'react';
import { NavigationBar } from 'components/common/components/NavigationBar';
import { Body, Div, HorizontalView, VerticalView } from 'vendors/Wrapper';
import { Loading } from 'vendors/Loading';
import { Formik, FormikProps } from 'formik';
import { ImagePicker } from 'components/common/components/ImagePicker/ImagePicker';
import { Input } from 'vendors/Input';
import { TextAreaInput } from 'vendors/TextAreaInput';
import { PrimaryButton } from 'components/common/components/Button/PrimaryButton';
import { IUser } from 'models/User';
import { ResetPasswordModal } from '../parts/ResetPasswordModal';
import { ButtonOutline } from 'components/common/components/Button/ButtonOutline';
import { useAppSetting } from 'hooks/useAppSetting';
import { Button } from 'vendors/Button';
import { AppColors } from 'helpers';
import { ChangeStatusModal } from 'components/common/components/Modals/ChangeStatusModal';
import { IChangeAccountStatusInput } from 'models/BusinessAccount';
import { useTranslationText } from 'locale';
import { Text } from 'vendors/Text';
import { MessageBox } from 'components/common/components/MessageBox';
import { MembershipInformation } from './parts/MembershipInformation';
import { IMembership } from 'models/Membership';
import { ILocation } from 'models/Location';

type PropsType = {
  loading: boolean;
  company?: IUser;
  changeStatusLoading: boolean;
  onUpdate: (values: IUser) => void;
  onChangePassword: (password: string) => void;
  onChangeStatus: (input: IChangeAccountStatusInput) => void;
  onUpdateMembership?: (membership?: IMembership) => void;
  getLocation: (code: string) => void;
  locationLoading: boolean;
  locations: ILocation[];
};

export const CompanyDetailComponent = (props: PropsType) => {
  const { translation } = useTranslationText();
  const ref = React.useRef<FormikProps<IUser>>(null);
  const [showModalChangePass, setShowModalChangePass] = React.useState(false);
  const [showModalChangeStatus, setShowModalChangeStatus] = React.useState(false);
  const { appLayout, appSize } = useAppSetting();

  React.useEffect(() => {
    if (props.company) {
      if (ref && ref.current) {
        ref.current.setValues(JSON.parse(JSON.stringify(props.company)));
      }
    }
  }, [props.company]);

  React.useEffect(() => {
    if (props.locations && props.locations.length) {
      if (ref && ref.current) {
        ref.current.setFieldValue(
          'company.stateOrCityName',
          props.locations[0]?.province + props.locations[0]?.city + props.locations[0]?.building,
        );
      }
    }
  }, [props.locations]);

  return (
    <BaseComponent contentLoading={props.loading}>
      <>
        <NavigationBar header={'usersSearch.header'} path={'/cms/customer'} background={'#EDEDED'} />
        <Body>
          <Loading loading={props.loading || props.changeStatusLoading} />
          {props.company?.id && (
            <VerticalView fullWidth paddingTop="20px">
              <MembershipInformation
                company={props.company}
                membership={props.company.membership}
                onUpdateMembership={props.onUpdateMembership}
              />
              <Formik
                innerRef={ref}
                initialValues={props.company as IUser}
                onSubmit={(values: IUser) => {
                  props.onUpdate(values);
                }}
              >
                {({ values, handleChange, setFieldValue, submitCount, errors }) => {
                  return (
                    <VerticalView fullWidthHeight alignContent="topLeft" paddingTop="20px">
                      {props.company?.status === 'blocked' && (
                        <Div fullWidth alignContent="center" padding="20px">
                          <MessageBox message={props.company?.reason || ''} title={translation('blockedReason')} />
                        </Div>
                      )}

                      <Div fullWidth alignContent={'centerLeft'}>
                        <ImagePicker
                          required
                          maxImageWidth={512}
                          label={`logo`}
                          ratios={['1x1']}
                          width={'100%'}
                          height={'230px'}
                          value={values?.company?.logo}
                          onSelect={(url) => setFieldValue('company.logo', url)}
                        />
                      </Div>
                      <Div fullWidth alignContent={'centerLeft'} paddingTop={'15px'}>
                        <ImagePicker
                          required
                          maxImageWidth={1024}
                          label={`cover`}
                          ratios={['2x1']}
                          width={'100%'}
                          height={'230px'}
                          value={values.company?.cover}
                          onSelect={(url) => setFieldValue('company.cover', url)}
                        />
                      </Div>
                      <Div fullWidth marginTop={'15px'}>
                        <Text
                          fontSize={appSize.h2}
                          fontWeight={600}
                          color={
                            props.company?.status === 'blocked'
                              ? AppColors.RED
                              : props.company?.status === 'active'
                              ? AppColors.GRASSY_GREEN
                              : AppColors.BLACK
                          }
                        >
                          {translation(`accountStatus.${props.company?.status}`)}
                        </Text>
                      </Div>

                      <Input
                        label={`userDetails.email`}
                        isPreview
                        placeholder={`userDetails.email`}
                        name={'email'}
                        fontSize={appSize.md}
                        value={values.email}
                        onChange={handleChange}
                      />

                      {/* <AccountIdentity user={props.company} /> */}
                      {props.company?.totalFollowers && (
                        <Div marginTop="15px">
                          <Input
                            label={`userDetails.totalFollowers`}
                            isPreview
                            placeholder={`userDetails.totalFollowers`}
                            name={'totalFollowers'}
                            fontSize={appSize.md}
                            value={values.totalFollowers}
                            onChange={handleChange}
                          />
                        </Div>
                      )}
                      {props.company?.totalFollowing && (
                        <Div marginTop="15px">
                          <Input
                            label={`userDetails.totalFollowing`}
                            isPreview
                            placeholder={`userDetails.totalFollowing`}
                            name={'totalFollowing'}
                            fontSize={appSize.md}
                            value={values.totalFollowing}
                            onChange={handleChange}
                          />
                        </Div>
                      )}
                      <Div fullWidth marginTop={'15px'}>
                        <Input
                          required
                          name={'company.name'}
                          label={'company.companyName'}
                          fontSize={'15px'}
                          placeholder={'company.companyName'}
                          value={values.company?.name}
                          background={'transparent'}
                          onChange={handleChange}
                        />
                      </Div>
                      <Div fullWidth marginTop={'15px'}>
                        <Input
                          required
                          name={'company.foundation'}
                          label={'company.foundation'}
                          fontSize={'15px'}
                          placeholder={'company.foundation'}
                          value={values.company?.foundation}
                          background={'transparent'}
                          onChange={handleChange}
                        />
                      </Div>
                      <Div fullWidth marginTop={'15px'}>
                        <Input
                          required
                          name={'company.representativeName'}
                          label={'company.representatorName'}
                          fontSize={'15px'}
                          placeholder={'company.representatorName'}
                          value={values.company?.representativeName}
                          background={'transparent'}
                          onChange={handleChange}
                        />
                      </Div>
                      <Div fullWidth marginTop={'15px'}>
                        <Input
                          required
                          name={'company.tax'}
                          label={'company.tax'}
                          fontSize={'15px'}
                          placeholder={'company.tax'}
                          value={values.company?.tax}
                          background={'transparent'}
                          onChange={handleChange}
                        />
                      </Div>
                      <HorizontalView fullWidth marginBottom={'28px'} alignContent="centerBetween">
                        <Div width="70%">
                          <Input
                            required
                            name={'company.postalCode'}
                            label={'public.register.postalCode'}
                            fontSize={'15px'}
                            placeholder={'public.register.postalCode'}
                            value={values.company?.postalCode}
                            background={'transparent'}
                            onChange={handleChange}
                          />
                        </Div>
                        <Div width="30%" marginTop="29px" alignContent="centerRight">
                          <Button
                            disabled={values.company?.postalCode?.length !== 7}
                            preixIcon="search"
                            fontSize="14px"
                            width={appLayout.isMobile ? '100%' : '100px'}
                            title="search"
                            borderRadius={appLayout.isMobile ? '0' : '10px'}
                            loading={props.locationLoading}
                            height="40px"
                            color="#fff"
                            background={AppColors.PRIMARY}
                            onClick={() => {
                              props.getLocation(values.company?.postalCode || '');
                            }}
                          />
                        </Div>
                      </HorizontalView>
                      {values.company.postalCode &&
                        values.company.postalCode?.length > 0 &&
                        values.company.postalCode?.length !== 7 && (
                          <Text color="red">{translation('public.register.code')} </Text>
                        )}
                      <Div width={'100%'} marginBottom={'28px'}>
                        <Input
                          required
                          minWidth={'100px'}
                          disabled={props.loading}
                          id={'company.stateOrCityName'}
                          fontSize={'15px'}
                          value={values.company?.stateOrCityName}
                          label={'public.register.stateOrCityName'}
                          placeholder={'public.register.stateOrCityName'}
                          onChange={handleChange}
                          height={'40px'}
                          background={AppColors.WHITE}
                          error={
                            submitCount && errors.company?.stateOrCityName ? errors.company.stateOrCityName : undefined
                          }
                        />
                      </Div>
                      <Div fullWidth marginTop={'15px'}>
                        <Input
                          required
                          name={'company.buildingNameAndRoomName'}
                          label={'company.building'}
                          fontSize={'15px'}
                          placeholder={'company.building'}
                          value={values.company?.buildingNameAndRoomName}
                          background={'transparent'}
                          onChange={handleChange}
                        />
                      </Div>
                      <Div fullWidth marginTop={'15px'}>
                        <Input
                          required
                          name={'company.telephone'}
                          label={'company.phone'}
                          fontSize={'15px'}
                          placeholder={'company.phone'}
                          value={values.company?.telephone}
                          background={'transparent'}
                          onChange={handleChange}
                        />
                      </Div>
                      <Div fullWidth marginTop={'15px'}>
                        <TextAreaInput
                          required
                          name={'company.businessContent'}
                          label={'company.bussinessContent'}
                          size={'15px'}
                          placeholder={'company.bussinessContent'}
                          value={values.company?.businessContent}
                          background={'transparent'}
                          onChange={handleChange}
                        />
                      </Div>
                      <Div fullWidth marginTop={'15px'}>
                        <TextAreaInput
                          required
                          name={'company.description'}
                          label={'company.description'}
                          size={'15px'}
                          placeholder={'company.description'}
                          value={values.company?.description}
                          background={'transparent'}
                          onChange={handleChange}
                        />
                      </Div>
                      <HorizontalView fullWidth marginTop={'20px'} alignContent={'centerRight'}>
                        <Button
                          width={appLayout.isMobile ? '100%' : 'auto'}
                          marginRight={appLayout.isMobile ? '0px' : '20px'}
                          title={'changeStatus.button'}
                          color={AppColors.WHITE}
                          borderRadius={'4px'}
                          padding="10px 15px"
                          fontSize={'15px'}
                          fontWeight={600}
                          background={AppColors.RED_PRIMARY}
                          onClick={() => {
                            setShowModalChangeStatus(!showModalChangeStatus);
                          }}
                        />
                        <ButtonOutline
                          width={appLayout.isMobile ? '100%' : 'auto'}
                          icon={'key'}
                          title={`userDetails.changePass`}
                          marginRight={appLayout.isMobile ? '0px' : '20px'}
                          onClick={() => {
                            setShowModalChangePass(!showModalChangePass);
                          }}
                        />
                        <PrimaryButton
                          icon={'save'}
                          title={'company.save'}
                          onClick={() => {
                            if (ref && ref.current) {
                              ref.current.submitForm();
                            }
                          }}
                        />
                      </HorizontalView>
                    </VerticalView>
                  );
                }}
              </Formik>
              <ChangeStatusModal
                loading={props.loading}
                open={showModalChangeStatus}
                statusTypes={'userStatus'}
                status={props.company.status || ''}
                onClose={() => setShowModalChangeStatus(false)}
                onUpdate={(input) => {
                  props.onChangeStatus({
                    id: props.company?.id,
                    status: input.status,
                    reason: input.reason,
                  } as IChangeAccountStatusInput);
                }}
              />
              <ResetPasswordModal
                loading={props.loading}
                show={showModalChangePass}
                onClose={() => {
                  setShowModalChangePass(false);
                }}
                onChange={(password) => {
                  setShowModalChangePass(false);
                  props.onChangePassword(password);
                }}
              />
            </VerticalView>
          )}
        </Body>
      </>
    </BaseComponent>
  );
};

export default React.memo(CompanyDetailComponent);
