import React from 'react';
import { Div, HorizontalView } from 'vendors/Wrapper';
import { AppColors } from 'helpers';
import { VerticalView } from 'vendors/CommonWrappers';
import { Label } from 'vendors/Label';
import { Text } from 'vendors/Text';
import { SearchKeywordInput } from 'components/common/components/Input/SearchKeywordInput';
import { useAppSetting } from 'hooks/useAppSetting';
import { Button } from 'vendors/Button';

type SearchBarProps = {
  loading?: boolean;
  isServicePage: boolean;
  totalItems: number;
  onSearch: (params: string) => void;
  onChangeLimit: (limit: number) => void;
  onSelectTags: (tags: string[], type: string) => void;
  onCreate: () => void;
};

export const CmsSearchCategoryBar = (props: SearchBarProps) => {
  const { appLayout } = useAppSetting();
  return (
    <VerticalView fullWidth>
      <HorizontalView fullWidth alignContent={'centerBetween'} paddingBottom={'20px'} paddingTop={'20px'}>
        <Div width={appLayout.isMobile ? '100%' : '50%'} paddingBottom={appLayout.isMobile ? '20px' : '0px'}>
          <SearchKeywordInput onSearch={props.onSearch} />
        </Div>
        <HorizontalView fullWidth alignContent={'centerBetween'} paddingTop={'20px'} paddingBottom={'20px'}>
          <Button
            loading={props.loading}
            title={'new'}
            height={'40px'}
            minWidth={'130px'}
            preixIcon={'plus'}
            background={AppColors.RED2}
            color={AppColors.WHITE}
            borderRadius={'5px'}
            onClick={props.onCreate}
          />
          <HorizontalView>
            <Text paddingRight={'20px'}>
              <Label label={'all'} />
              {` ${props.totalItems} `}
              <Label label={'item'} />
            </Text>
          </HorizontalView>
        </HorizontalView>
      </HorizontalView>
    </VerticalView>
  );
};
