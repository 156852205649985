import React from 'react';
import { IUser } from 'models/User';
import { AppColors } from 'helpers';
import { Button } from 'vendors/Button';
import { useSocket } from 'context/SocketContext';
import { IRoom, SocketEvent } from 'containers/cms-containers/chat/type/Chat';
import { IError } from 'models/Models';
import { useSession } from 'stores/userStore';
import { useNavigate } from 'react-router-dom';

interface Props {
  user: IUser;
}

export const ChatButton = (props: Props) => {
  const { socket } = useSocket();
  const navigative = useNavigate();
  const { session } = useSession();
  const [loading, setLoading] = React.useState(false);
  if (session.user.id === props.user.id) {
    return null;
  }
  return (
    <Button
      width={'35px'}
      height={'35px'}
      preixIcon={'message'}
      borderRadius={'4px'}
      color={AppColors.WHITE}
      background={AppColors.GRASSY_GREEN}
      margin={'10px'}
      disabled={loading}
      loading={loading}
      onClick={() => {
        setLoading(true);
        socket.emit(SocketEvent.GetRoom, { friendId: props.user.id }, (data: { error: IError; room: IRoom }) => {
          setLoading(false);
          if (data.room) {
            navigative('/cms/chat/' + data.room.id);
          }
        });
      }}
    />
  );
};
