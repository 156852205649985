import { useBaseMutation } from 'api/hooks/base/useBaseMutation';
import { useTranslationText } from 'locale';
import { IUpdatePageSettingInput } from 'models/HomePage';
import { openNotification } from 'vendors/Notification';
import { homeMutations } from 'api/graphql/home';

export const useUpdatePageSetting = () => {
  const { loading, mutation } = useBaseMutation<IUpdatePageSettingInput, boolean>({
    mutation: homeMutations.UPDATE,
  });
  const { translation } = useTranslationText();

  async function updatePageSetting(input: IUpdatePageSettingInput) {
    const result = await mutation(input);
    if (result.error) {
      openNotification({
        type: 'error',
        message: result.error?.title || '',
        description: result.error.message,
      });
    } else {
      openNotification({
        type: 'success',
        message: translation('updateSuccess'),
      });
    }
  }

  return {
    loading,
    updatePageSetting,
  };
};
