import React from 'react';
import styled from 'styled-components';
import { AppColors } from 'helpers';
import { HorizontalView, VerticalView } from 'vendors/Wrapper';
import { Image } from 'vendors/Image';
import { Text } from 'vendors/Text';
import { dateUtil } from 'helpers/date.utils';
import { useAppSetting } from 'hooks/useAppSetting';
import { Hr } from 'vendors/CommonParts';

//CHAPTER MENU
const CardWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1;
  padding-top: 30px;
  cursor: pointer;
  position: relative;

  &:hover {
    background: #f2f2f2;
  }
`;

const ThumbnailWrapper = styled.div<{ width?: string }>`
  width: ${(props) => props.width || '150px'};
  cursor: pointer;
  background-size: cover;
  overflow: hidden;
  border-radius: 4px;
  transition: all 0.5s;
  object-fit: cover;
  -webkit-transform: scale(1);
  transform: scale(1);
`;

const TitleWrapper = styled.div<{ size?: string }>`
  font-weight: 600;
  font-size: ${(props) => props.size || '24px'};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DescriptionWrapper = styled.div<{ size?: string }>`
  margin-top: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${(props) => props.size || '16px'};
  font-weight: 400;
`;

const CategoryWrapper = styled.div<{ size?: string }>`
  font-size: ${(props) => props.size || '16px'};
  font-weight: 500;
`;

interface AboutProps {
  align: 'left' | 'right';
  title?: string;
  time?: string;
  image?: string;
  category?: string;
  description?: string;
  onClick: () => void;
}

export const NewsCard = (props: AboutProps) => {
  const { appSize, appLayout } = useAppSetting();
  const isSmallSize = appLayout.width < 600;

  return (
    <CardWrapper
      onClick={(e) => {
        e.preventDefault();
        props.onClick();
      }}
    >
      {isSmallSize && (
        <VerticalView fullWidth flexWrap={'nowrap'} alignContent={'topLeft'} paddingBottom={'20px'}>
          <ThumbnailWrapper width={'100%'}>
            <Image src={props.image || '/image/default-thumbnail.jpeg'} objectFit={'cover'} width={'100%'} />
          </ThumbnailWrapper>
          <VerticalView width={'100%'} alignContent={'centerLeft'} marginTop={'20px'}>
            <TitleWrapper size={appSize.h2}>{props.title}</TitleWrapper>
            <DescriptionWrapper size={appSize.h4}>{props.description}</DescriptionWrapper>

            <HorizontalView marginTop={'8px'} fullWidth alignContent={'centerLeft'}>
              <Text fontSize={appSize.h5} color={AppColors.DARK}>
                {dateUtil.dateFormat(props.time || '', 'MMM D, YYYY')}
              </Text>
              <Text fontSize={appSize.h5} padding={'8px'} color={AppColors.DARK}>
                -
              </Text>
              <CategoryWrapper size={appSize.h5}>{props.category?.toUpperCase()}</CategoryWrapper>
            </HorizontalView>
          </VerticalView>
        </VerticalView>
      )}
      {!isSmallSize && (
        <HorizontalView fullWidth flexWrap={'nowrap'} alignContent={'topLeft'} paddingBottom={'20px'}>
          <ThumbnailWrapper width={'250px'}>
            <Image src={props.image} objectFit={'cover'} width={'100%'} />
          </ThumbnailWrapper>
          <VerticalView
            width={appLayout.isMobile ? 'calc(100% - 80px)' : 'calc(100% - 250px)'}
            alignContent={'centerLeft'}
            paddingLeft={'20px'}
            paddingRight={'20px'}
          >
            <TitleWrapper size={appSize.h2}>{props.title}</TitleWrapper>
            <DescriptionWrapper size={appSize.h4}>{props.description}</DescriptionWrapper>

            <HorizontalView marginTop={'10px'} fullWidth alignContent={'centerLeft'}>
              <Text fontSize={appSize.h5} color={AppColors.DARK}>
                {dateUtil.dateFormat(props.time || '', 'MMM D, YYYY')}
              </Text>
              <Text fontSize={appSize.h5} padding={'10px'} color={AppColors.DARK}>
                -
              </Text>
              <CategoryWrapper size={appSize.h5}>{props.category?.toUpperCase()}</CategoryWrapper>
            </HorizontalView>
          </VerticalView>
        </HorizontalView>
      )}

      <Hr />
    </CardWrapper>
  );
};
