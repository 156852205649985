import * as queries from './queries';
import * as mutations from './mutation';

export const homeQueries = {
  ...queries,
};

export const homeMutations = {
  ...mutations,
};
