import { AppColors } from 'helpers';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Div } from 'vendors/Wrapper';
import { Image } from 'vendors/Image';
import { Text } from 'vendors/Text';
import { useAppSetting } from 'hooks/useAppSetting';

type PropsType = {
  image: string;
  path?: string;
  size?: string;
  padding?: string;
  margin?: string;
  name?: string;
};

export const RoundedIcon = (props: PropsType) => {
  const navigate = useNavigate();
  const { appSize } = useAppSetting();

  return (
    <Div
      minWidth={props.size || '50px'}
      width={props.size || '50px'}
      margin={props.margin || '5px 10px'}
      padding={props.padding || '0px'}
    >
      <Div
        minWidth={props.size || '50px'}
        height={props.size || '50px'}
        borderRadius="50%"
        overflow={'hidden'}
        border={`2px solid ${AppColors.PRIMARY}`}
        onClick={() => {
          if (props.path) navigate(props.path);
        }}
      >
        <Image width="100%" height="100%" objectFit="fill" src={props.image} />
      </Div>
      <Div fullWidth paddingTop="5px" height="25px" overflow={'hidden'} alignContent="center">
        <Text fontSize={appSize.h3}>{props.name}</Text>
      </Div>
    </Div>
  );
};
