import { useSearchLocation } from 'api/hooks/location/useSearchLocation';
import { useCreateBussinessAccount } from 'api/hooks/user/useCreateBusinessAccount';
import { BusinessRegisterComponent } from 'components/user-interface/customer-register';
import { ErrorCodes } from 'helpers/const';
import { useTranslationText } from 'locale';
import { IRegisterBusinessAccountInput } from 'models/Company';
import React from 'react';
import { openNotification } from 'vendors/Notification';

export const BusinessRegisterContainer = () => {
  const { translation } = useTranslationText();
  const { registBussinessAccount, loading } = useCreateBussinessAccount();
  const { locations, searchLocation, loading: locationLoading } = useSearchLocation();
  const [isSuccess, setIsSuccess] = React.useState(false);
  const onRegister = async (input: IRegisterBusinessAccountInput) => {
    const result = await registBussinessAccount(input);
    console.log(result);
    if (!result.error && result.account) {
      setIsSuccess(true);
    } else {
      if (
        result.error?.code === ErrorCodes.ERROR_CODE_EMAIL_EXIST ||
        result.error?.code === ErrorCodes.ERROR_CODE_ITEM_EXIST
      ) {
        openNotification({
          type: 'error',
          message: translation('register.emailExist'),
        });
      } else {
        openNotification({
          type: 'error',
          message: translation('message.error'),
        });
      }
    }
  };

  const getLocation = (code?: string) => {
    searchLocation({
      code,
      limit: 1,
      page: 1,
    });
  };

  return (
    <BusinessRegisterComponent
      isLoading={loading}
      isSuccess={isSuccess}
      locationLoading={locationLoading}
      handleSubmitForm={(values) => {
        const input = {
          ...values,
          streetAddress: 'なし',
          homephone: 'なし',
        } as IRegisterBusinessAccountInput;
        onRegister(input);
      }}
      getLocation={getLocation}
      locations={locations}
    />
  );
};

export default BusinessRegisterContainer;
