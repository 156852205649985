import React from 'react';
import { useParams } from 'react-router';
import { VerifyAccountComponent } from 'components/user-interface/verify-account';
import { useVerifyAccount } from 'api/hooks/user/useUserApi';

export const VerifyAccountContainer = () => {
  const { token } = useParams();
  const { onVerifyAccount } = useVerifyAccount();
  const [loading, setLoading] = React.useState(true);
  const [success, setSuccess] = React.useState(false);

  async function verify() {
    if (token) {
      const result = await onVerifyAccount({ token });
      setSuccess(!result.error);
      setLoading(false);
    }
  }

  React.useEffect(() => {
    verify();
  }, []);

  return <VerifyAccountComponent isSuccess={success} isLoading={loading} />;
};

export default VerifyAccountContainer;
