import { helpQueries } from 'api/graphql/help-center';
import { IHelp } from 'models/Help';
import { useEffect } from 'react';
import { useBaseGetQuery } from '../base/useBaseGetQuery';

export const useGetHelp = (id?: string) => {
  const { getQuery, loading, item } = useBaseGetQuery<{ id: string }, IHelp>({
    query: helpQueries.GET,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (id) {
      getQuery({ id });
    }
  }, [id]);

  return {
    loading,
    help: item,
    getHelp: getQuery,
  };
};
