import { downloadMutations } from 'api/graphql/download';
import { IRequestDownloadFileInput } from 'models/Download';
import { useBaseMutation } from '../base/useBaseMutation';

export const useRequestDownloadFile = () => {
  const { mutation, loading } = useBaseMutation<IRequestDownloadFileInput, boolean>({
    mutation: downloadMutations.REQUEST_DOWNLOAD,
  });

  return {
    requestDownload: mutation,
    loading,
  };
};
