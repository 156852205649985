/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components';
import { WrapperAlignProps } from './Type';
import { alignContentColumn, getViewStyle } from './Utils';

const Wrapper = styled.div<{ cursor: 'pointer' | 'auto' | undefined; css?: string }>`
  ${(props) => (props.cursor ? `cursor: ${props.cursor}` : '')};
  ${(props) => props.css || ''};
`;

export const Div = React.forwardRef<HTMLDivElement | null, WrapperAlignProps>((props, ref) => {
  const baseStyle = getViewStyle(props);
  const alignContentStyle = alignContentColumn(props);
  if (!props.flexWrap) {
    alignContentStyle.flexWrap = 'wrap';
  }

  props.display && (alignContentStyle.display = props.display);
  const mixStyle = { ...alignContentStyle, ...baseStyle };

  return (
    <Wrapper
      id={props.id}
      ref={ref}
      style={mixStyle}
      cursor={props.onClick ? 'pointer' : undefined}
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      css={props.css}
    >
      {props.children}
    </Wrapper>
  );
});
