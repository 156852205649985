import { IChatMessage, IMessageType } from 'containers/cms-containers/chat/type/Chat';
import React from 'react';
import { AvatarProps } from 'react-gifted-chat';
import { Image } from 'vendors/Image';

export const RenderAvatar = (props: AvatarProps<IChatMessage>) => {
  const currentMessage = props.currentMessage;
  if (currentMessage?.type === IMessageType.Notification) {
    return null;
  }
  if (!currentMessage?.user.avatar?.length) {
    return <Image src={'/images/avatar.png'} width={'35px'} height={'35px'} />;
  }
  return (
    <Image src={currentMessage?.user.avatar} width={'35px'} height={'35px'} objectFit={'cover'} borderRadius={'20px'} />
  );
};
