export const ERROR_MESSAGE = {
  //COMMON
  ERR_0000: {
    code: 'ERR_0000',
    title: '',
    message: '',
  },
  ERR_0001: {
    code: 'ERR_0001',
    title: 'サーバーエラー。',
    message: 'サーバーエラー。もう一回確認してください。',
  },
  ERR_0002: {
    code: 'ERR_0002',
    title: 'CSVファイル。',
    message: 'CSVファイルをアップロードしてください。',
  },
  ERR_0003: {
    code: 'ERR_0003',
    title: 'ログイン',
    message: 'ログインしてください。',
  },
  ERR_0004: {
    code: 'ERR_0004',
    title: 'サーバーエラー。',
    message: 'サーバーエラー。もう一回確認してください。',
  },
  ERR_0005: {
    code: 'ERR_0005',
    title: '管理者アカウント。',
    message: '管理者ではないです。管理者アカウントをログインしてください。',
  },
  ERR_0006: {
    code: 'ERR_0006',
    title: '権限がないです。',
    message: '権限がないです。',
  },
  ERR_0007: {
    code: 'ERR_0007',
    title: 'データ見つかりません。',
    message: 'データ見つかりません。',
  },
  ERR_0008: {
    code: 'ERR_0008',
    title: 'データエラー',
    message: 'データエラー',
  },
  ERR_0009: {
    code: 'ERR_0009',
    title: 'データ更新エラー',
    message: 'データ更新エラー',
  },
  ERR_0010: {
    code: 'ERR_0010',
    title: 'データ削除エラー',
    message: 'データ削除エラー',
  },
  //USER ERR_U***
  ERR_U000: {
    code: 'ERR_U000',
    title: 'ユーザー。',
    message: 'ユーザーが見つかりませんでした。',
  },
  ERR_U001: {
    code: 'ERR_U001',
    title: 'ユーザー。',
    message: 'ユーザーIDまたはパスワードが無効です. もう一度やり直してください',
  },
  ERR_U101: {
    code: 'ERR_U101',
    title: 'ログイン',
    message: 'ユーザーが存在しません。',
  },
  ERR_U102: {
    code: 'ERR_1102',
    title: 'ログイン',
    message: 'アカウントはロックされています。',
  },
  ERR_U103: {
    code: 'ERR_U103',
    title: 'ログイン',
    message: 'パスワードが違います. もう一度やり直してください。',
  },
  ERR_U104: {
    code: 'ERR_U104',
    title: 'ログイン',
    message: 'IDまたはパスワードが違います. もう一度やり直してください',
  },
  ERR_U105: {
    code: 'ERR_U105',
    title: 'エラー',
    message: 'アカウントはすでに存在しています',
  },
  ERR_U106: {
    code: 'ERR_U106',
    title: 'エラー',
    message: '認証されていないアカウント。 メールでアカウントを確認してください。',
  },
  ERR_U201: {
    code: 'ERR_U201',
    title: 'TOKEN',
    message: '',
  },
  ERR_U202: {
    code: 'ERR_U202',
    title: 'TOKEN',
    message: '',
  },
  ERR_U300: {
    code: 'ERR_U300',
    title: 'ユーザーの作成に失敗しました。',
    message: 'ユーザーの作成に失敗しました。',
  },
  ERR_U301: {
    code: 'ERR_U301',
    title: 'ユーザーが存在しています',
    message: 'ユーザーが存在しています。もう一度やり直してください。',
  },
  ERR_U302: {
    code: 'ERR_U302',
    title: 'メールが存在しています。',
    message: 'メールが存在しています。もう一度やり直してください。',
  },
  ERR_U303: {
    code: 'ERR_U302',
    title: 'ユーザーの更新。',
    message: 'ユーザーの更新に失敗しました。',
  },
  ERR_U304: {
    code: 'ERR_U304',
    title: 'リセットパスワード',
    message: '現在のパスワードが正しくありません. もう一度やり直してください',
  },
  ERR_U305: {
    code: 'ERR_U305',
    title: 'パスワードのリセット',
    message: 'パスワードのリセットに失敗しました。',
  },
  ERR_U306: {
    code: 'ERR_U306',
    title: 'ユーザーの削除',
    message: 'ユーザーの削除に失敗しました。',
  },
  ERR_U401: {
    code: 'ERR_U401',
    title: 'ユーザーのログアウト',
    message: 'ユーザーのログアウトに失敗しました。',
  },
  ERR_O501: {
    code: 'ERR_O501',
    title: '注文',
    message: '',
  },
  ERR_O502: {
    code: 'ERR_O501',
    title: '注文',
    message: '注文の作成に失敗しました。',
  },
  ERR_C600: {
    code: 'ERR_C600',
    title: '連絡先',
    message: '',
  },
  ERR_C601: {
    code: 'ERR_C601',
    title: '連絡先',
    message: '連絡先の作成に失敗しました。',
  },
  ERR_C602: {
    code: 'ERR_C602',
    title: '連絡先',
    message: '連絡先の削除に失敗しました。',
  },
  ERR_C603: {
    code: 'ERR_C603',
    title: '連絡先',
    message: '予約時間はすでに存在します',
  },

  SYSTEM_ERROR: {
    code: 500,
    title: 'サーバーエラー',
    message: 'サーバーエラー。もう一回確認してください。',
  },
  INVALID_USERNAME_OR_PASSWORD: {
    code: 402,
    title: 'ログイン失敗',
    message: 'ユーザーID、パスワードのいずれかに誤りがあります。',
  },
  INVALID_PASSWORD: {
    code: 402,
    title: 'ログイン失敗',
    message: 'パスワードのいずれかに誤りがあります。',
  },
  ACCOUNT_TEMPORARY_LOCKED: {
    code: 414,
    title: 'ログイン失敗',
    message: 'アカウントは一時的にロックされています。',
  },

  ACCOUNT_IS_NOT_VERIFY: {
    code: 410,
    title: 'ログイン失敗',
    message: 'メールアドレスが検証されていません',
  },

  TOKEN_IS_INVALID: {
    code: 407,
    title: '検証失敗',
    message: 'トーケンが存在しません',
  },

  ACCOUNT_IS_NOT_EXIST: {
    code: 407,
    title: 'ログイン失敗',
    message: 'アカウントが存在しません',
  },
  ARTICLE_IS_NOT_EXIST: {
    code: 407,
    title: '記事',
    message: 'この記事は存在しません',
  },
  ARTICLE_CREATE_ERROR: {
    code: 500,
    title: '記事の作成',
    message: '記事の作成が失敗しました',
  },
  ARTICLE_UPDATE_ERROR: {
    code: 400,
    title: '記事の変更',
    message: '記事の変更が失敗しました',
  },
  ARTICLE_DELETE_ERROR: {
    code: 400,
    title: '記事の削除',
    message: '記事の削除が失敗しました',
  },
  CATEGORY_CREATE_ERROR: {
    code: 500,
    title: 'カテゴリーの作成',
    message: 'カテゴリーの作成が失敗しました',
  },
  CATEGORY_UPDATE_ERROR: {
    code: 400,
    title: 'カテゴリーの変更',
    message: 'カテゴリーの変更が失敗しました',
  },
  CATEGORY_DELETE_ERROR: {
    code: 400,
    title: 'カテゴリーの削除',
    message: 'カテゴリーの削除が失敗しました',
  },
  PRODUCT_CREATE_ID_ERROR: {
    code: 407,
    title: 'プランのID',
    message: 'プランのIDはすでに存在します',
  },
  ERROR_CODE_IDENTITY_ID_IS_EXIST: {
    code: 433,
    title: 'ニックネーム',
    message: 'ニックネームは存在します',
  },
  UNABLE_GET_CHECKOUT_SOURCE: {
    code: 500,
    title: 'エラー',
    message: 'エラーが発生しました。もう一度やり直してください',
  },
  ERROR_VERIFY_MEMBERSHIP: {
    code: 401,
    title: 'メンバーシップ',
    message: 'メンバーシップを確認するときにエラーが発生する',
  },
  ERROR_CANCEL_MEMBERSHIP: {
    code: 401,
    title: 'プラン',
    message: 'プランのキャンセルを実施するときにエラーが発生する',
  },
};
