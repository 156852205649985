import { productMutations } from 'api/graphql/product';
import { useBaseMutation } from 'api/hooks/base/useBaseMutation';
import { useTranslationText } from 'locale';
import { openNotification } from 'vendors/Notification';

export const useDeleteProduct = () => {
  const { translation } = useTranslationText();
  const { loading, mutation } = useBaseMutation<{ id: string }, boolean>({
    mutation: productMutations.DELETE,
  });
  async function deleteProduct(params: { id: string }) {
    const result = await mutation(params);
    if (!result.error) {
      openNotification({
        type: 'success',
        message: translation('productPage.deleteSuccess'),
      });
    } else {
      openNotification({
        type: 'error',
        message: translation('message.error'),
        description: result.error.code + (result.error?.title || ''),
      });
    }
    return result;
  }

  return {
    loading,
    deleteProduct,
  };
};
