import { homeQueries } from 'api/graphql/home';
import { IHomePage } from 'models/HomePage';
import { useEffect } from 'react';
import { useBaseGetQuery } from '../base/useBaseGetQuery';

export const useGetAppLink = () => {
  const { item, getQuery } = useBaseGetQuery<null, IHomePage>({
    fetchPolicy: 'cache-and-network',
    query: homeQueries.GET_APP_LINK,
  });

  useEffect(() => {
    getQuery();
  }, []);

  return {
    iosLink: item?.company?.iosAppUrl || '',
    androidLink: item?.company?.androidAppUrl || '',
  };
};
