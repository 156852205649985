import gql from 'graphql-tag';

export const CHECK_AUTH = gql`
  query checkAuth {
    checkAuth {
      id
      email
      role
    }
  }
`;

export const GET = gql`
  query operationGetUser($input: OperationGetUserInput!) {
    operationGetUser(input: $input) {
      id
      snsId
      status
      companyId
      identityId
      lastChangeIdentityTime
      company {
        id
        identityId
        name
        status
        logo
        cover
        email
        description
        foundation
        representativeName
        postalCode
        stateOrCityName
        streetAddress
        buildingNameAndRoomName
        homephone
        telephone
        contact
        fax
        tax
        businessContent
        locationMapUrl
      }
      name
      token
      avatar
      role
      email
      gender
      birthday
      address
      isDeleted
      deletedAt
      provider
      isVerified
      isFollowed
      oldPassword
      password
      reason
      cover
      totalFollowers
      totalFollowing
      totalLikes
      passwordAttemptTimes
    }
  }
`;

export const SEARCH_USER = gql`
  query operationSearchUsers($input: OperationSearchUsersInput!) {
    operationSearchUsers(input: $input) {
      totalItems
      items {
        id
        name
        status
        isVerified
        email
        role
        avatar
        updatedTime
        createdTime
      }
    }
  }
`;

export const GET_PROFILE = gql`
  query getProfile($input: GetProfileInput!) {
    getProfile(input: $input) {
      id
      snsId
      status
      identityId
      companyId
      company {
        id
        identityId
        name
        status
        logo
        cover
        email
        description
        foundation
        representativeName
        postalCode
        stateOrCityName
        streetAddress
        buildingNameAndRoomName
        homephone
        telephone
        contact
        fax
        tax
        businessContent
        privacyPolicy
        termsOfService
        locationMapUrl
        iosAppUrl
        androidAppUrl
        createdBy
        updatedBy
        updatedTime
        createdTime
      }
      name
      token
      avatar
      role
      email
      gender
      birthday
      address
      provider
      isVerified
      isFollowed
      oldPassword
      password
      reason
      cover
      resume {
        id
        status
        avatar
        name
        birthday
        gender
        phone
        email
        address
        summary
        certificate
        workHistory
        education
        skill
        userId
        updatedTime
        createdTime
      }
      totalFollowers
      totalFollowing
      totalLikes
      createdBy
      updatedBy
      passwordAttemptTimes
      membership {
        id
        deviceType
        paymentServiceType
        userId
        packageId
        transactionId
        isMembership
        isTrialPeriod
        price
        planName
        planPrice
        planDesc
        localizedPlanName
        localizedPlanPrice
        localizedPlanDesc
        isAutoRenew
        purchaseDate
        expiresDate
        receipt
      }
      updatedTime
      createdTime
    }
  }
`;
