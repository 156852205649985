import VerifyAccountContainer from 'containers/user-container/verify-account';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

export const VerifyAccountRoutes = () => {
  return (
    <Routes>
      <Route path="/:token" element={<VerifyAccountContainer />} />
    </Routes>
  );
};
