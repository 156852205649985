import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Footer } from '../../components/common/components/PageFooter/Footer';
import { Header } from '../../components/common/components/Header/Header';
import { BodyContentWrapper } from '../../vendors/CommonWrappers';
import { useAppSetting } from 'hooks/useAppSetting';
import { ArticleRouter } from './Article';
import { AboutRouter } from './About';
import { ContactRouter } from './Contact';
import { HomeRouter } from './Home';
import { NotFoundRouter } from './Notfound';
import { LoginRouter } from './Login';
import { EmployerPageRouter } from './Employer';
import { EmployeePageRouter } from './Customer';
import { PrivacyRouter } from './Privacy';
import { VideosRouter } from './VideoDetail';
import { ResetPasswordRoute } from './ResetPassword';
import { VerifyAccountRoutes } from './VerifyAccount';
import { TermOfServiceRoutes } from './TermOfService';
import { PlanRouter } from './Plan';
import { CheckoutRouter } from './Checkout';
import { HelpPageRouter } from './Help';
import { BusinessRegisterRoutes } from './BusinessRegister';
import ComercialLawContainer from 'containers/user-container/specified-commercial-law';

export const PublicRouter = () => {
  const { appLayout } = useAppSetting();

  return (
    <div>
      <Header />
      <BodyContentWrapper isMobile={appLayout.isMobile}>
        <Routes>
          <Route index element={<HomeRouter />} />
          <Route path={'about'} element={<AboutRouter />} />
          <Route path={'contact'} element={<ContactRouter />} />
          <Route path={'plan'} element={<PlanRouter />} />
          <Route path={'checkout/*'} element={<CheckoutRouter />} />
          <Route path={'articles/*'} element={<ArticleRouter />} />
          <Route path={'articles/*'} element={<ArticleRouter />} />
          <Route path={'videos/*'} element={<VideosRouter />} />
          <Route path={'login'} element={<LoginRouter />} />
          <Route path={'helps/*'} element={<HelpPageRouter />} />
          <Route path={'privacy/*'} element={<PrivacyRouter />} />
          <Route path={'terms-of-service'} element={<TermOfServiceRoutes />} />
          <Route path={'specified-commercial-law'} element={<ComercialLawContainer />} />
          <Route path={'employer/*'} element={<EmployerPageRouter />} />
          <Route path={'employee/*'} element={<EmployeePageRouter />} />
          <Route path={'reset-password/*'} element={<ResetPasswordRoute />} />
          <Route path={'verify-account/*'} element={<VerifyAccountRoutes />} />
          <Route path={'business-account-register/*'} element={<BusinessRegisterRoutes />} />
          <Route path={'*'} element={<NotFoundRouter />} />
        </Routes>
        <Footer />
      </BodyContentWrapper>
    </div>
  );
};
