import React from 'react';
import styled from 'styled-components';
import { AppConst } from 'helpers';
import { useLocation } from 'react-router-dom';
import { MobileHeaderView } from './MobileHeaderView';
import { Div, HorizontalView } from 'vendors/Wrapper';
import { useSession } from 'stores/userStore';
import { useAppSetting } from 'hooks/useAppSetting';

const TopHeaderWrapper = styled.div<{ borderColor: string; background: string }>`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.background};
  border-bottom: 1px solid ${(props) => props.borderColor};
`;

export const CmsHeader = () => {
  const location = useLocation();
  const { session } = useSession();
  const { appTheme, appLayout } = useAppSetting();

  const isMobile = appLayout.type === 'mobile' || appLayout.type === 'tablet';

  let height = '60px';

  if (location.pathname.includes('/cms') || location.pathname.includes('/user')) {
    if (!location.pathname.includes('/user/exam/')) {
      height = '40px';
    }
  }

  return (
    <HorizontalView
      id={'header'}
      width={appLayout.header.width}
      height={appLayout.isMobile ? '60px' : height}
      position={'fixed'}
      left={'0px'}
      top={'0px'}
      zIndex={AppConst.zIndex.header + 1}
    >
      <TopHeaderWrapper background={appTheme.header.bg} borderColor={appTheme.header.border}>
        <Div fullWidthHeight>
          <MobileHeaderView
            isMobile={isMobile}
            isCMS
            path={location.pathname}
            auth={session.user}
            isSessionLoaded={session.isLoaded}
          />
        </Div>
      </TopHeaderWrapper>
    </HorizontalView>
  );
};

export default CmsHeader;
