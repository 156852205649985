import { IChatMessage } from 'containers/cms-containers/chat/type/Chat';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useChatManager } from './useChatManager';

export const useChatting = () => {
  const { roomId } = useParams();
  const { state, onLoadMessagesEarliers, loadMessages, onJoinRoom, onLeaveRoom, onSendMessage } = useChatManager();

  /**
   * Load load messages earliers after joined room
   */
  useEffect(() => {
    if (state.currentRoom?.room?.id) {
      loadMessages({ roomId: state.currentRoom.room.id, limit: state.currentRoom.limit });
    }
  }, [state.currentRoom?.room?.id]);

  const onSend = useCallback(
    (_messages: IChatMessage[]) => {
      if (!roomId) {
        return;
      }
      onSendMessage(_messages[0], roomId, (message) => {
        if (message) {
        }
      });
    },
    [roomId],
  );

  useEffect(() => {
    if (roomId) {
      onJoinRoom(roomId);
    }
    return () => {
      if (roomId) {
        onLeaveRoom(roomId);
      }
    };
  }, [roomId]);

  return {
    loading: state.currentRoom?.loading,
    hasMore: state.currentRoom?.hasMore,
    roomId,
    messages: state.currentRoom?.messages || [],
    onSend,
    onLoadEarlier: onLoadMessagesEarliers,
  };
};
