import React from 'react';
import { AppColors } from 'helpers';
import { Button } from 'vendors/Button';
import { CardWrapper } from 'vendors/CommonWrappers';
import { Input } from 'vendors/Input';
import { VerticalView, HorizontalView, Div } from 'vendors/Wrapper';
import { Text } from 'vendors/Text';
import { useTranslationText } from 'locale';
import { Formik, FormikProps } from 'formik';
import { useAppSetting } from 'hooks/useAppSetting';
import { BaseComponent } from 'components/common/components/BaseComponent';
import { loginValidatorSchema } from 'validators/loginValidators';

type LoginPros = {
  isLoading: boolean;
  handleSubmitForm: (values: LoginFormik) => void;
};

export type LoginFormik = {
  email: string;
  password: string;
};

export const LoginComponent = (props: LoginPros) => {
  const ref = React.useRef<FormikProps<LoginFormik>>(null);
  const { translation } = useTranslationText();
  const { appLayout, appSize } = useAppSetting();

  React.useEffect(() => {
    document.title = 'Homepage - Login';
  }, []);

  const isMobile = appLayout.isMobile;

  return (
    <BaseComponent scrollToTop>
      <VerticalView fullWidthHeight alignContent={'center'} height={'1000px'} marginTop={'55px'}>
        <CardWrapper padding={'0px'} width={isMobile ? '90%' : '450px'} noShadow={false}>
          <VerticalView fullWidth paddingTop={'0px'} background={AppColors.WHITE}>
            <Text
              fontWeight={600}
              marginTop={'25px'}
              fontSize={appLayout.isMobile ? appSize.h3 : appSize.h2}
              marginBottom={'20px'}
              color={AppColors.DARK}
            >
              {translation('public.login.title')}
            </Text>
          </VerticalView>
          <Formik
            innerRef={ref}
            initialValues={{ email: '', password: '' }}
            validationSchema={loginValidatorSchema}
            //login check
            onSubmit={(values: LoginFormik) => {
              props.handleSubmitForm(values);
            }}
          >
            {({ values, submitCount, errors, handleChange }) => {
              return (
                <VerticalView
                  width={'100%'}
                  alignContent={'center'}
                  background={AppColors.WHITE}
                  padding={'20px'}
                  paddingTop={'15px'}
                >
                  <VerticalView width={'100%'}>
                    <Div width={'100%'} marginBottom={'28px'}>
                      <Input
                        minWidth={'100px'}
                        disabled={props.isLoading}
                        id={'email'}
                        value={values.email}
                        placeholder={'public.register.email'}
                        onChange={handleChange}
                        height={'40px'}
                        background={AppColors.WHITE}
                        error={submitCount && errors.email ? errors.email : undefined}
                      />
                    </Div>
                    <Div width={'100%'} marginBottom={'20px'}>
                      <Input
                        disabled={props.isLoading}
                        id={'password'}
                        fontSize={'13px'}
                        type={'password'}
                        height={'40px'}
                        value={values.password}
                        background={AppColors.WHITE}
                        onChange={handleChange}
                        placeholder={'public.register.password'}
                        error={submitCount ? errors.password : undefined}
                      />
                    </Div>
                  </VerticalView>
                  <HorizontalView fullWidth paddingTop={'20px'} alignContent={'center'}>
                    <Button
                      loading={props.isLoading}
                      disabled={props.isLoading}
                      width={'300px'}
                      height={'30px'}
                      title={'public.login.loginButton'}
                      fontSize={'14px'}
                      background={AppColors.BLUE_LIGHT}
                      color={AppColors.WHITE}
                      onClick={() => {
                        if (ref.current) {
                          ref.current.handleSubmit();
                        }
                      }}
                    />
                  </HorizontalView>
                </VerticalView>
              );
            }}
          </Formik>
        </CardWrapper>
      </VerticalView>
    </BaseComponent>
  );
};

export default React.memo(LoginComponent);
