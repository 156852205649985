import { BaseComponent } from 'components/common/components/BaseComponent';
import { BottomBar } from 'components/common/components/BottomBar';
import { useTranslationText } from 'locale';
import React from 'react';
import { IReport, IReportType } from 'models/Report';
import { useAppSetting } from 'hooks/useAppSetting';
import { Div, HorizontalView, VerticalView } from 'vendors/Wrapper';
import { Loading } from 'vendors/Loading';
import { CardWrapper } from 'vendors/CommonWrappers';
import { TextView } from 'vendors/TextView';
import { Hr } from 'vendors/CommonParts';
import { dateUtil } from 'helpers/date.utils';
import { useNavigate } from 'react-router-dom';
import { IRole } from 'models/types';
import { Text } from 'vendors/Text';
import { TextLink } from 'vendors/TextLink';
import { AppColors } from 'helpers';

type PropsType = {
  loading: boolean;
  report: IReport;
};

export const CMSReportDetailComponents = (props: PropsType) => {
  const { translation } = useTranslationText();
  const navigate = useNavigate();
  const { appLayout, appTheme, appSize } = useAppSetting();

  const reportItems = [
    {
      label: translation('report.reasons.rule'),
      value: 'reportRule',
    },
    {
      label: translation('report.reasons.spam'),
      value: 'reportSpam',
    },
    {
      label: translation('report.reasons.trouble'),
      value: 'reportTrouble',
    },
    {
      label: translation('report.reasons.sex'),
      value: 'reportSexContent',
    },
    {
      label: translation('report.reasons.violence'),
      value: 'reportViolence',
    },
    {
      label: translation('report.reasons.other'),
      value: 'reportOther',
    },
  ];

  return (
    <BaseComponent documentTitle={translation('contactDetails')}>
      <Div fullWidth>
        <Loading loading={props.loading} />
        {props.report?.id && (
          <HorizontalView fullWidthHeight paddingTop={'20px'} alignContent="center">
            <VerticalView fullWidth maxWidth={'900px'}>
              <CardWrapper
                noShadow={appLayout.isMobile}
                borderTop={!appLayout.isMobile}
                borderColor={appTheme.sidebar.bg}
              >
                <VerticalView fullWidth overflow={'hidden'} alignContent={'centerLeft'}>
                  <h1>{translation('report.detail')}</h1>

                  <Div marginTop={'10px'} marginBottom={'10px'} alignContent={'centerLeft'}>
                    <TextView
                      label={translation('report.reasonType')}
                      value={reportItems.find((item) => item.value === props.report.reasonType)?.label || ''}
                      fontSize={appSize.h4}
                    />
                  </Div>
                  <Hr />
                  <Div marginTop={'10px'} marginBottom={'10px'} alignContent={'centerLeft'}>
                    <TextView
                      label={translation('report.type')}
                      value={props.report.type ? translation(`report.${props.report.type}`) : ''}
                      fontSize={appSize.h4}
                    />
                  </Div>
                  <Hr />
                  <Div marginTop={'10px'} marginBottom={'10px'} alignContent={'centerLeft'}>
                    <TextView
                      label={translation('report.content')}
                      value={props.report.content}
                      fontSize={appSize.h4}
                    />
                  </Div>

                  <Hr />
                  {props.report.type === IReportType.ReportVideo && props.report.video && (
                    <Div
                      marginTop={'10px'}
                      marginBottom={'10px'}
                      alignContent={'centerLeft'}
                      onClick={() => {
                        if (props.report.video?.id) {
                          navigate(`/cms/videos/${props.report.video.id}`);
                        }
                      }}
                    >
                      <VerticalView alignContent={'centerLeft'}>
                        <Text fontWeight={500} fontSize={appSize.h4} marginBottom={'6px'}>
                          {translation('report.reportedVideo')}
                        </Text>

                        <Text color={AppColors.BLUE} fontWeight={500} fontSize={appSize.h4}>
                          {props.report.video?.name}
                        </Text>
                      </VerticalView>
                    </Div>
                  )}
                  {props.report.type === IReportType.ReportVideo && <Hr />}
                  {props.report.reportUser && props.report.type === IReportType.ReportUser && (
                    <Div marginTop={'10px'} marginBottom={'10px'} alignContent={'centerLeft'}>
                      <VerticalView fullWidth alignContent={'centerLeft'}>
                        <Text fontWeight={500} fontSize={appSize.h4} marginBottom={'6px'}>
                          {translation('report.reportedUser')}
                        </Text>
                        <TextLink
                          color={AppColors.BLUE}
                          href={`${
                            props.report.reportUser?.role === IRole.Customer
                              ? `/cms/customer/detail/${props.report.reportUser.id}`
                              : `/cms/users/${props.report.reportUser?.id}`
                          }`}
                          fontSize={appSize.h4}
                        >
                          {props.report.reportUser.name}
                        </TextLink>
                      </VerticalView>
                    </Div>
                  )}
                  {props.report.type === IReportType.ReportUser && <Hr />}

                  {props.report.reporter && (
                    <Div marginTop={'10px'} marginBottom={'10px'} alignContent={'centerLeft'}>
                      <Div marginTop={'10px'} marginBottom={'10px'} alignContent={'centerLeft'}>
                        <VerticalView fullWidth alignContent={'centerLeft'}>
                          <Text fontWeight={500} fontSize={appSize.h4} marginBottom={'6px'}>
                            {translation('report.reporter')}
                          </Text>
                          <TextLink
                            color={AppColors.BLUE}
                            href={`${
                              props.report.reporter?.role === IRole.Customer
                                ? `/cms/customer/detail/${props.report.reporter.id}`
                                : `/cms/users/${props.report.reporter?.id}`
                            }`}
                            fontSize={appSize.h4}
                          >
                            {props.report.reporter.name}
                          </TextLink>
                        </VerticalView>
                      </Div>
                    </Div>
                  )}

                  <Hr />
                  <Div marginTop={'10px'} marginBottom={'10px'} alignContent={'centerLeft'}>
                    <TextView
                      label={translation('createdTime')}
                      value={dateUtil.dateFormat(props.report.createdTime || '', 'YYYY/MM/DD HH:mm')}
                      fontSize={appSize.h4}
                    />
                  </Div>
                </VerticalView>
              </CardWrapper>
              <HorizontalView fullWidth>
                <Div marginTop={'20px'} width="50%">
                  <BottomBar path={'/cms/report'} />
                </Div>
              </HorizontalView>
            </VerticalView>
          </HorizontalView>
        )}
      </Div>
    </BaseComponent>
  );
};
