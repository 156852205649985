import { useLazyQuery, WatchQueryFetchPolicy } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { useMemo } from 'react';
import { IError } from 'models/Models';

type UseBaseGetQuery = {
  fetchPolicy?: WatchQueryFetchPolicy;
  query: DocumentNode;
};

export function useBaseGetQuery<TQuery = any, TData = any>(props: UseBaseGetQuery) {
  const [getQuery, { data, loading, error }] = useLazyQuery(props.query, { fetchPolicy: props.fetchPolicy });

  const item: TData = useMemo(() => {
    if (data) {
      const keys = Object.keys(data);
      if (keys?.length) {
        return data[keys[0]];
      }
      return undefined;
    } else {
      return undefined;
    }
  }, [data]);

  const onGetData = (input?: TQuery) => {
    if (input) {
      getQuery({ variables: { input } });
    } else {
      getQuery();
    }
  };

  return {
    /**
     * Loading call api
     */
    loading,
    /**
     * Error response
     */
    error: (error as Record<string, any>) as IError,
    /**
     * Result data
     */
    item: item,
    /**
     * Fn Get item data
     */
    getQuery: onGetData,
  };
}
