import { membershipMutations } from 'api/graphql/membership';
import { useBaseMutation } from 'api/hooks/base/useBaseMutation';
import { useTranslationText } from 'locale';
import { ICancelMembershipInput, IMembership } from 'models/Membership';
import { openNotification } from 'vendors/Notification';

export const useCancelMembership = () => {
  const { getError, translation } = useTranslationText();
  const { mutation, loading } = useBaseMutation<ICancelMembershipInput, { cancelMembership: IMembership }>({
    mutation: membershipMutations.CANCEL_MEMBERSHIP,
  });

  async function onCancelMembership(params: ICancelMembershipInput) {
    const result = await mutation(params);

    if (!result.error) {
      openNotification({
        type: 'success',
        message: translation('membership.cancelSuccess'),
      });
    } else {
      openNotification({
        type: 'error',
        message: getError('ERROR_CANCEL_MEMBERSHIP').message,
      });
    }

    return result;
  }

  return {
    loading,
    onCancelMembership,
  };
};
