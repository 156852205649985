import { useGetHelp } from 'api/hooks/help-center/useGetHelp';
import { HelpDetailsComponent } from 'components/user-interface/help/detail';
import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

export const HelpDetailsContainer = () => {
  const { getHelp, help, loading: getHelpLoading } = useGetHelp();
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const lang = searchParams.get('lang');

  React.useEffect(() => {
    if (id) {
      getHelp({ id });
    }
  }, []);

  return <HelpDetailsComponent help={help} loading={getHelpLoading} lang={lang || 'ja'} />;
};

export default HelpDetailsContainer;
