import * as mutations from './mutations';
import * as queries from './queries';

export const videoMutations = {
  ...mutations,
};

export const videoQueries = {
  ...queries,
};
