import gql from 'graphql-tag';

export const CREATE = gql`
  mutation operationCreateArticle($input: OperationCreateArticleInput!) {
    operationCreateArticle(input: $input) {
      id
    }
  }
`;

export const UPDATE = gql`
  mutation operationUpdateArticle($input: OperationUpdateArticleInput!) {
    operationUpdateArticle(input: $input) {
      id
    }
  }
`;

export const DELETE = gql`
  mutation operationDeleteArticle($input: OperationDeleteArticleInput!) {
    operationDeleteArticle(input: $input)
  }
`;
