import React, { memo } from 'react';
import { MediaView } from 'components/common/components/Media';
import { BaseComponent } from 'components/common/components/BaseComponent';
import { Div } from 'vendors/Wrapper';
import { PageTitle } from 'components/common/components/PageTitle';

export const CMSMediaListComponent = () => {
  return (
    <BaseComponent>
      <Div fullWidth>
        <PageTitle title={'mediaCenter.title'} />
        <MediaView accept={'image,audio,video,application'} />
      </Div>
    </BaseComponent>
  );
};

export default memo(CMSMediaListComponent);
