import { WrapperAlignProps, WrapperProps } from './Type';

export function alignContentRow(props: WrapperAlignProps) {
  const baseStyle: React.CSSProperties = {};
  baseStyle.display = 'flex';
  baseStyle.flexDirection = 'column';
  baseStyle.justifyContent = 'flex-start';
  baseStyle.alignItems = 'center';

  switch (props.alignContent || 'left') {
    case 'topLeft':
      baseStyle.justifyContent = 'flex-start';
      baseStyle.alignItems = 'flex-start';
      break;
    case 'topCenter':
      baseStyle.justifyContent = 'flex-start';
      baseStyle.alignItems = 'center';
      break;
    case 'topRight':
      baseStyle.justifyContent = 'flex-start';
      baseStyle.alignItems = 'flex-end';
      break;
    case 'centerLeft':
      baseStyle.justifyContent = 'center';
      baseStyle.alignItems = 'flex-start';
      break;
    case 'center':
      baseStyle.justifyContent = 'center';
      baseStyle.alignItems = 'center';
      break;
    case 'centerRight':
      baseStyle.justifyContent = 'center';
      baseStyle.alignItems = 'flex-end';
      break;
    case 'bottomLeft':
      baseStyle.justifyContent = 'flex-end';
      baseStyle.alignItems = 'flex-start';
      break;
    case 'bottomCenter':
      baseStyle.justifyContent = 'flex-end';
      baseStyle.alignItems = 'center';
      break;
    case 'bottomRight':
      baseStyle.justifyContent = 'flex-end';
      baseStyle.alignItems = 'flex-end';
      break;
    case 'topBetween':
      baseStyle.justifyContent = 'space-between';
      baseStyle.alignItems = 'flex-start';
      break;
    case 'centerBetween':
      baseStyle.justifyContent = 'space-between';
      baseStyle.alignItems = 'center';
      break;
    case 'bottomBetween':
      baseStyle.justifyContent = 'space-between';
      baseStyle.alignItems = 'flex-end';
      break;
    case 'topAround':
      baseStyle.justifyContent = 'space-around';
      baseStyle.alignItems = 'flex-start';
      break;
    case 'centerAround':
      baseStyle.justifyContent = 'space-around';
      baseStyle.alignItems = 'center';
      break;
    case 'bottomAround':
      baseStyle.justifyContent = 'space-around';
      baseStyle.alignItems = 'flex-end';
      break;
    case 'topEvenly':
      baseStyle.justifyContent = 'space-evenly';
      baseStyle.alignItems = 'flex-start';
      break;
    case 'centerEvenly':
      baseStyle.justifyContent = 'space-evenly';
      baseStyle.alignItems = 'center';
      break;
    case 'bottomEvenly':
      baseStyle.justifyContent = 'space-evenly';
      baseStyle.alignItems = 'flex-end';
      break;
    case 'stretch':
      baseStyle.justifyContent = 'stretch';
      baseStyle.alignItems = 'stretch';
      break;
  }

  return baseStyle;
}

export function alignContentColumn(props: WrapperAlignProps) {
  const baseStyle: React.CSSProperties = {};
  baseStyle.display = 'flex';
  baseStyle.flexDirection = 'row';
  baseStyle.justifyContent = 'flex-start';
  baseStyle.alignItems = 'center';

  switch (props.alignContent || 'left') {
    case 'topLeft':
      baseStyle.justifyContent = 'flex-start';
      baseStyle.alignItems = 'flex-start';
      break;
    case 'topCenter':
      baseStyle.justifyContent = 'center';
      baseStyle.alignItems = 'flex-start';
      break;
    case 'topRight':
      baseStyle.justifyContent = 'flex-end';
      baseStyle.alignItems = 'flex-start';
      break;
    case 'centerLeft':
      baseStyle.justifyContent = 'flex-start';
      baseStyle.alignItems = 'center';
      break;
    case 'center':
      baseStyle.justifyContent = 'center';
      baseStyle.alignItems = 'center';
      break;
    case 'centerRight':
      baseStyle.justifyContent = 'flex-end';
      baseStyle.alignItems = 'center';
      break;
    case 'bottomLeft':
      baseStyle.justifyContent = 'flex-start';
      baseStyle.alignItems = 'flex-end';
      break;
    case 'bottomCenter':
      baseStyle.justifyContent = 'center';
      baseStyle.alignItems = 'flex-end';
      break;
    case 'bottomRight':
      baseStyle.justifyContent = 'flex-end';
      baseStyle.alignItems = 'flex-end';
      break;
    case 'topBetween':
      baseStyle.justifyContent = 'space-between';
      baseStyle.alignItems = 'flex-start';
      break;
    case 'centerBetween':
      baseStyle.justifyContent = 'space-between';
      baseStyle.alignItems = 'center';
      break;
    case 'bottomBetween':
      baseStyle.justifyContent = 'space-between';
      baseStyle.alignItems = 'flex-end';
      break;
    case 'topAround':
      baseStyle.justifyContent = 'space-around';
      baseStyle.alignItems = 'flex-start';
      break;
    case 'centerAround':
      baseStyle.justifyContent = 'space-around';
      baseStyle.alignItems = 'center';
      break;
    case 'bottomAround':
      baseStyle.justifyContent = 'space-around';
      baseStyle.alignItems = 'flex-end';
      break;
    case 'topEvenly':
      baseStyle.justifyContent = 'space-evenly';
      baseStyle.alignItems = 'flex-start';
      break;
    case 'centerEvenly':
      baseStyle.justifyContent = 'space-evenly';
      baseStyle.alignItems = 'center';
      break;
    case 'bottomEvenly':
      baseStyle.justifyContent = 'space-evenly';
      baseStyle.alignItems = 'flex-end';
      break;
    case 'stretch':
      baseStyle.justifyContent = 'stretch';
      baseStyle.alignItems = 'stretch';
      break;
  }

  return baseStyle;
}

export const getViewStyle = (props: WrapperProps) => {
  const baseStyle: React.CSSProperties = {};
  baseStyle.top = props.top;
  baseStyle.left = props.left;
  baseStyle.right = props.right;
  baseStyle.bottom = props.bottom;
  baseStyle.opacity = props.opacity;
  baseStyle.zIndex = props.zIndex;
  baseStyle.flex = props.flex;
  baseStyle.transition = props.transition;
  baseStyle.boxSizing = props.boxSizing;
  baseStyle.flexBasis = props.flexBasis;
  props.whiteSpace && (baseStyle.whiteSpace = props.whiteSpace);
  props.margin && (baseStyle.margin = props.margin);
  props.background && (baseStyle.backgroundColor = props.background);
  props.padding && (baseStyle.padding = props.padding);
  props.paddingTop && (baseStyle.paddingTop = props.paddingTop);
  props.paddingBottom && (baseStyle.paddingBottom = props.paddingBottom);
  props.paddingLeft && (baseStyle.paddingLeft = props.paddingLeft);
  props.paddingRight && (baseStyle.paddingRight = props.paddingRight);
  props.marginTop && (baseStyle.marginTop = props.marginTop);
  props.marginLeft && (baseStyle.marginLeft = props.marginLeft);
  props.marginRight && (baseStyle.marginRight = props.marginRight);
  props.marginBottom && (baseStyle.marginBottom = props.marginBottom);
  props.flexWrap && (baseStyle.flexWrap = props.flexWrap);
  props.height && (baseStyle.height = props.height);
  props.width && (baseStyle.width = props.width);
  props.minWidth && (baseStyle.minWidth = props.minWidth);
  props.overflowY && (baseStyle.overflowY = props.overflowY);
  props.overflowX && (baseStyle.overflowX = props.overflowX);
  props.overflow && (baseStyle.overflow = props.overflow);
  props.position && (baseStyle.position = props.position);
  props.maxWidth && (baseStyle.maxWidth = props.maxWidth);
  props.maxHeight && (baseStyle.maxHeight = props.maxHeight);
  props.minHeight && (baseStyle.minHeight = props.minHeight);
  props.borderRadius && (baseStyle.borderRadius = props.borderRadius);
  props.backgroundImage && (baseStyle.backgroundImage = props.backgroundImage);
  props.border && (baseStyle.border = props.border);
  props.fullWidth && (baseStyle.width = '100%');
  props.fullHeight && (baseStyle.height = '100%');
  if (props.fullWidthHeight) {
    baseStyle.width = '100%';
    baseStyle.height = '100%';
  }

  props.display && (baseStyle.display = props.display);

  return { ...baseStyle, ...(props.style ? props.style : {}) };
};
