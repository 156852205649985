import React, { useEffect, useState } from 'react';
import { BaseComponent } from 'components/common/components/BaseComponent';
import { Body, Div, HorizontalView, VerticalView } from 'vendors/Wrapper';
import { Loading } from 'vendors/Loading';
import { Input } from 'vendors/Input';
import { TextAreaInput } from 'vendors/TextAreaInput';
import { IUpdateUserInput, IUser } from 'models/User';
import { useAppSetting } from 'hooks/useAppSetting';
import moment from 'moment';
import { Text } from 'vendors/Text';
import { CardWrapper } from 'vendors/CommonWrappers';
import { Image } from 'vendors/Image';
import { Button } from 'vendors/Button';
import { AppColors } from 'helpers';
import { IRole } from 'models/types';
import { MembershipPlan } from './parts/MembershipPlan';
import { Hr } from 'vendors/CommonParts';
import { ICreateInvoiceInput, ITransaction } from 'models/Transaction';
import { DatePicker } from 'antd';
import { useTranslationText } from 'locale';
import { dateUtil } from 'helpers/date.utils';
import { numberWithCommas } from 'helpers/common.utils';
import { IMembership } from 'models/Membership';
import { ICompany } from 'models/Company';
import { BillingManager } from './parts/BillingManager';
import { ILocation } from 'models/Location';

type PropsType = {
  loading: boolean;
  cancelLoading: boolean;
  user?: IUser;
  loadingInvoice: boolean;
  onLogout?: () => void;
  totalRevenue?: number;
  transations?: ITransaction[];
  from: string;
  loadingTransaction: boolean;
  to: string;
  onChangeDate: (from: string, to: string) => void;
  newInvoice: (input: ICreateInvoiceInput) => void;
  onCancelMembership: (membership: IMembership) => void;
  onUpdateUserBussiness: (company: ICompany) => void;
  bussinessLoading: boolean;
  updateUser: (input: IUpdateUserInput) => void;
  getLocation: (code: string) => void;
  locations: ILocation[];
  searchLocationLoading: boolean;
};
const { RangePicker } = DatePicker;
export const UserProfileComponent = (props: PropsType) => {
  const { appSize, appLayout } = useAppSetting();
  const [state, setState] = useState(1);
  const { translation } = useTranslationText();
  const [preview, setPreview] = useState(true);
  const [company, setCompany] = useState<ICompany>({} as ICompany);

  console.log(props.locations);

  useEffect(() => {
    if (props.user) {
      setCompany({ ...props.user?.company });
    }
  }, [props.user]);
  useEffect(() => {
    if (props.locations.length === 1) {
      setCompany({
        ...company,
        stateOrCityName: props.locations[0]?.province + props.locations[0]?.city + props.locations[0]?.building,
      });
    }
  }, [props.locations]);
  return (
    <BaseComponent contentLoading={props.loading}>
      <Body maxWidth="1200px">
        {!appLayout.isMobile && (
          <HorizontalView fullWidth alignContent="topCenter">
            <VerticalView width="20%" marginTop="60px" alignContent="topCenter">
              <Div
                width="100%"
                height="40px"
                style={{
                  boxShadow:
                    state !== 2
                      ? ' rgba(0, 0, 0, 0.35) 1.95px 1.95px 2.6px'
                      : ' rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
                }}
              >
                <Button
                  width="100%"
                  fontWeight={600}
                  height="40px"
                  title="checkout.myProfile"
                  onClick={() => setState(1)}
                />
              </Div>
              {props.user?.role === 'CUSTOMER' && (
                <Div
                  width="100%"
                  height="40px"
                  style={{
                    boxShadow:
                      state !== 1
                        ? ' rgba(0, 0, 0, 0.35) 1.95px 1.95px 2.6px'
                        : ' rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
                  }}
                  marginTop="20px"
                >
                  <Button
                    width="100%"
                    fontWeight={600}
                    height="40px"
                    title="userDetails.invoice"
                    onClick={() => setState(2)}
                  />
                </Div>
              )}
            </VerticalView>
            {state === 1 && (
              <VerticalView width="70%" padding="20px">
                <CardWrapper padding="0px">
                  <Loading loading={props.loading} />
                  {props.user?.id && (
                    <VerticalView fullWidthHeight alignContent="topLeft">
                      <Div fullWidth alignContent={'centerLeft'} position={'relative'} height={'250px'}>
                        <Div fullWidthHeight position="absolute" overflow={'hidden'}>
                          <Image
                            width="100%"
                            height={'250px'}
                            src={props.user?.cover || '/images/application_background.png'}
                            objectFit={'cover'}
                          />
                          <Div
                            fullWidthHeight
                            position="absolute"
                            background="black"
                            opacity={0.1}
                            overflow={'hidden'}
                          />
                        </Div>
                        <HorizontalView
                          width="100%"
                          borderRadius="40px"
                          position="absolute"
                          left="15px"
                          bottom="-15px"
                          overflow={'hidden'}
                          alignContent={'centerLeft'}
                        >
                          <Image
                            width="80px"
                            height="80px"
                            borderRadius="40px"
                            src={props.user?.avatar || '/images/avatar.png'}
                          />
                          <VerticalView
                            alignContent={'centerLeft'}
                            paddingLeft="10px"
                            height={'80px'}
                            paddingBottom={'15px'}
                          >
                            <Text color="white" fontSize="20px" fontWeight={500}>
                              {props.user?.name}
                            </Text>
                            {props.user?.identityId?.length && <Text color="white">@{props.user?.identityId}</Text>}
                          </VerticalView>
                        </HorizontalView>
                      </Div>

                      <VerticalView fullWidth alignContent="centerLeft" padding="20px" marginTop="20px">
                        {props.user.role === IRole.Customer && !props.user.membership?.isMembership && (
                          <Div fullWidth paddingBottom="20px" paddingTop="20px">
                            <Text textAlign="center">{translation('membershipWarning')}</Text>
                          </Div>
                        )}
                        <HorizontalView fullWidth alignContent="centerBetween">
                          {props.user.role === IRole.Customer && (
                            <HorizontalView maxWidth="400px" alignContent="centerLeft" paddingBottom="20px">
                              <MembershipPlan
                                loading={props.cancelLoading}
                                company={props.user}
                                membership={props.user.membership}
                                onCancelMembership={props.onCancelMembership}
                              />
                            </HorizontalView>
                          )}
                          <Div>
                            <Button
                              width="100px"
                              height="40px"
                              borderRadius="10px"
                              background={AppColors.PRIMARY}
                              color="#fff"
                              title={preview ? 'edit' : 'save'}
                              onClick={() => {
                                setPreview(!preview);
                                if (!preview) {
                                  props.onUpdateUserBussiness(company);
                                }
                              }}
                            />
                          </Div>
                        </HorizontalView>
                        <BillingManager membership={props.user.membership} />
                        <Input
                          label={`userDetails.email`}
                          isPreview
                          placeholder={`userDetails.email`}
                          name={'email'}
                          fontSize={appSize.md}
                          value={props.user.email}
                        />

                        {props.user.role === IRole.Customer && props.user.company && (
                          <VerticalView fullWidth alignContent="centerLeft">
                            <Div fullWidth marginTop={'15px'}>
                              <Input
                                isPreview={preview}
                                name={'company.foundation'}
                                label={'company.foundation'}
                                fontSize={'15px'}
                                placeholder={'company.foundation'}
                                value={company.foundation}
                                background={'transparent'}
                                onChange={async (e) => setCompany({ ...company, foundation: e.target.value })}
                              />
                            </Div>
                            <Div fullWidth marginTop={'15px'}>
                              <Input
                                isPreview={preview}
                                name={'company.representativeName'}
                                label={'company.representatorName'}
                                fontSize={'15px'}
                                placeholder={'company.representatorName'}
                                value={company.representativeName}
                                background={'transparent'}
                                onChange={async (e) => setCompany({ ...company, representativeName: e.target.value })}
                              />
                            </Div>
                            {/* <Div fullWidth marginTop={'15px'}>
                              <Input
                                isPreview={preview}
                                name={'company.tax'}
                                label={'company.tax'}
                                fontSize={'15px'}
                                placeholder={'company.tax'}
                                value={company.tax}
                                background={'transparent'}
                                onChange={async (e) => setCompany({ ...company, tax: e.target.value })}
                              />
                            </Div> */}
                            <HorizontalView fullWidth marginTop={'15px'} alignContent="centerBetween">
                              <Div width="80%">
                                <Input
                                  isPreview={preview}
                                  name={'company.postalCode'}
                                  label={'company.postalCode'}
                                  fontSize={'15px'}
                                  placeholder={'company.postalCode'}
                                  value={company.postalCode}
                                  background={'transparent'}
                                  onChange={async (e) => {
                                    setCompany({ ...company, postalCode: e.target.value });
                                  }}
                                />
                              </Div>
                              {!preview && (
                                <Div width="20%" marginTop="29px" alignContent="centerRight">
                                  <Button
                                    disabled={company.postalCode?.length !== 7}
                                    preixIcon="search"
                                    fontSize="14px"
                                    width="100px"
                                    borderRadius="10px"
                                    title="search"
                                    loading={props.searchLocationLoading}
                                    height="40px"
                                    color="#fff"
                                    background={AppColors.PRIMARY}
                                    onClick={() => {
                                      props.getLocation(company?.postalCode || '');
                                    }}
                                  />
                                </Div>
                              )}
                            </HorizontalView>
                            {company.postalCode &&
                              company.postalCode?.length > 0 &&
                              company.postalCode?.length !== 7 && (
                                <Text color="red">{translation('public.register.code')} </Text>
                              )}

                            {(props.locations.length === 1 || company.stateOrCityName) && (
                              <Div fullWidth marginTop={'15px'}>
                                <Input
                                  isPreview={preview}
                                  name={'company.stateOrCityName'}
                                  label={'company.city'}
                                  fontSize={'15px'}
                                  placeholder={'company.city'}
                                  value={company?.stateOrCityName}
                                  background={'transparent'}
                                  onChange={async (e) => setCompany({ ...company, stateOrCityName: e.target.value })}
                                />
                              </Div>
                            )}

                            <Div fullWidth marginTop={'15px'}>
                              <Input
                                isPreview={preview}
                                name={'company.buildingNameAndRoomName'}
                                label={'company.building'}
                                fontSize={'15px'}
                                placeholder={'company.building'}
                                value={company.buildingNameAndRoomName}
                                background={'transparent'}
                                onChange={async (e) =>
                                  setCompany({ ...company, buildingNameAndRoomName: e.target.value })
                                }
                              />
                            </Div>
                            <Div fullWidth marginTop={'15px'}>
                              <Input
                                isPreview={preview}
                                name={'company.telephone'}
                                label={'company.phone'}
                                fontSize={'15px'}
                                placeholder={'company.phone'}
                                value={company.telephone}
                                background={'transparent'}
                                onChange={async (e) => setCompany({ ...company, telephone: e.target.value })}
                              />
                            </Div>

                            <Div fullWidth marginTop={'15px'}>
                              <TextAreaInput
                                isPreview={preview}
                                name={'company.businessContent'}
                                label={'company.bussinessContent'}
                                size={'15px'}
                                placeholder={'company.bussinessContent'}
                                value={company.businessContent}
                                background={'transparent'}
                                onChange={async (e) => setCompany({ ...company, businessContent: e.target.value })}
                              />
                            </Div>
                            <Div fullWidth marginTop={'15px'}>
                              <TextAreaInput
                                isPreview={preview}
                                name={'company.description'}
                                label={'company.description'}
                                size={'15px'}
                                placeholder={'company.description'}
                                value={company.description}
                                background={'transparent'}
                                onChange={async (e) => setCompany({ ...company, description: e.target.value })}
                              />
                            </Div>
                          </VerticalView>
                        )}
                      </VerticalView>
                      <Div fullWidth marginTop={'25px'} alignContent={'center'} paddingBottom={'20px'}>
                        <Button
                          width="250px"
                          height="40px"
                          borderRadius="10px"
                          background={AppColors.RED2}
                          color={AppColors.WHITE}
                          title="signout"
                          onClick={props.onLogout}
                        />
                      </Div>
                    </VerticalView>
                  )}
                </CardWrapper>
              </VerticalView>
            )}
            {state === 2 && (
              <VerticalView width="70%" padding="20px">
                <CardWrapper padding="0px">
                  <Loading loading={props.loadingTransaction} />
                  {props.transations && (
                    <VerticalView fullWidthHeight alignContent="topLeft">
                      <HorizontalView fullWidth padding="20px">
                        <VerticalView width="350px" alignContent="center" padding="20px">
                          <RangePicker
                            allowClear={false}
                            defaultValue={[moment(props.from, 'YYYY/MM/DD'), moment(props.to, 'YYYY/MM/DD')]}
                            format={'YYYY/MM/DD'}
                            onChange={(values, formatString: string[]) => {
                              props.onChangeDate(formatString[0], formatString[1]);
                            }}
                          />
                        </VerticalView>
                        {props.transations.length > 0 && (
                          <Button
                            width="150px"
                            marginLeft="35px"
                            fontWeight={600}
                            height="35px"
                            title="membership.totalDownload"
                            background={state === 2 ? '#ffcd4d' : AppColors.PRIMARY}
                            onClick={() => props.newInvoice({ from: props.from, to: props.to })}
                          />
                        )}
                      </HorizontalView>

                      <Hr />
                      <HorizontalView width={'100%'} padding={'15px'}>
                        {/* <CardWrapper width={'100%'} borderRadius={'4px'}> */}
                        {/* <VerticalView> */}
                        <VerticalView width="100%">
                          <HorizontalView fullWidth padding="10px">
                            <Div width="25%">
                              <Text fontSize={appSize.h3} fontWeight={500}>
                                {translation('revenue.buyDate')}
                              </Text>
                            </Div>
                            <Div width="30%">
                              <Text fontSize={appSize.h3} fontWeight={500}>
                                {translation('revenue.platform')}
                              </Text>
                            </Div>
                            <Div width="25%">
                              <Text fontSize={appSize.h3} fontWeight={500}>
                                {translation('revenue.price')}
                              </Text>
                            </Div>
                            <Div width="20%" alignContent="center">
                              <Text fontSize={appSize.h3} fontWeight={500}>
                                {translation('revenue.download')}
                              </Text>
                            </Div>
                          </HorizontalView>
                          {props?.transations?.map((item, index) => {
                            return (
                              <HorizontalView key={index} fullWidth paddingBottom="10px">
                                <Div width="25%" padding="5px">
                                  <Text fontSize={appSize.h4}>
                                    {dateUtil.dateFormat(item?.purchaseDate || '', 'YYYY/MM/DD HH:mm')}
                                  </Text>
                                </Div>
                                <Div width="30%" padding="5px">
                                  <Text fontSize={appSize.h4}>{item.device}</Text>
                                </Div>
                                <Div width="25%">
                                  <Text fontSize={appSize.h4}>{numberWithCommas(item.price) + translation('yen')}</Text>
                                </Div>
                                <Div width="20%" alignContent="center">
                                  <Button
                                    preixIcon="download"
                                    fontSize="18px"
                                    onClick={() => props.newInvoice({ transactionId: item.id })}
                                  />
                                </Div>
                              </HorizontalView>
                            );
                          })}
                        </VerticalView>
                        {/* </VerticalView> */}
                        {/* </CardWrapper> */}
                      </HorizontalView>
                    </VerticalView>
                  )}
                </CardWrapper>
              </VerticalView>
            )}
          </HorizontalView>
        )}
        {appLayout.isMobile && (
          <VerticalView fullWidth alignContent="topCenter">
            {props.user?.role === IRole.Customer && (
              <HorizontalView width="100%" marginTop="60px" alignContent="topCenter">
                <Div
                  width="40%"
                  height="40px"
                  style={
                    state === 2
                      ? {
                          boxShadow:
                            'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                        }
                      : {
                          boxShadow: ' rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
                        }
                  }
                >
                  <Button
                    width="100%"
                    fontWeight={600}
                    fontSize="12px"
                    height="40px"
                    title="userDetails.invoice"
                    onClick={() => setState(1)}
                  />
                </Div>
                <Div
                  width="40%"
                  height="40px"
                  style={
                    state === 1
                      ? {
                          boxShadow:
                            'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                        }
                      : {
                          boxShadow: ' rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
                        }
                  }
                >
                  <Button
                    width="100%"
                    fontWeight={600}
                    fontSize="12px"
                    height="40px"
                    title="userDetails.invoice"
                    onClick={() => setState(2)}
                  />
                </Div>
              </HorizontalView>
            )}

            {state === 1 && (
              <VerticalView width="100%" padding="20px">
                <CardWrapper padding="0px">
                  <Loading loading={props.loading} />
                  {props.user?.id && (
                    <VerticalView fullWidthHeight alignContent="topLeft">
                      <Div fullWidth alignContent={'centerLeft'} position={'relative'} height={'150px'}>
                        <Div fullWidthHeight position="absolute" overflow={'hidden'}>
                          <Image
                            width="100%"
                            height={'150px'}
                            src={props.user?.cover || '/images/application_background.png'}
                            objectFit={'cover'}
                          />
                          <Div
                            fullWidthHeight
                            position="absolute"
                            background="black"
                            opacity={0.1}
                            overflow={'hidden'}
                          />
                        </Div>
                        <HorizontalView
                          width="100%"
                          borderRadius="40px"
                          position="absolute"
                          left="15px"
                          bottom="-15px"
                          overflow={'hidden'}
                          alignContent={'centerLeft'}
                        >
                          <Image
                            width="80px"
                            height="80px"
                            borderRadius="40px"
                            src={props.user?.avatar || '/images/avatar.png'}
                          />
                          <VerticalView
                            alignContent={'centerLeft'}
                            paddingLeft="10px"
                            height={'80px'}
                            paddingBottom={'15px'}
                          >
                            <Text color="white" fontSize="20px" fontWeight={500}>
                              {props.user?.name}
                            </Text>
                            {props.user?.identityId?.length && <Text color="white">@{props.user?.identityId}</Text>}
                          </VerticalView>
                        </HorizontalView>
                      </Div>

                      <VerticalView fullWidth alignContent="centerLeft" padding="20px" marginTop="20px">
                        {props.user.role === IRole.Customer && (
                          <VerticalView maxWidth="400px" alignContent="centerLeft" paddingBottom="20px">
                            <MembershipPlan
                              loading={props.cancelLoading}
                              company={props.user}
                              membership={props.user.membership}
                              onCancelMembership={props.onCancelMembership}
                            />
                          </VerticalView>
                        )}
                        <Div fullWidth alignContent="center" paddingBottom="20px">
                          <Button
                            width="100%"
                            height="40px"
                            borderRadius="10px"
                            background={AppColors.PRIMARY}
                            color="#fff"
                            title={preview ? 'edit' : 'save'}
                            onClick={() => {
                              setPreview(!preview);
                              if (!preview) {
                                props.onUpdateUserBussiness(company);
                              }
                            }}
                          />
                        </Div>
                        <BillingManager membership={props.user.membership} />
                        <Input
                          label={`userDetails.email`}
                          isPreview={preview}
                          placeholder={`userDetails.email`}
                          name={'email'}
                          fontSize={appSize.md}
                          value={props.user.email}
                        />

                        {props.user.role === IRole.Customer && props.user.company && (
                          <VerticalView fullWidth alignContent="centerLeft">
                            <Div fullWidth marginTop={'15px'}>
                              <Input
                                isPreview={preview}
                                name={'company.foundation'}
                                label={'company.foundation'}
                                fontSize={'15px'}
                                placeholder={'company.foundation'}
                                value={props.user.company.foundation}
                                background={'transparent'}
                              />
                            </Div>
                            <Div fullWidth marginTop={'15px'}>
                              <Input
                                isPreview={preview}
                                name={'company.representativeName'}
                                label={'company.representatorName'}
                                fontSize={'15px'}
                                placeholder={'company.representatorName'}
                                value={props.user.company.representativeName}
                                background={'transparent'}
                              />
                            </Div>
                            <Div fullWidth marginTop={'15px'}>
                              <Input
                                isPreview={preview}
                                name={'company.tax'}
                                label={'company.tax'}
                                fontSize={'15px'}
                                placeholder={'company.tax'}
                                value={props.user.company.tax}
                                background={'transparent'}
                              />
                            </Div>
                            {/* <Div fullWidth marginTop={'15px'}>
                              <Input
                                isPreview={preview}
                                name={'company.postalCode'}
                                label={'company.postalCode'}
                                fontSize={'15px'}
                                placeholder={'company.postalCode'}
                                value={props.user.company.postalCode}
                                background={'transparent'}
                              />
                            </Div>
                            <Div fullWidth marginTop={'15px'}>
                              <Input
                                isPreview={preview}
                                name={'company.stateOrCityName'}
                                label={'company.city'}
                                fontSize={'15px'}
                                placeholder={'company.city'}
                                value={props.user.company.stateOrCityName}
                                background={'transparent'}
                              />
                            </Div> */}
                            <HorizontalView fullWidth marginTop={'15px'} alignContent="centerBetween">
                              <Div width="70%">
                                <Input
                                  isPreview={preview}
                                  name={'company.postalCode'}
                                  label={'company.postalCode'}
                                  fontSize={'15px'}
                                  placeholder={'company.postalCode'}
                                  value={company.postalCode}
                                  background={'transparent'}
                                  onChange={async (e) => {
                                    setCompany({ ...company, postalCode: e.target.value });
                                  }}
                                />
                              </Div>
                              {!preview && (
                                <Div width="30%" marginTop="29px" alignContent="centerRight">
                                  <Button
                                    disabled={company.postalCode?.length !== 7}
                                    preixIcon="search"
                                    fontSize="14px"
                                    width="100%"
                                    title="search"
                                    loading={props.searchLocationLoading}
                                    height="40px"
                                    color="#fff"
                                    background={AppColors.PRIMARY}
                                    onClick={() => {
                                      props.getLocation(company?.postalCode || '');
                                    }}
                                  />
                                </Div>
                              )}
                            </HorizontalView>
                            {company.postalCode &&
                              company.postalCode?.length > 0 &&
                              company.postalCode?.length !== 7 && (
                                <Text color="red">{translation('public.register.code')} </Text>
                              )}

                            {(props.locations.length === 1 || company.stateOrCityName) && (
                              <Div fullWidth marginTop={'15px'}>
                                <Input
                                  isPreview={preview}
                                  name={'company.stateOrCityName'}
                                  label={'company.city'}
                                  fontSize={'15px'}
                                  placeholder={'company.city'}
                                  value={
                                    props.locations[0]?.province +
                                      props.locations[0]?.city +
                                      props.locations[0]?.building || company?.stateOrCityName
                                  }
                                  background={'transparent'}
                                  onChange={async (e) => setCompany({ ...company, stateOrCityName: e.target.value })}
                                />
                              </Div>
                            )}
                            <Div fullWidth marginTop={'15px'}>
                              <Input
                                isPreview={preview}
                                name={'company.buildingNameAndRoomName'}
                                label={'company.building'}
                                fontSize={'15px'}
                                placeholder={'company.building'}
                                value={props.user.company.buildingNameAndRoomName}
                                background={'transparent'}
                              />
                            </Div>
                            <Div fullWidth marginTop={'15px'}>
                              <Input
                                isPreview={preview}
                                name={'company.telephone'}
                                label={'company.phone'}
                                fontSize={'15px'}
                                placeholder={'company.phone'}
                                value={props.user.company.telephone}
                                background={'transparent'}
                              />
                            </Div>

                            <Div fullWidth marginTop={'15px'}>
                              <TextAreaInput
                                isPreview={preview}
                                name={'company.businessContent'}
                                label={'company.bussinessContent'}
                                size={'15px'}
                                placeholder={'company.bussinessContent'}
                                value={props.user.company.businessContent}
                                background={'transparent'}
                              />
                            </Div>
                            <Div fullWidth marginTop={'15px'}>
                              <TextAreaInput
                                isPreview={preview}
                                name={'company.description'}
                                label={'company.description'}
                                size={'15px'}
                                placeholder={'company.description'}
                                value={props.user.company.description}
                                background={'transparent'}
                              />
                            </Div>
                          </VerticalView>
                        )}
                      </VerticalView>
                      <Div fullWidth marginTop={'25px'} alignContent={'center'} paddingBottom={'20px'}>
                        <Button
                          width="250px"
                          height="40px"
                          borderRadius="10px"
                          background={AppColors.RED2}
                          color={AppColors.WHITE}
                          title="signout"
                          onClick={props.onLogout}
                        />
                      </Div>
                    </VerticalView>
                  )}
                </CardWrapper>
              </VerticalView>
            )}
            {state === 2 && (
              <VerticalView width="100%" padding="20px">
                <CardWrapper padding="0px">
                  <Loading loading={props.loadingTransaction} />
                  {props.transations && (
                    <VerticalView fullWidthHeight alignContent="topLeft">
                      <VerticalView fullWidth>
                        <VerticalView width="350px" alignContent="center" marginTop="30px">
                          <RangePicker
                            allowClear={false}
                            defaultValue={[moment(props.from, 'YYYY/MM/DD'), moment(props.to, 'YYYY/MM/DD')]}
                            format={'YYYY/MM/DD'}
                            onChange={(values, formatString: string[]) => {
                              props.onChangeDate(formatString[0], formatString[1]);
                            }}
                          />
                        </VerticalView>
                        {props.transations.length > 0 && (
                          <Button
                            marginTop="10px"
                            marginBottom="20px"
                            width="150px"
                            fontWeight={600}
                            height="35px"
                            title="membership.totalDownload"
                            background={AppColors.PRIMARY}
                            onClick={() => props.newInvoice({ from: props.from, to: props.to })}
                          />
                        )}
                      </VerticalView>

                      <Hr />
                      <HorizontalView width={'100%'} padding={'15px'}>
                        {/* <CardWrapper width={'100%'} borderRadius={'4px'}> */}
                        {/* <VerticalView> */}
                        <VerticalView width="100%">
                          {props?.transations?.map((item, index) => {
                            return (
                              <VerticalView key={index} fullWidth paddingBottom="10px">
                                <HorizontalView fullWidth>
                                  <Div width="40%">
                                    <Text fontSize={appSize.h4} fontWeight={500}>
                                      {translation('revenue.buyDate')}
                                    </Text>
                                  </Div>
                                  <Div width="60%" padding="5px">
                                    <Text fontSize={appSize.h4}>
                                      {dateUtil.dateFormat(item?.purchaseDate || '', 'YYYY/MM/DD HH:mm')}
                                    </Text>
                                  </Div>
                                </HorizontalView>
                                <HorizontalView fullWidth>
                                  <Div width="40%">
                                    <Text fontSize={appSize.h5} fontWeight={500}>
                                      {translation('revenue.platform')}
                                    </Text>
                                  </Div>
                                  <Div width="60%" padding="5px">
                                    <Text fontSize={appSize.h4}>{item.device}</Text>
                                  </Div>
                                </HorizontalView>
                                <HorizontalView fullWidth>
                                  <Div width="40%">
                                    <Text fontSize={appSize.h5} fontWeight={500}>
                                      {translation('revenue.price')}
                                    </Text>
                                  </Div>
                                  <Div width="60%">
                                    <Text fontSize={appSize.h4}>
                                      {numberWithCommas(item.price) + translation('yen')}
                                    </Text>
                                  </Div>
                                </HorizontalView>

                                <Div width="100%" alignContent="center" marginBottom="10px">
                                  <Button
                                    marginTop="15px"
                                    background={AppColors.PRIMARY}
                                    loading={props.loadingInvoice}
                                    preixIcon="download"
                                    fontSize="14px"
                                    title="revenue.download"
                                    width="120px"
                                    onClick={() => props.newInvoice({ transactionId: item.id })}
                                  />
                                </Div>
                                <Hr />
                              </VerticalView>
                            );
                          })}
                        </VerticalView>
                        {/* </VerticalView> */}
                        {/* </CardWrapper> */}
                      </HorizontalView>
                    </VerticalView>
                  )}
                </CardWrapper>
              </VerticalView>
            )}
          </VerticalView>
        )}
        <Loading overlay loading={props.loadingInvoice} />
      </Body>
    </BaseComponent>
  );
};

export default React.memo(UserProfileComponent);
