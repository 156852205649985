import { helpMutations } from 'api/graphql/help-center';
import { IError } from 'models/Models';
import { useBaseMutation } from '../base/useBaseMutation';

export const useCreateHelp = () => {
  const { mutation, loading } = useBaseMutation<{ createHelp: string }>({
    mutation: helpMutations.CREATE,
  });

  async function onCreateHelp(): Promise<{ error?: IError; id?: string }> {
    const { data, error } = await mutation();
    if (error || !data?.createHelp) {
      return { error };
    }
    return { id: data?.createHelp };
  }

  return {
    loading,
    onCreateHelp,
  };
};
