import { useTranslationText } from 'locale';
import React, { useState } from 'react';
import styled from 'styled-components';
import * as colors from '../../helpers/colors';
import { DropZone } from '../Dropzone';
import { Text } from '../Text';
import { HorizontalView, VerticalView } from '../Wrapper';

type IFileUpload = {
  files?: FileList;
};

export type AcceptFileType = 'csv' | 'image' | 'audio' | 'video' | 'image,audio,video,application' | 'application';

type UploadProps = {
  accept: AcceptFileType;
  title?: string;
  reset?: boolean;
  hideUploadButton?: boolean;
  isImportLoading?: boolean;
  onSelect?: (files: FileList) => void;
  onUpload?: (files?: FileList) => void;
};

const FileUploaderUploadInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`;
const UploadWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
const UploadBox = styled.div`
  margin: auto;
  min-width: 320px;
`;
const UploadButton = styled.button`
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid ${colors.GRAY_LIGHT};
  cursor: pointer;
`;

const acceptType = (type: AcceptFileType) => {
  switch (type) {
    case 'csv':
      return '.csv';
    case 'image':
      return 'image/*';
    case 'audio':
      return 'audio/mp3,audio/x-m4a';
    case 'video':
      return 'video/mp4,video/quicktime';
    case 'application':
      return 'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    case 'image,audio,video,application':
      return 'image/*,audio/mp3,audio/x-m4a,video/mp4,video/quicktime,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    default:
      return 'image/*,audio/mp3,audio/x-m4a,video/mp4,video/quicktime';
  }
};

const validType = (type: AcceptFileType) => {
  switch (type) {
    case 'csv':
      return '.csv';
    case 'image':
      return 'jpeg,png';
    case 'audio':
      return 'mp3,m4a';
    case 'video':
      return 'mp4,mov';
    case 'application':
      return 'pdf,doc,docx';
    case 'image,audio,video,application':
      return 'jpeg,png,mp3,m4a,mp4,mov,pdf,doc';
    default:
      return 'jpeg,png,mp3,m4a,mp4,mov';
  }
};

export const UploadComponent = (props: UploadProps) => {
  const [filesUpload, setFilesUpload] = useState({} as IFileUpload);
  const { translation } = useTranslationText();

  React.useEffect(() => {
    if (props.reset) {
      setFilesUpload({});
    }
  }, [props.reset]);

  return (
    <UploadWrapper>
      <UploadBox>
        <DropZone
          minHeight={'200px'}
          hasRemove={filesUpload.files ? true : false}
          isLoading={props.isImportLoading}
          onDrop={(files) => {
            setFilesUpload({ files: files });
            props.onSelect && props.onSelect(files);
          }}
          onRemove={() => {
            setFilesUpload({});
          }}
        >
          <VerticalView width={'100%'} height={'100%'} alignContent={'center'}>
            {!filesUpload.files && (
              <Text fontSize={'20px'}>
                ({validType(props.accept)}){translation('media.message')}
              </Text>
            )}
            {filesUpload.files && (
              <HorizontalView paddingLeft={'40px'} paddingRight={'40px'} alignContent={'center'}>
                {Array.from(filesUpload.files).map((file, index) => {
                  return (
                    <div key={index}>
                      <Text fontSize={'17px'} marginRight={'10px'}>
                        {file.name}
                      </Text>
                    </div>
                  );
                })}
              </HorizontalView>
            )}
          </VerticalView>

          <FileUploaderUploadInput
            accept={acceptType(props.accept)}
            onChange={(e) => {
              if (e.target && e.target.files) {
                setFilesUpload({ files: e.target.files });
                props.onSelect && props.onSelect(e.target.files);
              }
            }}
            onClick={(event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
              const element = event.target as HTMLInputElement;
              element.value = '';
            }}
            type="file"
            multiple
          />
        </DropZone>
        {!props.hideUploadButton && (
          <HorizontalView alignContent={'centerRight'}>
            <UploadButton
              disabled={!filesUpload.files}
              onClick={(e) => {
                e.preventDefault();
                props.onUpload && props.onUpload(filesUpload.files);
              }}
            >
              <Text padding={'10px'} fontSize={'15px'} fontWeight={600}>
                Upload
              </Text>
            </UploadButton>
          </HorizontalView>
        )}
      </UploadBox>
    </UploadWrapper>
  );
};
