import { CMSReportDetailContainer } from 'containers/cms-containers/report/detail';
import { CMSReportListContainer } from 'containers/cms-containers/report/list';
import NotFoundContainer from 'containers/user-container/not-found';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

export const ReportRouter = () => {
  return (
    <Routes>
      <Route index element={<CMSReportListContainer />} />
      <Route path=":reportId" element={<CMSReportDetailContainer />} />
      <Route path="*" element={<NotFoundContainer />} />
    </Routes>
  );
};
