import { membershipMutations } from 'api/graphql/membership';
import { ErrorCodes } from 'helpers/const';
import { useBaseMutation } from 'api/hooks/base/useBaseMutation';
import { useTranslationText } from 'locale';
import { IChangeMembershipInput, IMembership } from 'models/Membership';
import { openNotification } from 'vendors/Notification';

export const useChangeMembership = () => {
  const { translation } = useTranslationText();
  const { mutation, loading } = useBaseMutation<IChangeMembershipInput, IMembership>({
    mutation: membershipMutations.CHANGE_MEMBERSHIP,
  });

  async function onChange(params: IChangeMembershipInput) {
    const result = await mutation(params);
    if (!result.error) {
      openNotification({
        type: 'success',
        message: translation('membership.changeSuccess'),
      });
    } else {
      if (result.error.code === ErrorCodes.ERROR_CODE_PACKAGE_NOT_EXIST) {
        openNotification({
          type: 'error',
          message: translation('membership.pagekageNotFound'),
        });
      } else {
        openNotification({
          type: 'error',
          message: translation('membership.changeError'),
        });
      }
    }
    return result;
  }

  return {
    loading,
    onChangeMembership: onChange,
  };
};
