import React from 'react';
import styled from 'styled-components';
import { useAppSetting } from 'hooks/useAppSetting';
import { Image } from 'vendors/Image';
import { HorizontalView, VerticalView } from 'vendors/Wrapper';
import { Text } from 'vendors/Text';
import { AppColors } from 'helpers';
import { HomeButtonAction } from './HomeButtonAction';

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  width: 100%;
`;

const ThumbnailWrapper = styled.div`
  width: 40%;
`;

const ServiceWrapper = styled.div`
  width: 100%;
  max-width: 1000px;
`;

interface AboutProps {
  key?: number;
  align: 'left' | 'right';
  title?: string;
  description?: string;
  image?: string;
  onDetails?: () => void;
}

export const ServiceCard = (props: AboutProps) => {
  const { appSize, appLayout, appTheme } = useAppSetting();
  const imageContent = (
    <ThumbnailWrapper>
      <Image src={props.image} objectFit={'contain'} width={'100%'} />
    </ThumbnailWrapper>
  );

  const description = (
    <VerticalView
      minWidth={'280px'}
      width={appLayout.isMobile ? '100%' : '50%'}
      alignContent={'center'}
      padding={'20px'}
    >
      <Text color={AppColors.BLACK} width={'100%'} textAlign={'center'} fontWeight={600} fontSize={appSize.h2}>
        {props.title}
      </Text>
      <Text
        color={AppColors.DARK}
        marginTop={'15px'}
        textAlign={'left'}
        width={'100%'}
        fontSize={appSize.h3}
        whiteSpace={'pre-wrap'}
      >
        {props.description}
      </Text>

      {props.onDetails && (
        <HorizontalView
          fullWidth
          position={'relative'}
          alignContent={'center'}
          paddingTop={'30px'}
          paddingBottom={'20px'}
        >
          <HomeButtonAction title={'details'} onClick={props.onDetails} />
        </HorizontalView>
      )}
    </VerticalView>
  );

  if (appLayout.isMobile) {
    return (
      <CardWrapper>
        <ServiceWrapper>
          <VerticalView fullWidth alignContent={'centerBetween'} background={appTheme.base.white}>
            <HorizontalView paddingBottom={'10px'}>
              <Image src={props.image} objectFit={'contain'} width={'100%'} height={'200px'} />
            </HorizontalView>
            {description}
          </VerticalView>
        </ServiceWrapper>
      </CardWrapper>
    );
  } else {
    return (
      <CardWrapper>
        <VerticalView
          zIndex={2}
          fullWidth
          alignContent={'center'}
          background={appTheme.base.white}
          paddingTop={'60px'}
          paddingBottom={'60px'}
        >
          <ServiceWrapper>
            {props.align === 'left' && (
              <HorizontalView fullWidth alignContent={'centerBetween'}>
                {description}
                {imageContent}
              </HorizontalView>
            )}
            {props.align !== 'left' && (
              <HorizontalView fullWidth alignContent={'centerBetween'}>
                {imageContent}
                {description}
              </HorizontalView>
            )}
          </ServiceWrapper>
        </VerticalView>
      </CardWrapper>
    );
  }
};
