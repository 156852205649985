import * as yup from 'yup';
export const businessValidatorSchema = yup.object().shape({
  email: yup.string().required('validator.requiredEmail'),
  password: yup
    .string()
    .min(8, 'validator.passwordMin')
    .max(20, 'validator.passwordMax')
    .matches(/^(?=.*[0-9])(?=.*[a-zA-Z])([\x20-\x7E]+)$/, 'validator.passwordInvalid')
    .required('validator.passwordRequired'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), undefined], 'validator.passwordNotMatch')
    .required('validator.passwordConfirmRequired'),
  companyName: yup.string().required('validator.companyNameRequired'),
  companyDescription: yup.string().required('validator.requiredDescription'),
  companyFoundation: yup.string().required('validator.companyFoundationRequired'),
  companyRepresentativeName: yup.string().required('validator.companyRepresentativeName'),
  postalCode: yup.string().required('validator.postalCodeRequired'),
  stateOrCityName: yup.string().required('validator.stateOrCityNameRequired'),
  telephone: yup.string().required('validator.requiredPhone'),
  businessContent: yup.string().required('validator.businessContentRequired'),
});
