import React from 'react';
import styled from 'styled-components';
import { HorizontalView, VerticalView } from 'vendors/Wrapper';
import { TableWrapper } from 'vendors/CommonParts';
import { CreateUserModal } from './parts/CreateUserModal';
import { Loading } from 'vendors/Loading';
import { BaseComponent } from 'components/common/components/BaseComponent';
import { WebTableView } from './parts/WebTableView';
import { CmsSearchUserBar } from './parts/CmsSearchUserBar';
import { PageTitle } from 'components/common/components/PageTitle';
import { PaginationBar } from 'components/common/components/Pagination/PaginationBar';
import { IOperationCreateUserInput, IUser } from 'models/User';
import { ISendMultipleEmailInput, ISendMultipleEmailOutput } from 'models/types';
import { useTranslationText } from 'locale';
import { openNotification } from 'vendors/Notification';
import { SendMailModal } from 'components/common/components/Email/EmailModal';

export const SearchWrapper = styled.div`
  width: 100%;
  padding: 10px;
`;

export const ModalWrapper = styled.div``;
export const AlertModalWrapper = styled.div``;
export const ContentWrapper = styled.div``;
export const AddUserWrapper = styled.div``;

interface UserListProps {
  loading: boolean;
  createLoading: boolean;
  deleteLoading: boolean;
  totalPages: number;
  currentPage: number;
  totalItems: number;
  users: IUser[];
  showCreate: boolean;
  setShowCreate: (showCreate: boolean) => void;
  onDelete: (userId: string) => void;
  onDetails: (userId: string) => void;
  onSearch: (keyword: string) => void;
  onCreate: (values: IOperationCreateUserInput) => void;
  onChangePage: (page: number) => void;
  onChangeLimit: (page: number) => void;
  sendingEmail: boolean;
  mailResult?: ISendMultipleEmailOutput;
  onSendEmail: (input: ISendMultipleEmailInput) => void;
}

export const UserSearchComponent = (props: UserListProps) => {
  const [checkedMail, setCheckedMail] = React.useState([] as string[]);
  const [sendMail, setSendMail] = React.useState(false);
  const [openSendMail, setOpenSendMail] = React.useState(false);
  const [sendAll, setSendAll] = React.useState(false);
  const { translation } = useTranslationText();

  const onCheckUncheck = (mail: string) => {
    const m = checkedMail;
    if (checkedMail.includes(mail)) {
      const index = checkedMail.indexOf(mail);
      m.splice(index, 1);
    } else {
      m.push(mail);
    }
    setCheckedMail(m);
    setSendMail(checkedMail.length > 0);
  };

  React.useEffect(() => {
    if (props.mailResult) {
      if (props.mailResult.failedEmail) {
        openNotification({
          type: 'error',
          message: translation('emailModal.sendFailed'),
          description: translation('emailModal.sendFailedMessage'),
        });
        if (sendAll) setSendAll(false);
        setCheckedMail(props.mailResult.failedEmail);
      } else {
        openNotification({
          type: 'success',
          message: translation('emailModal.sent'),
        });
        setOpenSendMail(!openSendMail);
        setSendAll(false);
      }
    }
  }, [props.mailResult]);

  return (
    <BaseComponent documentTitle={translation('usersSearch.header')}>
      <VerticalView fullWidth alignContent={'center'}>
        <Loading loading={props.createLoading || props.deleteLoading} />
        <PageTitle title={'usersSearch.header'} />
        <CmsSearchUserBar
          createLoading={props.createLoading}
          totalItems={props.totalItems}
          onSearch={(keyword) => props.onSearch(keyword)}
          onCreate={() => props.setShowCreate(true)}
          onChangeLimit={props.onChangeLimit}
          sendMailButton={sendMail}
          onOpenSendMail={() => {
            setOpenSendMail(!openSendMail);
          }}
          onOpenSendMailAll={() => {
            setSendAll(true);
            setOpenSendMail(!openSendMail);
          }}
        />
        <TableWrapper>
          <WebTableView
            loading={props.loading}
            users={props.users}
            onDelete={props.onDelete}
            onDetails={props.onDetails}
            checkedMail={checkedMail}
            onCheck={onCheckUncheck}
          />
        </TableWrapper>

        {props.totalPages && !props.loading && (
          <HorizontalView fullWidth alignContent={'centerRight'}>
            <PaginationBar
              totalPages={props.totalPages}
              currentPage={props.currentPage}
              onChangePage={props.onChangePage}
            />
          </HorizontalView>
        )}
        <SendMailModal
          open={openSendMail}
          sendAll={sendAll}
          emails={checkedMail}
          sendingEmail={props.sendingEmail}
          onSend={props.onSendEmail}
          onClose={() => {
            setOpenSendMail(!openSendMail);
            setSendAll(false);
          }}
        />
        <CreateUserModal
          showCreate={props.showCreate}
          createLoading={props.createLoading}
          onCreate={props.onCreate}
          onClose={() => {
            props.setShowCreate(false);
          }}
        />
      </VerticalView>
    </BaseComponent>
  );
};
