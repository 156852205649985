import { BaseComponent } from 'components/common/components/BaseComponent';
import { PageBodyHeader } from 'components/common/components/Header/PageBodyHeader';
import { SearchBar } from 'components/common/components/SearchBar/SearchBar';
import { SearchParams } from 'components/common/components/SearchBar/SearchBarCommon';
import { useAppSetting } from 'hooks/useAppSetting';
import { useTranslationText } from 'locale';
import { IHelpItem } from 'models/Help';
import React from 'react';
import { TableWrapper } from 'vendors/CommonParts';
import { VerticalView } from 'vendors/Wrapper';
import { WebTableView } from './parts/WebTableView';

type PropsType = {
  loading: boolean;
  helpList: IHelpItem[];
  onCreate: () => void;
  onDetails: (id: string) => void;
  onDelete: (id: string) => void;
  onSearch: (params: SearchParams) => void;
};

export const CmsHelpCenterList = React.memo((props: PropsType) => {
  const { translation } = useTranslationText();
  const { appLayout } = useAppSetting();
  return (
    <BaseComponent documentTitle={translation('helpTitle')}>
      <VerticalView fullWidth alignContent="center">
        {!appLayout.isMobile && <PageBodyHeader title={translation('helpTitle')} />}
        {/* <Body> */}
        <SearchBar
          placeholder={'keyword'}
          addTitle={'new'}
          paddingLeft={appLayout.isMobile ? '15px' : '0px'}
          paddingRight={appLayout.isMobile ? '15px' : '0px'}
          onSearch={(keyword) => props.onSearch({ keyword })}
          onCreate={() => props.onCreate()}
        />
        <TableWrapper>
          <WebTableView
            loading={props.loading}
            helps={props.helpList}
            onDelete={props.onDelete}
            onDetails={props.onDetails}
            onCreate={() => {
              props.onCreate();
            }}
          />
        </TableWrapper>
        {/* </Body> */}
      </VerticalView>
    </BaseComponent>
  );
});
