import { IChatMessage, IMessageType } from 'containers/cms-containers/chat/type/Chat';
import React from 'react';
import { Bubble, BubbleProps } from 'react-gifted-chat';
import { Text } from 'vendors/Text';
import { VerticalView } from 'vendors/Wrapper';

export const RenderBubble = (props: BubbleProps<IChatMessage>) => {
  const currentMessage = props.currentMessage;
  if (currentMessage?.type === IMessageType.Notification) {
    return (
      <VerticalView width={'100%'} alignContent={'center'} paddingTop={'20px'}>
        <Text>{currentMessage.text}</Text>
      </VerticalView>
    );
  }
  return (
    <div id={currentMessage?.id}>
      <Bubble {...props} />
    </div>
  );
};
