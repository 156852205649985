import { helpQueries } from 'api/graphql/help-center';
import { IHelpItem, ISearchHelpsInput } from 'models/Help';
import { useBaseSearchQuery } from '../base/useBaseSearchQuery';

export const useSearchHelp = () => {
  const { loading, items, onSearch, error } = useBaseSearchQuery<ISearchHelpsInput, IHelpItem>({
    fetchPolicy: 'cache-and-network',
    query: helpQueries.SEARCH,
  });
  return {
    loading: loading,
    help: items,
    error: error,
    onSearchHelp: onSearch,
  };
};
