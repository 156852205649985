import { BaseComponent } from 'components/common/components/BaseComponent';
import { PageTitle } from 'components/common/components/PageTitle';
import { PaginationBar } from 'components/common/components/Pagination/PaginationBar';
import { IReport } from 'models/Report';
import React from 'react';
import { TableWrapper } from 'vendors/CommonParts';
import { HorizontalView, VerticalView } from 'vendors/Wrapper';
import { CmsSearchReportBar } from './parts/CmsSearchReportBar';
import { ReportWebTable } from './parts/ReportWebTable';

type PropsType = {
  loading: boolean;
  page: number;
  limit: number;
  totalItems: number;
  totalPages: number;
  reports: IReport[];
  onDetails: (id: string) => void;
  onSearch: (keyword: string) => void;
  onDelete: (id: string) => void;
  onChangePage: (page: number) => void;
};

export const CMSReportListComponent = React.memo((props: PropsType) => {
  return (
    <BaseComponent>
      <VerticalView fullWidth>
        <PageTitle title={'report.header'} />
        <CmsSearchReportBar totalItems={props.totalItems} onSearch={(keyword) => props.onSearch(keyword)} />
        <TableWrapper>
          <ReportWebTable
            page={props.page}
            totalPages={props.totalPages}
            rowPerPage={props.limit}
            loading={props.loading}
            reports={props.reports}
            onDetails={props.onDetails}
            onDelete={props.onDelete}
          />
        </TableWrapper>
        {props.totalPages && !props.loading && (
          <HorizontalView fullWidth alignContent={'centerRight'}>
            <PaginationBar totalPages={props.totalPages} currentPage={props.page} onChangePage={props.onChangePage} />
          </HorizontalView>
        )}
      </VerticalView>
    </BaseComponent>
  );
});
