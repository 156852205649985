import React, { useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { Footer } from '../../components/common/components/PageFooter/Footer';
import { Header } from '../../components/common/components/Header/Header';
import { BodyContentWrapper } from '../../vendors/CommonWrappers';
import { useAppSetting } from 'hooks/useAppSetting';
import { NotFoundRouter } from 'routes/public/Notfound';
import { UserProfileContainer } from 'containers/user-container/user-profile';
import { PlashScreen } from 'components/common/components/Plash';
import { useSession } from 'stores/userStore';
import { IRole } from 'models/types';

export const UserRouter = () => {
  const { appLayout } = useAppSetting();
  const location = useLocation();
  const navigative = useNavigate();
  const { session } = useSession();

  useEffect(() => {
    if (!session.isLoaded) {
      return;
    }

    if (!session.isAuthenticated) {
      navigative('/');
      return;
    }

    if (session.user.role === IRole.Admin) {
      navigative('/cms/users');
    }
  }, [session.user.role, session.isLoaded]);

  if (
    !session.isLoaded ||
    session.user.role === IRole.Admin ||
    !session.isAuthenticated ||
    (location.pathname.includes('/user') && !location.pathname.includes('/user/me') && location.pathname.length > 6)
  ) {
    return <PlashScreen />;
  }

  return (
    <div>
      <Header />
      <BodyContentWrapper isMobile={appLayout.isMobile}>
        <Routes>
          <Route
            path={'me'}
            element={
              <Routes>
                <Route index element={<UserProfileContainer />} />
              </Routes>
            }
          />
          <Route path={'*'} element={<NotFoundRouter />} />
        </Routes>
        <Footer />
      </BodyContentWrapper>
    </div>
  );
};
