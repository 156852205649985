/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components';
import { WrapperAlignProps } from './Type';
import { alignContentColumn, getViewStyle } from './Utils';

const Div = styled.div<{ cursor: 'pointer' | 'auto' | undefined }>`
  ${(props) => (props.cursor ? `cursor: ${props.cursor}` : '')};
`;

export const HorizontalView = React.forwardRef<HTMLDivElement | null, WrapperAlignProps>((props, ref) => {
  const baseStyle = getViewStyle(props);
  const alignContentStyle = alignContentColumn(props);
  if (!props.flexWrap) {
    alignContentStyle.flexWrap = 'wrap';
  }

  props.display && (alignContentStyle.display = props.display);

  return (
    <Div
      id={props.id}
      ref={ref}
      style={{ ...baseStyle, ...alignContentStyle }}
      cursor={props.onClick ? 'pointer' : undefined}
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      {props.children}
    </Div>
  );
});
