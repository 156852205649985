import * as yup from 'yup';
export const loginValidatorSchema = yup.object().shape({
  email: yup.string().required('validator.requiredEmail'),
  password: yup
    .string()
    .min(8, 'validator.passwordMin')
    .max(20, 'validator.passwordMax')
    .matches(/^(?=.*[0-9])(?=.*[a-zA-Z])([\x20-\x7E]+)$/, 'validator.passwordInvalid')
    .required('validator.passwordRequired'),
});
