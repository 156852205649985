import React from 'react';
import { IRoom, IChatMessage } from 'containers/cms-containers/chat/type/Chat';

export const LIMIT_ROOM = 30;
export const LIMIT_MESSAGE = 30;

export type ChatStore = {
  state: ChatState;
  dispatch: React.Dispatch<Actions>;
};

export type CurrentRoom = {
  isFounded: boolean;
  loading: boolean;
  room?: IRoom;
  lastMessageTime?: string;
  limit: number;
  totalMessages: number;
  hasMore?: boolean;
  messages: IChatMessage[];
};

export type RoomList = {
  isNoRoom: boolean;
  loading: boolean;
  page: number;
  limit: number;
  rooms: IRoom[];
  hasMoreRoom?: boolean;
  totalRoms: number;
};

export type ChatState = {
  newMessages: IChatMessage[];
  roomList: RoomList;
  currentRoom?: CurrentRoom;
};

export type Actions =
  | { type: 'reset' }
  | { type: 'add-rooms'; rooms: IRoom[]; totalRoms: number }
  | { type: 'join-room'; room: IRoom }
  | { type: 'load-more-rooms' }
  | { type: 'leave-room'; roomId: string }
  | { type: 'loading-messages'; loading: boolean }
  | { type: 'add-message'; message: IChatMessage }
  | { type: 'shift' }
  | { type: 'new-message'; message: IChatMessage }
  | { type: 'add-messages-earliers'; messages: IChatMessage[]; totalMessages: number };

const shiftMessage = (state: ChatState) => {
  const newMessages = [...state.newMessages];
  if (newMessages.length) {
    newMessages.shift();
    return { ...state, newMessages: newMessages };
  }
  return { ...state, newMessages: [] };
};

const newMessage = (state: ChatState, newMessage: IChatMessage) => {
  const isExist = state.newMessages.some((msg) => msg.id === newMessage.id);

  if (!isExist) {
    return { ...state, newMessages: [...state.newMessages, newMessage] };
  }
  return state;
};

const addMessages = (state: ChatState, newMessages: IChatMessage[], totalMessages: number) => {
  const _state = { ...state };
  newMessages.forEach((message) => {
    // Update messages in chatting
    if (_state.currentRoom && _state.currentRoom.room?.id === message.roomId) {
      if (!_state.currentRoom.messages.length) {
        _state.currentRoom.messages = [];
      }
      if (!_state.currentRoom.messages.some((msg) => msg.id === message.id)) {
        const newMessage = { ...message };
        newMessage._id = message.id;
        newMessage.createdAt = new Date(message.createdTime);

        newMessage.user = {
          ...newMessage.user,
          _id: message.userId,
        };

        const newMessagesList = [newMessage, ..._state.currentRoom.messages].sort((a, b) =>
          a.createdAt > b.createdAt ? -1 : 1,
        );
        _state.currentRoom.messages = newMessagesList;
      }

      _state.currentRoom.totalMessages = totalMessages;
    }

    // Update lastMessage of room
    _state.roomList.rooms.forEach((room) => {
      if (room.id === message.roomId) {
        if (room.lastMessage && room.lastMessage.createdTime < message.createdTime) {
          room.lastMessage = message;
        }
        if (!room.lastMessage) {
          room.lastMessage = message;
        }
        if (_state.currentRoom?.room?.id === room.id) {
          room.lastMessage.isSeen = true;
        }
      }
    });
  });

  return _state;
};

const addMessage = (state: ChatState, newMessage: IChatMessage) => {
  const _state = { ...state };
  const totalMessages = _state.currentRoom?.totalMessages || 0;
  return addMessages(_state, [newMessage], totalMessages + 1);
};

const addMessageEarliers = (state: ChatState, newMessages: IChatMessage[], totalMessages: number) => {
  let _state = { ...state };
  if (_state.currentRoom) {
    _state.currentRoom.hasMore = totalMessages > newMessages.length;
  }
  _state = addMessages(_state, newMessages, totalMessages);
  if (_state.currentRoom) {
    _state.currentRoom.loading = false;
  }
  return _state;
};

const joinRoom = (state: ChatState, _room?: IRoom) => {
  const _state = { ...state };

  if (_room) {
    let isNew = true;
    _state.roomList.rooms.map((room) => {
      if (room.id === _room.id) {
        isNew = false;
        return { ...room, ..._room };
      }
      return room;
    });
    if (isNew) {
      _state.roomList.rooms = [_room, ..._state.roomList.rooms];
    }
  }

  _state.currentRoom = {
    isFounded: Boolean(_room),
    loading: Boolean(_room),
    room: _room,
    limit: LIMIT_MESSAGE,
    totalMessages: 0,
    messages: [],
  };

  return _state;
};

const leaveRoom = (state: ChatState, roomId: string) => {
  const _state = { ...state };
  if (_state.currentRoom?.room?.id === roomId) {
    _state.currentRoom = undefined;
  }
  return _state;
};

const addRooms = (state: ChatState, newRooms: IRoom[], totalRoms: number): ChatState => {
  const _rooms = [...state.roomList.rooms];
  const _newRooms = newRooms.filter((room) => !_rooms.some((r) => r.id === room.id)) || [];
  const rooms = [..._rooms, ..._newRooms];
  return {
    ...state,
    roomList: {
      ...state.roomList,
      rooms: rooms,
      loading: false,
      hasMoreRoom: rooms.length < totalRoms,
      isNoRoom: rooms.length === 0,
    },
  };
};

export function chatReducer(state: ChatState, action: Actions): ChatState {
  switch (action.type) {
    case 'reset':
      return {
        roomList: { rooms: [], totalRoms: 0, page: 1, limit: LIMIT_ROOM, isNoRoom: false, loading: false },
        newMessages: [],
      };
    case 'load-more-rooms':
      if (state.roomList.totalRoms > state.roomList.rooms.length) {
        const nextPage = state.roomList.hasMoreRoom ? state.roomList.page + 1 : state.roomList.page;
        return { ...state, roomList: { ...state.roomList, loading: true, page: nextPage } };
      }
      return state;
    case 'loading-messages':
      // eslint-disable-next-line no-case-declarations
      const _state = { ...state };
      if (_state.currentRoom) {
        _state.currentRoom.loading = action.loading;
      }
      return _state;
    case 'add-rooms':
      return addRooms(state, action.rooms, action.totalRoms);
    case 'join-room':
      return joinRoom(state, action.room);
    case 'leave-room':
      return leaveRoom(state, action.roomId);
    case 'new-message':
      return newMessage(state, action.message);
    case 'shift':
      return shiftMessage(state);
    case 'add-message':
      return addMessage(state, action.message);
    case 'add-messages-earliers':
      return addMessageEarliers(state, action.messages, action.totalMessages);
    default:
      return state;
  }
}
