import React from 'react';
import styled from 'styled-components';
import { ContentLoading } from 'vendors/Loading';
import { VerticalView, Div } from 'vendors/Wrapper';
import { AppColors } from 'helpers';
import { ArticleEditorInput } from 'vendors/ArticleEditor';
import { PageTitle } from 'components/common/components/PageTitle';
import { PrimaryButton } from 'components/common/components/Button/PrimaryButton';
import { BaseComponent } from 'components/common/components/BaseComponent';

const ContentWrapper = styled.div`
  width: 100%;
  padding-top: 20px;
`;

interface CreateBookProps {
  loading: boolean;
  updateLoading: boolean;
  companyTOS: string;
  onSave: (data: string) => void;
}

export const CMSTermOfServicesComponent = React.memo((props: CreateBookProps) => {
  const { loading } = props;
  const [contentEdit, setContent] = React.useState('');

  React.useEffect(() => {
    setContent(props.companyTOS);
  }, [props.companyTOS]);

  return (
    <BaseComponent contentLoading={props.loading}>
      <Div fullWidth>
        <PageTitle title={'company.termOfService'} />

        {loading && (
          <VerticalView fullWidth minHeight={'350px'} alignContent={'center'}>
            <ContentLoading loading color={AppColors.BLUE} />
          </VerticalView>
        )}

        {!loading && (
          <ContentWrapper>
            <VerticalView fullWidth alignContent={'centerLeft'}>
              <ArticleEditorInput
                isEditing
                placeholder={'company.privacy'}
                defaultValue={contentEdit}
                name={'details'}
                style={{ width: '100%' }}
                onChange={(content) => {
                  setContent(content);
                }}
              />
              <Div fullWidth marginTop={'20px'} alignContent={'centerRight'}>
                <PrimaryButton
                  loading={props.updateLoading}
                  icon={'save'}
                  title={'company.save'}
                  onClick={() => {
                    props.onSave(contentEdit);
                  }}
                />
              </Div>
            </VerticalView>
          </ContentWrapper>
        )}
      </Div>
    </BaseComponent>
  );
});
