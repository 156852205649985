import dayjs from 'dayjs';
import JapaneseHolidays from 'japanese-holidays';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);
import 'dayjs/locale/ja';
import 'dayjs/locale/vi';
import 'dayjs/locale/en';

/*
 * DAYJS BASIC FORTMAT
 *
 * YYYY  :   2018        Four-digit year
 * M     :   1-12        Month, beginning at 1
 * MM    :   01-12       Month, 2-digits
 * D     :   1-31        Day of month
 * DD    :   01-31       Day of month, 2-digits
 * d	    :   0-6	        The day of the week, with Sunday as 0
 * dd	  :   Su-Sa	      The min name of the day of the week
 * ddd	  :   Sun-Sat	    The short name of the day of the week
 * dddd	:   Sunday-Saturday	The name of the day of the week
 * H     :   0-23        Hours
 * HH    :   00-23       Hours, 2-digits
 * h	    :   1-12	      Hours, 12-hour clock
 * hh    :   01-12	      Hours, 12-hour clock, 2-digits
 * m	    :   0-59	      Minutes
 * mm	  :   00-59	      Minutes, 2-digits
 * s	   :   0-59	      Seconds
 * ss	  :   00-59	      Seconds, 2-digits
 */

type DateFormatType =
  | 'YYYY'
  | 'YYYY/MM/DD, dddd'
  | 'MM/DD'
  | 'MM/DD HH:mm'
  | 'MM/DD ddd'
  | 'YYYY/MM'
  | 'DD'
  | 'MM/DD'
  | 'MM'
  | '（ddd）'
  | 'ddd'
  | 'MM/MM (ddd)'
  | 'MM/MM \n (ddd)'
  | 'YYYY/MM/DD（月）　hh:mm'
  | 'YYYY/MM/DD HH:mm'
  | 'MM/DD HH:mm'
  | 'YYYY/MM/DD'
  | 'HH:mm'
  | 'H:mm'
  | 'MMM D, YYYY'
  | 'MMMM D, YYYY'
  | 'YYYY MMMM D';

export function dateFormat(value: string, format: DateFormatType): string {
  if (!value) {
    return '';
  }
  if (value.length === 0) {
    return '';
  }
  //"2020-11-23T00:00:00.000+09:00"
  const dateString = value.split('/').join('-').replace(' ', 'T');

  const date = dayjs(dateString);
  if (date.isValid()) {
    try {
      const formatted = date.locale(localStorage.getItem('QHC_LANG') || 'ja').format(format);
      return formatted;
    } catch (_) {}
  }

  return value;
}

export function getCurrentTime(format: DateFormatType): string {
  return dayjs()
    .locale(localStorage.getItem('QHC_LANG') || 'ja')
    .format(format);
}

export function formatTimeAgo(value: string): string {
  if (!value) {
    return '';
  }
  if (value.length === 0) {
    return '';
  }
  //"2020-11-23T00:00:00.000+09:00"
  return dayjs(value)
    .locale(localStorage.getItem('QHC_LANG') || 'ja')
    .fromNow();
}

export function getDateTime(time: string): dayjs.Dayjs {
  const dateString = time.split('/').join('-').replace(' ', 'T');
  const date = dayjs(dateString).locale(localStorage.getItem('QHC_LANG') || 'ja');
  return date;
}

export function isHoliday(date: string): boolean {
  try {
    const holiday = JapaneseHolidays.isHoliday(new Date(date));
    return Boolean(
      dateFormat(date, 'ddd') === 'T7' ||
        dateFormat(date, 'ddd') === 'CN' ||
        dateFormat(date, 'ddd') === '土' ||
        dateFormat(date, 'ddd') === '日' ||
        holiday,
    );
  } catch (error) {
    return false;
  }
}

export const dateUtil = {
  getDateTime: getDateTime,
  getCurrentTime: getCurrentTime,
  dateFormat: dateFormat,
  formatTimeAgo: formatTimeAgo,
  isHoliday,
};
