import React from 'react';
import { TableWrapper } from 'vendors/CommonParts';
import { IContact } from 'models/Contact';
import { useAppSetting } from 'hooks/useAppSetting';
import { ContactMobileTable } from './parts/ContactMobileTable';
import { ContactWebTable } from './parts/ContactWebTable';
import { BaseComponent } from 'components/common/components/BaseComponent';
import { PageTitle } from 'components/common/components/PageTitle';
import { CmsSearchContactBar } from './parts/CmsSearchContactBar';
import { VerticalView } from 'vendors/Wrapper/VerticalView';
import { PaginationBar } from 'components/common/components/Pagination/PaginationBar';
import { HorizontalView } from 'vendors/CommonWrappers/index';

interface Props {
  loading: boolean;
  page: number;
  limit: number;
  totalItems: number;
  totalPages: number;
  contacts: IContact[];
  onDetails: (id: string) => void;
  onSearch: (keyword: string) => void;
  onChangePage: (page: number) => void;
  onChangeLimit: (limit: number) => void;
}

export const CmsContactsSearchComponent = (props: Props) => {
  const { appLayout } = useAppSetting();

  return (
    <BaseComponent>
      <VerticalView fullWidth>
        <PageTitle title={'contactList'} />
        <CmsSearchContactBar
          totalItems={props.totalItems}
          onSearch={(keyword) => props.onSearch(keyword)}
          onChangeLimit={props.onChangeLimit}
        />
        <TableWrapper>
          {appLayout.isMobile && (
            <ContactMobileTable
              page={props.page}
              totalPages={props.totalPages}
              rowPerPage={props.limit}
              loading={props.loading}
              contacts={props.contacts}
              onDetails={props.onDetails}
            />
          )}
          {!appLayout.isMobile && (
            <ContactWebTable
              page={props.page}
              totalPages={props.totalPages}
              rowPerPage={props.limit}
              loading={props.loading}
              contacts={props.contacts}
              onDetails={props.onDetails}
            />
          )}
        </TableWrapper>
        {props.totalPages && !props.loading && (
          <HorizontalView fullWidth alignContent={'centerRight'}>
            <PaginationBar totalPages={props.totalPages} currentPage={props.page} onChangePage={props.onChangePage} />
          </HorizontalView>
        )}
      </VerticalView>
    </BaseComponent>
  );
};
