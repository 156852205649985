export const SUCCESS_TITLE = 'SUCCESS';
export const ERROR_TITLE = 'ERROR';
export const UPLOAD_SUCCESS = 'Upload successfully!';
export const UPLOAD_FAILED = 'Upload failed!';
export const CREATE_FAILED = 'Create failed!';
export const DELETE_FAILED = 'Delete failed!';
export const DELETE_SUCCESS = 'Delete successfully!';

export const CREATE_USER_SUCCESS = 'アカウント作成されました！';
export const DELETE_USER_SUCCESS = 'アカウント削除されました！';

export const DELETE_BOOK_SUCCESS = 'Book削除されました！';

export const DELETE_COURSE_SUCCESS = 'Course削除されました！';
