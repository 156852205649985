import gql from 'graphql-tag';
export const GET = gql`
  query getProduct($input: GetProductInput!) {
    getProduct(input: $input) {
      id
      platform
      type
      name
      description
      packageId
      price
      createdTime
      updatedTime
    }
  }
`;

export const SEARCH_PRODUCT = gql`
  query searchProducts($input: SearchProductsInput!) {
    searchProducts(input: $input) {
      totalItems
      items {
        id
        platform
        type
        name
        description
        packageId
        price
        createdTime
        updatedTime
      }
    }
  }
`;
