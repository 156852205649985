import gql from 'graphql-tag';

export const GET = gql`
  query getMyCompany {
    getMyCompany {
      id
      name
      status
      logo
      cover
      email
      description
      foundation
      representativeName
      postalCode
      stateOrCityName
      streetAddress
      buildingNameAndRoomName
      homephone
      telephone
      contact
      fax
      commercialLaw
      tax
      businessContent
      privacyPolicy
      termsOfService
      locationMapUrl
      iosAppUrl
      androidAppUrl
      createdBy
      updatedBy
      updatedTime
      createdTime
    }
  }
`;

export const GETPRIVACY = gql`
  query getMyCompany {
    getMyCompany {
      privacyPolicy
    }
  }
`;

export const GETTOS = gql`
  query getMyCompany {
    getMyCompany {
      termsOfService
    }
  }
`;

export const GET_C_LAW = gql`
  query getMyCompany {
    getMyCompany {
      commercialLaw
    }
  }
`;

export const GET_FOOTER = gql`
  query getMyCompany {
    getMyCompany {
      name
      description
      telephone
      postalCode
      stateOrCityName
      streetAddress
      buildingNameAndRoomName
    }
  }
`;
