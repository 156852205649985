import React from 'react';
import { Routes, Route } from 'react-router';
import { CheckoutSuccessContainer } from 'containers/user-container/checkout-success';

export const CheckoutRouter = () => {
  return (
    <Routes>
      <Route path="success" element={<CheckoutSuccessContainer />} />
    </Routes>
  );
};
