import React from 'react';
import styled from 'styled-components';
import { Div, HorizontalView } from 'vendors/Wrapper';
import { Input } from 'vendors/Input';
import { AppColors } from 'helpers';
import { VerticalView } from 'vendors/CommonWrappers';
import { Label } from 'vendors/Label';
import { PrimaryButton } from 'components/common/components/Button/PrimaryButton';
import { Text } from 'vendors/Text';
import { Hr } from 'vendors/CommonParts';
import { ITag, TagSelect } from 'components/common/components/Tag/TagSelect';
import { useTranslationText } from 'locale';
import { VideoStatus } from 'models/types';

export const SearchWrapper = styled.div`
  border: 1px solid ${AppColors.BORDER};
  border-radius: 25px;
  overflow: hidden;
  width: 80%;
`;

type SearchBarProps = {
  totalItems: number;
  onChangeLimit: (limit: number) => void;
  onSearch: (tags: VideoStatus[], params: string) => void;
  onSelectTags: (tags: VideoStatus[]) => void;
};

const Status = (props: { onSelectTags: (tags: string[]) => void }) => {
  const { translation } = useTranslationText();
  const [tags, setTags] = React.useState<ITag[]>([]);

  React.useEffect(() => {
    setTags([
      {
        value: VideoStatus.active,
        label: translation('videoPage.status.active'),
        isSelect: false,
      },
      {
        value: VideoStatus.inactive,
        label: translation('videoPage.status.inactive'),
        isSelect: false,
      },
      {
        value: VideoStatus.blocked,
        label: translation('videoPage.status.blocked'),
        isSelect: false,
      },
      {
        value: VideoStatus.pending,
        label: translation('videoPage.status.pending'),
        isSelect: true,
      },
      {
        value: VideoStatus.reject,
        label: translation('videoPage.status.reject'),
        isSelect: false,
      },
    ]);
  }, []);

  return (
    <TagSelect
      label={translation('status')}
      type={'status'}
      values={tags}
      setValues={setTags}
      onSelectTags={props.onSelectTags}
    />
  );
};

const SearchKeyword = (props: { onSearch: (key: string) => void }) => {
  const [keyword, setKeyword] = React.useState('');
  return (
    <HorizontalView fullWidth alignContent={'centerLeft'} paddingTop={'20px'}>
      <Div width={'350px'} paddingRight={'20px'}>
        <Input
          width={'100%'}
          height={'50px'}
          placeholder={'usersSearch.searchbar.keyword'}
          value={keyword}
          borderRadius={'5px'}
          onChange={(e) => {
            setKeyword(e.target.value);
          }}
          onPressEnter={() => {
            props.onSearch(keyword);
          }}
        />
      </Div>
      <Div>
        <PrimaryButton
          icon={'search'}
          title={'search'}
          onClick={() => {
            props.onSearch(keyword);
          }}
        />
      </Div>
    </HorizontalView>
  );
};

export const CmsSearchVideoBar = (props: SearchBarProps) => {
  const [tags, setTags] = React.useState([] as VideoStatus[]);
  return (
    <VerticalView fullWidth>
      <VerticalView width={'100%'} borderRadius={'5px'} alignContent={'centerLeft'} paddingBottom={'20px'}>
        <Status
          onSelectTags={(tags) => {
            setTags(tags as VideoStatus[]);
            props.onSelectTags(tags as VideoStatus[]);
          }}
        />
        <SearchKeyword onSearch={(keyword) => props.onSearch(tags, keyword)} />
      </VerticalView>
      <Hr />
      <HorizontalView fullWidth alignContent={'centerBetween'} paddingTop={'20px'} paddingBottom={'20px'}>
        <HorizontalView>
          <Text paddingRight={'20px'}>
            <Label label={'all'} />
            {` ${props.totalItems} `}
            <Label label={'item'} />
          </Text>
          {/* <SelectLimit onChangeLimit={props.onChangeLimit} /> */}
        </HorizontalView>
      </HorizontalView>
    </VerticalView>
  );
};
