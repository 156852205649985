import * as mutations from './mutations';
import * as queries from './queries';

export const categoryMutations = {
  ...mutations,
};

export const categoryQueries = {
  ...queries,
};
