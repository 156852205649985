import { helpMutations } from 'api/graphql/help-center';
import { useBaseMutation } from '../base/useBaseMutation';

export const useDeleteHelp = () => {
  const { mutation, loading } = useBaseMutation<{ id: string }, boolean>({
    mutation: helpMutations.DELETE,
  });

  return {
    deleteHelp: mutation,
    loading,
  };
};
