import { useBaseGetQuery } from 'api/hooks/base/useBaseGetQuery';
import { ICustomerPage, IHomePage, IUserPage } from 'models/HomePage';
import { useEffect } from 'react';
import { homeQueries } from 'api/graphql/home';

export const useGetHomeContent = () => {
  const { loading, item, getQuery, error } = useBaseGetQuery<null, IHomePage>({
    fetchPolicy: 'cache-and-network',
    query: homeQueries.GET,
  });

  useEffect(() => {
    getQuery();
  }, []);

  return {
    loading: loading,
    homePage: item,
    error: error,
    onGetHomePage: getQuery,
  };
};

export const useGetCustomerContent = () => {
  const { loading, item, getQuery, error } = useBaseGetQuery<null, ICustomerPage>({
    fetchPolicy: 'cache-and-network',
    query: homeQueries.GET_CUSTOMER_PAGE,
  });

  useEffect(() => {
    getQuery();
  }, []);

  return {
    loading: loading,
    customerPage: item,
    error: error,
    onGetCustomerPage: getQuery,
  };
};

export const useGetUserContent = () => {
  const { loading, item, getQuery, error } = useBaseGetQuery<null, IUserPage>({
    fetchPolicy: 'cache-and-network',
    query: homeQueries.GET_USER_PAGE,
  });

  useEffect(() => {
    getQuery();
  }, []);

  return {
    loading: loading,
    userPage: item,
    error: error,
    onGetUserPage: getQuery,
  };
};
