import React from 'react';
import styled from 'styled-components';
import { AppConst } from 'helpers';
import { Div, HorizontalView } from 'vendors/Wrapper';
import { WebHeaderBar } from './WebHeaderBar';
import { MobileHeaderBar } from './MobileHeaderBar';
import { useAppSetting } from 'hooks/useAppSetting';

const TopHeaderWrapper = styled.div<{ borderColor: string; background: string }>`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.background};
  border-bottom: 1px solid ${(props) => props.borderColor};
`;

export const Header = () => {
  const { appTheme, appLayout } = useAppSetting();
  const isMobile = appLayout.type === 'mobile' || appLayout.type === 'tablet';

  return (
    <HorizontalView
      id={'header'}
      width={appLayout.header.width}
      left={'0px'}
      top={'0px'}
      position={'fixed'}
      zIndex={AppConst.zIndex.header + 1}
    >
      <TopHeaderWrapper background={appTheme.header.bg} borderColor={appTheme.header.border}>
        <Div fullWidthHeight>
          <MobileHeaderBar isMobile={isMobile} />

          <WebHeaderBar isMobile={isMobile} />
        </Div>
      </TopHeaderWrapper>
    </HorizontalView>
  );
};

export default Header;
