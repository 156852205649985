import { BaseComponent } from 'components/common/components/BaseComponent';
import { Formik, FormikProps } from 'formik';
import React from 'react';
import { Button } from 'vendors/Button';
import { Div, HorizontalView, VerticalView } from 'vendors/Wrapper';
import { CardWrapper } from '../help/part';
import { Text } from 'vendors/Text';
import { useAppSetting } from 'hooks/useAppSetting';
import { AppColors } from 'helpers';
import { useTranslationText } from 'locale';
import { IRegisterBusinessAccountInput } from 'models/Company';
import { Input } from 'vendors/Input';
import { businessValidatorSchema } from 'validators/createBusinessValidators';
import { useNavigate } from 'react-router-dom';
import { ILocation } from 'models/Location';
import { Modal } from 'vendors/Modal';

type PropsType = {
  isLoading: boolean;
  handleSubmitForm: (values: IRegisterBusinessAccountInput) => void;
  isSuccess: boolean;
  getLocation: (code: string) => void;
  locationLoading: boolean;
  locations: ILocation[];
};

export const BusinessRegisterComponent = React.memo((props: PropsType) => {
  const { appLayout, appSize } = useAppSetting();
  const isMobile = appLayout.isMobile;
  const { translation } = useTranslationText();
  const navigate = useNavigate();
  const [openAlert, setOpenAlert] = React.useState(props.isSuccess);
  const ref = React.useRef<FormikProps<IRegisterBusinessAccountInput>>(null);

  React.useEffect(() => {
    if (props.isSuccess) {
      setOpenAlert(true);
    }
  }, [props.isSuccess]);

  React.useEffect(() => {
    if (props.locations.length) {
      if (ref && ref.current) {
        ref.current.setFieldValue(
          'stateOrCityName',
          props.locations[0]?.province + props.locations[0]?.city + props.locations[0]?.building,
        );
      }
    }
  }, [props.locations]);

  return (
    <BaseComponent scrollToTop>
      <VerticalView fullWidthHeight alignContent={'center'} marginTop={'55px'}>
        <CardWrapper padding={'0px'} width={isMobile ? '90%' : '70%'}>
          <VerticalView fullWidth paddingTop={'0px'} background={AppColors.WHITE}>
            <Text
              fontWeight={600}
              marginTop={'25px'}
              fontSize={appLayout.isMobile ? appSize.h3 : appSize.h2}
              marginBottom={'20px'}
              color={AppColors.DARK}
            >
              {translation('public.register.title')}
            </Text>
          </VerticalView>
          <Formik
            innerRef={ref}
            initialValues={{} as IRegisterBusinessAccountInput}
            validationSchema={businessValidatorSchema}
            //login check
            onSubmit={(values: IRegisterBusinessAccountInput) => {
              props.handleSubmitForm(values);
            }}
          >
            {({ values, submitCount, errors, handleChange }) => {
              return (
                <VerticalView
                  width={'100%'}
                  alignContent={'center'}
                  background={AppColors.WHITE}
                  padding={'20px'}
                  paddingTop={'15px'}
                >
                  <VerticalView width={'100%'}>
                    <Div width={'100%'} marginBottom={'28px'}>
                      <Input
                        required
                        minWidth={'100px'}
                        fontSize={'15px'}
                        disabled={props.isLoading}
                        id={'email'}
                        value={values.email}
                        label={'public.register.email'}
                        placeholder={'public.register.email'}
                        onChange={handleChange}
                        height={'40px'}
                        background={AppColors.WHITE}
                        error={submitCount && errors.email ? errors.email : undefined}
                      />
                    </Div>
                    <Div width={'100%'} marginBottom={'20px'}>
                      <Input
                        required
                        disabled={props.isLoading}
                        id={'password'}
                        fontSize={'15px'}
                        type={'password'}
                        height={'40px'}
                        value={values.password}
                        background={AppColors.WHITE}
                        onChange={handleChange}
                        label={'public.register.password'}
                        placeholder={'public.register.password'}
                        error={submitCount ? errors.password : undefined}
                      />
                    </Div>
                    <Div width={'100%'} marginBottom={'20px'}>
                      <Input
                        required
                        disabled={props.isLoading}
                        id={'confirmPassword'}
                        fontSize={'15px'}
                        type={'password'}
                        height={'40px'}
                        value={values.confirmPassword}
                        background={AppColors.WHITE}
                        onChange={handleChange}
                        label={'public.register.confirmPassword'}
                        placeholder={'public.register.confirmPassword'}
                        error={submitCount ? errors.confirmPassword : undefined}
                      />
                    </Div>
                    <Div width={'100%'} marginBottom={'28px'}>
                      <Input
                        required
                        minWidth={'100px'}
                        disabled={props.isLoading}
                        fontSize={'15px'}
                        id={'companyName'}
                        value={values.companyName}
                        label={'public.register.companyName'}
                        placeholder={'public.register.companyName'}
                        onChange={handleChange}
                        height={'40px'}
                        background={AppColors.WHITE}
                        error={submitCount && errors.companyName ? errors.companyName : undefined}
                      />
                    </Div>
                    <Div width={'100%'} marginBottom={'28px'}>
                      <Input
                        required
                        minWidth={'100px'}
                        disabled={props.isLoading}
                        id={'companyDescription'}
                        fontSize={'15px'}
                        value={values.companyDescription}
                        label={'public.register.companyDescription'}
                        placeholder={'public.register.companyDescription'}
                        onChange={handleChange}
                        height={'40px'}
                        background={AppColors.WHITE}
                        error={submitCount && errors.companyDescription ? errors.companyDescription : undefined}
                      />
                    </Div>
                    <Div width={'100%'} marginBottom={'28px'}>
                      <Input
                        required
                        minWidth={'100px'}
                        disabled={props.isLoading}
                        fontSize={'15px'}
                        id={'companyFoundation'}
                        value={values.companyFoundation}
                        label={'public.register.companyFoundation'}
                        placeholder={'public.register.companyFoundation'}
                        onChange={handleChange}
                        height={'40px'}
                        background={AppColors.WHITE}
                        error={submitCount && errors.companyFoundation ? errors.companyFoundation : undefined}
                      />
                    </Div>
                    <Div width={'100%'} marginBottom={'28px'}>
                      <Input
                        required
                        minWidth={'100px'}
                        disabled={props.isLoading}
                        id={'companyRepresentativeName'}
                        fontSize={'15px'}
                        value={values.companyRepresentativeName}
                        label={'public.register.companyRepresentativeName'}
                        placeholder={'public.register.companyRepresentativeName'}
                        onChange={handleChange}
                        height={'40px'}
                        background={AppColors.WHITE}
                        error={
                          submitCount && errors.companyRepresentativeName ? errors.companyRepresentativeName : undefined
                        }
                      />
                    </Div>
                    <HorizontalView fullWidth marginBottom={'28px'} alignContent="centerBetween">
                      <Div width="70%">
                        <Input
                          required
                          name={'postalCode'}
                          label={'public.register.postalCode'}
                          fontSize={'15px'}
                          placeholder={'public.register.postalCode'}
                          value={values.postalCode}
                          background={'transparent'}
                          onChange={handleChange}
                        />
                      </Div>
                      <Div width="30%" marginTop="29px" alignContent="centerRight">
                        <Button
                          disabled={values.postalCode?.length !== 7}
                          preixIcon="search"
                          fontSize="14px"
                          width={appLayout.isMobile ? '100%' : '100px'}
                          title="search"
                          borderRadius={appLayout.isMobile ? '0' : '10px'}
                          loading={props.locationLoading}
                          height="40px"
                          color="#fff"
                          background={AppColors.PRIMARY}
                          onClick={() => {
                            props.getLocation(values?.postalCode || '');
                          }}
                        />
                      </Div>
                    </HorizontalView>
                    {values.postalCode && values.postalCode?.length > 0 && values.postalCode?.length !== 7 && (
                      <Text color="red">{translation('public.register.code')} </Text>
                    )}
                    <Div width={'100%'} marginBottom={'28px'}>
                      <Input
                        required
                        minWidth={'100px'}
                        disabled={props.isLoading}
                        id={'stateOrCityName'}
                        fontSize={'15px'}
                        value={values.stateOrCityName}
                        label={'public.register.stateOrCityName'}
                        placeholder={'public.register.stateOrCityName'}
                        onChange={handleChange}
                        height={'40px'}
                        background={AppColors.WHITE}
                        error={submitCount && errors.stateOrCityName ? errors.stateOrCityName : undefined}
                      />
                    </Div>
                    <Div width={'100%'} marginBottom={'28px'}>
                      <Input
                        minWidth={'100px'}
                        disabled={props.isLoading}
                        id={'buildingNameAndRoomName'}
                        fontSize={'15px'}
                        value={values.buildingNameAndRoomName}
                        label={'public.register.buildingNameAndRoomName'}
                        placeholder={'public.register.buildingNameAndRoomName'}
                        onChange={handleChange}
                        height={'40px'}
                        background={AppColors.WHITE}
                        error={
                          submitCount && errors.buildingNameAndRoomName ? errors.buildingNameAndRoomName : undefined
                        }
                      />
                    </Div>
                    <Div width={'100%'} marginBottom={'28px'}>
                      <Input
                        required
                        minWidth={'100px'}
                        disabled={props.isLoading}
                        id={'telephone'}
                        fontSize={'15px'}
                        value={values.telephone}
                        label={'public.register.telephone'}
                        placeholder={'public.register.telephone'}
                        onChange={handleChange}
                        height={'40px'}
                        background={AppColors.WHITE}
                        error={submitCount && errors.telephone ? errors.telephone : undefined}
                      />
                    </Div>
                    <Div width={'100%'} marginBottom={'28px'}>
                      <Input
                        required
                        minWidth={'100px'}
                        disabled={props.isLoading}
                        id={'businessContent'}
                        value={values.businessContent}
                        fontSize={'15px'}
                        label={'public.register.businessContent'}
                        placeholder={'public.register.businessContent'}
                        onChange={handleChange}
                        height={'40px'}
                        background={AppColors.WHITE}
                        error={submitCount && errors.businessContent ? errors.businessContent : undefined}
                      />
                    </Div>
                  </VerticalView>
                  <HorizontalView fullWidth paddingTop={'20px'} alignContent={'center'}>
                    <Button
                      loading={props.isLoading}
                      disabled={props.isLoading}
                      width={'300px'}
                      paddingBottom={'10px'}
                      paddingTop={'10px'}
                      borderRadius={'10px'}
                      title={'public.register.registButton'}
                      fontSize={'16px'}
                      background={AppColors.BLUE_LIGHT}
                      color={AppColors.WHITE}
                      onClick={() => {
                        if (ref.current) {
                          ref.current.handleSubmit();
                        }
                      }}
                    />
                  </HorizontalView>
                </VerticalView>
              );
            }}
          </Formik>
        </CardWrapper>
        <Modal
          title={translation('public.register.success')}
          open={openAlert}
          closeOnBackDropClick={false}
          onClose={() => {
            navigate('/');
            setOpenAlert(false);
          }}
          footer={
            <HorizontalView width={'100%'} alignContent={'center'}>
              <Button
                title="page404.goHome"
                fontWeight={600}
                color={AppColors.PRIMARY}
                onClick={() => {
                  navigate('/');
                  setOpenAlert(false);
                }}
              />
            </HorizontalView>
          }
        >
          <Div padding="20px" maxWidth="500px">
            <Text fontSize={appSize.h4}>{translation('public.register.completedMessage')}</Text>
          </Div>
        </Modal>
      </VerticalView>
    </BaseComponent>
  );
});
