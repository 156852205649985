import React from 'react';
import styled from 'styled-components';
import { AppColors } from 'helpers';
import { useTranslationText } from 'locale';
import { BaseComponent } from 'components/common/components/BaseComponent';
import { BannerPreview } from 'components/common/components/Banner';
import { IHelp, IHelpTranslation } from 'models/Help';
import { useAppSetting } from 'hooks/useAppSetting';
import { IOptionType } from 'models/IOptionType';
import { Div, HorizontalView, VerticalView } from 'vendors/Wrapper';
import { TextLink } from 'vendors/TextLink';
import { Text } from 'vendors/Text';
import { Hr } from 'vendors/CommonParts';
import { ArticleEditorPreview } from 'vendors/ArticleEditor';
import { HelpSearchBar } from '../part/HelpSearchBar';

const TitleWrapper = styled.div`
  font-weight: bold;
  font-size: 18px;
  color: white;
  margin-bottom: 5px;
`;

const ContentWrapper = styled.div<{ border: boolean }>`
  ${(props) => (props.border ? 'border: 1px solid #ccc;' : '')}
  border-radius: 5px;
  width: 100%;
  padding: 1px;
`;

type HelpProps = {
  loading: boolean;
  help?: IHelp;
  lang: string;
};

export const HelpDetailsComponent = (props: HelpProps) => {
  const { appLayout, appSize } = useAppSetting();
  const { translation, getTypes } = useTranslationText();
  const userRoles: IOptionType[] = getTypes('userRoles');

  let translationData: IHelpTranslation | undefined | null = undefined;

  if (props.help?.translations && props.help.translations.length > 0) {
    translationData = props.help.translations?.find((tran) => tran?.lang === props.lang);
  }

  const role = userRoles.find((role) => role.value === props.help?.forRole);

  return (
    <BaseComponent contentLoading={props.loading}>
      <VerticalView fullWidth>
        <Div fullWidth>
          <BannerPreview
            height="400px"
            banner={'/images/helps.jpeg'}
            title={translation(`helpTitle`)}
            description={translation('helpMessage')}
          >
            <HorizontalView fullWidth marginTop={'20px'} alignContent="center">
              <HelpSearchBar />
            </HorizontalView>
          </BannerPreview>
        </Div>

        <VerticalView fullWidth maxWidth={'1280px'} marginTop={'15px'} marginBottom={'20px'}>
          <VerticalView
            fullHeight
            width={appLayout.isMobile ? '100%' : 'calc(100% - 350px)'}
            alignContent={'center'}
            paddingTop={'20px'}
            paddingLeft={'20px'}
            paddingRight={'20px'}
          >
            <HorizontalView fullWidth alignContent={'centerLeft'}>
              <TextLink fontSize={appSize.h4} href={'/helps'}>
                {translation('helpCenter')}
              </TextLink>
              {role && <Text padding={'5px'}>{' / '}</Text>}
              {role && <Text fontSize={appSize.h4}>{role.label}</Text>}
            </HorizontalView>

            {translationData && (
              <HorizontalView fullWidth alignContent={'centerLeft'}>
                <TitleWrapper>
                  <Text color={AppColors.BLACK}>{translationData?.title}</Text>
                </TitleWrapper>
              </HorizontalView>
            )}

            <Hr />
            <HorizontalView fullWidth alignContent={'center'} paddingTop={appLayout.isMobile ? '10px' : '30px'}>
              <VerticalView fullWidth alignContent={'centerLeft'} marginBottom={'20px'}>
                {translationData && (
                  <ContentWrapper border={true}>
                    {translationData.content && <ArticleEditorPreview setContents={translationData.content} />}
                  </ContentWrapper>
                )}
              </VerticalView>
              {/* {!appLayout.isMobile && (
                <VerticalView width={'350px'} paddingLeft={'20px'}>
                  {relateHelps &&
                    relateHelps.map((group, index) => {
                      const category = helpCategories.find((category) => category.value === group.category);
                      const menus = group.helps?.map((help) => {
                        const seoname = friendlyURL(help?.id!, help?.seoname || '');

                        let title: string | undefined = '';
                        if (help?.translations && help.translations.length > 0) {
                          const findData = help.translations?.find((tran) => tran?.lang === lang);
                          title = findData ? findData.title : help.translations[0]?.title || '';
                        }

                        return {
                          title: title || help?.seoname,
                          url: `/helps/${seoname}`,
                        };
                      });
                      return (
                        <HorizontalView fullWidth key={index}>
                          <BasicCard
                            width={'100%'}
                            title={category ? category?.label : helpCategories[helpCategories.length - 1].label}
                            menus={menus as any}
                          />
                        </HorizontalView>
                      );
                    })}
                </VerticalView>
              )} */}
            </HorizontalView>
          </VerticalView>
        </VerticalView>
      </VerticalView>
    </BaseComponent>
  );
};
