import React from 'react';
import styled from 'styled-components';
import { Formik, FormikProps } from 'formik';
import { AppColors, AppConst } from 'helpers';
import { useTranslationText } from 'locale';
import { useAppSetting } from 'hooks/useAppSetting';
import { Modal } from 'vendors/Modal';
import { Div, HorizontalView, VerticalView } from 'vendors/Wrapper';
import { Input } from 'vendors/Input';
import { TextAreaInput } from 'vendors/TextAreaInput';
import { Button } from 'vendors/Button';
import { IDeviceType, IProduct, IProductType } from 'models/Transaction';
import { IOptionType } from 'models/IOptionType';
import { RadioItem, RadioSelect } from 'vendors/Radio';
import { SelectDropdown } from 'vendors/Select';
import { updateProductValidatorSchema } from 'validators/updateProductValidators';

export const ModalWrapper = styled.div``;
export const AlertModalWrapper = styled.div``;
export const ContentWrapper = styled.div``;
export const AddUserWrapper = styled.div``;

interface Props {
  open: boolean;
  product: IProduct | null;
  onChange: (feature: IProduct) => void;
  onClose: () => void;
}

const Wrapper = styled.div``;

export const ProductModal = (props: Props) => {
  const { translation, getTypes } = useTranslationText();
  const productTypes = getTypes<IOptionType[]>('productTypes');
  const { appTheme, appLayout, appSize } = useAppSetting();
  const ref = React.useRef<FormikProps<IProduct>>(null);

  React.useEffect(() => {
    if (ref && ref.current) {
      if (props.product) {
        ref.current.setValues(props.product);
      } else {
        ref.current.setValues({
          platform: IDeviceType.Ios,
          type: IProductType.AutoRenewableSubscription,
          name: '',
          description: '',
        } as IProduct);
      }
    }
  }, [props.product]);

  return (
    <Wrapper>
      <Modal
        title={translation('home.category.header')}
        open={props.open}
        headerBackground={appTheme.sidebar.bg}
        height={appLayout.isMobile ? '100vh' : 'auto'}
        zIndex={AppConst.zIndex.header + 60}
        onClose={() => {
          props.onClose();
        }}
        closeOnBackDropClick
      >
        <HorizontalView
          width={appLayout.isMobile ? '100vw' : '400px'}
          fullHeight
          alignContent={'topCenter'}
          marginBottom={'10px'}
        >
          <Formik
            innerRef={ref}
            validationSchema={updateProductValidatorSchema}
            initialValues={
              {
                platform: IDeviceType.Ios,
                type: IProductType.AutoRenewableSubscription,
                name: '',
                description: '',
                packageId: '',
              } as IProduct
            }
            onSubmit={(values: IProduct) => {
              props.onChange(values);
            }}
          >
            {({ values, handleChange, setFieldValue, errors }) => {
              return (
                <VerticalView fullWidth alignContent={'centerLeft'} position={'relative'} padding={'20px'}>
                  <Div width={'100%'} alignContent={'centerLeft'} paddingTop={'20px'}>
                    <RadioSelect
                      required
                      fontSize={appSize.md}
                      defaultValue={{ value: values.platform } as RadioItem}
                      label={`productPage.platform`}
                      options={getTypes('deviceTypes')}
                      onChange={async (e) => {
                        setFieldValue('platform', e?.value);
                      }}
                    />
                  </Div>
                  <SelectDropdown
                    required
                    width={'250px'}
                    size={appSize.md}
                    direction={'column'}
                    label={'productPage.type'}
                    value={values.type || ''}
                    options={productTypes}
                    onChange={(t) => setFieldValue('type', t)}
                  />
                  <VerticalView fullWidth paddingBottom={'20px'}>
                    <Input
                      required
                      disabled={props.product !== null}
                      id={'packageId'}
                      type={'text'}
                      value={values.packageId}
                      label={'productPage.packageId'}
                      placeholder={'productPage.packageId'}
                      error={errors.packageId}
                      onChange={handleChange}
                    />
                  </VerticalView>
                  <VerticalView fullWidth paddingBottom={'20px'}>
                    <Input
                      required
                      id={'name'}
                      type={'text'}
                      value={values.name}
                      label={'productPage.name'}
                      placeholder={'productPage.name'}
                      error={errors.name}
                      onChange={handleChange}
                    />
                  </VerticalView>
                  <VerticalView fullWidth paddingBottom={'20px'}>
                    <TextAreaInput
                      id={'description'}
                      required
                      size={'15px'}
                      height={'100px'}
                      color={AppColors.BLACK}
                      label={'productPage.description'}
                      placeholder={'productPage.description'}
                      value={values.description || ''}
                      background={AppColors.TRANSPARENT}
                      error={errors.description}
                      onChange={handleChange}
                    />
                  </VerticalView>
                  <VerticalView fullWidth paddingBottom={'20px'}>
                    <Input
                      required
                      id={'price'}
                      type={'number'}
                      value={values.price}
                      label={'productPage.price'}
                      placeholder={'productPage.price'}
                      error={errors.price}
                      onChange={handleChange}
                    />
                  </VerticalView>
                  <HorizontalView fullWidth paddingTop={'30px'}>
                    <Button
                      title={`${props.product ? 'save' : 'create'}`}
                      width={'100%'}
                      background={AppColors.CORAL_PINK}
                      color={AppColors.WHITE}
                      onClick={() => {
                        if (ref.current) {
                          ref.current.handleSubmit();
                        }
                      }}
                    />
                  </HorizontalView>
                </VerticalView>
              );
            }}
          </Formik>
        </HorizontalView>
      </Modal>
    </Wrapper>
  );
};
