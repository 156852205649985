import { useDeleteVideo, useSearchVideo } from 'api/hooks/video/useVideoApi';
import { CMSVideosListComponent } from 'components/cms-interface/videos/list';
import { useTranslationText } from 'locale';
import { VideoStatus } from 'models/types';
import { IVideoShort } from 'models/Video';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { openNotification } from 'vendors/Notification';

export const CMSVideosListContainer = () => {
  const { translation } = useTranslationText();
  const navigate = useNavigate();

  const {
    loading,
    videos,
    page,
    totalItems,
    totalPages,
    searchVideo,
    onChangeLimit,
    onChangePage,
    onDeleteItem,
  } = useSearchVideo();
  const { loading: deleteLoading, deleteVideo } = useDeleteVideo();

  const onDelete = async (id: string) => {
    const result = await deleteVideo(id);
    if (!result.error) {
      onDeleteItem({ id } as IVideoShort);
      openNotification({
        type: 'success',
        message: translation('message.delete'),
        description: translation('message.success'),
      });
    } else {
      openNotification({
        type: 'error',
        message: translation('message.delete'),
        description: translation('message.error'),
      });
    }
  };

  const onSearchBy = (tags: VideoStatus[], keyword?: string) => {
    searchVideo({
      limit: 100,
      page: 1,
      status: tags,
      keyword,
    });
  };

  return (
    <CMSVideosListComponent
      loading={loading}
      videos={videos}
      totalPages={totalPages}
      totalItems={totalItems}
      deleteLoading={deleteLoading}
      currentPage={page}
      onDelete={onDelete}
      onDetails={(id) => {
        navigate(`/cms/videos/${id}`);
      }}
      onSearch={onSearchBy}
      onChangePage={onChangePage}
      onChangeLimit={onChangeLimit}
      onSelectTags={onSearchBy}
    />
  );
};
