import * as React from 'react';
import * as ReactDOM from 'react-dom';
import styled from 'styled-components';
import { AppColors } from 'helpers';
import { Button } from '../Button';
import { Text } from '../Text';
import { HorizontalView } from '../Wrapper';
import { CSSTransition } from 'react-transition-group';

export interface ModalProps extends ModalHeaderProps {
  open: boolean;
  transparent?: boolean;
  children?: React.ReactNode;
  mountSelector?: string;
  size?: string; //'sm'
  height?: string;
  title?: string;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  footerTitle?: string;
  backdropColor?: string;
  headerBackground?: string;
  headerColor?: string;
  backdrop?: boolean;
  innerPadding?: boolean;
  style?: React.CSSProperties;
  closeOnBackDropClick?: boolean;
  zIndex?: number;
  bottom?: string;
  onClose: () => void;
  confirmAction?: () => void;
}

interface ModalHeaderProps {
  closeButton?: boolean;
}

const duration = 300;

const Content = styled.section<{ height?: string; transparent?: boolean; bottom?: string }>`
  position: fixed;
  ${(props) => (props.bottom ? `bottom:${props.bottom};` : '')};
  height: ${(props) => props.height || 'auto'};
  background-color: ${(props) => (props.transparent ? 'transparent' : '#fff')};
  z-index: 1;
  border-radius: 4px;
`;

const Wrapper = styled.div<{ zIndex?: number }>`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${(props) => props.zIndex || 200};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  border-radius: 5px;
  &.modal-transition-enter {
    opacity: 0;
  }

  &.modal-transition-enter-active {
    transition: opacity ${duration}ms ease-out;
    opacity: 1;
  }

  &.modal-transition-exit {
    opacity: 1;
  }

  &.modal-transition-exit-active {
    opacity: 0;
    transition: opacity ${duration}ms ease-in;
  }
  webkit-box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3), 0 1px 5px -2px rgba(152, 162, 179, 0.3);
  box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3), 0 1px 5px -2px rgba(152, 162, 179, 0.3);
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
`;

const Backdrop = styled.div`
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${(props: ModalProps) => (props.backdropColor ? props.backdropColor : 'rgba(0,0,0,.2)')};
`;

const Header = styled.div<{ background?: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px;
  min-height: 70px;
  color: white;
  background: ${(props) => (props.background ? props.background : AppColors.PRIMARY)};
  overflow: hidden;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const Body = styled.div`
  flex: 1;
  font-size: 13px;
  height: 100%;
  overflow-y: auto;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top: 1px solid #e0e0e0;
  background: #f6f4f0;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const FooterButton = styled(Button)`
  background: #2c2a28;
  color: white;
  border-radius: 5px;
  outer: none;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-right: 20px;
`;

export function Modal(props: ModalProps) {
  const selectorEl = props.mountSelector ? document.querySelector(props.mountSelector) : null;
  const modalRoot = selectorEl || document.body;

  return ReactDOM.createPortal(
    <CSSTransition
      in={props.open}
      timeout={duration}
      className="modal-transition"
      classNames="modal-transition"
      unmountOnExit
    >
      <Wrapper {...props}>
        {props.backdrop ? (
          <Backdrop
            open={props.open}
            onClose={props.onClose}
            backdropColor={props.backdropColor}
            onClick={props.closeOnBackDropClick && props.onClose ? props.onClose : undefined}
          />
        ) : (
          <Backdrop open={props.open} onClose={props.onClose} backdropColor={props.backdropColor || 'transparent'} />
        )}
        <Content height={props.height} transparent={props.transparent} bottom={props.bottom}>
          <Inner>
            {props.title && (
              <Header background={props.headerBackground}>
                <HorizontalView width={'100%'} height={'100%'} alignContent={'centerBetween'}>
                  <Text fontWeight={600} fontSize={'17px'} color={props.headerColor || 'white'}>
                    {props.title}
                  </Text>
                  <Button
                    preixIcon={'close'}
                    fontSize={'27px'}
                    height={'40px'}
                    width={'40px'}
                    borderRadius={'20px'}
                    color={props.headerColor || 'white'}
                    onClick={props.onClose}
                  />
                </HorizontalView>
              </Header>
            )}
            {props.header && <Header background={props.headerBackground}> {props.header}</Header>}
            <Body>{props.children}</Body>
            {props.footer && <Footer>{props.footer}</Footer>}
            {props.footerTitle && (
              <Footer>
                <HorizontalView width={'100%'} height={'100%'} alignContent={'centerRight'}>
                  <FooterButton
                    onClick={() => {
                      props.confirmAction && props.confirmAction();
                    }}
                  >
                    {props.footerTitle}
                  </FooterButton>
                </HorizontalView>
              </Footer>
            )}
          </Inner>
        </Content>
      </Wrapper>
    </CSSTransition>,
    modalRoot,
  );
}

Modal.defaultProps = {
  innerPadding: true,
  backdrop: true,
  closeButton: true,
  closeOnBackDropClick: true,
};
