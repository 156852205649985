import chroma from 'chroma-js';
export const PRIMARY = '#FFA600';
export const BLACK = 'black';
export const DARK = '#383A3C';
export const WHITE = 'white';
export const WHITE2 = '#F7F7F7';

export const DISABLED = '#666666';
export const BCKGROUND_DISABLED = '#cccccc';
export const TRANSPARENT = 'transparent';
export const RED = 'red';
export const RED2 = '#ED5252';
export const RED_PRIMARY = '#CE2B2C';
export const ORANGE = 'orange';

export const BLUE_BOLD = '#051E34';
export const BLUE = '#094A82'; //'#800200'//'rgb(0, 45, 114)';
export const BLUE_LIGHT = '#1e7ac9';
export const BLUE_GREENY = '#5cbdb9';

export const BRUTAL_BLUE = '#0049B7';
export const YASS_QUEEN = '#ff1d58';
export const CROWN_YELLOW = '#fff685';

export const BACKGROUND_TAN = '#fceed1';
export const PURPLE_Y = '#7d3cff';
export const SAND_TAN = '#e1b382';
export const NIGHT_BLUE = '#2d545e';
export const CORAL_PINK = '#ff5e6c';
export const GRASSY_GREEN = '#9bc400';
export const BORDER = '#CACBCE';
export const TEXT = '#333';
export const LINK = '#1890ff';
export const FACEBOOK = 'rgb(25,118,210)';
export const BACKGROUND_SAND = '#EEEAE3';

export const YELLOW_BACKGROUND = '#ffde22';
export const BACKGROUND_GREY = '#ecedf1';

export const BACKGROUND_REVIEW = '#FBFBF8';

export const GRAY_LIGHT = '#E8E8E8';
export const GRAY_LIGHT2 = '#F7F7F7';
export const GRAY_LIGHT3 = '#F2F3F5';
export const GRAY_BGR = '#EEEEEE';

export const PAGE_HEADER = '#505763';

export const GRAY_LIGHT_F2 = '#F2F2F2';
export const GRAY_LIGHT_F8 = '#F8F8F8';
export const GRAY_DARK = '#808080';

export const AUTHOR_HEADER = '#7398a5';
export const AUTHOR_SIDEBAR = '#333742';
export const MEMBER = '#7398a5';
export const CMS = '#094A82';

export const BODY_BACKGROUND = '#F4F4F9';

//CHAPTER MENU COLOR
export const CHAPTER_ROW = WHITE;
export const CHAPTER_INDEX = BODY_BACKGROUND;
export const CHAPTER_TEXT = BLACK;
//LESSON
export const LECTURE_TITLE_BG = '#BFC6D8';

export const BANNER_BLUE = 'linear-gradient(90deg, rgb(0, 45, 114), rgb(0, 94, 184))';
// export const GRADIENT_BLUE = 'linear-gradient(rgb(0, 45, 114), rgb(0, 94, 184));';
// export const GRADIENT_BLUE = 'linear-gradient(#800200, #ea352d);';
export const GRADIENT_BLUE = `linear-gradient(${BLUE}, ${BLUE_LIGHT});`;

export const YELLOW_LIGHT = 'rgb(247, 187, 86)';

export const HEADER = '#094A82';

export const sidebar = {
  bg: BLUE_BOLD,
  author: '#37474F',
  admin: '#051E34',
  member: '#7398a5',
  cms: '#1E222D',
  menu: '#122B43',
  text: WHITE,
};

export const controlColor = {
  base: '#424242',
  hover: '#EEEEEE',
  focus: '#b9d7e6',
};

export const border = {
  input: '#d9d9d9',
};

// setting.appTheme.sidebar.bg = '#37474F';
// setting.appTheme.sidebar.bgHover = 'rgb(255,255,255,0.1)';
// setting.appTheme.sidebar.text = 'white';
// setting.appTheme.sidebar.selected = '#e1b382';
// setting.appTheme.sidebar.border = 'rgb(255,255,255,0.1)';
export const tableTime = {
  bg: '#37474F',
};

export const tableTrOdd = '#F9F9F9';
export const tableTrHover = chroma('#0074D9').alpha(0.05).css();
