import { BaseComponent } from 'components/common/components/BaseComponent';
import { MessageBox } from 'components/common/components/MessageBox';
import { NavigationBar } from 'components/common/components/NavigationBar';
import { Formik, FormikProps } from 'formik';
import { useAppSetting } from 'hooks/useAppSetting';
import { useTranslationText } from 'locale';
import { IChangeVideoStatusInput, IVideo, IVideoType } from 'models/Video';
import React from 'react';
import { Loading } from 'vendors/Loading';
import { VideoPlayer } from 'vendors/Video/VideoPlayer';
import { Body, Div, HorizontalView, VerticalView } from 'vendors/Wrapper';
import { Text } from 'vendors/Text';
import { AppColors } from 'helpers';
import { Input } from 'vendors/Input';
import { PrimaryButton } from 'components/common/components/Button/PrimaryButton';
import { Button } from 'vendors/Button';
import { Image } from 'vendors/Image';
import { ChangeStatusModal } from 'components/common/components/Modals/ChangeStatusModal';
import { useNavigate } from 'react-router-dom';
import { CardWrapper } from 'vendors/CommonWrappers';
import { getCategoryNameByLang, numberWithCommas } from 'helpers/common.utils';
import { dateUtil } from 'helpers/date.utils';

type PropsType = {
  loading: boolean;
  video: IVideo;
  changeStatusLoading: boolean;
  onUpdate: (values: IVideo) => void;
  onChangeStatus: (input: IChangeVideoStatusInput) => void;
};

export const CMSVideoDetailComponent = (props: PropsType) => {
  const { translation } = useTranslationText();
  const ref = React.useRef<FormikProps<IVideo>>(null);
  const [showModalChangeStatus, setShowModalChangeStatus] = React.useState(false);
  const { appLayout, appSize } = useAppSetting();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (props.video) {
      if (ref && ref.current) {
        ref.current.setValues(JSON.parse(JSON.stringify(props.video)));
      }
    }
  }, [props.video]);

  const onSave = () => {
    if (ref && ref.current) {
      ref.current.submitForm();
    }
  };

  return (
    <BaseComponent contentLoading={props.loading} documentTitle={translation('videoPage.detail')}>
      <>
        <NavigationBar header={'videoPage.detail'} path={'/cms/videos'} background={'#EDEDED'} />
        <Body>
          <Loading loading={props.loading || props.changeStatusLoading} />
          {props.video && (
            <VerticalView fullWidth paddingTop="20px" alignContent="topCenter">
              {props.video.status === 'blocked' && (
                <Div fullWidth alignContent="center" padding="20px">
                  <MessageBox message={props.video.reason || ''} title={translation('blockedReason')} />
                </Div>
              )}
              {props.video.videoUrl && (
                <Div fullWidth padding="20px">
                  <VideoPlayer src={props.video.videoUrl} height={appLayout.isMobile ? '200px' : '500px'} />
                </Div>
              )}
              <Formik
                innerRef={ref}
                initialValues={props.video}
                onSubmit={(values: IVideo) => {
                  props.onUpdate(values);
                }}
              >
                {({ values, handleChange }) => {
                  return (
                    <VerticalView fullWidthHeight>
                      <Div fullWidth marginTop={'15px'}>
                        <Text
                          fontSize={appSize.h2}
                          fontWeight={600}
                          color={
                            props.video?.status === 'blocked'
                              ? AppColors.RED
                              : props.video?.status === 'active'
                              ? AppColors.GRASSY_GREEN
                              : AppColors.BLACK
                          }
                        >
                          {translation(`videoPage.status.${props.video?.status}`)}
                        </Text>
                      </Div>
                      {props.video.category && (
                        <Div fullWidth marginTop="15px" marginBottom="15px">
                          <Text fontSize={appSize.h3} fontWeight={600}>
                            {translation('videoPage.category') + getCategoryNameByLang(props.video.category)}
                          </Text>
                        </Div>
                      )}
                      {props.video.uploader && (
                        <CardWrapper>
                          <HorizontalView
                            onClick={() => {
                              if (props.video.type === IVideoType.Job) {
                                navigate(`/cms/customer/detail/${props.video.uploader?.id}`);
                              } else navigate(`/cms/users/${props.video.uploader?.id}`);
                            }}
                          >
                            <Div padding="10px">
                              <Image width="50px" height="50px" src={props.video.uploader?.avatar} />
                            </Div>
                            <VerticalView alignContent="centerLeft">
                              <HorizontalView>
                                <Text fontSize={appSize.h3} fontWeight={600} marginRight="10px">
                                  {translation('videoPage.userId')}
                                </Text>
                                <Text fontSize={appSize.h3}>{props.video.uploader?.id}</Text>
                              </HorizontalView>
                              <HorizontalView>
                                <Text fontSize={appSize.h3} fontWeight={600} marginRight="10px">
                                  {translation('videoPage.userName')}
                                </Text>
                                <Text fontSize={appSize.h3}>{props.video.uploader?.name}</Text>
                              </HorizontalView>
                              {props.video.type === IVideoType.Job && (
                                <VerticalView alignContent="centerLeft">
                                  {props.video.salary && (
                                    <HorizontalView>
                                      <Text fontSize={appSize.h3} fontWeight={600} marginRight="10px">
                                        {translation('videoPage.salary')}
                                      </Text>
                                      {props.video.salary.type === 'fixed' && (
                                        <Text fontSize={appSize.h3}>
                                          {numberWithCommas(props.video.salary.fixed) + translation('yen')}
                                        </Text>
                                      )}
                                      {props.video.salary.type === 'ranger' && (
                                        <Text fontSize={appSize.h3}>
                                          {numberWithCommas(props.video.salary.min) +
                                            ' ~ ' +
                                            numberWithCommas(props.video.salary.max) +
                                            translation('yen')}
                                        </Text>
                                      )}
                                    </HorizontalView>
                                  )}
                                  {props.video.salaryPayType && (
                                    <HorizontalView>
                                      <Text fontSize={appSize.h3} fontWeight={600} marginRight="10px">
                                        {translation('videoPage.salaryPayType')}
                                      </Text>
                                      <Text fontSize={appSize.h3}>
                                        {translation(
                                          `videoPage.salaryPayTypeValue.${props.video.salaryPayType.toLowerCase()}`,
                                        )}
                                      </Text>
                                    </HorizontalView>
                                  )}
                                  {props.video.employmentType && (
                                    <HorizontalView>
                                      <Text fontSize={appSize.h3} fontWeight={600} marginRight="10px">
                                        {translation('videoPage.employmentType')}
                                      </Text>
                                      <Text fontSize={appSize.h3}>
                                        {translation(
                                          `videoPage.employmentTypeValue.${props.video.employmentType.toLowerCase()}`,
                                        )}
                                      </Text>
                                    </HorizontalView>
                                  )}
                                  {props.video.workplace && (
                                    <HorizontalView>
                                      <Text fontSize={appSize.h3} fontWeight={600} marginRight="10px">
                                        {translation('videoPage.workLocation')}
                                      </Text>
                                      <Text fontSize={appSize.h3}>{`〒${props.video.workplace.code}${
                                        props.video.workplace.province
                                      }${props.video.workplace.city}${props.video.workplace.building}${
                                        props.video.workplace.address || ''
                                      }`}</Text>
                                    </HorizontalView>
                                  )}
                                  {props.video.period && (
                                    <HorizontalView>
                                      <Text fontSize={appSize.h3} fontWeight={600} marginRight="10px">
                                        {translation('videoPage.expireDate')}
                                      </Text>
                                      <Text fontSize={appSize.h3}>
                                        {dateUtil.dateFormat(props.video.period, 'YYYY/MM/DD')}
                                      </Text>
                                    </HorizontalView>
                                  )}
                                </VerticalView>
                              )}
                            </VerticalView>
                          </HorizontalView>
                        </CardWrapper>
                      )}
                      <Div fullWidth marginTop={'15px'}></Div>
                      <Div fullWidth marginTop={'15px'}>
                        <Input
                          required
                          name={'name'}
                          label={'videoPage.name'}
                          fontSize={'15px'}
                          placeholder={'videoPage.name'}
                          value={values.name}
                          background={'transparent'}
                          onChange={handleChange}
                        />
                      </Div>
                      <Div fullWidth marginTop={'15px'}>
                        <Input
                          required
                          name={'description'}
                          label={'videoPage.description'}
                          fontSize={'15px'}
                          placeholder={'videoPage.description'}
                          value={values.description}
                          background={'transparent'}
                          onChange={handleChange}
                        />
                      </Div>
                      <HorizontalView fullWidth marginTop={'20px'} alignContent={'centerRight'}>
                        <Button
                          width={appLayout.isMobile ? '100%' : 'auto'}
                          marginRight={appLayout.isMobile ? '0px' : '20px'}
                          title={'changeStatus.button'}
                          color={AppColors.WHITE}
                          borderRadius={'4px'}
                          padding="10px 15px"
                          fontSize={'15px'}
                          fontWeight={600}
                          background={AppColors.RED_PRIMARY}
                          onClick={() => {
                            setShowModalChangeStatus(!showModalChangeStatus);
                          }}
                        />
                        <PrimaryButton icon={'save'} title={'company.save'} onClick={onSave} />
                      </HorizontalView>
                    </VerticalView>
                  );
                }}
              </Formik>
              <ChangeStatusModal
                loading={props.loading}
                statusTypes={'videoStatus'}
                open={showModalChangeStatus}
                status={props.video.status || ''}
                onClose={() => setShowModalChangeStatus(false)}
                onUpdate={(input) => {
                  props.onChangeStatus({
                    id: props.video?.id,
                    status: input.status,
                    reason: input.reason,
                  } as IChangeVideoStatusInput);
                }}
              />
            </VerticalView>
          )}
        </Body>
      </>
    </BaseComponent>
  );
};

export default React.memo(CMSVideoDetailComponent);
