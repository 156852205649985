import React, { useCallback, useEffect, useMemo } from 'react';
import { useSearchProducts } from 'api/hooks/product/useSearchProduct';
import { PlanComponent } from 'components/user-interface/plan';
import { IDeviceType, IProduct } from 'models/Transaction';
import { useSession } from 'stores/userStore';
import { useGetMembershipCheckoutSource } from 'api/hooks/membership/useGetMembershipCheckoutSource';
import { openNotification } from 'vendors/Notification';

export const PlanContainer = () => {
  const { loading, searchProducts, packages } = useSearchProducts();
  const { checkoutSource, error, loading: loadSource, onGetCheckoutSource } = useGetMembershipCheckoutSource();
  const { session } = useSession();

  const onChoosePlan = useCallback(
    async (product: IProduct) => {
      onGetCheckoutSource({ packageId: product.packageId! });
    },
    [session],
  );

  useEffect(() => {
    searchProducts({
      limit: 30,
      page: 1,
      platform: [IDeviceType.Web],
    });
  }, []);

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
    }

    if (query.get('canceled')) {
    }
  }, []);

  useEffect(() => {
    if (error) {
      openNotification({ type: 'error', message: error.message });
    }
  }, [error]);

  useEffect(() => {
    if (!checkoutSource) {
      return;
    }
    window.location.href = checkoutSource.url;
  }, [checkoutSource]);

  const productSorted = useMemo(() => {
    return [...packages]?.sort((a, b) => {
      return a.price && b.price && a.price > b.price ? 1 : -1;
    });
  }, [packages]);

  return (
    <PlanComponent
      loading={loading}
      sourceLoading={loadSource}
      products={productSorted || []}
      onChoosePlan={onChoosePlan}
    />
  );
};
