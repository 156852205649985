import config from 'config';
import React from 'react';
import { IMembership } from 'models/Membership';
import { VerticalView } from 'vendors/Wrapper';
import { Label } from 'vendors/Label';
import { AppColors } from 'helpers';
import { Button } from 'vendors/Button';
import { IPaymentServiceType } from 'models/Transaction';
import { useTranslationText } from 'locale';
import { TextLink } from 'vendors/TextLink';

export const BillingManager = (props: { membership?: IMembership }) => {
  const { translation } = useTranslationText();

  return (
    <VerticalView fullWidth alignContent="centerLeft" paddingBottom="15px">
      <Label fontSize="15px" label="userDetails.billing" fontWeight={500} />
      {(!props.membership?.isMembership || props.membership?.paymentServiceType !== IPaymentServiceType.Google) && (
        <form method="post" action={`${config.API_HOST_URL}/api/v1/account/billing`}>
          <input type="hidden" name="token" value={localStorage.getItem('SESSION_TOKEN') || ''} />
          <Button
            marginTop="5px"
            preixIcon={'card'}
            type={'submit'}
            width="100%"
            fontSize={'14px'}
            color={AppColors.BLUE}
            title="userDetails.billingmanager"
            borderRadius="5px"
          />
        </form>
      )}
      {props.membership?.isMembership && props.membership?.paymentServiceType === IPaymentServiceType.Google && (
        <>
          {/* <Text>{translation('userDetails.usingGooglePayment')}</Text> */}
          <TextLink color={AppColors.BLUE} href="https://support.google.com/googleplay/answer/4646404?hl=ja">
            {translation('userDetails.seeDetails')}
          </TextLink>
        </>
      )}
    </VerticalView>
  );
};
