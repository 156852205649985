import { zIndex } from 'helpers/const';
import React from 'react';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LineShareButton,
  LineIcon,
} from 'react-share';
import { useAppSetting } from 'hooks/useAppSetting';
import { VerticalView, HorizontalView, Div } from 'vendors/Wrapper';

export const ShareBar = (props: { shareUrl: string }) => {
  const { appLayout } = useAppSetting();
  const { shareUrl } = props;
  return (
    <VerticalView
      width={'60px'}
      top={'400px'}
      position={'fixed'}
      zIndex={zIndex.header}
      left={appLayout.isMobile ? '10px' : undefined}
      marginRight={appLayout.isMobile ? '0px' : '100px'}
    >
      <FacebookShareButton url={shareUrl}>
        <VerticalView borderRadius={'50%'} overflow={'hidden'}>
          <FacebookIcon size={40} />
        </VerticalView>
      </FacebookShareButton>
    </VerticalView>
  );
};

export const ShareBarHorizontal = (props: { shareUrl: string; disabled?: boolean }) => {
  const { shareUrl } = props;

  return (
    <HorizontalView fullWidth alignContent={'center'}>
      <Div>
        <FacebookShareButton disabled={props.disabled} url={shareUrl}>
          <VerticalView borderRadius={'50%'} overflow={'hidden'}>
            <FacebookIcon size={40} />
          </VerticalView>
        </FacebookShareButton>
      </Div>
      <Div padding={'20px'}>
        <TwitterShareButton disabled={props.disabled} url={shareUrl}>
          <VerticalView borderRadius={'50%'} overflow={'hidden'}>
            <TwitterIcon size={40} />
          </VerticalView>
        </TwitterShareButton>
      </Div>
      <Div>
        <LineShareButton disabled={props.disabled} url={shareUrl}>
          <VerticalView borderRadius={'50%'} overflow={'hidden'}>
            <LineIcon size={40} />
          </VerticalView>
        </LineShareButton>
      </Div>
    </HorizontalView>
  );
};
