import { useBaseGetQuery } from 'api/hooks/base/useBaseGetQuery';
import { useBaseMutation } from 'api/hooks/base/useBaseMutation';
import { useTranslationText } from 'locale';
import {
  IChangeAccountStatusInput,
  ICreateBusinessAccountInput,
  ISearchBusinessAccountsInput,
  IUpdateBusinessAccountInput,
} from 'models/BusinessAccount';
import { IUser } from 'models/User';
import { ErrorCodes } from 'helpers/const';
import { businessMutations, businessQueries } from 'api/graphql/business';
import { IError } from 'models/Models';
import { useBaseSearchQuery } from 'api/hooks/base/useBaseSearchQuery';
import React from 'react';
import { openNotification } from 'vendors/Notification';

export const useCreateBusiness = () => {
  const { getError } = useTranslationText();
  const { mutation, loading } = useBaseMutation<ICreateBusinessAccountInput, IUser>({
    mutation: businessMutations.CREATE,
  });

  async function onNewBusiness(input: ICreateBusinessAccountInput): Promise<{ error?: IError; user?: IUser }> {
    const { data, error } = await mutation(input);

    if (error || !data) {
      switch (error?.code) {
        case ErrorCodes.ERROR_CODE_ITEM_EXIST:
          return { error: getError('EMAIL_IS_EXIST') };
        default:
          return { error: getError('SYSTEM_ERROR') };
      }
    }
    return { user: data };
  }

  return {
    createBusinessAccount: onNewBusiness,
    loading,
  };
};

export const useSearchBusiness = () => {
  const {
    input,
    items,
    onSearch,
    loading,
    totalItems,
    totalPages,
    onDeleteItem,
    onChangeLimit,
    onChangePage,
    onLoadMore,
    page,
  } = useBaseSearchQuery<ISearchBusinessAccountsInput, IUser>({
    fetchPolicy: 'cache-and-network',
    query: businessQueries.SEARCH,
  });

  React.useEffect(() => {
    onSearch({
      limit: 100,
      page: 1,
    });
  }, []);

  return {
    input,
    businesses: items,
    searchBusiness: onSearch,
    loading,
    totalItems,
    totalPages,
    onDeleteItem,
    onChangeLimit,
    onChangePage,
    onLoadMore,
    page,
  };
};

export const useDeleteBusiness = () => {
  const { getError } = useTranslationText();

  const { mutation, loading } = useBaseMutation<{ id: string }, boolean>({
    mutation: businessMutations.DELETE,
  });

  async function onDeleteBusiness(input: string): Promise<{ error?: IError; success?: boolean }> {
    const { data, error } = await mutation({ id: input });
    if (error) return { error: getError('User_DELETE_ERROR') };

    return { success: data };
  }

  return {
    loading,
    onDeleteBusiness,
  };
};

export const useGetBusiness = () => {
  const { item, getQuery, loading } = useBaseGetQuery<{ id: string }, IUser>({
    fetchPolicy: 'cache-and-network',
    query: businessQueries.GET,
  });

  return {
    business: item,
    getBusiness: getQuery,
    loading,
  };
};

export const useUpdateBusiness = () => {
  const { mutation, loading } = useBaseMutation<IUpdateBusinessAccountInput, IUser>({
    mutation: businessMutations.UPDATE,
  });

  return {
    updateBusiness: mutation,
    loading,
  };
};
export const useUpdateBusinessUser = () => {
  const { getError } = useTranslationText();

  const { mutation, loading } = useBaseMutation<IUpdateBusinessAccountInput, IUser>({
    mutation: businessMutations.UPDATE_BUSINESS_USER,
  });

  async function onUpdateUserBusiness(input: IUpdateBusinessAccountInput): Promise<{ error?: IError; user?: IUser }> {
    const { data, error } = await mutation(input);
    if (error) return { error: getError('USER_UPDATE_ERROR') };

    return { user: data };
  }

  return {
    loading,
    onUpdateUserBusiness,
  };
};
export const useChangeAccountStatus = () => {
  const { translation } = useTranslationText();
  const { mutation, loading } = useBaseMutation<IChangeAccountStatusInput, IUser>({
    mutation: businessMutations.CHANGE_STATUS,
  });

  async function changeStatus(params: IChangeAccountStatusInput) {
    const result = await mutation(params);
    if (!result.error) {
      openNotification({
        type: 'success',
        message: translation('changeStatus.success'),
      });
    } else {
      openNotification({
        type: 'error',
        message: translation('changeStatus.error'),
        description: result.error.code + (result.error?.title || ''),
      });
    }
  }

  return {
    changeStatus,
    loading,
  };
};
