import { useQuery } from '@apollo/client';
import { userQueries } from 'api/graphql/user';
import { useGetProfile } from 'api/hooks/user/useGetProfile';
import * as React from 'react';
import { IUser } from 'models/User';
import { authReducer, SessionState } from 'stores/userStore';
import { SessionContext } from 'stores/userStore';

const initialState = {
  isLoaded: false,
  token: null,
  isAuthenticated: false,
  user: {},
} as SessionState;

export const SessionProvider = (props: { children: React.ReactNode }) => {
  const token = localStorage.getItem('SESSION_TOKEN');
  const { loading: getProfileLoading, user, onGetProfile } = useGetProfile();
  const { data, loading } = useQuery(userQueries.CHECK_AUTH, { fetchPolicy: 'network-only' });
  const [session, dispatch] = React.useReducer(authReducer, initialState);

  const checkAuth: IUser = data ? data.checkAuth : undefined;
  const isUnAuthenticated = !checkAuth || !token || (token && token.length === 0);

  React.useEffect(() => {
    if (!loading) {
      const user = {
        id: checkAuth?.id,
        role: checkAuth?.role,
      } as IUser;
      dispatch({
        type: 'update',
        payload: { user: user, isAuthenticated: !isUnAuthenticated, token: localStorage.getItem('SESSION_TOKEN') },
      });
    }
    // eslint-disable-next-line
  }, [checkAuth, loading]);

  React.useEffect(() => {
    if (session?.user.id) {
      onGetProfile({ userId: session?.user.id });
    }
    // eslint-disable-next-line
  }, [session?.user.id]);

  React.useEffect(() => {
    if (!getProfileLoading) {
      dispatch({
        type: 'update-profile',
        payload: { user: user },
      });
    }
    // eslint-disable-next-line
  }, [getProfileLoading, user]);

  return <SessionContext.Provider value={{ session, dispatch }}>{props.children}</SessionContext.Provider>;
};
