import React from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { HorizontalView, VerticalView } from 'vendors/Wrapper';
import { Text } from 'vendors/Text';
import Table from 'components/common/components/Table';
import { IReport } from 'models/Report';
import { dateUtil } from 'helpers/date.utils';
import { useTranslationText } from 'locale';
import { Confirm } from 'components/common/components/Modals/ConfirmModal';
import { Button } from 'vendors/Button';
import { AppColors } from 'helpers';
import { IDownloadManager } from 'models/Download';

export function columns(translation: any, onDelete: (id: string) => void) {
  const tableColumns: Array<ColumnDescription<IReport>> = [
    {
      dataField: 'id',
      text: 'No',
      headerStyle: () => {
        return { width: '5%', textAlign: 'left', whiteSpace: 'unset', padding: '10px' };
      },
      formatter: (_, row: IReport, index) => {
        return (
          <HorizontalView fullWidth alignContent={'centerBetween'} padding={'10px'} flexWrap={'nowrap'}>
            {index + 1}
          </HorizontalView>
        );
      },
    },
    {
      dataField: 'name',
      text: translation('public.contact.contactName'),
      headerStyle: () => {
        return { width: '30%', textAlign: 'left', padding: '10px' };
      },
    },
    {
      dataField: 'email',
      text: translation('createUser.email'),
      headerStyle: () => {
        return { width: '20%', textAlign: 'left', padding: '10px' };
      },
    },
    {
      dataField: 'documentLink',
      text: 'URL',
      headerStyle: () => {
        return { width: '20%', textAlign: 'left', padding: '10px' };
      },
    },
    {
      dataField: 'createdTime',
      text: translation('createdTime'),
      headerStyle: () => {
        return { width: '15%', textAlign: 'left', padding: '10px' };
      },
      formatter: (_, row: IReport) => {
        return (
          <HorizontalView fullWidth alignContent={'centerLeft'} padding={'10px'} flexWrap={'nowrap'}>
            <Text>{dateUtil.dateFormat(row.createdTime || '', 'YYYY/MM/DD HH:mm')}</Text>
          </HorizontalView>
        );
      },
    },
    {
      dataField: 'menu',
      csvExport: false,
      text: '',
      headerStyle: () => {
        return { width: '10%', maxWidth: '80px', textAlign: 'center', padding: '10px' };
      },
      // eslint-disable-next-line react/display-name
      formatter: (_, row) => {
        return (
          <HorizontalView fullWidth alignContent={'centerAround'} padding={'5px'}>
            <Confirm
              title={'docDownloaded.deleteMessage'}
              leftText={'home.category.alertDelete.close'}
              rightText={'home.category.alertDelete.delete'}
              onConfirm={() => {
                onDelete(row.id);
              }}
            >
              <Button
                width={'35px'}
                height={'35px'}
                preixIcon={'trash'}
                borderRadius={'4px'}
                color={AppColors.WHITE}
                background={AppColors.RED}
              />
            </Confirm>
          </HorizontalView>
        );
      },
    },
  ];

  return tableColumns;
}

interface Props {
  loading: boolean;
  downloads: IDownloadManager[];
  page: number;
  onDelete: (id: string) => void;
}

export const DownloadedTable = (props: Props) => {
  const { translation } = useTranslationText();
  return (
    <VerticalView fullWidth minWidth={'800px'}>
      <Table
        loading={props.loading}
        keyField={'id'}
        data={props.downloads}
        bordered={false}
        striped={true}
        hover={true}
        columns={columns(translation, props.onDelete)}
      />
    </VerticalView>
  );
};
