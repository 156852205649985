import React, { memo } from 'react';
import styled from 'styled-components';
import { Modal } from 'vendors/Modal';
import { MediaView } from '../Media';
import { AppConst } from 'helpers';
import { useAppSetting } from 'hooks/useAppSetting';
import { CropRatio } from './CropImageModal';
import { useTranslationText } from 'locale';
import { AcceptFileType } from 'vendors/Upload';

interface Props {
  open: boolean;
  ratios?: CropRatio[];
  accept: AcceptFileType;
  onSelect: (media: string) => void;
  onClose: () => void;
  maxImageWidth?: number;
}

const Content = styled.div<{ width: string; height: string }>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  padding: 20px;
`;

export const MediaModal = (props: Props) => {
  const { appTheme, appLayout } = useAppSetting();
  const { translation } = useTranslationText();

  React.useEffect(() => {
    document.body.style.overflow = props.open ? 'hidden' : 'auto';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [props.open]);

  return (
    <Modal
      zIndex={AppConst.zIndex.header + 2000}
      backdropColor={'rgb(1,1,1,0.6)'}
      height={appLayout.isMobile ? '80vh' : '90vh'}
      headerBackground={appTheme.sidebar.bg}
      open={props.open}
      title={translation('media.title')}
      onClose={() => {
        props.onClose();
      }}
      closeOnBackDropClick
    >
      <Content width={appLayout.isMobile ? '100vw' : '90vw'} height={appLayout.isMobile ? '80vh' : '90vh'}>
        <MediaView
          accept={props.accept}
          maxImageWidth={props.maxImageWidth}
          ratios={props.ratios}
          isModal
          onSelect={props.onSelect}
        />
      </Content>
    </Modal>
  );
};

export default memo(MediaModal);
