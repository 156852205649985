import React from 'react';
import { History } from 'history';
import { Div, HorizontalView, VerticalView } from 'vendors/Wrapper';
import { useNavigate } from 'react-router';
import { useTranslationText } from 'locale';
import { ErrorMessage } from 'vendors/CommonWrappers';
import { ButtonOutline } from '../Button/ButtonOutline';
import { Label } from 'vendors/Label';
import { useAppSetting } from 'hooks/useAppSetting';
import { BackButton2 } from '../Button/BackButton2';

type NavigationBarProp = {
  header?: string;
  path: string;
  value?: string;
  history?: History;
  children?: React.ReactNode;
  saving?: boolean;
  error?: boolean;
  background?: string;
  onSave?: () => void;
};
export const NavigationBar = (props: NavigationBarProp) => {
  const navigate = useNavigate();

  const { appTheme } = useAppSetting();
  const { translation } = useTranslationText();

  return (
    <HorizontalView
      fullWidth
      alignContent={'centerBetween'}
      background={props.background}
      padding={'10px'}
      borderRadius={'4px'}
    >
      <HorizontalView minWidth={'50%'} flexWrap={'nowrap'}>
        <Div position={'relative'}>
          <BackButton2 onClick={() => navigate(props.path)} />
        </Div>
        {props.header && (
          <HorizontalView paddingLeft={'15px'}>
            <Label fontSize={'25px'} label={props.header} color={appTheme.base.base} fontWeight={'bolder'} />
          </HorizontalView>
        )}
      </HorizontalView>

      <>
        {props.onSave && (
          <VerticalView alignContent={'centerRight'}>
            <ButtonOutline loading={props.saving} title={'save'} icon={'save'} onClick={props.onSave} height={'40px'} />
            {props.error ? <ErrorMessage>{translation('invalidDataAlert.message')}</ErrorMessage> : null}
          </VerticalView>
        )}
        {props.children}
      </>
    </HorizontalView>
  );
};
