import React, { useEffect } from 'react';
import { useTranslationText } from 'locale';
import { CmsHelpCenterList } from 'components/cms-interface/help-center/list';
import { openNotification } from 'vendors/Notification';
import { useNavigate } from 'react-router-dom';
import { useCreateHelp } from 'api/hooks/help-center/useCreateHelp';
import { useOperatorSearchHelp } from 'api/hooks/help-center/useOperatorSearchHelp';
import { IOperationSearchHelpsInput } from 'models/Help';
import { useDeleteHelp } from 'api/hooks/help-center/useDeleteHelp';

export const CMSHelpCenterListContainer = () => {
  const { translation } = useTranslationText();
  const navigate = useNavigate();

  const { loading: createLoading, onCreateHelp } = useCreateHelp();
  const { searchHelp, loading, helps, onDeleteItem } = useOperatorSearchHelp();
  const { deleteHelp, loading: deleteLoading } = useDeleteHelp();

  useEffect(() => {
    searchHelp({ limit: 100, page: 1, lang: 'ja' } as IOperationSearchHelpsInput);
  }, []);

  return (
    <CmsHelpCenterList
      loading={createLoading || loading || deleteLoading}
      helpList={helps}
      onSearch={(params) => {
        searchHelp({
          keyword: params.keyword,
          lang: 'ja',
          limit: 100,
          page: 1,
        } as IOperationSearchHelpsInput);
      }}
      onCreate={async () => {
        const Help = await onCreateHelp();
        if (Help.id) {
          const id = Help.id!;
          navigate(`/cms/helps/${id}`);
        } else {
          openNotification({
            type: 'error',
            message: translation('message.create'),
            description: translation('message.error'),
          });
        }
      }}
      onDetails={(id) => {
        navigate(`/cms/helps/${id}`);
      }}
      onDelete={async (id) => {
        const result = await deleteHelp({ id });
        if (result.data) {
          onDeleteItem({ id });
          openNotification({
            type: 'success',
            message: translation('message.delete'),
            description: translation('message.success'),
          });
        } else {
          openNotification({
            type: 'error',
            message: translation('message.delete'),
            description: translation('message.error'),
          });
        }
      }}
    />
  );
};

export default CMSHelpCenterListContainer;
