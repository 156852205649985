import gql from 'graphql-tag';

export const GET_APP_LINK = gql`
  query getHomepage {
    getHomepage {
      company {
        androidAppUrl
        iosAppUrl
      }
    }
  }
`;

export const GET = gql`
  query getHomepage {
    getHomepage {
      slideImages
      features {
        id
        title
        description
        thumbnail
      }
      promotions {
        id
        status
        type
        title
        description
        cover
        thumbnail
        videoId
        video {
          id
          categoryId
          companyId
          type
          status
          name
          description
          content
          videoUrl
          videoRatio
          thumbnailSmall
          thumbnail
          salary {
            type
            fixed
            min
            max
          }
          workingTime
          company {
            id
            name
            status
            logo
            cover
            email
            description
            foundation
            representativeName
            postalCode
            stateOrCityName
            streetAddress
            buildingNameAndRoomName
            homephone
            telephone
            contact
            fax
            tax
            businessContent
            privacyPolicy
            termsOfService
            locationMapUrl
            iosAppUrl
            androidAppUrl
            createdBy
            updatedBy
            updatedTime
            createdTime
          }
          category {
            id
            status
            thumbnail
            locales {
              lang
              name
            }
            createdBy
            updatedBy
            updatedTime
            createdTime
          }
          features
          reason
          totalViews
          totalComments
          totalLikes
          userId
          isApplied
          isLiked
          isFavorited
          uploader {
            id
            cover
            avatar
            name
          }
          createdBy
          updatedBy
          period
          updatedTime
          createdTime
        }
        articleId
        article {
          id
          status
          category
          thumbnail
          cover
          title
          seoname
          description
          content
          createdBy
          updatedBy
          updatedTime
          createdTime
        }
        createdBy
        updatedBy
        updatedTime
        createdTime
      }
      customers {
        id
        name
        logo
        cover
        description
        foundation
        representativeName
        postalCode
        stateOrCityName
        streetAddress
        buildingNameAndRoomName
        homephone
        telephone
        fax
        tax
        businessContent
        locationMapUrl
        iosAppUrl
        androidAppUrl
      }
      customerVideos {
        id
        categoryId
        companyId
        category {
          id
          status
          thumbnail
          locales {
            lang
            name
          }
          createdBy
          updatedBy
          updatedTime
          createdTime
        }
        type
        status
        name
        salary {
          type
          fixed
          min
          max
        }

        workingTime
        description
        videoUrl
        videoRatio
        thumbnailSmall
        thumbnail
        period
        userId
        isLiked
        totalViews
        totalComments
        totalLikes
        isFavorited
        isApplied
        uploader {
          id
          cover
          avatar
          name
        }
        createdBy
        updatedBy
        updatedTime
        createdTime
      }
      userVideos {
        id
        categoryId
        companyId
        category {
          id
          status
          thumbnail
          locales {
            lang
            name
          }
          createdBy
          updatedBy
          updatedTime
          createdTime
        }
        type
        status
        name
        salary {
          type
          fixed
          min
          max
        }

        workingTime
        description
        videoUrl
        videoRatio
        thumbnailSmall
        thumbnail
        period
        userId
        isLiked
        totalViews
        totalComments
        totalLikes
        isFavorited
        isApplied
        uploader {
          id
          cover
          avatar
          name
        }
        createdBy
        updatedBy
        updatedTime
        createdTime
      }
      articles {
        id
        status
        category
        thumbnail
        cover
        title
        seoname
        description
        updatedTime
        createdTime
      }
      company {
        id
        name
        logo
        cover
        description
        foundation
        representativeName
        postalCode
        stateOrCityName
        streetAddress
        buildingNameAndRoomName
        homephone
        telephone
        fax
        tax
        businessContent
        locationMapUrl
        iosAppUrl
        androidAppUrl
      }
    }
  }
`;

export const GET_CUSTOMER_PAGE = gql`
  query getCustomerPage {
    getCustomerPage {
      id
      type
      slideImages
      features {
        id
        title
        description
        thumbnail
      }
      customers {
        id
        name
        logo
        cover
        description
        foundation
        representativeName
        postalCode
        stateOrCityName
        streetAddress
        buildingNameAndRoomName
        homephone
        telephone
        fax
        tax
        businessContent
        locationMapUrl
        iosAppUrl
        androidAppUrl
      }
      videos {
        id
        categoryId
        companyId
        category {
          id
          status
          thumbnail
          locales {
            lang
            name
          }
          createdBy
          updatedBy
          updatedTime
          createdTime
        }
        type
        status
        name
        salary {
          type
          fixed
          min
          max
        }

        workingTime
        description
        videoUrl
        videoRatio
        thumbnailSmall
        thumbnail
        period
        userId
        isLiked
        totalViews
        totalComments
        totalLikes
        isFavorited
        isApplied
        uploader {
          id
          cover
          avatar
          name
        }
        createdBy
        updatedBy
        updatedTime
        createdTime
      }
      articles {
        id
        status
        category
        thumbnail
        cover
        title
        seoname
        description
        updatedTime
        createdTime
      }
      fileLinkDownload
    }
  }
`;

export const GET_USER_PAGE = gql`
  query getUserPage {
    getUserPage {
      id
      type
      slideImages
      features {
        id
        title
        description
        thumbnail
      }
      videos {
        id
        categoryId
        companyId
        category {
          id
          status
          thumbnail
          locales {
            lang
            name
          }
          createdBy
          updatedBy
          updatedTime
          createdTime
        }
        type
        status
        name
        salary {
          type
          fixed
          min
          max
        }

        workingTime
        description
        videoUrl
        videoRatio
        thumbnailSmall
        thumbnail
        period
        userId
        isLiked
        totalViews
        totalComments
        totalLikes
        isFavorited
        isApplied
        uploader {
          id
          cover
          avatar
          name
        }
        createdBy
        updatedBy
        updatedTime
        createdTime
      }
      articles {
        id
        status
        category
        thumbnail
        cover
        title
        seoname
        description
        updatedTime
        createdTime
      }
    }
  }
`;
