import { BaseComponent } from 'components/common/components/BaseComponent';
import { BottomBar } from 'components/common/components/BottomBar';
import { PageBodyHeader } from 'components/common/components/Header/PageBodyHeader';
import { AppColors } from 'helpers';
import { useAppSetting } from 'hooks/useAppSetting';
import { useTranslationText } from 'locale';
import { IHelp, IHelpTranslation } from 'models/Help';
import React from 'react';
import { ArticleEditorInput } from 'vendors/ArticleEditor';
import { Button } from 'vendors/Button';
import { Hr } from 'vendors/CommonParts';
import { CardWrapper } from 'vendors/CommonWrappers';
import { Input } from 'vendors/Input';
import { Loading } from 'vendors/Loading';
import { RadioSelect } from 'vendors/Radio';
import { SelectDropdown, SelectItem } from 'vendors/Select';
import { Text } from 'vendors/Text';
import { Div, HorizontalView, VerticalView } from 'vendors/Wrapper';
import { AlertModal } from 'components/common/components/Modals/AlertModal';
import { updateHelpValidator } from 'validators/user';

type PropsType = {
  loading: boolean;
  updateLoading?: boolean;
  help?: IHelp;
  onUpdate: (help: IHelp) => void;
};

export const CmsHelpCenterDetail = React.memo((props: PropsType) => {
  const { appLayout, appSize } = useAppSetting();
  const [message, setMessage] = React.useState('');
  const { translation, getTypes } = useTranslationText();
  const helpTypes = getTypes<SelectItem[]>('helpCategories');
  const [help, setHelp] = React.useState({} as IHelp);
  const languages = getTypes<SelectItem[]>('languages');
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
  const [showAlert, setShowAlert] = React.useState(false);
  const [language, setLanguage] = React.useState('ja');
  const [isPreview, setPreview] = React.useState(false);
  const [translationData, setTranslationData] = React.useState({
    title: '',
    content: '',
    lang: 'vi',
  } as IHelpTranslation);
  const validatorUpdate = updateHelpValidator;

  function updateTranslation(translations: IHelpTranslation[]) {
    let tran = { title: '', content: '', lang: language } as IHelpTranslation;
    if (translations.length > 0) {
      const data = translations.find((tran) => tran?.lang === language);
      if (data) {
        tran = data;
      }
    }
    setTranslationData(JSON.parse(JSON.stringify(tran)));
  }

  React.useEffect(() => {
    if (props.help?.id) {
      setHelp(JSON.parse(JSON.stringify(props.help)));
      const trans = props.help.translations || [];
      updateTranslation(trans as IHelpTranslation[]);
    }
  }, [props.help]);

  React.useEffect(() => {
    if (help.id) {
      const trans = help.translations || [];
      updateTranslation(trans as IHelpTranslation[]);
    }
  }, [language]);

  if (props.loading) return <Loading loading={props.loading} />;

  if (!help?.id) return <></>;

  return (
    <BaseComponent>
      <VerticalView>
        {!appLayout.isMobile && <PageBodyHeader title={translation('helpEdit')} />}
        <HorizontalView fullWidth alignContent="center">
          <HorizontalView fullWidth maxWidth={'1000px'}>
            <VerticalView fullWidth marginTop={'20px'}>
              <CardWrapper noShadow={appLayout.isMobile} padding={appLayout.isMobile ? '10px' : '20px'}>
                <HorizontalView fullWidth alignContent={'centerBetween'} marginBottom={'10px'}>
                  <Text fontSize={appSize.h3} fontWeight={600}>
                    {translation('helpTitle')}
                  </Text>
                </HorizontalView>
                <Hr />

                <HorizontalView fullWidth paddingTop={'15px'} alignContent={'centerLeft'}>
                  <RadioSelect
                    fontSize={appSize.md}
                    defaultValue={{ value: help.status || 'inactive' }}
                    label={'publicTitle'}
                    options={getTypes('statusType')}
                    onChange={(option) => {
                      const status = option?.value;
                      setHelp((help) => {
                        const _help = JSON.parse(JSON.stringify(help));
                        _help.status = status;
                        return _help;
                      });
                    }}
                  />
                </HorizontalView>
                <HorizontalView fullWidth paddingTop={'15px'} alignContent={'centerLeft'}>
                  <RadioSelect
                    required
                    fontSize={appSize.md}
                    defaultValue={{ value: help.forRole }}
                    label={'helpFor'}
                    options={getTypes('publicUserRoles')}
                    error={errors.forRole}
                    onChange={(option) => {
                      const role = option?.value;
                      setHelp((help) => {
                        const _help = JSON.parse(JSON.stringify(help));
                        _help.forRole = role;
                        return _help;
                      });
                    }}
                  />
                </HorizontalView>
                <HorizontalView fullWidth paddingTop={'15px'} alignContent={'centerLeft'}>
                  <SelectDropdown
                    required
                    width={'250px'}
                    size={appSize.md}
                    direction={'column'}
                    label={`category`}
                    value={help.category || ''}
                    options={helpTypes}
                    error={errors.category}
                    onChange={(value) => {
                      const category = value;
                      setHelp((help) => {
                        const _help: IHelp = JSON.parse(JSON.stringify(help));
                        _help.category = category;
                        return _help;
                      });
                    }}
                  />
                </HorizontalView>
              </CardWrapper>

              <CardWrapper
                noShadow={appLayout.isMobile}
                padding={appLayout.isMobile ? '10px' : '20px'}
                marginTop={'20px'}
              >
                <VerticalView width={'100%'} alignContent={'topLeft'}>
                  <HorizontalView>
                    <SelectDropdown
                      width={'250px'}
                      size={appSize.md}
                      direction={'column'}
                      label={`language`}
                      value={language as string}
                      options={languages}
                      onChange={(value) => {
                        setLanguage(value);
                      }}
                    />
                  </HorizontalView>

                  <HorizontalView fullWidth paddingTop={'15px'}>
                    <Input
                      required
                      label={'editArticle.title'}
                      fontSize={appSize.md}
                      name={'title'}
                      placeholder={'editArticle.title'}
                      value={translationData.title}
                      background={'transparent'}
                      error={errors.title}
                      onChange={(e) => {
                        const title = e.target.value;
                        setTranslationData((data) => {
                          const tranData: IHelpTranslation = JSON.parse(JSON.stringify(data));
                          tranData.title = title;
                          return tranData;
                        });
                      }}
                    />
                  </HorizontalView>
                </VerticalView>

                <Div fullWidth marginTop="20px">
                  <VerticalView fullWidth alignContent={'centerLeft'}>
                    <ArticleEditorInput
                      required
                      label={'editArticle.content'}
                      isEditing={!isPreview}
                      defaultValue={translationData.content}
                      name={'content'}
                      width={'100%'}
                      error={errors.content}
                      onChange={(helpcontent) => {
                        setPreview(false);
                        const content = helpcontent;
                        setTranslationData((data) => {
                          const tranData: IHelpTranslation = JSON.parse(JSON.stringify(data));
                          tranData.content = content;
                          return tranData;
                        });
                      }}
                    />
                  </VerticalView>
                </Div>
              </CardWrapper>
              <BottomBar path={'/cms/helps'}>
                <Button
                  loading={props.updateLoading}
                  title={'save'}
                  fontSize={'20px'}
                  preixIcon={'file'}
                  borderRadius={'5px'}
                  paddingLeft={'10px'}
                  paddingRight={'10px'}
                  minWidth={'40px'}
                  height={'40px'}
                  color={'white'}
                  background={AppColors.GRASSY_GREEN}
                  onClick={async () => {
                    const validateData = {
                      content: translationData.content,
                      title: translationData.title,
                      category: help.category,
                      forRole: help.forRole,
                    };

                    validatorUpdate
                      .validate(validateData)
                      .then(() => {
                        const helpData: IHelp = JSON.parse(JSON.stringify(help));

                        let translations = helpData.translations;
                        if (translations && translations.length > 0) {
                          let isUpdated = false;
                          translations = translations.map((tran) => {
                            if (tran?.lang === translationData.lang) {
                              isUpdated = true;
                              return translationData;
                            }
                            return tran;
                          });
                          if (!isUpdated) {
                            translations = [...translations, translationData];
                          }
                        } else {
                          translations = [translationData];
                        }

                        helpData.translations = translations;
                        setHelp(helpData);
                        setErrors({});
                        props.onUpdate(helpData);
                      })
                      .catch((error) => {
                        const errors: string[] = error.errors.map((path: string) => path);
                        setMessage(errors.join(', '));
                        setErrors({ [error.path]: errors.join(', ') });
                        setShowAlert(true);
                      });
                  }}
                />
              </BottomBar>
            </VerticalView>
          </HorizontalView>
          <AlertModal
            open={showAlert}
            message={translation(message)}
            closeTitle={'close'}
            onClose={() => setShowAlert(false)}
          />
        </HorizontalView>
      </VerticalView>
    </BaseComponent>
  );
});
