import { HelpContainer } from 'containers/user-container/help';
import HelpDetailsContainer from 'containers/user-container/help/Detail';
import React from 'react';
import { Routes, Route } from 'react-router';

export const HelpPageRouter = () => {
  return (
    <Routes>
      <Route index element={<HelpContainer />} />
      <Route path="/:id" element={<HelpDetailsContainer />} />
    </Routes>
  );
};
