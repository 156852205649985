import { BaseComponent } from 'components/common/components/BaseComponent';
import { VideoPreviewSection } from 'components/common/components/VideoPreviewSection';
import { useTranslationText } from 'locale';
import { IArticleShort } from 'models/Article';
import React from 'react';
import { HorizontalView, VerticalView } from 'vendors/Wrapper';
import { HomeArticlePreview } from '../home/parts/HomeArticlePreview';
import { FeatureSection } from './parts/FeatureSection';
import { Text } from 'vendors/Text';
import { useAppSetting } from 'hooks/useAppSetting';
import { Button } from 'vendors/Button';
import { AppColors } from 'helpers';
import { IVideoShort } from 'models/Video';
import { PromotionPreview } from 'components/common/components/Promotion/PromotionView';
import { IPageFeature } from 'models/HomePage';
import { useNavigate } from 'react-router-dom';
import { DownloadDocModal } from './parts/DownloadDocModal';

type PropsType = {
  loading: boolean;
  requestLoading: boolean;
  videos?: IVideoShort[];
  news?: IArticleShort[];
  slideImages?: string[];
  features?: IPageFeature[];
  onSendLinkDownload: (name: string, email: string) => void;
  sendSuccess?: boolean;
};

export const EmployerComponent = (props: PropsType) => {
  const { translation } = useTranslationText();
  const { appSize, appLayout } = useAppSetting();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = React.useState(false);

  return (
    <BaseComponent scrollToTop contentLoading={props.loading}>
      <VerticalView fullWidth>
        <PromotionPreview
          slidePictures={props.slideImages}
          title={translation('forEmployer')}
          thumbnail={'images/app_src.png'}
          actionButton={{
            show: true,
            title: 'businessRegister',
            onClick: () => navigate('/business-account-register'),
          }}
        />
        {props.videos && props.videos.length > 0 && (
          <VideoPreviewSection
            loading={props.loading}
            videoTabs={[{ title: 'employerPage.newVideosEmployee', videos: props.videos }]}
          />
        )}
        {props.features && <FeatureSection features={props.features} />}
        {props.news && <HomeArticlePreview header={'common.topMenu.news'} seeMore={'seeMore'} article={props.news} />}
        <HorizontalView fullWidth maxWidth="1200px" alignContent="centerEvenly" paddingTop="30px">
          <VerticalView
            width={appLayout.isMobile ? '70%' : '40%'}
            padding="20px"
            border={`3px solid ${AppColors.PRIMARY}`}
            marginBottom="20px"
          >
            <Text fontSize={appSize.h2} fontWeight={600} marginBottom="10px" textAlign="center">
              {translation('employerPage.serviceDetails')}
            </Text>
            <Button
              width={'95%'}
              title={'employerPage.btnDownload'}
              fontSize={appSize.h2}
              fontWeight={'bold'}
              background={AppColors.WHITE}
              paddingTop="15px"
              paddingBottom="10px"
              color={AppColors.PRIMARY}
              borderRadius={'20px'}
              border={`2px solid ${AppColors.PRIMARY}`}
              css={`
                &:hover {
                  opacity: 1;
                  color: ${AppColors.WHITE};
                  background-color: ${AppColors.PRIMARY};
                }
              `}
              onClick={() => setOpenModal(!openModal)}
            />
          </VerticalView>
          <VerticalView
            width={appLayout.isMobile ? '70%' : '40%'}
            padding="20px"
            border={`3px solid ${AppColors.PRIMARY}`}
            marginBottom="20px"
          >
            <Text fontSize={appSize.h2} fontWeight={600} marginBottom="10px" textAlign="center">
              {translation('employerPage.plan')}
            </Text>
            <Button
              width={'95%'}
              title={'employerPage.btnPlan'}
              fontSize={appSize.h2}
              fontWeight={'bold'}
              background={AppColors.WHITE}
              paddingTop="15px"
              paddingBottom="10px"
              color={AppColors.PRIMARY}
              borderRadius={'20px'}
              border={`2px solid ${AppColors.PRIMARY}`}
              css={`
                &:hover {
                  opacity: 1;
                  color: ${AppColors.WHITE};
                  background-color: ${AppColors.PRIMARY};
                }
              `}
              onClick={() => navigate('/contact')}
            />
          </VerticalView>
        </HorizontalView>
        <DownloadDocModal
          loading={props.requestLoading}
          success={props.sendSuccess}
          open={openModal}
          onClose={() => setOpenModal(false)}
          onSend={props.onSendLinkDownload}
        />
      </VerticalView>
    </BaseComponent>
  );
};

export default React.memo(EmployerComponent);
