import { locationQueries } from 'api/graphql/location';
import { ILocation, ISearchLocationsInput } from 'models/Location';
import { useBaseSearchQuery } from '../base/useBaseSearchQuery';

export const useSearchLocation = () => {
  const { items, loading, onSearch } = useBaseSearchQuery<ISearchLocationsInput, ILocation>({
    fetchPolicy: 'cache-and-network',
    query: locationQueries.SEARCH,
  });

  return {
    locations: items,
    searchLocation: onSearch,
    loading,
  };
};
