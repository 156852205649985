import gql from 'graphql-tag';

export const CREATE = gql`
  mutation sendContact($input: SendContactInput!) {
    sendContact(input: $input)
  }
`;

export const DELETE = gql`
  mutation deleteContact($input: DeleteContactInput!) {
    deleteContact(input: $input)
  }
`;
