import { musicMutations } from 'api/graphql/music';
import { useBaseMutation } from 'api/hooks/base/useBaseMutation';
import { useTranslationText } from 'locale';
import { IUpdateMusicInput } from 'models/Music';
import { openNotification } from 'vendors/Notification';

export const useUpdateMusic = () => {
  const { translation } = useTranslationText();
  const { mutation, loading } = useBaseMutation<IUpdateMusicInput, boolean>({
    mutation: musicMutations.UPDATE,
  });

  async function onUpdateMusic(params: IUpdateMusicInput) {
    const result = await mutation(params);
    if (!result.error) {
      openNotification({
        type: 'success',
        message: translation('musicPage.updateSuccess'),
      });
    } else {
      openNotification({
        type: 'error',
        message: translation('message.error'),
        description: result.error.code + (result.error?.title || ''),
      });
    }
    return result;
  }

  return {
    loading,
    onUpdateMusic,
  };
};
