import React, { useState } from 'react';
import { IChangeMembershipInput, IMembership } from 'models/Membership';
import { CardWrapper } from 'vendors/CommonWrappers';
import { HorizontalView, VerticalView } from 'vendors/Wrapper';
import { Image } from 'vendors/Image';
import { Text } from 'vendors/Text';
import { Label } from 'vendors/Label';
import { dateFormat } from 'helpers/date.utils';
import { useTranslationText } from 'locale';
import { AppColors } from 'helpers';
import { Button } from 'vendors/Button';
import { DatePicker } from 'vendors/DatePicker';
import dayjs from 'dayjs';
import { useExtendMembership } from 'api/hooks/membership/useExtendMembership';
import { useChangeMembership } from 'api/hooks/membership/useChangeMembership';
import { useAppSetting } from 'hooks/useAppSetting';
import { Input } from 'vendors/Input';
import { RadioSelect } from 'vendors/Radio';
import { ICompany } from 'models/Company';
import { IPaymentServiceType } from 'models/Transaction';

export const MembershipInformation = (props: {
  company: ICompany;
  membership?: IMembership;
  onUpdateMembership?: (membership?: IMembership) => void;
}) => {
  const { loading: extendLoading, onExtendMembership } = useExtendMembership();
  const { loading: changeLoading, onChangeMembership } = useChangeMembership();
  const { translation, getTypes } = useTranslationText();
  const { appLayout } = useAppSetting();
  const [isExtend, setExtend] = useState(false);
  const [isChangePlan, setChangePlan] = useState(false);
  const [extendDate, setExtendDate] = useState<string | null>(null);
  const [changePlanInput, setChangePlanInput] = useState<IChangeMembershipInput>({} as IChangeMembershipInput);

  const onExtend = async () => {
    if (!extendDate || !props.membership?.id) {
      return;
    }
    const { data } = await onExtendMembership({
      id: props.membership.id,
      expiresDate: extendDate,
    });

    if (data && props.onUpdateMembership) {
      props.onUpdateMembership(data);
    }
  };

  const onChangePlan = async () => {
    if (!changePlanInput.packageId?.trim()?.length || !changePlanInput.expiresDate?.trim()?.length) {
      return;
    }
    const { data } = await onChangeMembership({
      ...changePlanInput,
      userId: props.company.id,
    });

    if (data && props.onUpdateMembership) {
      props.onUpdateMembership(data);
    }
  };

  return (
    <CardWrapper>
      <VerticalView fullWidth alignContent="topLeft">
        <HorizontalView fullWidth alignContent="topLeft">
          <Image width="150px" src="/images/membership.png" />

          {props.membership?.id && (
            <VerticalView
              width={appLayout.isMobile ? '100%' : 'auto'}
              maxWidth={!appLayout.isMobile ? 'calc(100% - 160px)' : undefined}
              paddingLeft={!appLayout.isMobile ? '20px' : '0px'}
              alignContent="centerLeft"
            >
              <Label label="membership.plan" fontSize="25px" fontWeight={500} />
              <HorizontalView>
                <Text fontSize="20px" fontWeight={'bold'}>
                  {props.membership?.planName || props.membership.localizedPlanName}
                </Text>
              </HorizontalView>
              <HorizontalView paddingTop="5px">
                <Label label="membership.price" />:
                <Text paddingLeft="5px">{props.membership?.planPrice || props.membership?.localizedPlanPrice}</Text>
              </HorizontalView>

              <HorizontalView paddingTop="5px">
                {props.membership?.isMembership && (
                  <Text color={AppColors.GRASSY_GREEN}>
                    {translation('membership.date')}
                    {`: `}
                    {dateFormat(props.membership?.expiresDate || '', 'YYYY/MM/DD HH:mm')}
                  </Text>
                )}
                {!props.membership?.isMembership && (
                  <HorizontalView paddingTop="10px">
                    {!isExtend && !isChangePlan && (
                      <VerticalView alignContent="centerLeft">
                        <Text fontWeight={500} color={AppColors.RED} paddingRight="10px">
                          {translation('membership.membershipIsExpired')}
                        </Text>
                        <HorizontalView paddingTop="20px">
                          <Button
                            title="membership.extendPlan"
                            borderRadius="10px"
                            padding="5px"
                            paddingLeft="10px"
                            paddingRight="10px"
                            background={AppColors.PRIMARY}
                            color={AppColors.WHITE}
                            onClick={() => {
                              setExtend(true);
                              setChangePlan(false);
                            }}
                          />
                          <Button
                            marginLeft="10px"
                            title="membership.changePlan"
                            borderRadius="10px"
                            padding="5px"
                            paddingLeft="10px"
                            paddingRight="10px"
                            background={AppColors.RED}
                            color={AppColors.WHITE}
                            onClick={() => {
                              setExtend(false);
                              setChangePlan(true);
                            }}
                          />
                        </HorizontalView>
                      </VerticalView>
                    )}
                  </HorizontalView>
                )}
                {props.membership?.isMembership && props.membership.paymentServiceType === IPaymentServiceType.Manual && (
                  <HorizontalView fullWidth>
                    {!isExtend && !isChangePlan && (
                      <VerticalView fullWidth alignContent="centerLeft">
                        <HorizontalView paddingTop="20px">
                          <Button
                            title="membership.extendPlan"
                            borderRadius="10px"
                            padding="5px"
                            paddingLeft="10px"
                            paddingRight="10px"
                            background={AppColors.PRIMARY}
                            color={AppColors.WHITE}
                            onClick={() => {
                              setExtend(true);
                              setChangePlan(false);
                            }}
                          />
                        </HorizontalView>
                      </VerticalView>
                    )}
                  </HorizontalView>
                )}
              </HorizontalView>
            </VerticalView>
          )}

          {!props.membership?.id && (
            <VerticalView
              width={appLayout.isMobile ? '100%' : 'auto'}
              maxWidth={!appLayout.isMobile ? 'calc(100% - 160px)' : undefined}
              paddingLeft={!appLayout.isMobile ? '20px' : '0px'}
              alignContent="centerLeft"
            >
              <Label label="membership.plan" fontSize="25px" fontWeight={500} />
              <HorizontalView paddingTop="10px">
                {!isChangePlan && (
                  <VerticalView alignContent="centerLeft">
                    <Text fontWeight={500} fontSize={'20px'} color={AppColors.RED} paddingRight="10px">
                      {translation('membership.noPlan')}
                    </Text>

                    <Button
                      title="membership.addPlan"
                      marginTop="20px"
                      borderRadius="10px"
                      padding="5px"
                      paddingLeft="10px"
                      paddingRight="10px"
                      background={AppColors.RED}
                      color={AppColors.WHITE}
                      onClick={() => {
                        setExtend(false);
                        setChangePlan(true);
                      }}
                    />
                  </VerticalView>
                )}
              </HorizontalView>
            </VerticalView>
          )}

          {isExtend && (
            <CardWrapper>
              <VerticalView width={appLayout.isMobile ? '100%' : '450px'} alignContent="centerLeft">
                <Label label="membership.extendPlan" fontSize="25px" fontWeight={500} />
                <DatePicker
                  showTime
                  showNow={false}
                  style={{ width: appLayout.isMobile ? '80%' : '450px' }}
                  minDate={
                    props.membership?.expiresDate && dayjs(props.membership?.expiresDate).isValid()
                      ? dayjs(props.membership?.expiresDate).add(1, 'day').toISOString()
                      : dayjs().add(1, 'day').toISOString()
                  }
                  onChange={(date) => setExtendDate(date || null)}
                />
                <HorizontalView fullWidth paddingTop="15px" alignContent="centerBetween">
                  <Button
                    marginTop="10px"
                    loading={extendLoading}
                    disabled={extendLoading}
                    width={appLayout.isMobile ? '100%' : '45%'}
                    title="membership.extendPlan"
                    borderRadius="5px"
                    padding="5px"
                    paddingLeft="10px"
                    paddingRight="10px"
                    background={AppColors.PRIMARY}
                    color={AppColors.WHITE}
                    onClick={onExtend}
                  />
                  <Button
                    marginTop="10px"
                    title="membership.cancel"
                    disabled={extendLoading}
                    width={appLayout.isMobile ? '100%' : '45%'}
                    borderRadius="5px"
                    padding="5px"
                    paddingLeft="10px"
                    paddingRight="10px"
                    background={AppColors.RED}
                    color={AppColors.WHITE}
                    onClick={() => setExtend(false)}
                  />
                </HorizontalView>
              </VerticalView>
            </CardWrapper>
          )}
          {isChangePlan && (
            <CardWrapper>
              <Label
                label={!props.membership?.id ? 'membership.addPlan' : 'membership.changePlan'}
                fontSize="25px"
                fontWeight={500}
              />
              <VerticalView width={appLayout.isMobile ? '100%' : '450px'} alignContent="centerLeft" paddingTop="15px">
                <Input
                  value={changePlanInput.packageId}
                  label="membership.packageId"
                  onChange={(e) => setChangePlanInput({ ...changePlanInput, packageId: e.target.value })}
                />
                <HorizontalView paddingTop="15px">
                  <RadioSelect
                    fontSize={'14px'}
                    defaultValue={{ value: changePlanInput.isTrialPeriod }}
                    label={'membership.isFreeTrial'}
                    options={getTypes('yesNoTypes')}
                    onChange={(option) =>
                      setChangePlanInput({ ...changePlanInput, isTrialPeriod: option?.value || false })
                    }
                  />
                </HorizontalView>

                <Label label="membership.expireDate" paddingTop="15px" />
                <DatePicker
                  showTime
                  showNow={false}
                  style={{ width: appLayout.isMobile ? '80%' : '450px' }}
                  minDate={dayjs().add(1, 'day').toISOString()}
                  onChange={(date) =>
                    setChangePlanInput({
                      ...changePlanInput,
                      expiresDate: date || '',
                    })
                  }
                />
                <HorizontalView fullWidth paddingTop="15px" alignContent="centerBetween">
                  <Button
                    marginTop="10px"
                    loading={changeLoading}
                    disabled={
                      changeLoading ||
                      !changePlanInput.packageId?.trim()?.length ||
                      !changePlanInput.expiresDate?.trim()?.length
                    }
                    width={appLayout.isMobile ? '100%' : '45%'}
                    title={!props.membership?.id ? 'membership.addPlan' : 'membership.changePlan'}
                    borderRadius="5px"
                    padding="5px"
                    paddingLeft="10px"
                    paddingRight="10px"
                    background={AppColors.PRIMARY}
                    color={AppColors.WHITE}
                    onClick={onChangePlan}
                  />
                  <Button
                    marginTop="10px"
                    title="membership.cancel"
                    disabled={changeLoading}
                    width={appLayout.isMobile ? '100%' : '45%'}
                    borderRadius="5px"
                    padding="5px"
                    paddingLeft="10px"
                    paddingRight="10px"
                    background={AppColors.RED}
                    color={AppColors.WHITE}
                    onClick={() => setChangePlan(false)}
                  />
                </HorizontalView>
              </VerticalView>
            </CardWrapper>
          )}
        </HorizontalView>
      </VerticalView>
    </CardWrapper>
  );
};
