import React from 'react';
import { AppColors } from 'helpers';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { useAppSetting } from 'hooks/useAppSetting';
import { Carousel } from 'react-responsive-carousel';
import { PromotionCard } from './PromotionCard';
import { useResize } from 'vendors/Hooks/useResize';
import { Text } from 'vendors/Text';
import { Div, HorizontalView, VerticalView } from 'vendors/Wrapper';
import { Image } from 'vendors/Image';
import { Button } from 'vendors/Button';
import { useGetAppLink } from 'api/hooks/home/useGetAppLink';

type BannerProps = {
  thumbnail?: string;
  slidePictures?: string[];
  title: string;
  description?: string;
  actionButton?: {
    show: boolean;
    title: string;
    onClick: () => void;
  };
};

export const PromotionPreview = (props: BannerProps) => {
  const boxRef = React.useRef<HTMLDivElement | null>(null);
  const { appLayout, appSize } = useAppSetting();
  const [height, setHeight] = React.useState(350);
  const { width } = useResize(boxRef);
  const { iosLink, androidLink } = useGetAppLink();

  React.useEffect(() => {
    width > 100 && setHeight(width / (appLayout.isMobile ? 1.55 : 3.0));
  }, [width, appLayout.isMobile]);

  return (
    <HorizontalView
      fullWidth
      ref={boxRef}
      position={'relative'}
      height={`${height}px`}
      flexWrap={'nowrap'}
      background={AppColors.TRANSPARENT}
      alignContent={'centerLeft'}
    >
      <VerticalView
        alignContent={'center'}
        position="absolute"
        top={'0'}
        left={'0'}
        bottom={'0'}
        right={'0'}
        zIndex={2}
      >
        <HorizontalView maxWidth="1200px" fullWidth alignContent="center">
          {props.thumbnail && (
            <Div width="30%" height={`${height}px`} alignContent="center">
              <Image height="70%" width="100%" objectFit="contain" src={props.thumbnail} />
            </Div>
          )}
          <VerticalView width="60%" height={`${height}px`} alignContent="center">
            <Text
              marginTop={appLayout.isMobile ? '10px' : '0px'}
              fontSize={`${appLayout.isMobile ? 20 : height / 14}px`}
              color={'white'}
              fontWeight={700}
              textAlign={'left'}
            >
              {props.title}
            </Text>
            {props.description && (
              <Text fontSize={`${height / 25}px`} marginTop={'10px'} color={'white'} textAlign={'center'}>
                {props.description}
              </Text>
            )}
            <HorizontalView
              paddingTop={`${appLayout.isMobile ? 20 : height / 14}px`}
              fullWidth
              alignContent="centerEvenly"
            >
              <Div
                width={width > 700 ? '30%' : '80%'}
                marginBottom={width > 700 ? '0' : '10px'}
                height={`${height / 7}px`}
                onClick={() => {
                  if (iosLink) {
                    window.open(iosLink);
                  }
                }}
              >
                <Image width="100%" height="100%" objectFit="contain" src="apple-store.png" />
              </Div>
              <Div
                width={width > 700 ? '30%' : '80%'}
                height={`${height / 7}px`}
                onClick={() => {
                  if (androidLink) {
                    window.open(androidLink);
                  }
                }}
              >
                <Image width="100%" height="100%" objectFit="contain" src="google-play.png" />
              </Div>
            </HorizontalView>
            {props.actionButton && props.actionButton.show && (
              <HorizontalView
                paddingTop={`${appLayout.isMobile ? 20 : height / 14}px`}
                fullWidth
                alignContent="centerEvenly"
              >
                <Button
                  title={props.actionButton.title}
                  fontSize={appLayout.isMobile ? `${appLayout.width / 43}px` : appSize.h2}
                  paddingLeft={appLayout.isMobile ? '15px' : '55px'}
                  paddingRight={appLayout.isMobile ? '15px' : '55px'}
                  paddingTop={appLayout.isMobile ? '5px' : '10px'}
                  paddingBottom={appLayout.isMobile ? '5px' : '10px'}
                  background={AppColors.BLACK}
                  color={AppColors.WHITE}
                  border={`2px solid ${AppColors.WHITE}`}
                  borderRadius={'10px'}
                  onClick={props.actionButton.onClick}
                />
              </HorizontalView>
            )}
          </VerticalView>
        </HorizontalView>
      </VerticalView>
      <Div width={'100%'} height="100%" alignContent="center">
        {props.slidePictures && (
          <Carousel
            width="100vw"
            showStatus={false}
            showArrows={false}
            showThumbs={false}
            autoFocus
            autoPlay
            infiniteLoop
            showIndicators={props.slidePictures ? props.slidePictures.length > 1 : false}
          >
            {props.slidePictures.map((item, index) => {
              return (
                <Div width="100vw" height={`${height}px`} key={index} flex={'1'} position="relative">
                  <PromotionCard cardHeight={height} picture={item} />
                </Div>
              );
            })}
          </Carousel>
        )}
        {(!props.slidePictures || props.slidePictures.length === 0) && (
          <Div width="100vw" height={`${height}px`} flex={'1'} position="relative">
            <PromotionCard cardHeight={height} picture={'images/head_banner.jpeg'} />
          </Div>
        )}
      </Div>
    </HorizontalView>
  );
};
