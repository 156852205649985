import { IMessage } from 'react-gifted-chat';

export type MessageProps = {};
export enum IMessageType {
  Link = 'link',
  Image = 'image',
  Text = 'text',
  Audio = 'audio',
  Video = 'video',
  File = 'file',
  Notification = 'notification',
}

export enum SocketEvent {
  Connect = 'connect',
  Disconnect = 'disconnect',
  Online = 'online',
  Offline = 'offline',
  JoinRoom = 'join_room',
  Message = 'message',
  LeaveRoom = 'leave_room',
  DeleteRoom = 'delete_room',
  SearchRooms = 'search_rooms',
  GetRoom = 'get_room',
  CreateRoom = 'create_room',
  SendMessage = 'send_message',
  SeenMessage = 'seen_message',
  ReceiveMessage = 'receive_message',
  MessagesEarliers = 'messages_earliers',
  UnsendMessage = 'unsend_message',
  DeleteMessage = 'delete_message',
}

export enum IMessageStateType {
  Sending = 'sending',
  Comming = 'comming',
  Sent = 'sent',
  Received = 'received',
  Seen = 'seen',
  Remove = 'remove',
  Error = 'error',
}

export enum Channel {
  Global = 'Global',
  Comming = 'Comming',
  Sent = 'Sent',
  Seen = 'Seen',
  Remove = 'Remove',
  Error = 'Error',
}

export type IMessageState = {
  id: string;
  messageId: string;
  userId?: string;
  state?: IMessageStateType;
};

export type IChatMessageFilter = {
  keyword?: string;
  roomId: string;
  lastMessageTime?: string;
  limit?: number;
};

export type ISender = {
  _id: string;
  id: string;
  name?: string;
  avatar?: string;
};

export type IChatMessage = IMessage & {
  id: string;
  roomId?: string;
  userId: string;
  user: ISender;
  text?: string;
  type: IMessageType;
  videos?: string;
  audios?: string;
  photos?: string;
  isSeen?: boolean;
  states?: IMessageState[];
  timestamp?: number;
  createdTime: string;
};

export type IMember = {
  id: string;
  name?: string;
  avatar?: string;
};

export type IRoomFilter = {
  keyword?: string;
  id?: string;
  // For chat 1 - 1
  friendId?: string;
  page?: number;
  limit?: number;
};

export type ISearchRoomsResult = {
  totalItems: number;
  items: [IRoom];
};

export type IRoom = {
  id: string;
  type?: string;
  name?: string;
  icon?: string;
  userIds?: string[];
  lastMessageId?: string;
  lastMessage?: IChatMessage;
  users?: IMember[];
  createdBy: string;
  updatedBy?: string;
  createdTime?: string;
  updatedTime?: string;
};
