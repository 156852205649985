import React from 'react';
import { Routes, Route } from 'react-router';
import { ArticleListPage } from 'containers/user-container/articles/ArticleListPage';
import { ArticleDetailsPage } from 'containers/user-container/articles/ArticleDetailsPage';

export const ArticleRouter = () => {
  return (
    <Routes>
      <Route index element={<ArticleListPage />} />
      <Route path="/:articleId" element={<ArticleDetailsPage />} />
    </Routes>
  );
};
