import React, { useEffect } from 'react';
import { ArticleRouter } from './Article';
import { CompanyRouter } from './Company';
import { ContactRouter } from './Contact';
import { MediaRouter } from './Media';
import { UsersRouter } from './User';
import { PlashScreen } from 'components/common/components/Plash';
import { CMSLayout } from 'components/cms-interface/layout';
import { useSession } from 'stores/userStore';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { NotFoundRouter } from 'routes/public/Notfound';
import { CreateCategoryRouter } from './Category';
import { CMSCustomerCompaniesRoute } from './Customer';
import { CMSVideosPageRoutes } from './Video';
import { ChatRouter } from './Chat';
import { StatisticsRouter } from './Statistic';
import { HomeSettingRouter } from './HomeSetting';
import { ProductRouter } from './Product';
import { MusicRouter } from './Music';
import { IRole } from 'models/types';
import { HelpCenterRoutes } from './HelpCenter';
import { ReportRouter } from './Report';
import { DocDownloadedRoutes } from './DocDownloaded';

export const CmsRouter = () => {
  const location = useLocation();
  const navigative = useNavigate();
  const { session } = useSession();

  useEffect(() => {
    if (!session.isLoaded) {
      return;
    }

    if (!session.isAuthenticated) {
      navigative('/');
      return;
    }

    if (location.pathname == '/cms' || location.pathname == '/cms/index') {
      navigative('/cms/users');
      return;
    }

    if (session.user.role !== IRole.Admin) {
      navigative('/');
    }
  }, [session.user.role, location.pathname]);

  if (!session.isLoaded) {
    return <PlashScreen />;
  }

  return (
    <CMSLayout>
      <Routes>
        <Route path={'chat/*'} element={<ChatRouter />} />
        <Route path={'categories/*'} element={<CreateCategoryRouter />} />
        <Route path={'medias/*'} element={<MediaRouter />} />
        <Route path={'users/*'} element={<UsersRouter />} />
        <Route path={'articles/*'} element={<ArticleRouter />} />
        <Route path={'company/*'} element={<CompanyRouter />} />
        <Route path={'contacts/*'} element={<ContactRouter />} />
        <Route path={'customer/*'} element={<CMSCustomerCompaniesRoute />} />
        <Route path={'videos/*'} element={<CMSVideosPageRoutes />} />
        <Route path={'setting/*'} element={<HomeSettingRouter />} />
        <Route path={'statistics/*'} element={<StatisticsRouter />} />
        <Route path={'product/*'} element={<ProductRouter />} />
        <Route path={'musics/*'} element={<MusicRouter />} />
        <Route path={'helps/*'} element={<HelpCenterRoutes />} />
        <Route path={'report/*'} element={<ReportRouter />} />
        <Route path={'downloaded/*'} element={<DocDownloadedRoutes />} />
        <Route path={'*'} element={<NotFoundRouter />} />
      </Routes>
    </CMSLayout>
  );
};
