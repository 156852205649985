import { BaseComponent } from 'components/common/components/BaseComponent';
import { PageTitle } from 'components/common/components/PageTitle';
import { PaginationBar } from 'components/common/components/Pagination/PaginationBar';
import { IDownloadManager } from 'models/Download';
import React from 'react';
import { TableWrapper } from 'vendors/CommonParts';
import { HorizontalView, VerticalView } from 'vendors/Wrapper';
import { CmsSearchDownloadedBar } from './parts/CmsSearchDownloadedBar';
import { DownloadedTable } from './parts/DownloadedTable';

type PropsType = {
  downloads: IDownloadManager[];
  loading: boolean;
  totalItems: number;
  page: number;
  totalPages: number;
  onSearch: (keyword: string) => void;
  onChangePage: (page: number) => void;
  onDelete: (id: string) => void;
};

export const DocDownloadedComponent = (props: PropsType) => {
  return (
    <BaseComponent>
      <VerticalView fullWidth>
        <PageTitle title={'docDownloaded.header'} />
        <CmsSearchDownloadedBar totalItems={props.totalItems} onSearch={(keyword) => props.onSearch(keyword)} />
        <TableWrapper>
          <DownloadedTable
            page={props.page}
            loading={props.loading}
            downloads={props.downloads}
            onDelete={props.onDelete}
          />
        </TableWrapper>
        {props.totalPages && !props.loading && (
          <HorizontalView fullWidth alignContent={'centerRight'}>
            <PaginationBar totalPages={props.totalPages} currentPage={props.page} onChangePage={props.onChangePage} />
          </HorizontalView>
        )}
      </VerticalView>
    </BaseComponent>
  );
};
