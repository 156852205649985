import React from 'react';
import { Routes, Route } from 'react-router';
import CMSUserDetailsContainer from '../../containers/cms-containers/users/UserDetailsPage';
import CMSUserListContainer from '../../containers/cms-containers/users/UsersSearchPage';
import { NotFoundContainer } from '../../containers/user-container/not-found/index';

export const UsersRouter = () => {
  return (
    <Routes>
      <Route index element={<CMSUserListContainer />} />
      <Route path="/:userId" element={<CMSUserDetailsContainer />} />
      <Route path="*" element={<NotFoundContainer />} />
    </Routes>
  );
};
