import React from 'react';
import { Div, HorizontalView, VerticalView } from 'vendors/Wrapper';
import { Text } from 'vendors/Text';
import { CardWrapper } from 'vendors/CommonWrappers';
import { Image } from 'vendors/Image';
import { FlatList } from 'vendors/FlatList';
import { PrimaryButton } from 'components/common/components/Button/PrimaryButton';
import { useSession } from 'stores/userStore';
import { AppColors } from 'helpers';

type PropsType = {
  isLoading?: boolean;
  size: 'large' | 'normal';
  priceId: string;
  width: string;
  title?: string;
  price?: string;
  description?: string;
  onChoose: () => void;
};

export const PlanCard = (props: PropsType) => {
  const { session } = useSession();

  return (
    <Div width={props.width} padding="10px">
      <CardWrapper
        background={
          session.profile?.membership?.isMembership && session.profile?.membership?.packageId === props.priceId
            ? AppColors.BODY_BACKGROUND
            : AppColors.WHITE
        }
      >
        <VerticalView fullWidth>
          <HorizontalView fullWidth alignContent="centerLeft">
            <Image width={props.size === 'large' ? '60px' : '40px'} src="/images/membership.png" />
            <VerticalView width="calc(100% - 60px)" alignContent="centerLeft" paddingLeft="10px">
              <Text fontSize="20px" fontWeight={'bold'}>
                {props.title}
              </Text>
              {/* <Text fontSize="15px" fontWeight={'bold'}>
                {props.price}
              </Text> */}
            </VerticalView>
          </HorizontalView>
          <Div background="#ccc" height="1px" width="100%" marginTop="20px" marginBottom="10px" />
          <FlatList
            values={props.description?.split('\n')}
            renderItem={(item, index) => {
              return (
                <Div
                  fullWidth
                  alignContent="centerLeft"
                  key={index}
                  marginTop={props.size === 'large' ? '15px' : '10px'}
                >
                  <Text>{item}</Text>
                </Div>
              );
            }}
          />
          {session.profile?.membership?.isMembership && (
            <>
              {session.profile?.membership?.packageId === props.priceId && (
                <PrimaryButton
                  loading={props.isLoading}
                  width="100%"
                  marginTop={props.size === 'large' ? '40px' : '30px'}
                  title="plan.yourPlan"
                />
              )}
              {session.profile?.membership?.packageId !== props.priceId && (
                <PrimaryButton
                  disabled={props.isLoading || session.profile?.membership?.isMembership}
                  loading={props.isLoading}
                  width="100%"
                  marginTop={props.size === 'large' ? '40px' : '30px'}
                  title="plan.choosePlan"
                />
              )}
            </>
          )}
          {!session.profile?.membership?.isMembership && (
            <>
              <PrimaryButton
                disabled={props.isLoading}
                loading={props.isLoading}
                width="100%"
                marginTop={props.size === 'large' ? '40px' : '30px'}
                title="plan.choosePlan"
                onClick={props.onChoose}
              />
            </>
          )}
        </VerticalView>
      </CardWrapper>
    </Div>
  );
};
