import gql from 'graphql-tag';

export const SEARCH = gql`
  query searchLocations($input: SearchLocationsInput!) {
    searchLocations(input: $input) {
      totalItems
      items {
        id
        type
        code
        region
        province
        city
        building
      }
    }
  }
`;
