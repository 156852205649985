import gql from 'graphql-tag';

export const SEARCH = gql`
  query searchTransactions($input: SearchTransactionsInput!) {
    searchTransactions(input: $input) {
      totalPrice
      items {
        id
        description
        type
        invoice
        packageId
        purchaseDate
        device
        price
        receipt
        userId
        user {
          id
          avatar
          name
        }
        createdBy
        updatedTime
        createdTime
      }
    }
  }
`;
