import { BaseComponent } from 'components/common/components/BaseComponent';
import { IComment } from 'models/Comment';
import { IVideo } from 'models/Video';
import React from 'react';
import { Div, HorizontalView, VerticalView } from 'vendors/Wrapper';
import { VideoPlayer } from 'vendors/Video/VideoPlayer';
import { useAppSetting } from 'hooks/useAppSetting';
import { Image } from 'vendors/Image';
import { Text } from 'vendors/Text';
import { useTranslationText } from 'locale';
import { dateUtil } from 'helpers/date.utils';

type PropsType = {
  loading: boolean;
  video: IVideo;
  comments: IComment[];
};

export const VideoDetailComponent = (props: PropsType) => {
  const { appLayout, appSize } = useAppSetting();
  const { translation } = useTranslationText();

  return (
    <BaseComponent contentLoading={props.loading}>
      <VerticalView fullWidth alignContent="center">
        <VerticalView
          fullWidth
          maxWidth="1200px"
          alignContent="topCenter"
          paddingLeft="10px"
          paddingRight="10px"
          paddingTop="20px"
        >
          <HorizontalView fullWidth alignContent="topBetween">
            <VerticalView width={appLayout.isMobile ? '100%' : '70%'}>
              {props.video && props.video.videoUrl && (
                <Div fullWidth paddingRight={appLayout.isMobile ? '0' : '20px'}>
                  <VideoPlayer src={props.video.videoUrl} height={appLayout.isMobile ? '200px' : '500px'} />
                </Div>
              )}
            </VerticalView>
            <VerticalView width={appLayout.isMobile ? '100%' : '30%'} alignContent="topLeft">
              <HorizontalView marginBottom={'10px'}>
                <Div alignContent="center" padding="10px">
                  <Div width="40px" height="40px" borderRadius="50%" overflow={'hidden'}>
                    <Image
                      width="40px"
                      height="40px"
                      src={props.video?.uploader?.avatar || '/images/avatar.png'}
                      objectFit={'cover'}
                    />
                  </Div>
                </Div>
                <VerticalView alignContent="topLeft">
                  <Text fontSize={appSize.h3} fontWeight={600}>
                    {props.video?.uploader?.name || ''}
                  </Text>
                  <Text fontSize={appSize.h5} fontWeight={300}>
                    {dateUtil.formatTimeAgo(props.video?.createdTime || '')}
                  </Text>
                </VerticalView>
              </HorizontalView>
              <VerticalView fullWidth alignContent="topLeft">
                <Text fontSize={appSize.h3} fontWeight={600}>
                  {props.video?.name || ''}
                </Text>
                <Text fontSize={appSize.h4}>{props.video?.description || ''}</Text>
              </VerticalView>
              <VerticalView fullWidth paddingTop="30px" alignContent="topLeft">
                <Div fullWidth paddingBottom="10px">
                  <Text fontSize={appSize.h3} fontWeight={600}>
                    {translation('comment')}
                  </Text>
                </Div>
                {props.comments &&
                  props.comments.map((item, index) => {
                    return (
                      <HorizontalView
                        key={index}
                        borderRadius={'15px'}
                        marginBottom={'10px'}
                        paddingRight={'10px'}
                        paddingTop={'10px'}
                        alignContent={'topCenter'}
                      >
                        <Div alignContent="topCenter" padding="10px">
                          <Div width="40px" height="40px" borderRadius="50%" overflow={'hidden'}>
                            <Image
                              width="40px"
                              height="40px"
                              src={item.commenter?.avatar || '/images/avatar.png'}
                              objectFit={'cover'}
                            />
                          </Div>
                        </Div>
                        <VerticalView alignContent="topLeft">
                          <Text fontSize={appSize.h4} fontWeight={600}>
                            {item.commenter?.name}
                          </Text>
                          <Text fontSize={appSize.h3} fontWeight={500}>
                            {item.text}
                          </Text>
                          <Text fontSize={appSize.h5} fontWeight={300}>
                            {dateUtil.formatTimeAgo(item.createdTime || '')}
                          </Text>
                        </VerticalView>
                      </HorizontalView>
                    );
                  })}
              </VerticalView>
            </VerticalView>
          </HorizontalView>
        </VerticalView>
      </VerticalView>
    </BaseComponent>
  );
};
