import React, { useState } from 'react';
import { IUser, IOperationCreateUserInput } from 'models/User';
import { openNotification } from 'vendors/Notification';
import { useTranslationText } from 'locale';
import { useNavigate } from 'react-router-dom';
import { UserSearchComponent } from '../../../components/cms-interface/users';
import { useOperationCreateUser, useOperationDeleteUser, useOperationSearchUsers } from 'api/hooks/user/useUserApi';

export const CMSUserListContainer = () => {
  const { translation } = useTranslationText();
  const [showCreate, setShowCreate] = useState(false);
  const navigate = useNavigate();

  const {
    onSearchUsers,
    onChangeLimit,
    onChangePage,
    onDeleteUserInList,
    query,
    totalItems,
    users,
    page,
    totalPages,
    loading,
  } = useOperationSearchUsers();
  const { onCreateUser, loading: createLoading } = useOperationCreateUser();
  const { onDeleteUser, loading: deleteLoading } = useOperationDeleteUser();

  const onSearch = (keyword: string) => {
    onSearchUsers({ ...query, keyword });
  };

  const onCreate = async (input: IOperationCreateUserInput) => {
    setShowCreate(false);

    const result = await onCreateUser({
      ...input,
    });

    if (!result.error) {
      navigate(`/cms/users/${result.user?.id}`);
    } else {
      openNotification({
        type: 'error',
        message: result.error?.title || translation('message.create'),
        description: result.error?.message || translation('message.error'),
      });
    }
  };

  const onDelete = async (id: string) => {
    const result = await onDeleteUser({ id });
    if (!result.error) {
      onDeleteUserInList({ id } as IUser);
      openNotification({
        type: 'success',
        message: translation('message.delete'),
        description: translation('message.success'),
      });
    } else {
      openNotification({
        type: 'error',
        message: translation('message.delete'),
        description: translation('message.error'),
      });
    }
  };

  return (
    <UserSearchComponent
      loading={loading}
      deleteLoading={deleteLoading}
      showCreate={showCreate}
      createLoading={createLoading}
      setShowCreate={setShowCreate}
      users={users || []}
      totalPages={totalPages}
      totalItems={totalItems}
      currentPage={page}
      onChangeLimit={onChangeLimit}
      onChangePage={onChangePage}
      onSearch={onSearch}
      onDetails={(id) => {
        navigate(`/cms/users/${id}`);
      }}
      onCreate={onCreate}
      onDelete={onDelete}
      sendingEmail={false}
      mailResult={undefined}
      onSendEmail={() => {}}
    />
  );
};

export default CMSUserListContainer;
