import React from 'react';
import dayjs from 'dayjs';
import { IMembership } from 'models/Membership';
import { CardWrapper } from 'vendors/CommonWrappers';
import { HorizontalView, VerticalView } from 'vendors/Wrapper';
import { Image } from 'vendors/Image';
import { Text } from 'vendors/Text';
import { Label } from 'vendors/Label';
import { dateFormat } from 'helpers/date.utils';
import { useTranslationText } from 'locale';
import { AppColors } from 'helpers';
import { Button } from 'vendors/Button';
import { ICompany } from 'models/Company';
import { useNavigate } from 'react-router-dom';
import { IDeviceType, IPaymentServiceType } from 'models/Transaction';
import { Confirm } from 'components/common/components/Modals/ConfirmModal';

export const MembershipPlan = (props: {
  loading: boolean;
  company: ICompany;
  membership?: IMembership;
  onCancelMembership?: (membership: IMembership) => void;
}) => {
  const navigative = useNavigate();
  const { translation } = useTranslationText();

  return (
    <VerticalView fullWidth alignContent="topLeft">
      <Label label="membership.plan" fontSize="15px" fontWeight={500} />
      <CardWrapper padding="10px">
        <HorizontalView fullWidth alignContent="centerLeft" position="relative" paddingLeft="10px" paddingRight="10px">
          <VerticalView fullWidth alignContent="centerLeft">
            {props.membership?.isMembership && (
              <VerticalView fullWidth alignContent="centerLeft" paddingTop="10px" paddingRight="10px">
                <HorizontalView>
                  <Text fontSize="14px" fontWeight={'bold'}>
                    {props.membership?.localizedPlanName || props.membership?.planName}
                  </Text>
                </HorizontalView>

                {!props.membership.isTrialPeriod && (
                  <HorizontalView paddingTop="5px">
                    <Label fontSize="13px" label="membership.price" />:
                    <Text paddingLeft="5px" fontSize="13px">
                      {props.membership?.localizedPlanPrice || props.membership?.planPrice}
                      {props.membership.deviceType === IDeviceType.Web ? '円' : ''}
                    </Text>
                  </HorizontalView>
                )}
                <HorizontalView paddingTop="5px">
                  {props.membership.isTrialPeriod && props.membership.isAutoRenew && (
                    <VerticalView fullWidth alignContent="centerLeft">
                      <Text>
                        Wakwork有料メンバーシップの試用期間は{' '}
                        <strong>{dayjs(props.membership.expiresDate).format('YYYY/MM/DD')}</strong>
                        までとなっております。
                      </Text>
                      <Text>
                        キャンセルなさらない限り、メンバーシップは自動的に継続され、お試し期間の終了する
                        <strong>{dayjs(props.membership.expiresDate).format('YYYY年MM月DD日 HH:mm')}</strong>
                        (日本時間帯)に月額料金が課金されます。
                      </Text>
                      <Text>
                        お試し期間の終了前にサブスクリプションをキャンセルされた場合、ご利用料金は発生いたしません。
                      </Text>
                    </VerticalView>
                  )}
                  {props.membership.isTrialPeriod && !props.membership.isAutoRenew && (
                    <VerticalView fullWidth alignContent="centerLeft">
                      <Text>
                        Wakwork有料メンバーシップの試用期間は{' '}
                        <strong>{dayjs(props.membership.expiresDate).format('YYYY/MM/DD')}</strong>
                        までとなっております。
                      </Text>
                      <Text>
                        お試し期間の終了前にサブスクリプションをキャンセルされた場合、ご利用料金は発生いたしません。
                      </Text>
                    </VerticalView>
                  )}
                  {!props.membership.isTrialPeriod && (
                    <Text color={AppColors.BLACK}>
                      {translation(props.membership.isAutoRenew ? 'membership.nextPayment' : 'membership.date')}
                      {`: `}
                      {dateFormat(props.membership?.expiresDate || '', 'YYYY/MM/DD HH:mm')}
                    </Text>
                  )}
                </HorizontalView>

                {props.membership.paymentServiceType === IPaymentServiceType.Stripe && props.membership.isAutoRenew && (
                  <VerticalView fullWidth alignContent="center">
                    <Confirm
                      title={'membership.alertCancel.message'}
                      leftText={'membership.alertCancel.close'}
                      rightText={'membership.alertCancel.ok'}
                      onConfirm={() =>
                        props.membership && props.onCancelMembership && props.onCancelMembership(props.membership)
                      }
                    >
                      <Button
                        loading={props.loading}
                        disabled={props.loading}
                        width="120px"
                        title="membership.cancel"
                        borderRadius="10px"
                        padding="5px"
                        paddingLeft="10px"
                        paddingRight="10px"
                        marginTop="20px"
                        marginBottom="10px"
                        background={AppColors.RED2}
                        color={AppColors.WHITE}
                      />
                    </Confirm>
                  </VerticalView>
                )}

                <HorizontalView right="-25px" top="-25px" position="absolute">
                  <Image width="50px" src="/images/membership.png" />
                </HorizontalView>
              </VerticalView>
            )}

            {!props.membership?.isMembership && (
              <HorizontalView>
                <Image width="50px" src="/images/membership.png" />
                <VerticalView alignContent="centerLeft" paddingLeft="20px">
                  <Button
                    loading={props.loading}
                    disabled={props.loading}
                    width="120px"
                    title="membership.addPlan"
                    borderRadius="10px"
                    padding="5px"
                    paddingLeft="10px"
                    paddingRight="10px"
                    background={AppColors.RED2}
                    color={AppColors.WHITE}
                    onClick={() => {
                      navigative('/plan');
                    }}
                  />
                </VerticalView>
              </HorizontalView>
            )}
          </VerticalView>
        </HorizontalView>
      </CardWrapper>
    </VerticalView>
  );
};
