import { useBaseGetQuery } from 'api/hooks/base/useBaseGetQuery';
import { transactionQueries } from 'api/graphql/transaction';
import { ISearchTransactionResult, ISearchTransactionsInput } from 'models/Transaction';

export const useSearchTransaction = () => {
  const { item, loading, getQuery } = useBaseGetQuery<ISearchTransactionsInput, ISearchTransactionResult>({
    fetchPolicy: 'cache-and-network',
    query: transactionQueries.SEARCH,
  });

  return {
    total: item?.totalPrice,
    loading,
    transactions: item?.items,
    searchTransactions: getQuery,
  };
};
