import React from 'react';
import { Div } from 'vendors/Wrapper';
import { IArticleShort } from 'models/Article';
import { BaseComponent } from 'components/common/components/BaseComponent';
import { VideoPreviewSection } from 'components/common/components/VideoPreviewSection';
import { HomeServicesPreview } from './parts/HomeServicesPreview';
import { HomeArticlePreview } from './parts/HomeArticlePreview';
import { ICompanyShort } from 'models/Company';
import { IVideoShort } from 'models/Video';
import { PromotionPreview } from 'components/common/components/Promotion/PromotionView';

interface Props {
  loading: boolean;
  news: IArticleShort[];
  lang: string;
  companies?: ICompanyShort[];
  videoTabs: { title: string; videos: IVideoShort[] }[];
  slideImages?: string[];
}

export const HomePageComponent = (props: Props) => {
  return (
    <BaseComponent scrollToTop>
      <Div alignContent={'center'}>
        <PromotionPreview
          slidePictures={props.slideImages}
          title={'動画で求人者と求職者が出会う'}
          thumbnail={'images/app_src.png'}
        />
        <VideoPreviewSection loading={props.loading} videoTabs={props.videoTabs} />
        <HomeServicesPreview />
        <HomeArticlePreview header={'common.topMenu.news'} seeMore={'seeMore'} article={props.news} />
      </Div>
    </BaseComponent>
  );
};
