import { PageTitle } from 'components/common/components/PageTitle';
import React from 'react';
import { VerticalView, Div } from 'vendors/Wrapper';
import { ServiceCard } from './ServiceCard';

export const HomeServicesPreview = () => {
  return (
    <Div fullWidth alignContent={'center'}>
      <VerticalView
        fullWidth
        maxWidth="1200px"
        minHeight="500px"
        alignContent="topCenter"
        paddingTop="30px"
        paddingBottom="30px"
      >
        <PageTitle title="services" />
        <ServiceCard
          align={'right'}
          title={'企業は'}
          description={
            '動画で、求人を出せます。\n説明しにくい仕事内容でも、簡単にビデオで伝えやすいです。会社の雰囲気、職場のメンバーもビデオで紹介が可能です。'
          }
          image={'images/footer_icon_2.png'}
        />
        <ServiceCard
          align={'left'}
          title={'求職者は'}
          description={
            '動画で仕事、バイトを探せる。\n自分の知らない職業に出会える。自分のスキルを動画でアピールできる。'
          }
          image={'images/education_3.png'}
        />
      </VerticalView>
    </Div>
  );
};
