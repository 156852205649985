import React from 'react';
import { Div, HorizontalView, VerticalView } from 'vendors/Wrapper';
import { NavigationBar } from 'components/common/components/NavigationBar';
import { useTranslationText } from 'locale';
import { useAppSetting } from 'hooks/useAppSetting';
import { Formik, FormikProps } from 'formik';
import { Input } from 'vendors/Input';
import { AlertModal } from 'components/common/components/Modals/AlertModal';
import { BaseComponent } from 'components/common/components/BaseComponent';
import { IError } from 'models/Models';
import { RadioSelect } from 'vendors/Radio';
import { ImagePicker2 } from 'components/common/components/ImagePicker/ImagePicker2';
import { ICategory } from 'models/Category';
import { RadioItem } from 'vendors/DatePicker';

interface NewsDetailsProps {
  loading: boolean;
  saving?: boolean;
  isServicePage: boolean;
  category?: ICategory;
  error?: IError;
  onUpdate: (news: ICategory) => void;
}

export const CMSCategoryCreateComponent = (props: NewsDetailsProps) => {
  const { getTypes, translation } = useTranslationText();
  const { loading } = props;
  const { appSize, appLayout } = useAppSetting();
  const [openAlert, setOpenAlert] = React.useState(false);
  const langOption = getTypes('languages') as RadioItem[];
  const [currentLang, setCurrentLag] = React.useState(0);
  const ref = React.useRef<FormikProps<ICategory>>(null);

  React.useEffect(() => {
    if (props.category) {
      if (ref && ref.current) {
        ref.current.setValues(JSON.parse(JSON.stringify(props.category)));
      }
    }
  }, [props.category]);

  const onSave = () => {
    if (ref && ref.current) {
      ref.current.submitForm();
    }
  };
  return (
    <BaseComponent error={props.error} contentLoading={loading} documentTitle={translation('home.category.header')}>
      <VerticalView>
        <NavigationBar
          header={'home.category.header'}
          saving={props.saving}
          path={props.isServicePage ? '/index' : '/cms/categories'}
          background={'#EDEDED'}
          onSave={props.category?.id ? onSave : undefined}
        />
        <VerticalView fullWidth>
          <Div width="100%">
            <Div fullWidth>
              <Formik
                innerRef={ref}
                initialValues={
                  props.category || ({ locales: [{ lang: 'vi' }, { lang: 'ja' }, { lang: 'en' }] } as ICategory)
                }
                // validationSchema={updateArticleValidatorSchema}
                onSubmit={(values: ICategory) => {
                  props.onUpdate(values);
                }}
              >
                {({ submitCount, setFieldValue, errors, values, handleChange }) => {
                  return (
                    <VerticalView fullWidth>
                      <HorizontalView fullWidth alignContent={'topLeft'} paddingTop={'40px'} paddingBottom={'20px'}>
                        <HorizontalView width={'100%'} alignContent={'topLeft'}>
                          {/* <Div>
                            <ImagePicker2
                              required
                              maxImageWidth={512}
                              label={'home.category.icon'}
                              ratios={['3x2']}
                              width={'150px'}
                              height={'150px'}
                              value={values.thumbnail || ''}
                              onSelect={(url) => setFieldValue('thumbnail', url)}
                              error={submitCount ? errors.thumbnail : undefined}
                            />
                          </Div> */}
                          {props.isServicePage && (
                            <VerticalView width={'100%'} alignContent={'centerLeft'} paddingTop={'20px'}>
                              <ImagePicker2
                                label={'editArticle.cover'}
                                ratios={['2x1']}
                                width={'100%'}
                                height={'300px'}
                                value={values.cover || ''}
                                onSelect={(url) => setFieldValue('cover', url)}
                                error={submitCount ? errors.cover : undefined}
                              />
                            </VerticalView>
                          )}
                          {/* input */}
                          <VerticalView width={'100%'} alignContent={'topLeft'} marginTop={'15px'}>
                            <Div fullWidth paddingTop={'15px'}>
                              <Input
                                required
                                width={appLayout.isMobile ? '100%' : '50%'}
                                label={'home.category.name'}
                                fontSize={appSize.md}
                                name={`locales[${currentLang}].name`}
                                height={'auto'}
                                placeholder={'home.category.name'}
                                value={values.locales[currentLang].name}
                                background={'transparent'}
                                error={submitCount ? errors.name : undefined}
                                onChange={handleChange}
                              />
                            </Div>
                            <Div fullWidth paddingTop={'15px'}>
                              <Input
                                required
                                width={appLayout.isMobile ? '100%' : '50%'}
                                label={'home.category.id'}
                                fontSize={appSize.md}
                                name={'id'}
                                height={'auto'}
                                placeholder={'home.category.id'}
                                value={values?.id}
                                background={'transparent'}
                                error={submitCount ? errors.name : undefined}
                                onChange={handleChange}
                              />
                            </Div>
                          </VerticalView>
                          <Div width={'100%'} alignContent={'centerLeft'} paddingTop={'30px'}>
                            <RadioSelect
                              fontSize={appSize.md}
                              defaultValue={{
                                // label: values?.locales?.find((locale) => currentLang.value === locale?.lang)?.name,
                                value: values?.locales?.find((locale) => langOption[currentLang].value === locale?.lang)
                                  ?.lang,
                              }}
                              label={'language'}
                              options={getTypes('languages')}
                              onChange={async (e) => {
                                langOption.map((lang, index) => {
                                  if (lang.value === e?.value) setCurrentLag(index);
                                });
                              }}
                            />
                          </Div>
                          <Div width={'100%'} alignContent={'centerLeft'} paddingTop={'30px'} marginBottom="40px">
                            <RadioSelect
                              fontSize={appSize.md}
                              defaultValue={{ value: values?.status }}
                              label={`editArticle.status`}
                              options={getTypes('statusType')}
                              onChange={async (e) => {
                                setFieldValue('status', e?.value);
                              }}
                            />
                          </Div>
                          <NavigationBar
                            saving={props.saving}
                            path={props.isServicePage ? '/cms/services' : '/cms/articles'}
                            error={Boolean(submitCount && Object.keys(errors).length > 0)}
                            onSave={() => {
                              if (ref && ref.current) {
                                ref.current.submitForm();
                              }
                            }}
                          />
                        </HorizontalView>
                      </HorizontalView>
                    </VerticalView>
                  );
                }}
              </Formik>
            </Div>
          </Div>
          {/*  */}
          <AlertModal
            open={openAlert}
            message={translation('invalidDataAlert.message')}
            closeTitle={translation('invalidDataAlert.close')}
            onClose={() => setOpenAlert(false)}
          />
        </VerticalView>
      </VerticalView>
    </BaseComponent>
  );
};
