import React from 'react';
import { useGetCompanyTermOfService, useUpdateCompany } from 'api/hooks/company/useCompanyApi';
import { IUpdateCompanyInput } from 'models/Company';
import { openNotification } from 'vendors/Notification';
import { useTranslationText } from 'locale';
import { CMSTermOfServicesComponent } from 'components/cms-interface/company/company-term-of-service';

export const CMSTermOfServicesContainer = () => {
  const { loading, company, onGetCompanyTOS } = useGetCompanyTermOfService();
  const { translation } = useTranslationText();
  const { onUpdateCompany, loading: updateLoading } = useUpdateCompany();

  React.useEffect(() => {
    onGetCompanyTOS();
  }, []);

  const update = async (data: string) => {
    const update = { id: 'wakjob', termsOfService: data } as IUpdateCompanyInput;
    const result = await onUpdateCompany(update);
    if (!result.error) {
      openNotification({
        type: 'success',
        message: translation('message.update'),
        description: translation('message.success'),
      });
    } else {
      openNotification({
        type: 'error',
        message: translation('message.update'),
        description: translation('message.error'),
      });
    }
  };

  return (
    <CMSTermOfServicesComponent
      loading={loading}
      updateLoading={updateLoading}
      companyTOS={company ? (company.termsOfService as string) : ''}
      onSave={(data) => update(data)}
    />
  );
};

export default CMSTermOfServicesContainer;
