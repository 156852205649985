import React from 'react';
import { Routes, Route } from 'react-router';
import NotFoundContainer from '../../containers/user-container/not-found';

export const NotFoundRouter = () => {
  return (
    <Routes>
      <Route path="*" element={<NotFoundContainer />} />
    </Routes>
  );
};
