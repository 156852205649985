import React from 'react';
import { IContact } from 'models/Contact';
import { useTranslationText } from 'locale';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { HorizontalView, VerticalView } from 'vendors/Wrapper';
import { TextLink } from 'vendors/TextLink';
import { Text } from 'vendors/Text';
import Table from 'components/common/components/Table';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function columns(startIndex: number, translation: any, onDetails: (id: string) => void) {
  const mobileColumns: Array<ColumnDescription<IContact>> = [
    {
      dataField: 'id',
      text: 'ID',
      headerStyle: () => {
        return {
          fontSize: '13px',
          width: '40px',
          textAlign: 'center',
          whiteSpace: 'unset',
          padding: '10px',
        };
      },
      // eslint-disable-next-line react/display-name
      formatter: (_, row: IContact, index) => {
        return (
          <HorizontalView fullWidth alignContent={'center'} padding={'5px'}>
            <TextLink fontWeight={600} href={`/cms/helps/${row.id}`}>
              {startIndex + index + 1}
            </TextLink>
          </HorizontalView>
        );
      },
    },
    {
      dataField: 'name',
      text: translation('information'),
      headerStyle: () => {
        return { fontSize: '13px', width: '60%', textAlign: 'center', padding: '10px' };
      },
      // eslint-disable-next-line react/display-name
      formatter: (_, row: IContact) => {
        return (
          <HorizontalView fullWidth alignContent={'center'} padding={'5px'}>
            <VerticalView fullWidth alignContent={'centerLeft'} overflow={'hidden'}>
              <TextLink fontWeight={500} href={`/cms/contacts/${row.id}`}>
                {row.email}
              </TextLink>
              <Text fontWeight={500}>{row.phone}</Text>
              <Text>{row.subject}</Text>
            </VerticalView>
          </HorizontalView>
        );
      },
    },
  ];

  return mobileColumns;
}

interface ContactMobileTableProps {
  loading: boolean;
  deleteLoading?: boolean;
  contacts: IContact[];
  page: number;
  rowPerPage: number;
  totalPages: number;
  onDetails: (id: string) => void;
}

export const ContactMobileTable = (props: ContactMobileTableProps) => {
  const { translation } = useTranslationText();

  return (
    <Table
      loading={props.loading}
      keyField={'id'}
      data={props.contacts}
      bordered={false}
      striped={true}
      hover={true}
      columns={columns((props.page - 1) * props.rowPerPage, translation, props.onDetails)}
    />
  );
};
