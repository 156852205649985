import { useGetCustomerContent, useGetHomeContent, useGetUserContent } from 'api/hooks/home/useHomeApi';
import { useUpdatePageSetting } from 'api/hooks/home/useUpdatePageSetting';
import { HomeSettingComponent } from 'components/cms-interface/setting';
import { IPageFeature, IUpdatePageSettingInput } from 'models/HomePage';
import React from 'react';

export const HomeSettingContainer = () => {
  const { homePage, loading: homeLoading, onGetHomePage } = useGetHomeContent();
  const { customerPage, loading: customerLoading, onGetCustomerPage } = useGetCustomerContent();
  const { userPage, loading: userLoading, onGetUserPage } = useGetUserContent();
  const { updatePageSetting, loading } = useUpdatePageSetting();

  function updateSetting(
    homePics: string[],
    employerPics: string[],
    employeePics: string[],
    documentLink: string,
    features: IPageFeature[],
  ) {
    const input = {
      home: { slideImages: homePics },
      customer: { slideImages: employerPics, fileLinkDownload: documentLink, features },
      user: { slideImages: employeePics },
    } as IUpdatePageSettingInput;
    updatePageSetting(input);
    setTimeout(() => {
      onGetHomePage();
      onGetCustomerPage();
      onGetUserPage();
    }, 100);
  }

  return (
    <HomeSettingComponent
      loading={homeLoading || customerLoading || userLoading}
      saving={loading}
      employerPagePictures={customerPage?.slideImages}
      employeePagePictures={userPage?.slideImages}
      homePagePictures={homePage?.slideImages}
      documentLink={customerPage?.fileLinkDownload}
      features={customerPage?.features}
      onSave={updateSetting}
    />
  );
};
