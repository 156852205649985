import { CMSVideoDetailContainer } from 'containers/cms-containers/videos/detail';
import { CMSVideosListContainer } from 'containers/cms-containers/videos/list';
import React from 'react';
import { Routes, Route } from 'react-router';

export const CMSVideosPageRoutes = () => {
  return (
    <Routes>
      <Route index element={<CMSVideosListContainer />} />
      <Route path="/:videoId" element={<CMSVideoDetailContainer />} />
    </Routes>
  );
};
