import { VideoDetailContainer } from 'containers/user-container/video-detail';
import React from 'react';
import { Routes, Route } from 'react-router';

export const VideosRouter = () => {
  return (
    <Routes>
      <Route path="/:videoId" element={<VideoDetailContainer />} />
    </Routes>
  );
};
