import React from 'react';
import styled from 'styled-components';
import { Hr } from 'vendors/CommonParts';
import { VerticalView } from 'vendors/CommonWrappers';
import { Text } from 'vendors/Text';
import { AppColors } from 'helpers';
import { IArticle } from 'models/Article';
import { dateUtil } from 'helpers/date.utils';
import { ArticleEditorPreview } from 'vendors/ArticleEditor';
import { Div, HorizontalView } from 'vendors/Wrapper';
import { useTranslationText } from 'locale';
import { AnimationView } from 'vendors/Animation';
import { BaseComponent } from 'components/common/components/BaseComponent';
import { IError } from 'models/Models';
import { useAppSetting } from 'hooks/useAppSetting';
import { BannerPreview } from 'components/common/components/Banner';
import { ShareBarHorizontal } from 'components/common/components/ShareBar';
import { useNavigate } from 'react-router';

export type LoginFormik = {
  email: string;
  password: string;
};

const CategoryWrapper = styled.div`
  font-weight: normal;
  font-size: 15px;
  font-weight: 600;
  color: ${AppColors.BLUE};
  background: transparent;
  cursor: pointer;
`;

const TimeWrapper = styled.div`
  font-weight: normal;
  font-size: 15px;
  color: ${AppColors.DARK};
  background: transparent;
`;

type NewsDetailsPros = {
  error?: IError;
  isLoading: boolean;
  news?: IArticle;
  recentNews: IArticle[];
};

export const NewsDetailsComponent = (props: NewsDetailsPros) => {
  const { translation } = useTranslationText();
  const { appLayout, appSize } = useAppSetting();
  const navigate = useNavigate();
  return (
    <BaseComponent scrollToTop error={props.error} fullLoading={props.isLoading}>
      <VerticalView fullWidth alignContent={'topCenter'}>
        <BannerPreview
          banner={'/images/news.jpeg'}
          title={translation('public.articleDetails.header')}
          height={'300px'}
          description={translation(`public.articleDetails.description`)}
        />
        <HorizontalView fullWidth maxWidth={'1280px'} alignContent={'topCenter'} position={'relative'}>
          <HorizontalView
            width={appLayout.isMobile ? '100%' : 'calc(100% - 360px)'}
            maxWidth={'700px'}
            marginTop={'20px'}
            marginLeft={appLayout.isMobile ? '0px' : '30px'}
          >
            <AnimationView width={'100%'} animation={'fadeIn'}>
              <VerticalView width="100%" alignContent="topLeft" padding={appLayout.isMobile ? '10px' : '0px'}>
                <HorizontalView fullWidth alignContent={'centerBetween'}>
                  <CategoryWrapper
                    onClick={(e) => {
                      e.preventDefault();
                      navigate('/articles');
                    }}
                  >
                    <Text fontSize={appSize.h4}>{translation('newsTitle')}</Text>
                  </CategoryWrapper>
                  <TimeWrapper>
                    <Text fontSize={appSize.h4}>
                      {dateUtil.dateFormat(props.news?.createdTime || '', 'MMM D, YYYY')}
                    </Text>
                  </TimeWrapper>
                </HorizontalView>
                {/* <Hr he/> */}
                <Text marginTop={'10px'} fontSize={appSize.h1}>
                  {props.news?.title}
                </Text>
                <Text marginTop={'10px'} fontSize={appSize.h3}>
                  {props.news?.description}
                </Text>
                <HorizontalView width={'calc(100% + 30px)'} marginLeft={'-15px'}>
                  {props.news && props.news.content && <ArticleEditorPreview setContents={props.news.content} />}
                </HorizontalView>
                <Hr />
                <VerticalView fullWidth paddingTop={'20px'} paddingBottom={'20px'}>
                  {translation(`sharePost`)}
                  <Div>{props.news && <ShareBarHorizontal shareUrl={encodeURI(document.URL)} />}</Div>
                </VerticalView>
              </VerticalView>
            </AnimationView>
          </HorizontalView>
        </HorizontalView>
      </VerticalView>
    </BaseComponent>
  );
};
