import React from 'react';
import styled from 'styled-components';
import config from 'config';
import { Button } from 'vendors/Button';
import { HorizontalView, Div, VerticalView } from 'vendors/Wrapper';
import { Image } from 'vendors/Image';
import { Text } from 'vendors/Text';
import { useTranslationText } from 'locale';
import { TitleWrapper } from 'vendors/CommonWrappers';
import { SVGIcon } from 'vendors/Icon';
import { useSession } from 'stores/userStore';
import { IMedia } from 'models/Media';
import { AppColors } from 'helpers';
import { Confirm } from '../Modals/ConfirmModal';
import { getMediaUrl } from 'helpers/common.utils';

export const ButtonCopyWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  right: 4px;
  left: 4px;
  top: 4px;
  position: absolute;
  opacity: 1;
  &:hover {
    color: red;
    opacity: 0.7;
  }
`;

export const MessageCopyWrapper = styled.div`
  right: 40px;
  top: 15px;
  position: absolute;
  opacity: 1;
  color: green;
`;

type MediaCardProps = {
  width: string;
  media: IMedia;
  onDelete: (media: IMedia) => void;
  onSelect?: (media: IMedia) => void;
};

export const MediaCard = (props: MediaCardProps) => {
  const { media, onDelete } = props;
  const { session } = useSession();
  const { translation } = useTranslationText();
  const [linkCopied, setLinkCopied] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (linkCopied) {
      setTimeout(() => {
        setLinkCopied(null);
      }, 600);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkCopied]);

  const handleCopyLink = async () => {
    try {
      const API_HOST_URL = config.API_HOST_URL;
      let src = media.url;
      if (src && !src.includes('http')) {
        src = API_HOST_URL + media.src;
      }
      await navigator.clipboard.writeText(src);
      setLinkCopied(media.id);
    } catch (err) {}
  };

  return (
    <HorizontalView
      width={props.width}
      position={'relative'}
      margin={'10px'}
      alignContent={'topCenter'}
      border={`1px solid ${AppColors.BORDER}`}
    >
      <Div
        width={props.width}
        height={props.width}
        onClick={() => {
          if (props.onSelect) {
            props.onSelect(media);
          }
        }}
        alignContent="center"
      >
        {media.mimetype.includes('audio') && <SVGIcon size={'60px'} color={AppColors.DARK} name={'music'} />}
        {media.mimetype.includes('video') && (
          <VerticalView fullWidthHeight position={'relative'} alignContent="center">
            <VerticalView position={'absolute'} fullWidthHeight>
              {media.thumbnail && (
                <Image width={props.width} height={props.width} src={media.thumbnail} objectFit={'contain'} />
              )}
            </VerticalView>
            <VerticalView position={'absolute'} fullWidthHeight alignContent="center">
              <SVGIcon size={'60px'} color={AppColors.ORANGE} name={'play'} />
            </VerticalView>
          </VerticalView>
        )}
        {media.mimetype.includes('image') && (
          <Image width={props.width} height={props.width} src={getMediaUrl(media)} objectFit={'contain'} />
        )}
      </Div>

      <HorizontalView
        fullWidth
        position={'absolute'}
        bottom={'0'}
        paddingLeft={'15px'}
        paddingRight={'15px'}
        background={AppColors.BLACK}
        opacity={0.4}
      >
        <TitleWrapper maxLine={1} color={AppColors.WHITE} size={'13px'}>
          {media.name}
        </TitleWrapper>
      </HorizontalView>

      <ButtonCopyWrapper>
        <HorizontalView fullWidth alignContent={'centerBetween'}>
          <>
            {session.user?.role === 'SUPER_ADMIN' && (
              <Confirm
                title={'modals.confirmDelete.message'}
                leftText={'modals.confirmDelete.close'}
                rightText={'modals.confirmDelete.delete'}
                onConfirm={() => {
                  onDelete(media);
                }}
              >
                <Button preixIcon={'trash'} fontSize={'20px'} />
              </Confirm>
            )}
          </>

          <Button
            preixIcon={'copy'}
            fontSize={'20px'}
            color={linkCopied && linkCopied === media.id ? AppColors.GRAY_LIGHT2 : AppColors.BLUE}
            onClick={handleCopyLink}
          />
        </HorizontalView>
      </ButtonCopyWrapper>
      {linkCopied && linkCopied === media.id && (
        <MessageCopyWrapper>
          <HorizontalView padding={'5px'} background={AppColors.BLACK} borderRadius={'4px'} opacity={0.4}>
            <Text color={AppColors.WHITE} fontSize={'10px'}>
              {translation('copied')}
            </Text>
          </HorizontalView>
        </MessageCopyWrapper>
      )}
    </HorizontalView>
  );
};
