import { useBaseSearchQuery } from 'api/hooks/base/useBaseSearchQuery';
import { IComment, ISearchCommentsInput } from 'models/Comment';
import { commentQueries } from 'api/graphql/comment';

export const useSearchComment = () => {
  const {
    loading,
    onSearch,
    items,
    totalItems,
    totalPages,
    page,
    onLoadMore,
    onDeleteItem,
    onChangePage,
    hasMore,
  } = useBaseSearchQuery<ISearchCommentsInput, IComment>({
    fetchPolicy: 'cache-and-network',
    isLoadForever: true,
    query: commentQueries.SEARCH,
  });

  return {
    loading,
    searchCommnents: onSearch,
    comments: items,
    totalItems,
    totalPages,
    page,
    hasMore,
    onLoadMore,
    onChangePage,
    onDeleteItem,
  };
};
