import { musicMutations } from 'api/graphql/music';
import { useBaseMutation } from 'api/hooks/base/useBaseMutation';
import { useTranslationText } from 'locale';
import { ICreateMusicInput, IMusic } from 'models/Music';
import { openNotification } from 'vendors/Notification';

export const useCreateMusic = () => {
  const { translation } = useTranslationText();
  const { loading, mutation } = useBaseMutation<ICreateMusicInput, IMusic>({
    mutation: musicMutations.CREATE,
  });

  async function onCreateMusic(params: ICreateMusicInput) {
    const result = await mutation(params);
    if (!result.error) {
      openNotification({
        type: 'success',
        message: translation('musicPage.createSuccess'),
      });
    } else {
      openNotification({
        type: 'error',
        message: translation('message.error'),
        description: result.error.code + (result.error?.title || ''),
      });
    }
    return { music: result.data, error: result.error };
  }

  return {
    loading,
    onCreateMusic,
  };
};
