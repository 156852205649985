import gql from 'graphql-tag';
export const CREATE = gql`
  mutation createProduct($input: CreateProductInput!) {
    createProduct(input: $input) {
      id
      platform
      type
      name
      description
      packageId
      price
      createdTime
      updatedTime
    }
  }
`;
export const UPDATE = gql`
  mutation updateProduct($input: UpdateProductInput!) {
    updateProduct(input: $input)
  }
`;
export const DELETE = gql`
  mutation deleteProduct($input: DeleteProductInput!) {
    deleteProduct(input: $input)
  }
`;
