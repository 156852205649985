import { useGetGroupHelp } from 'api/hooks/help-center/useGetGroupHelp';
import { HelpComponent } from 'components/user-interface/help';
import React from 'react';
import { useEffect } from 'react';

export const HelpContainer = () => {
  const { groupHelp, onGetGroupHelp, loading } = useGetGroupHelp();
  const onGetGroup = async (lang: string) => {
    await onGetGroupHelp({ lang: lang });
  };
  useEffect(() => {
    onGetGroupHelp({ lang: 'ja' });
  }, []);
  return <HelpComponent groups={groupHelp} loading={loading} onGetGroup={onGetGroup} />;
};
