import { AppColors } from 'helpers';
import { useAppSetting } from 'hooks/useAppSetting';
import React, { useState } from 'react';
import styled from 'styled-components';
import { IConType, SVGIcon } from 'vendors/Icon';
import { Text } from 'vendors/Text';
import { TextLink } from 'vendors/TextLink';
import { HorizontalView, VerticalView } from 'vendors/Wrapper';

const RowWrapper = styled.div`
  width: 100%;
`;

const RowCardNormalWrapper = styled.button<RowProps>`
  width: 100%;
  ${(props) => (props.isBorder ? 'border-bottom: 1px solid #eeeeee;' : '')}
  background:transparent;
  border-style: none;
  &:focus {
    outline: 0;
  }
`;

const ExplainRowHeader = styled.button`
  width: 100%;
  border: 0px solid transparent;
  background: #e8f0fe;
  cursor: pointer;
  padding: 0px;
  &:focus {
    outline: 0;
  }
`;
const ExplainRowDetails = styled.div<{ open: boolean }>`
  width: 100%;
  opacity: ${(props) => (props.open ? '1' : '0')};
  max-height: ${(props) => (props.open ? '100%' : '0')};
  overflow: hidden;
  transition: all 0.1s;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: ${(props) => (props.open ? '5px' : '0px')};
`;
const ExplainRow = styled.div`
  width: 100%;
  padding: 5px;
`;

export type RowProps = {
  title?: string;
  index?: number;
  icon?: IConType;
  color?: string;
  isBorder?: boolean;
  url?: string;
  subRows?: Array<RowProps>;
  fontWeight?: number;
  onClick?: () => void;
};

export const RowCardNormal = (props: RowProps) => {
  const { appSize } = useAppSetting();
  return (
    <RowCardNormalWrapper isBorder={props.isBorder}>
      <HorizontalView width={'100%'} alignContent={'centerLeft'}>
        <TextLink
          fontWeight={props.fontWeight || 400}
          fontSize={appSize.h4}
          href={props.url || '#'}
          textAlign={'left'}
          color={props.color}
        >
          {props.title}
        </TextLink>
      </HorizontalView>
    </RowCardNormalWrapper>
  );
};

export const RowCardExplain = (props: RowProps) => {
  const [explain, setExplain] = useState(true);
  const { appSize } = useAppSetting();
  return (
    <RowWrapper>
      <VerticalView width={'100%'} alignContent={'centerBetween'}>
        <ExplainRowHeader
          onClick={(e) => {
            e.preventDefault();
            setExplain(!explain);
          }}
        >
          <HorizontalView fullWidth alignContent={'centerBetween'} padding={'10px'}>
            <Text fontSize={appSize.h4} fontWeight={600} color={AppColors.DARK} textAlign={'left'}>
              {props.title}
            </Text>
            <SVGIcon name={!explain ? 'arrow-down' : 'arrow-up'} color={AppColors.DARK} />
          </HorizontalView>
        </ExplainRowHeader>

        {props.subRows && (
          <ExplainRowDetails open={explain}>
            {props.subRows.map((row, index) => {
              return (
                <ExplainRow key={`${index}`}>
                  <RowCardNormal {...row} color={AppColors.BLACK} isBorder={false} />
                </ExplainRow>
              );
            })}
          </ExplainRowDetails>
        )}
      </VerticalView>
    </RowWrapper>
  );
};
