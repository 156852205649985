import gql from 'graphql-tag';

export const SEARCH = gql`
  query searchComments($input: SearchCommentsInput!) {
    searchComments(input: $input) {
      totalItems
      items {
        id
        videoId
        text
        userId
        commenter {
          id
          avatar
          name
        }
        updatedTime
        createdTime
      }
    }
  }
`;
