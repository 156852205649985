import * as mutations from './mutations';
import * as queries from './queries';

export const productMutations = {
  ...mutations,
};

export const productQueries = {
  ...queries,
};
