import 'antd/dist/antd.css';
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Text } from 'vendors/Text';
import locale from 'antd/es/date-picker/locale/ja_JP';
import { DatePicker as AntDatePicker } from 'antd';

const InputWrapper = styled.div``;

const InputText = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
`;

const MustLabel = styled.span`
  display: inline-block;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1;
  border-radius: 3px;
  color: red;
  margin-left: 2px;
`;

const ErrorMessage = styled.div`
  margin-top: 5px;
  color: red;
  font-size: 10px;
`;

const InputContent = styled.div``;

export type RadioItem = {
  label?: string;
  value: any;
};

type DatePickerProps = {
  value?: string;
  name?: string;
  text?: string;
  showNow?: boolean;
  showTime?: boolean;
  minDate?: string;
  fontSize?: string;
  errors?: string;
  hidden?: boolean;
  disabled?: boolean;
  required?: boolean;
  invalid?: boolean;
  style?: React.CSSProperties;
  onChange?: (date?: string) => void;
};

export const DatePicker = (props: DatePickerProps) => {
  if (props.hidden) {
    return <></>;
  }

  return (
    <InputWrapper key={props.name}>
      {props.text && (
        <InputText>
          <Text fontWeight={500} fontSize={props.fontSize || '13px'}>
            {props.text} {props.required && <MustLabel>{'(*)'}</MustLabel>}
          </Text>
        </InputText>
      )}
      <InputContent style={props.style}>
        <AntDatePicker
          locale={locale}
          showNow={props.showNow}
          showTime={props.showTime}
          showHour
          showMinute
          showSecond={false}
          disabledDate={(date) =>
            props.minDate ? moment(date).toISOString() <= moment(props.minDate).toISOString() : false
          }
          value={props.value ? moment(props.value) : undefined}
          style={{ width: '100%' }}
          onChange={(value, dateString) => {
            props.onChange && props.onChange(dateString);
          }}
        />
        {props.errors && <ErrorMessage>{`※ ${props.errors}`}</ErrorMessage>}
      </InputContent>
    </InputWrapper>
  );
};
