import { CmsRouter } from 'routes/cms';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { SessionProvider } from '../context/SessionContext';
import { PublicRouter } from './public';
import { SocketProvider } from 'context/SocketContext';
import { ChatProvider } from 'containers/cms-containers/chat/context';
import { UserRouter } from './user';

export const AppRoutes = () => {
  return (
    <SessionProvider>
      <SocketProvider>
        <ChatProvider>
          <Routes>
            <Route path="cms/*" element={<CmsRouter />} />
            <Route path="user/*" element={<UserRouter />} />
            <Route path="*" element={<PublicRouter />} />
          </Routes>
        </ChatProvider>
      </SocketProvider>
    </SessionProvider>
  );
};
