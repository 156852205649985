import { notification } from 'antd';

export type INotification = {
  type: 'success' | 'error';
  message?: string;
  description?: string;
};

export const openNotification = (props: INotification) => {
  const config = {
    style: { marginTop: 50 },
    message: props.message,
    description: props.description,
  };

  if (props.type === 'success') {
    return notification.success(config);
  } else if (props.type === 'error') {
    return notification.error(config);
  }
};
