import { useState, useEffect } from 'react';
import { isTablet, isIPad13 } from 'react-device-detect';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions(): {
  isMobile: boolean;
  isTablet: boolean;
  windowDimensions: {
    width: number;
    height: number;
  };
} {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    windowDimensions,
    isTablet: isTablet || isIPad13 || windowDimensions.width <= 1024,
    isMobile: windowDimensions.width < 700,
  };
}
