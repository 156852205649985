import React from 'react';
import styled from 'styled-components';
import { Div, VerticalView } from 'vendors/Wrapper';
import { DescriptionWrapper, TitleWrapper } from 'vendors/CommonWrappers';
import { useAppSetting } from 'hooks/useAppSetting';
import { TextLink } from 'vendors/TextLink';

const Box = styled.div`
  width: 100%;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1), 0px 2px 5px 0px rgba(50, 50, 93, 0.1),
    0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 20px;
  background: white;
`;

type NoItemInCartProps = {
  title?: string;
  message: string;
  actionTitle?: string;
  link?: string;
  children?: React.ReactNode;
};

export const MessageBox = (props: NoItemInCartProps) => {
  const { appLayout } = useAppSetting();

  return (
    <VerticalView
      width={appLayout.isMobile ? '90%' : '550px'}
      alignContent={'centerLeft'}
      position={'relative'}
      opacity={1}
    >
      <Box>
        <VerticalView fullWidth>
          <TitleWrapper>{props.title}</TitleWrapper>
          <DescriptionWrapper>{props.message}</DescriptionWrapper>
          {props.children}
          {props.actionTitle && (
            <Div marginTop={'20px'}>
              <TextLink href={props.link || '#'}>{props.actionTitle}</TextLink>
            </Div>
          )}
        </VerticalView>
      </Box>
    </VerticalView>
  );
};
