import React from 'react';
import { IContact } from 'models/Contact';
import { useTranslationText } from 'locale';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { AppColors } from 'helpers';
import { Div, HorizontalView, VerticalView } from 'vendors/Wrapper';
import { Text } from 'vendors/Text';
import Table from 'components/common/components/Table';
import { SVGIcon } from 'vendors/Icon';
import dayjs from 'dayjs';

export function columns(startIndex: number, translation: any, onDetails: (id: string) => void) {
  const tableColumns: Array<ColumnDescription<IContact>> = [
    {
      dataField: 'id',
      text: 'No',
      headerStyle: () => {
        return { width: '10%', textAlign: 'left', whiteSpace: 'unset', padding: '10px' };
      },
      // eslint-disable-next-line react/display-name
      formatter: (_, row: IContact, index) => {
        return (
          <a
            href={`/cms/contacts/${row.id}`}
            color={AppColors.PRIMARY}
            onClick={(event) => {
              event.preventDefault();
              onDetails(row.id);
            }}
          >
            <HorizontalView fullWidth alignContent={'centerBetween'} padding={'10px'} flexWrap={'nowrap'}>
              {startIndex + index + 1}
              <Div width={'30px'}>
                <SVGIcon size={'20px'} name={'arrow-right'} color={AppColors.PRIMARY} />
              </Div>
            </HorizontalView>
          </a>
        );
      },
    },
    {
      dataField: 'datetime',
      text: translation('searchContacts.time'),
      headerStyle: () => {
        return { width: '20%', textAlign: 'left', padding: '10px' };
      },
      // eslint-disable-next-line react/display-name
      formatter: (_, row: IContact) => {
        return (
          <HorizontalView fullWidth alignContent={'center'} padding={'5px'}>
            <Text>{row.datetime ? row.datetime : translation('no')}</Text>
          </HorizontalView>
        );
      },
    },

    {
      dataField: 'name',
      text: translation('searchContacts.name'),
      headerStyle: () => {
        return { width: '30%', textAlign: 'left', padding: '10px' };
      },
    },
    {
      dataField: 'subject',
      text: translation('searchContacts.subject'),
      headerStyle: () => {
        return { width: '30%', textAlign: 'left', padding: '10px' };
      },
    },
    {
      dataField: 'createdTime',
      text: translation('createdTime'),
      headerStyle: () => {
        return { width: '10%', textAlign: 'left', padding: '10px' };
      },
      formatter: (_, row: IContact) => {
        return (
          <HorizontalView fullWidth alignContent={'center'} padding={'5px'}>
            <Text>{dayjs(row.createdTime).format('YYYY/MM/DD HH:mm')}</Text>
          </HorizontalView>
        );
      },
    },
  ];

  return tableColumns;
}

interface ContactWebTableProps {
  loading: boolean;
  contacts: IContact[];
  page: number;
  rowPerPage: number;
  totalPages: number;
  onDetails: (id: string) => void;
}

export const ContactWebTable = (props: ContactWebTableProps) => {
  const { translation } = useTranslationText();

  return (
    <VerticalView fullWidth minWidth={'1200px'}>
      <Table
        loading={props.loading}
        keyField={'id'}
        data={props.contacts}
        bordered={false}
        striped={true}
        hover={true}
        columns={columns((props.page - 1) * props.rowPerPage, translation, props.onDetails)}
      />
    </VerticalView>
  );
};
