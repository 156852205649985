import CommonSection from 'components/common/components/CommonSection';
import { PageTitle } from 'components/common/components/PageTitle';
import React from 'react';
import { FlatList } from 'vendors/FlatList';
import { Div, HorizontalView, VerticalView } from 'vendors/Wrapper';
import { Image } from 'vendors/Image';
import { AppColors } from 'helpers';
import { Text } from 'vendors/Text';
import { IConType, SVGIcon } from 'vendors/Icon';
import { useAppSetting } from 'hooks/useAppSetting';
import { IPageFeature } from 'models/HomePage';

export const FeatureSection = (props: { features: IPageFeature[] }) => {
  const { appSize, appTheme, appLayout } = useAppSetting();

  if (props.features.length > 0)
    return (
      <CommonSection>
        <HorizontalView fullWidth maxWidth={'1200px'} position={'relative'} alignContent={'center'}>
          <PageTitle title={'employerPage.merit'} />
          <FlatList
            alignContent={'topAround'}
            values={props.features}
            direction={'column'}
            renderItem={(_category, index) => {
              const blockWidth = '30%';
              return (
                <HorizontalView width={blockWidth} key={index}>
                  <VerticalView fullWidth alignContent={'center'} padding={'10px'}>
                    <Div width={'70%'}>
                      {_category.thumbnail && _category.thumbnail.includes('http') && (
                        <Image width={'100%'} objectFit={'contain'} src={_category.thumbnail} />
                      )}
                      {_category.thumbnail && !_category.thumbnail.includes('http') && (
                        <SVGIcon color={AppColors.BLACK} size={'40px'} name={_category.thumbnail as IConType} />
                      )}
                    </Div>
                    <HorizontalView paddingTop={'10px'}>
                      <Text
                        color={appTheme.base.text}
                        fontWeight={500}
                        fontSize={appLayout.isMobile ? appSize.h4 : appSize.h2}
                      >
                        {_category.title}
                      </Text>
                    </HorizontalView>
                    {!appLayout.isMobile && (
                      <HorizontalView fullWidth alignContent={'center'}>
                        <Text width={'100%'} textAlign={'center'} color={appTheme.base.text} fontSize={'15px'}>
                          {_category.description}
                        </Text>
                      </HorizontalView>
                    )}
                  </VerticalView>
                </HorizontalView>
              );
            }}
          />
        </HorizontalView>
      </CommonSection>
    );
  else return <div />;
};
