import { ICategory } from './Category';
import { ICompany } from './Company';
import { ISalary, ISalaryInput } from './Salary';
import { ISortDirection, VideoStatus } from './types';

export enum IVideoType {
  Job = 'Job',
  Video = 'Video',
}

export enum ISalaryPayType {
  Hour = 'Hour',
  Day = 'Day',
  Month = 'Month',
  Percent = 'Percent',
}

export enum IEmploymentType {
  Manager = 'Manager',
  PartTime = 'PartTime',
  FullTime = 'FullTime',
  Contract = 'Contract',
  Temporary = 'Temporary',
  Subcontracting = 'Subcontracting',
  Other = 'Other',
}

export type IWorkplace = {
  region?: string;
  province?: string;
  city?: string;
  code?: string;
  building?: string;
  address?: string;
};

export type IVideo = {
  id: string;
  categoryId?: string;
  companyId?: string;
  type: IVideoType;
  status: VideoStatus;
  name?: string;
  description?: string;
  content?: string;
  videoUrl?: string;
  videoRatio?: number;
  thumbnail?: string;
  salary: ISalary;
  salaryPayType?: ISalaryPayType;
  employmentType?: IEmploymentType;
  period?: string;
  workplace?: IWorkplace;
  company?: ICompany;
  category?: ICategory;
  features?: string[];
  reason?: string;
  userId: string;
  uploader?: IUploader;
  createdBy?: string;
  updatedBy?: string;
  updatedTime?: string;
  createdTime?: string;
};

export type IVideoShort = {
  __typename?: 'VideoShort';
  id: string;
  categoryId?: string;
  companyId: string;
  category: ICategory;
  type?: string;
  status: VideoStatus;
  name?: string;
  description?: string;
  videoUrl?: string;
  salaryPayType?: ISalaryPayType;
  workplace?: IWorkplace;
  videoRatio?: number;
  thumbnail?: string;
  userId: string;
  uploader?: IUploader;
  createdBy?: string;
  updatedBy?: string;
  updatedTime?: string;
  createdTime?: string;
};

export type IOperationSearchVideosInput = {
  keyword?: string;
  status?: VideoStatus[];
  categoryIds?: string[];
  sortDirection?: ISortDirection;
  limit: number;
  page: number;
};

export type ISearchVideosInput = {
  keyword?: string;
  status?: VideoStatus[];
  categoryIds?: string[];
  sortBy?: string;
  sortDirection?: ISortDirection;
  limit: number;
  page: number;
};

export type IUpdateVideoInput = {
  id: string;
  categoryId?: string;
  status?: string;
  name?: string;
  description?: string;
  content?: string;
  salary?: ISalaryInput;
  features?: string[];
};

export type ICreateVideoInput = {
  type?: string;
  categoryId?: string;
  companyId?: string;
  name?: string;
  description?: string;
  videoUrl?: string;
  videoRatio?: number;
  thumbnail?: string;
  content?: string;
  salary?: ISalaryInput;
  features?: string[];
};

export type IChangeVideoStatusInput = {
  id: string;
  status: string;
  reason?: string;
};

export type IUploader = {
  id?: string;
  avatar?: string;
  name?: string;
};
