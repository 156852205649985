import EmployeeContainer from 'containers/user-container/employee';
import React from 'react';
import { Routes, Route } from 'react-router';

export const EmployeePageRouter = () => {
  return (
    <Routes>
      <Route index element={<EmployeeContainer />} />
    </Routes>
  );
};
