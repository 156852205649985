import { useGetReport } from 'api/hooks/report/useGetReport';
import { CMSReportDetailComponents } from 'components/cms-interface/report/detail';
import React from 'react';
import { useParams } from 'react-router-dom';

export const CMSReportDetailContainer = () => {
  const { reportId } = useParams();
  const { getReport, loading, report } = useGetReport();
  React.useEffect(() => {
    if (reportId) {
      getReport({ id: reportId });
    }
  }, [reportId]);

  return <CMSReportDetailComponents loading={loading} report={report} />;
};

export default React.memo(CMSReportDetailContainer);
