import gql from 'graphql-tag';

export const GET = gql`
  query getCategory($input: GetCategoryInput!) {
    getCategory(input: $input) {
      id
      status
      locales {
        lang
        name
      }
    }
  }
`;

export const USER_SEARCH_CATEGORY = gql`
  query userSearchCategory($input: UserSearchCategoriesInput!) {
    userSearchCategories(input: $input) {
      totalItems
      items {
        id
        locales {
          lang
          name
        }
        status
      }
    }
  }
`;

export const OPERATION_SEARCH_CATEGORY = gql`
  query operationSearchCategories($input: OperationSearchCategoriesInput!) {
    operationSearchCategories(input: $input) {
      totalItems
      items {
        id
        locales {
          lang
          name
        }
        status
      }
    }
  }
`;
