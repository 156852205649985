import React, { useCallback } from 'react';
import { CMSMusicSearchComponent } from 'components/cms-interface/music/library';
import { ICreateMusicInput, IMusic } from 'models/Music';
import { useOpertionSearchMusic } from 'api/hooks/music/useOpertionSearchMusic';
import { useCreateMusic } from 'api/hooks/music/useCreateMusic';
import { useDeleteMusic } from 'api/hooks/music/useDeleteMusic';
import { useUpdateMusic } from 'api/hooks/music/useUpdateMusic';

export const CmsMusicLibraryPage = () => {
  const {
    onSearchMusic,
    onChangeLimit,
    onChangePage,
    onDeleteMusicInList,
    onUpdateMusicInList,
    query,
    totalItems,
    musics,
    page,
    totalPages,
    loading,
    limit,
  } = useOpertionSearchMusic();
  const { onCreateMusic, loading: createLoading } = useCreateMusic();
  const { onDeleteMusic, loading: deleteLoading } = useDeleteMusic();
  const { onUpdateMusic, loading: updateLoading } = useUpdateMusic();

  const onSearchBy = useCallback(
    (tags: string[], type: string) => {
      onSearchMusic({ ...query, [type]: tags });
    },
    [query],
  );

  const onCreate = async (music: ICreateMusicInput) => {
    await onCreateMusic(music);
    onSearchMusic({ ...query });
  };

  const onDelete = async (id: string) => {
    const result = await onDeleteMusic({ id });
    if (!result.error) {
      onDeleteMusicInList({ id } as IMusic);
    }
  };

  const onUpdate = async (music: IMusic) => {
    const result = await onUpdateMusic({
      id: music.id,
      status: music.status,
      thumbnail: music.thumbnail,
      name: music.name,
      authorName: music.authorName,
      url: music.url,
    });
    if (!result.error) {
      onUpdateMusicInList(music);
    }
  };

  const onSearchKeyword = (keyword: string) => {
    onSearchMusic({ ...query, keyword });
  };

  return (
    <CMSMusicSearchComponent
      loading={loading}
      createLoading={createLoading}
      updateLoading={updateLoading}
      deleteLoading={deleteLoading}
      musics={musics}
      currentPage={page}
      limit={limit}
      totalItems={totalItems}
      totalPages={totalPages}
      onSearch={onSearchKeyword}
      onSearchBy={onSearchBy}
      onCreate={onCreate}
      onUpdate={onUpdate}
      onDelete={onDelete}
      onChangeLimit={onChangeLimit}
      onChangePage={onChangePage}
    />
  );
};
