import { IMessage } from './Models';
import { ISortDirection } from './types';
import { IUser } from './User';
import { IVideo } from './Video';

export type IReport = {
  __typename?: 'Report';
  id: string;
  title?: string;
  type?: IReportType;
  reasonType?: string;
  content?: string;
  reportVideoId?: string;
  reportUserId?: string;
  userId?: string;
  video?: IVideo;
  reportUser?: IUser;
  reporter?: IUser;
  updatedTime?: string;
  createdTime?: string;
};

export type IReportResult = {
  __typename?: 'ReportResult';
  code: number;
  message: string;
  error?: IMessage;
  data?: IReport;
};

export enum IReportType {
  ReportUser = 'ReportUser',
  ReportVideo = 'ReportVideo',
}

export type IResetBadgeCountInput = {
  type: string;
};

export type ISearchReportsInput = {
  keyword?: string;
  sortDirection?: ISortDirection;
  limit: number;
  page: number;
};
