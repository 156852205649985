import React from 'react';
import styled from 'styled-components';
import { Modal } from 'vendors/Modal';
import { HorizontalView, VerticalView } from 'vendors/Wrapper';
import { AppColors, AppConst } from 'helpers';
import { useWindowDimensions } from 'vendors/Hooks/useWindow/useWindowDimensions';
import { Button } from 'vendors/Button';
import { Hr } from 'vendors/CommonParts';
import { Text } from 'vendors/Text';

export const ModalWrapper = styled.div``;
export const AlertModalWrapper = styled.div``;
export const ContentWrapper = styled.div``;
export const AddUserWrapper = styled.div``;

const Content = styled.div<{ width: string; height: string }>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background: ${AppColors.TRANSPARENT};
`;

interface Props {
  open: boolean;
  message?: string;
  closeTitle?: string;
  onClose: () => void;
}

export const AlertModal = (props: Props) => {
  const { isMobile } = useWindowDimensions();
  return (
    <Modal
      transparent
      zIndex={AppConst.zIndex.header + 3000}
      backdropColor={'rgb(1,1,1,0.3)'}
      open={props.open}
      onClose={() => {
        props.onClose();
      }}
      backdrop
      closeOnBackDropClick
    >
      <Content width={'100vw'} height={'auto'}>
        <VerticalView fullWidthHeight alignContent={'bottomCenter'}>
          <HorizontalView
            marginBottom={'20px'}
            width={isMobile ? '300px' : '400px'}
            background={AppColors.WHITE}
            borderRadius={'10px'}
            alignContent={'center'}
            overflow={'hidden'}
          >
            <HorizontalView paddingTop={isMobile ? '0px' : '20px'} paddingBottom={isMobile ? '0px' : '20px'}>
              <Text padding={'20px'} fontSize={'16px'} marginLeft={'10px'} color={AppColors.BLACK} textAlign={'center'}>
                {props.message}
              </Text>
            </HorizontalView>

            <VerticalView fullWidth>
              <Hr />

              <Button
                marginTop={'5px'}
                marginBottom={'5px'}
                title={props.closeTitle || 'YES'}
                width={'100%'}
                height={'50px'}
                borderRadius={'5px'}
                color={AppColors.RED}
                background={AppColors.WHITE}
                onClick={props.onClose}
              />
            </VerticalView>
          </HorizontalView>
        </VerticalView>
      </Content>
    </Modal>
  );
};
