import React from 'react';
import { Div, VerticalView } from 'vendors/Wrapper';
import { Image } from 'vendors/Image';

type PromotionCardProps = {
  cardHeight: number;
  picture: string;
};

export const PromotionCard = (props: PromotionCardProps) => {
  return (
    <VerticalView fullWidthHeight alignContent={'center'} flexWrap={'nowrap'} position="relative">
      <Div
        position={'absolute'}
        top={'0'}
        left={'0'}
        bottom={'0'}
        right={'0'}
        alignContent={'centerLeft'}
        background="black"
        opacity={0.3}
        zIndex={1}
      />
      <Div position={'absolute'} top={'0'} left={'0'} bottom={'0'} right={'0'} alignContent={'centerLeft'}>
        <Image height={'100%'} width={'100%'} objectFit={'cover'} src={props.picture || '/images/banner.png'} />
      </Div>
    </VerticalView>
  );
};
