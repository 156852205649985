import React from 'react';
import styled from 'styled-components';
import { Div, HorizontalView } from 'vendors/Wrapper';
import { Button } from 'vendors/Button';
import { Input } from 'vendors/Input';
import { AppColors } from 'helpers';
import { VerticalView } from 'vendors/CommonWrappers';
import { Label } from 'vendors/Label';
import { PrimaryButton } from 'components/common/components/Button/PrimaryButton';
import { Text } from 'vendors/Text';
import { Hr } from 'vendors/CommonParts';
import { ITag, TagSelect } from 'components/common/components/Tag/TagSelect';
import { useTranslationText } from 'locale';
import { SelectDropdown } from 'vendors/Select';

export const SearchWrapper = styled.div`
  border: 1px solid ${AppColors.BORDER};
  border-radius: 25px;
  overflow: hidden;
  width: 80%;
`;

type SearchBarProps = {
  createLoading: boolean;
  totalItems: number;
  onChangeLimit: (limit: number) => void;
  onSearch: (params: string) => void;
  onCreate: () => void;
  onSelectTags: (tags: string[]) => void;
  onFilterMemberStatus: (status?: boolean) => void;
  onOpenSendMail: () => void;
  onOpenSendMailAll: () => void;
  sendMailButton: boolean;
};

const Status = (props: { onSelectTags: (tags: string[]) => void }) => {
  const { translation } = useTranslationText();
  const [tags, setTags] = React.useState<ITag[]>([]);

  React.useEffect(() => {
    setTags([
      {
        value: 'active',
        label: translation('accountStatus.active'),
        isSelect: false,
      },
      {
        value: 'inactive',
        label: translation('accountStatus.inactive'),
        isSelect: false,
      },
      {
        value: 'blocked',
        label: translation('accountStatus.blocked'),
        isSelect: false,
      },
      {
        value: 'restrict',
        label: translation('accountStatus.restrict'),
        isSelect: false,
      },
      {
        value: 'suspended',
        label: translation('accountStatus.suspended'),
        isSelect: false,
      },
      {
        value: 'not_approved',
        label: translation('accountStatus.not_approved'),
        isSelect: false,
      },
    ]);
  }, []);

  return (
    <TagSelect
      label={translation('status')}
      type={'status'}
      values={tags}
      setValues={setTags}
      onSelectTags={props.onSelectTags}
    />
  );
};

const MembersStatusFilter = (props: { onFilterMemberStatus: (status?: boolean) => void }) => {
  const { getTypes } = useTranslationText();
  const [type, setType] = React.useState<string>('');

  return (
    <HorizontalView paddingBottom="15px">
      <Label width="150px" label={'usersSearch.member'} fontSize={'20px'} paddingRight={'10px'} />
      <SelectDropdown
        width={'250px'}
        direction={'column'}
        value={type}
        options={getTypes('accountMemberStatus')}
        onChange={(value) => {
          setType(value);
          props.onFilterMemberStatus(value === 'all' ? undefined : value);
        }}
      />
    </HorizontalView>
  );
};

const SearchKeyword = (props: { onSearch: (key: string) => void }) => {
  const [keyword, setKeyword] = React.useState('');
  return (
    <HorizontalView fullWidth alignContent={'centerLeft'} paddingTop={'20px'}>
      <Div width={'350px'} paddingRight={'20px'}>
        <Input
          width={'100%'}
          height={'50px'}
          placeholder={'usersSearch.searchbar.keyword'}
          value={keyword}
          borderRadius={'5px'}
          onChange={(e) => {
            setKeyword(e.target.value);
          }}
          onPressEnter={() => {
            props.onSearch(keyword);
          }}
        />
      </Div>
      <Div>
        <PrimaryButton
          icon={'search'}
          title={'search'}
          onClick={() => {
            props.onSearch(keyword);
          }}
        />
      </Div>
    </HorizontalView>
  );
};

export const CmsSearchUserBar = (props: SearchBarProps) => {
  return (
    <VerticalView fullWidth>
      <VerticalView width={'100%'} borderRadius={'5px'} alignContent={'centerLeft'} paddingBottom={'20px'}>
        <MembersStatusFilter onFilterMemberStatus={props.onFilterMemberStatus} />
        <Status onSelectTags={props.onSelectTags} />
        <SearchKeyword onSearch={props.onSearch} />
      </VerticalView>
      <Hr />
      <HorizontalView fullWidth alignContent={'centerBetween'} paddingTop={'20px'} paddingBottom={'20px'}>
        <HorizontalView>
          <Button
            loading={props.createLoading}
            title={'new'}
            height={'40px'}
            minWidth={'130px'}
            preixIcon={'plus'}
            background={AppColors.RED2}
            color={AppColors.WHITE}
            borderRadius={'5px'}
            marginBottom="10px"
            onClick={props.onCreate}
          />
        </HorizontalView>
        <HorizontalView>
          <Text paddingRight={'20px'}>
            <Label label={'all'} />
            {` ${props.totalItems} `}
            <Label label={'item'} />
          </Text>
        </HorizontalView>
      </HorizontalView>
    </VerticalView>
  );
};
