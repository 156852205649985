import { PlanContainer } from 'containers/user-container/plan';
import React from 'react';
import { Routes, Route } from 'react-router';

export const PlanRouter = () => {
  return (
    <Routes>
      <Route index element={<PlanContainer />} />
    </Routes>
  );
};
