import React, { useEffect, useState } from 'react';
import { HorizontalView } from 'vendors/Wrapper';
import { BaseComponent } from 'components/common/components/BaseComponent';
import { PaginationBar } from 'components/common/components/Pagination/PaginationBar';
import { VerticalView } from 'vendors/CommonWrappers';
import { TableWrapper } from 'vendors/CommonParts';
import { PageTitle } from 'components/common/components/PageTitle';
import { CmsSearchMusicBar } from './parts/CmsSearchMusicBar';
import { MusicTable } from './parts/MusicTable';
import { ICreateMusicInput, IMusic } from 'models/Music';
import { MusicModal } from './parts/MusicModal';
import { Loading } from 'vendors/Loading';

interface CourseListProps {
  loading: boolean;
  createLoading: boolean;
  updateLoading: boolean;
  deleteLoading: boolean;
  musics: IMusic[];
  currentPage: number;
  limit: number;
  totalPages: number;
  totalItems: number;
  onCreate: (newMusic: ICreateMusicInput) => void;
  onUpdate: (music: IMusic) => void;
  onDelete: (id: string) => void;
  onSearch: (keyword: string) => void;
  onChangeLimit: (limit: number) => void;
  onChangePage: (page: number) => void;
  onSearchBy: (tag: string[], type: string) => void;
}

export const CMSMusicSearchComponent = (props: CourseListProps) => {
  const [open, setOpen] = useState(false);
  const [editMusic, setEditMusic] = useState<IMusic | null>(null);

  useEffect(() => {
    setOpen(Boolean(editMusic));
  }, [editMusic]);

  return (
    <BaseComponent>
      <>
        <VerticalView fullWidth maxWidth={'100%'} alignContent={'centerLeft'}>
          <PageTitle title={'musicPage.header'} />
          <CmsSearchMusicBar
            loading={props.createLoading}
            totalItems={props.totalItems}
            onSelectTags={props.onSearchBy}
            onSearch={(keyword) => props.onSearch(keyword)}
            onCreate={() => setOpen(true)}
            onChangeLimit={props.onChangeLimit}
          />
          <TableWrapper>
            <MusicTable
              loading={props.loading}
              startIndex={(props.currentPage - 1) * props.limit}
              musics={props.musics}
              onDelete={props.onDelete}
              onDetails={(music) => {
                setEditMusic(music);
              }}
            />
          </TableWrapper>
        </VerticalView>

        {props.totalPages && !props.loading && (
          <HorizontalView fullWidth alignContent={'centerRight'}>
            <PaginationBar
              totalPages={props.totalPages}
              currentPage={props.currentPage}
              onChangePage={props.onChangePage}
            />
          </HorizontalView>
        )}
        <MusicModal
          open={open}
          loading={props.createLoading || props.updateLoading}
          music={editMusic}
          onChange={(music) => {
            if (editMusic) {
              props.onUpdate(music);
            } else {
              props.onCreate({
                name: music.name,
                thumbnail: music.thumbnail,
                authorName: music.authorName,
                url: music.url,
              });
            }
            setOpen(false);
            setEditMusic(null);
          }}
          onClose={() => {
            setOpen(false);
            setEditMusic(null);
          }}
        />
        <Loading overlay loading={props.createLoading || props.updateLoading} />
      </>
    </BaseComponent>
  );
};
