import React from 'react';
import { Div, HorizontalView, VerticalView } from 'vendors/Wrapper';
import { AppColors } from 'helpers';
import { Text } from 'vendors/Text';
import { CardWrapper } from 'vendors/CommonWrappers';
import { Image } from 'vendors/Image';
import { useAppSetting } from 'hooks/useAppSetting';
import { useTranslationText } from 'locale';
import { TextLink } from 'vendors/TextLink';

export const ContactSuccessMessage = () => {
  const { appLayout, appSize } = useAppSetting();
  const { translation } = useTranslationText();
  return (
    <CardWrapper
      width={appLayout.isMobile ? '250px' : '450px'}
      borderRadius={'10px'}
      noShadow
      padding={'0px'}
      background={AppColors.TRANSPARENT}
    >
      <VerticalView fullWidthHeight alignContent={'center'} minHeight={'250px'}>
        <HorizontalView>
          <Image src={'./images/email.png'} width={'50px'} objectFit={'contain'} />
        </HorizontalView>

        <Div marginTop={'20px'}>
          <Text fontWeight={500} fontSize={appSize.h3}>
            {translation('public.contact.sendContactSuccess')}
          </Text>
        </Div>
        <Div marginTop={'10px'} alignContent="center">
          <Text textAlign={'center'} fontSize={appSize.h5} marginBottom="10px">
            {translation('public.contact.contactSentMessage')}
          </Text>
          <Text textAlign={'center'} fontSize={appSize.h5} marginBottom="10px">
            {translation('public.contact.contactSentMessage2')}
          </Text>
          <Text textAlign={'center'} fontSize={appSize.h5} marginBottom="10px">
            {translation('public.contact.contactSentMessage3')}
          </Text>
        </Div>
        <Div paddingTop={'15px'}>
          <TextLink href={'/'} fontSize={appSize.h4} color={AppColors.BLUE} fontWeight={500}>
            {translation('page404.goHome')}
          </TextLink>
        </Div>
      </VerticalView>
    </CardWrapper>
  );
};
