import React from 'react';
import { Routes, Route } from 'react-router';
import ContactContainer from '../../containers/user-container/contacts/ContactPage';

export const ContactRouter = () => {
  return (
    <Routes>
      <Route index element={<ContactContainer />} />
    </Routes>
  );
};
