import { helpQueries } from 'api/graphql/help-center';
import { IGroupHelp } from 'models/Help';
import { useBaseGetQuery } from '../base/useBaseGetQuery';

export const useGetGroupHelp = () => {
  const { loading, item, getQuery, error } = useBaseGetQuery<{ lang: string }, IGroupHelp[]>({
    fetchPolicy: 'cache-and-network',
    query: helpQueries.GET_GROUP_HELP,
  });

  return {
    loading: loading,
    groupHelp: item,
    error: error,
    onGetGroupHelp: getQuery,
  };
};
