import * as mutations from './mutations';
import * as queries from './queries';

export const musicMutations = {
  ...mutations,
};

export const musicQueries = {
  ...queries,
};
