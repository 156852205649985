import { useChangeAccountStatus, useGetBusiness, useUpdateBusiness } from 'api/hooks/business/useBusinessApi';
import { useSearchLocation } from 'api/hooks/location/useSearchLocation';
import { useOperationResetUserPassword } from 'api/hooks/user/useUserApi';
import { CompanyDetailComponent } from 'components/cms-interface/customer/detail';
import { useTranslationText } from 'locale';
import { IUpdateBusinessAccountInput } from 'models/BusinessAccount';
import { IUser } from 'models/User';
import React from 'react';
import { useParams } from 'react-router-dom';
import { openNotification } from 'vendors/Notification';

export const CompanyDetailContainer = () => {
  const { companyId } = useParams();
  const { loading, getBusiness, business } = useGetBusiness();
  const { updateBusiness } = useUpdateBusiness();
  const { changeStatus, loading: changeStatusLoading } = useChangeAccountStatus();
  const { translation } = useTranslationText();
  const { onResetUserPassword } = useOperationResetUserPassword();
  const { locations, searchLocation, loading: locationLoading } = useSearchLocation();

  React.useEffect(() => {
    if (companyId) {
      getBusiness({ id: companyId });
    }
  }, [companyId]);

  const update = (input: IUser) => {
    const company = {
      id: input.id,
      logo: input.company.logo,
      cover: input.company.cover,
      companyName: input.company.name,
      companyDescription: input.company.description,
      companyFoundation: input.company.foundation,
      companyRepresentativeName: input.company.representativeName,
      postalCode: input.company.postalCode,
      stateOrCityName: input.company.stateOrCityName,
      streetAddress: 'なし',
      buildingNameAndRoomName: input.company.buildingNameAndRoomName,
      homephone: 'なし',
      telephone: input.company.telephone,
      businessContent: input.company.businessContent,
    } as IUpdateBusinessAccountInput;

    updateBusiness(company);
  };

  const onResetPassword = async (password: string) => {
    const result = await onResetUserPassword({
      id: companyId!,
      newPassword: password,
      confirmNewPassword: password,
    });
    if (!result.error) {
      openNotification({
        type: 'success',
        message: translation('message.update'),
        description: translation('message.success'),
      });
    } else {
      openNotification({
        type: 'error',
        message: result.error?.title,
        description: result.error?.message,
      });
    }
  };

  const onUpdateMembership = () => {
    companyId && getBusiness({ id: companyId });
  };

  const getLocation = (code?: string) => {
    searchLocation({
      code,
      limit: 1,
      page: 1,
    });
  };

  return (
    <CompanyDetailComponent
      loading={loading}
      company={business}
      changeStatusLoading={changeStatusLoading}
      onUpdate={update}
      onChangePassword={onResetPassword}
      onChangeStatus={(input) => changeStatus(input)}
      onUpdateMembership={onUpdateMembership}
      getLocation={getLocation}
      locations={locations}
      locationLoading={locationLoading}
    />
  );
};
