import gql from 'graphql-tag';

export const LIST = gql`
  query listMedia($input: ListMediaInput) {
    listMedia(input: $input) {
      id
      name
      mimetype
      ratio
      src
      thumbnail
      url
      createdBy
      updatedTime
      createdTime
    }
  }
`;

export const SEARCH = gql`
  query searchMedias($input: SearchMediasInput!) {
    searchMedias(input: $input) {
      totalItems
      items {
        id
        name
        mimetype
        ratio
        src
        thumbnail
        url
        createdBy
        updatedTime
        createdTime
      }
    }
  }
`;
