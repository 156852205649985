import { ArticleCategory } from 'helpers/const';
import { IDeviceType, IProductType } from 'models/Transaction';

export const typeList = {
  languages: [
    {
      label: '日本語',
      value: 'ja',
    },
    {
      label: 'ベトナム語',
      value: 'vi',
    },
    {
      label: '英語',
      value: 'en',
    },
  ],

  articleCategories: [
    // {
    //   label: 'ニュース',
    //   value: ArticleCategory.News,
    // },
    // {
    //   label: '情報',
    //   value: ArticleCategory.Information,
    // },
    {
      label: '通知',
      value: ArticleCategory.Notification,
    },
    {
      label: '求職者向け',
      value: ArticleCategory.User,
    },
    {
      label: '求人者向け',
      value: ArticleCategory.Customer,
    },
  ],
  serviceTypes: [
    {
      label: 'ニュース',
      value: 'NEWS',
    },
    {
      label: 'サービス',
      value: 'SERVICE',
    },
  ],

  footerMenu: [
    [
      {
        type: 'header',
        title: '運営会社',
      },
      {
        type: 'link',
        title: '企業情報',
        url: '/about',
      },
      {
        type: 'link',
        title: '個人情報保護方針',
        url: '/privacy',
      },
      {
        type: 'link',
        title: '使用条件',
        url: '/terms-of-service',
      },
      {
        type: 'link',
        title: '特定商取引法に基づく表記',
        url: '/specified-commercial-law',
      },
      {
        type: 'link',
        title: 'お問い合わせ',
        url: '/contact',
      },
    ],
    [
      {
        type: 'header',
        title: 'メニュー',
      },
      {
        type: 'link',
        title: 'ニュース',
        url: '/articles',
      },
      {
        type: 'link',
        title: 'ヘルプ',
        url: '/helps',
      },
    ],
  ],
  genders: [
    {
      label: '女性',
      value: 'FEMALE',
    },
    {
      label: '男性',
      value: 'MALE',
    },
  ],
  yesNoTypes: [
    {
      label: 'いいえ',
      value: false,
    },
    {
      label: 'はい',
      value: true,
    },
  ],
  cmsSidebarMenus: [
    {
      label: 'ユーザー管理',
      prefixIcon: 'user',
      subMenus: [
        {
          label: 'ユーザー管理',
          url: '/cms/users',
        },
        {
          label: '企業管理',
          url: '/cms/customer',
        },
      ],
    },
    {
      label: 'プラン',
      prefixIcon: 'gift',
      url: '/cms/product',
    },
    {
      label: '売上管理',
      prefixIcon: 'statistic',
      url: '/cms/statistics',
    },
    {
      label: 'チャット',
      prefixIcon: 'message',
      url: '/cms/chat',
    },
    {
      prefixIcon: 'file',
      label: '投稿',
      url: '/cms/articles',
    },
    {
      prefixIcon: 'play',
      label: '動画',
      url: '/cms/videos',
    },
    {
      prefixIcon: 'music',
      label: '音楽リスト',
      url: '/cms/musics',
    },
    {
      prefixIcon: 'cart',
      label: 'カテゴリー',
      url: '/cms/categories',
    },
    {
      prefixIcon: 'media',
      label: 'メディアセンター',
      url: '/cms/medias',
    },
    {
      prefixIcon: 'email',
      label: 'コンタクト',
      url: '/cms/contacts',
    },
    {
      prefixIcon: 'question',
      label: 'ヘルプ',
      url: '/cms/helps',
    },
    {
      prefixIcon: 'list',
      label: '報告',
      url: '/cms/report',
    },
    {
      prefixIcon: 'list',
      label: '資料をダウンロードした',
      url: '/cms/downloaded',
    },
    {
      prefixIcon: 'setting',
      label: 'ホームページ設定',
      url: '/cms/setting',
    },
    {
      prefixIcon: 'home',
      label: '会社',
      subMenus: [
        {
          label: '個人情報保護方針',
          url: '/cms/company/company-privacy',
        },
        {
          label: '運営会社',
          url: '/cms/company/company-profile',
        },
        {
          label: '使用条件',
          url: '/cms/company/company-term-of-service',
        },
        {
          label: '特定商取引法に基づく表記',
          url: '/cms/company/specified-commercial-law',
        },
      ],
    },
    {
      prefixIcon: 'logout',
      label: 'ログアウト',
      url: 'logout',
    },
  ],

  userRoles: [
    {
      label: '管理者',
      value: 'SUPER_ADMIN',
    },
    {
      label: '求職者',
      value: 'USER',
    },
    {
      label: '企業',
      value: 'CUSTOMER',
    },
  ],
  publicUserRoles: [
    {
      label: '求職者',
      value: 'USER',
    },
    {
      label: '企業',
      value: 'CUSTOMER',
    },
  ],
  segmentPublic: [
    {
      label: '公開',
      value: true,
    },
    {
      label: '非公開',
      value: false,
    },
  ],
  statusType: [
    {
      label: '公開',
      value: 'active',
    },
    {
      label: '非公開',
      value: 'inactive',
    },
  ],
  mediaGroup: [
    {
      label: '画像',
      value: 'image',
    },
    {
      label: 'ビデオ',
      value: 'video',
    },
    {
      label: 'オーディオ',
      value: 'audio',
    },
    {
      label: 'アプリケーション',
      value: 'application',
    },
  ],
  daysOfWeek: [
    {
      label: '月',
      value: 'monday',
    },
    {
      label: '火',
      value: 'tuesday',
    },
    {
      label: '水',
      value: 'wednesday',
    },
    {
      label: '木',
      value: 'thursday',
    },
    {
      label: '金',
      value: 'friday',
    },
    {
      label: '土',
      value: 'saturday',
    },
    {
      label: '日',
      value: 'sunday',
    },
  ],
  userStatus: [
    {
      label: '検証済み',
      value: 'active',
    },
    {
      label: '検証なし',
      value: 'inactive',
    },
    {
      label: 'ブロック',
      value: 'blocked',
    },
    {
      label: '制限',
      value: 'restrict',
    },
    {
      label: '停止',
      value: 'suspended',
    },
    {
      label: '拒否',
      value: 'not_approved',
    },
  ],
  videoStatus: [
    {
      label: '公開',
      value: 'active',
    },
    {
      label: '非公開',
      value: 'inactive',
    },
    {
      label: 'ブロック',
      value: 'blocked',
    },
    {
      label: '検証待ち',
      value: 'pending',
    },
    {
      label: '拒否',
      value: 'reject',
    },
  ],
  deviceTypes: [
    {
      label: 'アンドロイド',
      value: IDeviceType.Android,
    },
    {
      label: 'IOS',
      value: IDeviceType.Ios,
    },
    {
      label: 'ウェブ',
      value: IDeviceType.Web,
    },
  ],
  productTypes: [
    {
      label: 'Auto Renewable Subscription',
      value: IProductType.AutoRenewableSubscription,
    },
    {
      label: 'Consumable',
      value: IProductType.Consumable,
    },
    {
      label: 'Non Consumable',
      value: IProductType.NonConsumable,
    },
    {
      label: 'Non Renewing Subscription',
      value: IProductType.NonRenewingSubscription,
    },
  ],
  langStatus: [
    {
      label: '日本語',
      value: 'ja',
    },
    {
      label: 'Tiếng Việt',
      value: 'vi',
    },
    {
      label: 'English',
      value: 'en',
    },
  ],
  accountMemberStatus: [
    {
      label: '全て',
      value: 'all',
    },
    {
      label: 'メンバーシップ',
      value: true,
    },
    {
      label: 'メンバーシップない',
      value: false,
    },
  ],

  helpCategories: [
    {
      label: 'アカウント',
      value: 'account',
    },
    {
      label: '会員',
      value: 'membership',
    },
    {
      label: '支払い',
      value: 'payment',
    },
    {
      label: 'ポリシー',
      value: 'policy',
    },
    {
      label: 'その他',
      value: 'other',
    },
  ],
};
