import React from 'react';
import styled from 'styled-components';
import { Modal } from 'vendors/Modal';
import { AppConst } from 'helpers';
import { useAppSetting } from 'hooks/useAppSetting';
import { VideoPlayer } from 'vendors/Video/VideoPlayer';

interface Props {
  open: boolean;
  url: string;
  onClose: () => void;
}

const Content = styled.div<{ width: string; height: string }>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  padding: 10px;
  max-width: 900px;
`;

export const VideoPlayerModal = (props: Props) => {
  const { appTheme } = useAppSetting();

  React.useEffect(() => {
    document.body.style.overflow = props.open ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [props.open]);

  return (
    <Modal
      zIndex={AppConst.zIndex.header + 2000}
      backdropColor={'rgb(1,1,1,0.6)'}
      headerBackground={appTheme.sidebar.bg}
      open={props.open}
      //   title={translation('media.title')}
      onClose={() => {
        props.onClose();
      }}
      closeOnBackDropClick
    >
      <Content width={'80vw'} height={'100%'}>
        <VideoPlayer src={props.url} height="auto" />
      </Content>
    </Modal>
  );
};
