import { useAppSetting } from 'hooks/useAppSetting';
import { ICompanyShort } from 'models/Company';
import { IVideoShort } from 'models/Video';
import React from 'react';
import { HorizontalView, VerticalView } from 'vendors/Wrapper';
import { BaseComponent } from '../BaseComponent';
import { RoundedIcon } from './RoundedIcon';
import { VideoPreviewTab } from './VideoPreviewTab';

type PropsType = {
  loading: boolean;
  companies?: ICompanyShort[];
  videoTabs?: { title: string; videos: IVideoShort[] }[];
};

export const VideoPreviewSection = (props: PropsType) => {
  const { appLayout } = useAppSetting();
  const isMobile = appLayout.width < 500;

  return (
    <BaseComponent contentLoading={props.loading}>
      <VerticalView fullWidth maxWidth="1200px">
        {props.companies && props.companies.length > 0 && (
          <HorizontalView
            maxWidth={appLayout.width > 1200 ? '1200px' : `${appLayout.width}px`}
            alignContent={'centerLeft'}
            overflowX="scroll"
            paddingTop="20px"
            paddingBottom="20px"
            flexWrap="nowrap"
          >
            {props.companies.map((item, index) => {
              return (
                <RoundedIcon
                  key={index}
                  image={item.logo || 'images/icon.png'}
                  path={'/'}
                  name={item.name}
                  size={isMobile ? '50px' : '100px'}
                />
              );
            })}
          </HorizontalView>
        )}
        {props.videoTabs && props.videoTabs.length > 0 && (
          <VerticalView fullWidth>
            {props.videoTabs.map((item, index) => {
              return <VideoPreviewTab key={index} title={item.title} videos={item.videos} />;
            })}
          </VerticalView>
        )}
      </VerticalView>
    </BaseComponent>
  );
};
