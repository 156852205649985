import React from 'react';
import styled from 'styled-components';
import { Div, HorizontalView } from 'vendors/Wrapper';
import { AppColors } from 'helpers';
import { VerticalView } from 'vendors/CommonWrappers';
import { Label } from 'vendors/Label';
import { Text } from 'vendors/Text';
import { SearchKeywordInput } from 'components/common/components/Input/SearchKeywordInput';
import { useAppSetting } from 'hooks/useAppSetting';

export const SearchWrapper = styled.div`
  border: 1px solid ${AppColors.BORDER};
  border-radius: 25px;
  overflow: hidden;
  width: 80%;
`;

type SearchBarProps = {
  totalItems: number;
  onChangeLimit: (limit: number) => void;
  onSearch: (params: string) => void;
};

export const CmsSearchContactBar = (props: SearchBarProps) => {
  const { appLayout } = useAppSetting();
  return (
    <VerticalView fullWidth>
      <HorizontalView fullWidth alignContent={'centerBetween'} paddingBottom={'20px'} paddingTop={'20px'}>
        <Div width={appLayout.isMobile ? '100%' : '50%'} paddingBottom={appLayout.isMobile ? '20px' : '0px'}>
          <SearchKeywordInput onSearch={props.onSearch} />
        </Div>
        <HorizontalView>
          <Text paddingRight={'20px'}>
            <Label label={'searchContacts.all'} />
            {` ${props.totalItems} `}
            <Label label={'searchContacts.item'} />
          </Text>
        </HorizontalView>
      </HorizontalView>
    </VerticalView>
  );
};
