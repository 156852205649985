import CMSComercialLawContainer from 'containers/cms-containers/company/specified-commercial-law';
import CMSTermOfServicesContainer from 'containers/cms-containers/company/company-term-of-service';
import React from 'react';
import { Routes, Route } from 'react-router';
import CMSPrivacyContainer from '../../containers/cms-containers/company/company-privacy';
import CMSCompanyProfileContainer from '../../containers/cms-containers/company/company-profile';

export const CompanyRouter = () => {
  return (
    <Routes>
      <Route path="/company-privacy" element={<CMSPrivacyContainer />} />
      <Route path="/company-term-of-service" element={<CMSTermOfServicesContainer />} />
      <Route path="/company-profile" element={<CMSCompanyProfileContainer />} />
      <Route path="/specified-commercial-law" element={<CMSComercialLawContainer />} />
    </Routes>
  );
};
