import { BaseComponent } from 'components/common/components/BaseComponent';
import { useAppSetting } from 'hooks/useAppSetting';
import { IVideoShort } from 'models/Video';
import React from 'react';
import { HorizontalView, VerticalView } from 'vendors/Wrapper';
import { PageTitle } from 'components/common/components/PageTitle';
import { CmsSearchVideoBar } from '../parts/CmsSearchVideoBar';
import { TableWrapper } from 'vendors/CommonParts';
import { MobileTableView } from '../parts/MobileTableView';
import { WebTableView } from '../parts/WebTableView';
import { PaginationBar } from 'components/common/components/Pagination/PaginationBar';
import { VideoStatus } from 'models/types';
import { useTranslationText } from 'locale';

interface VideoListProps {
  loading: boolean;
  deleteLoading: boolean;
  totalPages: number;
  currentPage: number;
  totalItems: number;
  videos: IVideoShort[];
  onDelete: (userId: string) => void;
  onDetails: (userId: string) => void;
  onSearch: (tags: VideoStatus[], keyword: string) => void;
  onChangePage: (page: number) => void;
  onChangeLimit: (page: number) => void;
  onSelectTags: (tags: VideoStatus[]) => void;
}

export const CMSVideosListComponent = (props: VideoListProps) => {
  const { appLayout } = useAppSetting();
  const { translation } = useTranslationText();

  return (
    <BaseComponent documentTitle={translation('videoPage.header')}>
      <VerticalView fullWidth alignContent="center">
        <PageTitle title={'videoPage.header'} />
        <CmsSearchVideoBar
          totalItems={props.totalItems}
          onSearch={props.onSearch}
          onChangeLimit={props.onChangeLimit}
          onSelectTags={props.onSelectTags}
        />
        <TableWrapper>
          {appLayout.isMobile && (
            <MobileTableView
              loading={props.loading || props.deleteLoading}
              videos={props.videos}
              onDelete={props.onDelete}
              onDetails={props.onDetails}
            />
          )}
          {!appLayout.isMobile && (
            <WebTableView
              loading={props.loading || props.deleteLoading}
              videos={props.videos}
              onDelete={props.onDelete}
              onDetails={props.onDetails}
            />
          )}
        </TableWrapper>

        {props.totalPages && !props.loading && (
          <HorizontalView fullWidth alignContent={'centerRight'}>
            <PaginationBar
              totalPages={props.totalPages}
              currentPage={props.currentPage}
              onChangePage={props.onChangePage}
            />
          </HorizontalView>
        )}
      </VerticalView>
    </BaseComponent>
  );
};

export default React.memo(CMSVideosListComponent);
