import TermOfServiceContainer from 'containers/user-container/term-of-service';
import React from 'react';
import { Routes, Route } from 'react-router';

export const TermOfServiceRoutes = () => {
  return (
    <Routes>
      <Route index element={<TermOfServiceContainer />} />
    </Routes>
  );
};
