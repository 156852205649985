import React from 'react';
import styled from 'styled-components';
import { HorizontalView } from '../Wrapper/HorizontalView';
import { Spinner } from '../Spinner';
import { Div } from '../Wrapper/Wrapper';
import { useTranslationText } from 'locale';
import { IConType, SVGIcon } from '../Icon';

const ButtonDefault = styled.button<ButtonProps>`
  position: relative;
  &:focus {
    outline: none;
  }
  border: ${(props) => props.border || 'black'};
  &:hover {
    opacity: 0.7;
  }
  &:active {
    opacity: 0.4;
  }
  overflow: hidden;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  ${(props) => (props.fontSize ? `font-size:${props.fontSize}` : null)};
  ${(props) => (props.fontWeight ? `font-weight:${props.fontWeight}` : null)};

  color: ${(props) => (props.disabled ? '#666666' : props.color || 'black')};
  background: ${(props) => (props.disabled ? '#cccccc' : props.background || 'transparent')};

  width: ${(props) => props.width || 'auto'};
  height: ${(props) => props.height || 'auto'};
  ${(props) => (props.minWidth ? `min-width:${props.minWidth}` : null)};
  ${(props) => (props.margin ? `margin:${props.margin}` : null)};
  ${(props) => (props.marginTop ? `margin-top:${props.marginTop}` : null)};
  ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}` : null)};
  ${(props) => (props.marginRight ? `margin-right:${props.marginRight}` : null)};
  ${(props) => (props.marginBottom ? `margin-bottom:${props.marginBottom}` : null)};
  ${(props) => (props.padding ? `padding:${props.padding}` : null)};
  ${(props) => (props.paddingTop ? `padding-top:${props.paddingTop}` : null)};
  ${(props) => (props.paddingLeft ? `padding-left:${props.paddingLeft}` : null)};
  ${(props) => (props.paddingRight ? `padding-right:${props.paddingRight}` : null)};
  ${(props) => (props.paddingBottom ? `padding-bottom:${props.paddingBottom}` : null)};
  ${(props) => (props.borderRadius ? `border-radius:${props.borderRadius}` : null)};
  ${(props) => (props.transition ? `transition:${props.transition}` : null)};
  ${(props) => props.css};
`;

export type ButtonProps = {
  id?: string;
  loading?: boolean;
  disabled?: boolean;
  type?: 'submit' | 'reset' | 'button' | undefined;
  title?: string;
  fontSize?: string;
  color?: string;
  background?: string;
  preixIcon?: IConType;
  suffixIcon?: IConType;
  border?: string;
  fontWeight?: React.CSSProperties['fontWeight'];
  width?: string;
  minWidth?: string;
  height?: string;
  borderRadius?: string;
  margin?: string;
  marginTop?: string;
  marginLeft?: string;
  marginRight?: string;
  marginBottom?: string;
  padding?: string;
  paddingLeft?: string;
  paddingRight?: string;
  paddingTop?: string;
  paddingBottom?: string;
  transition?: React.CSSProperties['transition'];
  children?: React.ReactNode;
  css?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onMouseDown?: () => void;
  onMouseUp?: () => void;
  onClick?: () => void;
};

export const Button = React.memo((props: ButtonProps) => {
  const { translation } = useTranslationText();
  return (
    <ButtonDefault
      id={props.id}
      disabled={props.disabled}
      type={props.type}
      fontSize={props.fontSize}
      color={props.color}
      background={props.background}
      border={props.border}
      fontWeight={props.fontWeight}
      width={props.width}
      height={props.height}
      borderRadius={props.borderRadius}
      margin={props.margin}
      marginTop={props.marginTop}
      marginLeft={props.marginLeft}
      marginRight={props.marginRight}
      marginBottom={props.marginBottom}
      padding={props.padding}
      paddingLeft={props.paddingLeft}
      paddingRight={props.paddingRight}
      paddingTop={props.paddingTop}
      paddingBottom={props.paddingBottom}
      transition={props.transition}
      css={props.css}
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onMouseDown={props.onMouseDown}
      onMouseUp={props.onMouseUp}
    >
      {!props.title && !props.children && (
        <HorizontalView
          alignContent={'center'}
          flexWrap={'nowrap'}
          width={'100%'}
          height={'100%'}
          position={'relative'}
        >
          <Div>
            {props.loading && <Spinner loading color={props.color} size={props.fontSize || '13px'} />}
            {!props.loading ? (
              <SVGIcon name={props.preixIcon} color={props.color} size={props.fontSize || '13px'} />
            ) : null}
          </Div>
        </HorizontalView>
      )}
      {(props.title || props.children) && (
        <HorizontalView
          alignContent={'centerBetween'}
          flexWrap={'nowrap'}
          width={'100%'}
          height={'100%'}
          position={'relative'}
        >
          <Div
            paddingRight={Boolean(props.title || props.children) && (props.preixIcon || props.loading) ? '7px' : '0px'}
            position={'relative'}
          >
            {props.loading && <Spinner loading color={props.color} size={props.fontSize || '13px'} />}
            {!props.loading && props.preixIcon ? (
              <SVGIcon name={props.preixIcon} color={props.color} size={props.fontSize || '13px'} />
            ) : null}
          </Div>
          {props.title ? translation(props.title) : null}
          {props.children}
          <Div paddingLeft={Boolean(props.title || props.children) && props.suffixIcon ? '7px' : '0px'}>
            {props.suffixIcon && (
              <SVGIcon name={props.suffixIcon} color={props.color} size={props.fontSize || '13px'} />
            )}
          </Div>
        </HorizontalView>
      )}
    </ButtonDefault>
  );
});
