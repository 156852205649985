import { useGetCompanyComercialLaw } from 'api/hooks/company/useCompanyApi';
import { ComercialLawComponent } from 'components/user-interface/specified-commercial-law';
import React from 'react';

export const ComercialLawContainer = () => {
  const { company, onGetComLaw, loading } = useGetCompanyComercialLaw();
  React.useEffect(() => {
    onGetComLaw();
  }, []);

  return <ComercialLawComponent isLoading={loading} content={company ? (company.commercialLaw as string) : ''} />;
};

export default ComercialLawContainer;
