import { useGetHomeContent } from 'api/hooks/home/useHomeApi';
import React from 'react';
import { HomePageComponent } from '../../../components/user-interface/home';

export const HomeContainer = () => {
  const lang = localStorage['QHC_LANG'] || 'ja';
  const { homePage } = useGetHomeContent();

  return (
    <HomePageComponent
      lang={lang}
      loading={false}
      news={homePage?.articles ? homePage.articles : []}
      companies={homePage?.customers}
      slideImages={homePage?.slideImages}
      videoTabs={[
        { title: 'employeePage.newVideosEmployer', videos: homePage?.customerVideos || [] },
        { title: 'employerPage.newVideosEmployee', videos: homePage?.userVideos || [] },
      ]}
    />
  );
};

export default HomeContainer;
