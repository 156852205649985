import React from 'react';
import { groupBy } from 'lodash';
import dayjs from 'dayjs';
import { CompanyStatisticComponent } from 'components/cms-interface/statistic';
import { useSearchTransaction } from 'api/hooks/transaction/useSearchTransaction';
import { useCreateInvoice } from 'api/hooks/transaction/useCreateInvoice';
import { ICreateInvoiceInput } from 'models/Transaction';
import { openNotification } from 'vendors/Notification';

export const CompanyStatisticContainer = () => {
  const { transactions, searchTransactions, total, loading } = useSearchTransaction();
  const [graph, setGraph] = React.useState([['Date', '売上げ']] as (string | number)[][]);
  const [startDate, setStartDate] = React.useState(dayjs().startOf('month').format('YYYY/MM/DD'));
  const [endDate, setEndDate] = React.useState(dayjs().endOf('month').format('YYYY/MM/DD'));
  const { onNewInvoice, loading: loadingInvoice } = useCreateInvoice();

  function getDateList(start: string, end: string): (string | number)[][] {
    const dateArray = [['Date', '売上げ']] as (string | number)[][];
    let currentDate = start;
    while (currentDate <= end) {
      dateArray.push([currentDate, 0]);
      currentDate = dayjs(currentDate).add(1, 'day').format('YYYY/MM/DD');
    }
    return dateArray;
  }
  const dateList = getDateList(startDate, endDate);

  function getTransactions() {
    searchTransactions({
      from: startDate,
      to: endDate,
    });
  }

  React.useEffect(() => {
    getTransactions();
  }, [startDate, endDate]);

  React.useEffect(() => {
    if (transactions) {
      const group = groupBy(transactions, (re) => dayjs(re?.purchaseDate).format('YYYY/MM/DD').substring(0, 10));
      const result = Object.entries(group).map(([date, list]) => [
        date,
        list.reduce((pre, cur) => {
          return pre + (cur?.price || 0);
        }, 0),
      ]);
      const data = [...dateList];
      result.map((item) => {
        const i = data.find((e) => e[0] === item[0]);
        if (i) {
          const index = data.indexOf(i);
          data[index][1] = item[1];
        }
      });
      setGraph(data);
    }
  }, [transactions]);

  function changeDate(from: string, to: string): void {
    setStartDate(from);
    setEndDate(to);
  }

  const newInvoice = async (input: ICreateInvoiceInput) => {
    const result = await onNewInvoice(input);
    if (!result.error) {
      if (result.createInvoice) {
        window.open(result.createInvoice?.pdfLink);
      }
    } else if (result.error) {
      openNotification({
        type: 'error',
        description: 'error',
      });
    }
  };

  return (
    <CompanyStatisticComponent
      loading={loading}
      newInvoice={newInvoice}
      loadingInvoice={loadingInvoice}
      data={graph}
      totalRevenue={total}
      from={startDate}
      to={endDate}
      transations={transactions}
      onChangeDate={changeDate}
    />
  );
};

export default CompanyStatisticContainer;
