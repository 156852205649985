import React from 'react';
import styled from 'styled-components';
import { HorizontalView } from 'vendors/Wrapper';
import { BaseComponent } from 'components/common/components/BaseComponent';
import { PaginationBar } from 'components/common/components/Pagination/PaginationBar';
import { VerticalView } from 'vendors/CommonWrappers';
import { CmsSearchCategoryBar } from '../parts/CmsSearchCategoryBar';
import { TableWrapper } from 'vendors/CommonParts';
import { PageTitle } from 'components/common/components/PageTitle';
import { WebTableView } from '../parts/WebTable';
import { ICategory, ICreateCategoryInput } from 'models/Category';
import { CreateCategoryModal } from '../create-category';
import { useTranslationText } from 'locale';

export const ContentWrapper = styled.div``;

interface CourseListProps {
  loading: boolean;
  page: number;
  limit: number;
  isServicePage: boolean;
  createLoading: boolean;
  deleteLoading: boolean;
  category: ICategory[];
  currentPage: number;
  totalPages: number;
  totalItems: number;
  showCreate: boolean;
  setShowCreate: (showCreate: boolean) => void;
  onCreate: (values: ICreateCategoryInput) => void;
  onDetails: (id: string) => void;
  onDelete: (id: string) => void;
  onSearch: (keyword: string) => void;
  onChangeLimit: (limit: number) => void;
  onChangePage: (page: number) => void;
  onSearchBy: (tag: string[], type: string) => void;
}

export const CMSCategorySearchComponent = (props: CourseListProps) => {
  const { translation } = useTranslationText();

  return (
    <BaseComponent documentTitle={translation('home.category.header')}>
      <>
        <VerticalView fullWidth maxWidth={'100%'} alignContent={'centerLeft'}>
          <PageTitle title={'home.category.list'} />
          <CmsSearchCategoryBar
            isServicePage={props.isServicePage}
            loading={props.createLoading}
            totalItems={props.totalItems}
            onSelectTags={props.onSearchBy}
            onCreate={() => props.setShowCreate(true)}
            onSearch={(keyword) => props.onSearch(keyword)}
            onChangeLimit={props.onChangeLimit}
          />
          <TableWrapper>
            <WebTableView
              loading={props.loading}
              page={props.page}
              rowPerPage={props.limit}
              category={props.category}
              onDelete={props.onDelete}
              onDetails={props.onDetails}
            />
          </TableWrapper>
          <CreateCategoryModal
            showCreate={props.showCreate}
            createLoading={props.createLoading}
            onCreate={props.onCreate}
            onClose={() => {
              props.setShowCreate(false);
            }}
          />
        </VerticalView>
        {props.totalPages && !props.loading && (
          <HorizontalView fullWidth alignContent={'centerRight'}>
            <PaginationBar
              totalPages={props.totalPages}
              currentPage={props.currentPage}
              onChangePage={props.onChangePage}
            />
          </HorizontalView>
        )}
      </>
    </BaseComponent>
  );
};
