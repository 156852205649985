import React, { useRef } from 'react';
import styled from 'styled-components';
import { HorizontalView, VerticalView } from 'vendors/Wrapper';
import { ConversationView } from './ConversationView';
import { AppColors } from 'helpers';
import { IUser } from 'models/User';
import { IRoom } from 'containers/cms-containers/chat/type/Chat';
import { useLocation } from 'react-router-dom';
import { useAppSetting } from 'hooks/useAppSetting';
import { Spinner } from 'vendors/Spinner';

const SideBarWrapper = styled.div<{ width: string; isBorder: boolean }>`
  width: ${(props) => props.width};
  height: 100vh;
  overflow-y: scroll;
  ${(props) => (props.isBorder ? `border-right: 2px solid ${AppColors.GRAY_LIGHT2}` : '')};
`;

type ComponentProps = {
  auth: IUser;
  hasMore?: boolean;
  loading: boolean;
  currentRoom?: IRoom;
  rooms: IRoom[];
  onSelect: (room: IRoom) => void;
  onLoadmore: () => void;
};

export const RoomList = (props: ComponentProps) => {
  const { appLayout } = useAppSetting();
  const isMobile = appLayout.isMobile;
  const location = useLocation();
  const listInnerRef = useRef<HTMLDivElement | null>(null);

  if (isMobile && location.pathname !== '/cms/chat') {
    return null;
  }

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        props.onLoadmore();
      }
    }
  };

  return (
    <SideBarWrapper ref={listInnerRef} width={isMobile ? '100vw' : '250px'} isBorder={!isMobile} onScroll={onScroll}>
      <VerticalView alignContent={'topLeft'} width={'100%'}>
        {props.rooms.map((conv, index) => {
          return (
            <HorizontalView fullWidth key={index} padding={'5px'}>
              <HorizontalView
                fullWidth
                borderRadius={'5px'}
                padding={'10px'}
                background={props.currentRoom?.id === conv.id ? AppColors.GRAY_LIGHT2 : AppColors.WHITE}
              >
                <ConversationView
                  title={conv.name || 'No name'}
                  image={conv.icon || '/images/avatar.png'}
                  time={conv.lastMessage?.createdTime || conv.createdTime || ''}
                  lastMessage={conv.lastMessage?.text || ''}
                  key={index}
                  isSeen={conv.lastMessage?.isSeen || props.currentRoom?.id === conv.id || !conv.lastMessage}
                  onDetails={() => {
                    props.onSelect(conv);
                  }}
                />
              </HorizontalView>
            </HorizontalView>
          );
        })}
        {props.loading && (
          <HorizontalView fullWidth alignContent="center" height="100px">
            <Spinner loading />
          </HorizontalView>
        )}
      </VerticalView>
    </SideBarWrapper>
  );
};
