import { AppColors } from 'helpers';
import React from 'react';
import { Button } from 'vendors/Button';
import { Text } from 'vendors/Text';
import { HorizontalView } from 'vendors/Wrapper';
import { usePagination } from './usePagination';
import { useTranslationText } from 'locale';

interface PaginationProps {
  totalPages: number;
  currentPage: number;
  tintColor?: string;
  background?: string;
  hideHeader?: string;
  onChangePage?: (page: number) => void;
}
export const PaginationBar = (props: PaginationProps) => {
  const { pages, currentPage } = usePagination(props.currentPage, props.totalPages);
  const { translation } = useTranslationText();
  if (props.totalPages <= 1) {
    return null;
  }

  return (
    <>
      <HorizontalView fullWidth alignContent={'centerRight'} marginTop={`${pages.length > 1 ? 30 : 0}px`}>
        {!props.hideHeader && (
          <Text marginRight={'20px'} fontWeight={500} fontSize={'20px'}>
            {translation('pagination')}
          </Text>
        )}

        {pages.length > 1 && (
          <HorizontalView alignContent={'centerRight'}>
            <Button
              disabled={currentPage === 0}
              preixIcon={'arrow-left'}
              marginRight={'2px'}
              width={'35px'}
              height={'35px'}
              borderRadius={'4px'}
              border={`1px solid ${AppColors.GRAY_LIGHT}`}
              onClick={() => {
                if (currentPage <= 0) return;
                props.onChangePage && props.onChangePage(currentPage);
              }}
            />
            {pages.map((value: number, index) => {
              return (
                <HorizontalView key={index}>
                  <Button
                    marginLeft={'2px'}
                    marginRight={'2px'}
                    width={'35px'}
                    height={'35px'}
                    border={`1px solid ${AppColors.GRAY_LIGHT}`}
                    borderRadius={'4px'}
                    color={currentPage === value ? AppColors.WHITE : AppColors.BLACK}
                    background={currentPage === value ? AppColors.BLUE : AppColors.WHITE}
                    onClick={() => {
                      if (currentPage === value) return;
                      props.onChangePage && props.onChangePage(value + 1);
                    }}
                  >
                    {value + 1}
                  </Button>
                </HorizontalView>
              );
            })}
            <Button
              disabled={currentPage === props.totalPages - 1}
              preixIcon={'arrow-right'}
              marginLeft={'2px'}
              width={'35px'}
              height={'35px'}
              borderRadius={'4px'}
              border={`1px solid ${AppColors.GRAY_LIGHT}`}
              onClick={() => {
                if (currentPage >= props.totalPages - 1) return;
                props.onChangePage && props.onChangePage(currentPage + 2);
              }}
            />
          </HorizontalView>
        )}
      </HorizontalView>
    </>
  );
};
