import React from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { HorizontalView, VerticalView } from 'vendors/Wrapper';
import { AlignContent } from '../Wrapper/Type';

export type FlatListProps = {
  alignContent?: AlignContent;
  direction?: 'row' | 'column';
  values: any;
  noData?: React.ReactElement;
  container?: (nodes: React.ReactChild[]) => React.ReactElement;
  renderItem: (item: any, index: number) => React.ReactNode;
};

export const FlatList = (props: FlatListProps) => {
  const { values, direction, renderItem } = props;

  if (!values || values.length === 0 || !Array.isArray(values)) {
    return props.noData ? props.noData : null;
  }

  const Wrapper = props.container || direction === 'row' ? VerticalView : HorizontalView;
  if (props.container) {
    const items = props.values.map((item: any, index: number) => {
      return <LazyLoadComponent key={index}>{renderItem(item, index)}</LazyLoadComponent>;
    });
    return props.container(items);
  } else {
    return (
      <Wrapper fullWidth alignContent={props.alignContent || 'topLeft'}>
        {props.values.map((item: any, index: number) => {
          return renderItem(item, index);
        })}
      </Wrapper>
    );
  }
};
