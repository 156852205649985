import { membershipQueries } from 'api/graphql/membership';
import { useBaseGetQuery } from 'api/hooks/base/useBaseGetQuery';
import { useTranslationText } from 'locale';
import { IGetMembershipCheckoutSourceInput, IMembershipCheckoutSource } from 'models/Membership';
import { useMemo } from 'react';

/**
 * Use when checkout on web
 */
export const useGetMembershipCheckoutSource = () => {
  const { getError } = useTranslationText();

  const query = useBaseGetQuery<IGetMembershipCheckoutSourceInput, IMembershipCheckoutSource>({
    fetchPolicy: 'cache-and-network',
    query: membershipQueries.CHECKOUT_SOURCE,
  });

  const errorHanle = useMemo(() => {
    if (!query.loading && query.error) {
      return { error: getError('UNABLE_GET_CHECKOUT_SOURCE') };
    }
    return null;
  }, [query.error]);

  return {
    loading: query.loading,
    error: errorHanle?.error,
    checkoutSource: query.item,
    onGetCheckoutSource: query.getQuery,
  };
};
