import { useBaseGetQuery } from 'api/hooks/base/useBaseGetQuery';
import { useBaseMutation } from 'api/hooks/base/useBaseMutation';
import { useBaseSearchQuery } from 'api/hooks/base/useBaseSearchQuery';
import { ErrorCodes } from 'helpers/const';
import { useTranslationText } from 'locale';
import {
  ICreateCategoryInput,
  IDeleteCategoryInput,
  IGetCategoryInput,
  ISearchCategoryInput,
  IUpdateCategoryInput,
} from 'models/Category';
import { ICategory } from 'models/Category';
import { IError } from 'models/Models';
import { ISortDirection } from 'models/types';
import { useEffect, useMemo } from 'react';
import { categoryMutations, categoryQueries } from 'api/graphql/category/index';

export const useGetCategory = (categoryId?: string) => {
  const { getError } = useTranslationText();

  const query = useBaseGetQuery<IGetCategoryInput, ICategory>({
    fetchPolicy: 'cache-and-network',
    query: categoryQueries.GET,
  });

  const errorHanle = useMemo(() => {
    if (!query.loading && query.error) {
      switch (query.error.code) {
        case ErrorCodes.ERROR_CODE_ITEM_NOT_EXIST:
          return { error: getError('ARTICLE_IS_NOT_EXIST') };
        default:
          return { error: getError('SYSTEM_ERROR') };
      }
    }
    return null;
  }, [query.error]);

  useEffect(() => {
    if (categoryId) {
      query.getQuery({ id: categoryId });
    }
  }, [categoryId]);

  return {
    loading: query.loading,
    error: errorHanle,
    category: query.item,
  };
};

export const useCreateCategory = () => {
  const { getError } = useTranslationText();

  const { mutation, loading } = useBaseMutation<ICreateCategoryInput, { operationCreateCategory: ICategory }>({
    mutation: categoryMutations.CREATE,
  });

  async function onCreateCategory(input: ICreateCategoryInput): Promise<{ error?: IError; category?: ICategory }> {
    const { data, error } = await mutation(input);
    if (error || !data?.operationCreateCategory) {
      return { error: getError('CATEGORY_CREATE_ERROR') };
    }
    return { category: data?.operationCreateCategory };
  }

  return {
    loading,
    onCreateCategory,
  };
};

export const useUpdateCategory = () => {
  const { getError } = useTranslationText();

  const { mutation, loading } = useBaseMutation<IUpdateCategoryInput, ICategory>({
    mutation: categoryMutations.UPDATE,
  });

  async function onUpdateCategory(input: IUpdateCategoryInput): Promise<{ error?: IError; category?: ICategory }> {
    const { data, error } = await mutation(input);
    if (error) return { error: getError('CATEGORY_UPDATE_ERROR') };

    return { category: data };
  }

  return {
    loading,
    onUpdateCategory,
  };
};

export const useUserSearchCategory = () => {
  const query = useBaseSearchQuery<ISearchCategoryInput, ICategory>({
    defaulInput: {
      page: 1,
      sortDirection: ISortDirection.Asc,
      limit: 30,
    } as ISearchCategoryInput,
    fetchPolicy: 'cache-and-network',
    query: categoryQueries.OPERATION_SEARCH_CATEGORY,
  });

  /**
   * Call api
   */
  useEffect(() => {
    query.onSearch(query.input);
  }, []);

  return {
    loading: query.loading,
    error: query.error,
    query: query.input,
    page: query.page,
    hasMore: query.hasMore,
    totalItems: query.totalItems,
    totalPages: query.totalPages,
    limit: query.limit,
    categories: query.items,
    onChangePage: query.onChangePage,
    onChangeLimit: query.onChangeLimit,
    onSearchCategories: query.onSearch,
    onLoadMoreCategories: query.onLoadMore,
    onDeleteCategoryInList: query.onDeleteItem,
  };
};

export const useOperationSearchCategory = () => {
  const query = useBaseSearchQuery<ISearchCategoryInput, ICategory>({
    defaulInput: {
      page: 1,
      sortDirection: ISortDirection.Asc,
      limit: 30,
    } as ISearchCategoryInput,
    fetchPolicy: 'cache-and-network',
    query: categoryQueries.OPERATION_SEARCH_CATEGORY,
  });

  /**
   * Call api
   */
  useEffect(() => {
    query.onSearch(query.input);
  }, []);

  return {
    loading: query.loading,
    error: query.error,
    query: query.input,
    page: query.page,
    hasMore: query.hasMore,
    totalItems: query.totalItems,
    totalPages: query.totalPages,
    limit: query.limit,
    categories: query.items,
    onChangePage: query.onChangePage,
    onChangeLimit: query.onChangeLimit,
    onSearchCategories: query.onSearch,
    onLoadMoreCategories: query.onLoadMore,
    onDeleteCategoryInList: query.onDeleteItem,
  };
};

export const useDeleteCategory = () => {
  const { getError } = useTranslationText();

  const { mutation, loading } = useBaseMutation<IDeleteCategoryInput, ICategory>({
    mutation: categoryMutations.DELETE,
  });

  async function onDeleteCategory(input: IDeleteCategoryInput): Promise<{ error?: IError; category?: ICategory }> {
    const { data, error } = await mutation(input);
    if (error) return { error: getError('ARTICLE_DELETE_ERROR') };

    return { category: data };
  }

  return {
    loading,
    onDeleteCategory,
  };
};
