import React from 'react';
import { HorizontalView, Div, VerticalView } from 'vendors/Wrapper';
import { AppColors } from 'helpers';
import { AnimationView } from 'vendors/Animation';
import { ContentLoading } from 'vendors/Loading';
import { TitleWrapper } from 'vendors/CommonWrappers';

export const PlashScreen = () => {
  const [visible, setVisible] = React.useState(true);

  const [showPlash, setShowPlash] = React.useState(true);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setShowPlash(false);
    }, 1300);
    return () => clearTimeout(timeout);
  }, []);

  React.useEffect(() => {
    const time = setTimeout(() => {
      setVisible(false);
    }, 600);
    return () => clearTimeout(time);
  }, []);

  if (!visible) {
    return null;
  }

  return (
    <Div
      position={'fixed'}
      left={'0'}
      top={'0'}
      bottom={'0'}
      right={'0'}
      width={'100vw'}
      height={'100vh'}
      zIndex={10000}
    >
      <AnimationView
        duration={showPlash ? 5 : 200}
        animation={showPlash ? undefined : 'fadeOut'}
        width={'100vw'}
        height={'100vh'}
      >
        <HorizontalView
          position={'fixed'}
          fullWidthHeight
          zIndex={10000}
          background={AppColors.PRIMARY}
          alignContent={'center'}
        >
          <VerticalView>
            <TitleWrapper size={'40px'} color={'white'}>
              Homepage
            </TitleWrapper>
            <Div marginTop={'60px'}>
              <ContentLoading loading color={'white'} />
            </Div>
          </VerticalView>
        </HorizontalView>
      </AnimationView>
    </Div>
  );
};
