import { useDeleteReport } from 'api/hooks/report/useDeleteReport';
import { useSearchReports } from 'api/hooks/report/useSearchReport';
import { CMSReportListComponent } from 'components/cms-interface/report/list';
import { useTranslationText } from 'locale';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { openNotification } from 'vendors/Notification';

export const CMSReportListContainer = () => {
  const {
    loading,
    page,
    limit,
    totalItems,
    totalPages,
    reports,
    onChangePage,
    searchReports,
    onDeleteItem,
  } = useSearchReports();
  const { deleteReport, loading: deleting } = useDeleteReport();
  const { translation } = useTranslationText();
  const navigate = useNavigate();
  return (
    <CMSReportListComponent
      loading={loading || deleting}
      page={page}
      limit={limit}
      totalItems={totalItems}
      totalPages={totalPages}
      reports={reports}
      onDetails={(id: string) => {
        navigate(`/cms/report/${id}`);
      }}
      onSearch={(keyword: string) => {
        searchReports({
          limit: 30,
          page: 1,
          keyword,
        });
      }}
      onDelete={async (id) => {
        const result = await deleteReport({ id });
        if (result.data) {
          onDeleteItem({ id });
          openNotification({
            type: 'success',
            message: translation('message.delete'),
            description: translation('message.success'),
          });
        } else {
          openNotification({
            type: 'error',
            message: translation('message.delete'),
            description: translation('message.error'),
          });
        }
      }}
      onChangePage={onChangePage}
    />
  );
};

export default React.memo(CMSReportListContainer);
