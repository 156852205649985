import gql from 'graphql-tag';

export const CREATE = gql`
  mutation createMusic($input: CreateMusicInput!) {
    createMusic(input: $input) {
      id
    }
  }
`;

export const UPDATE = gql`
  mutation updateMusic($input: UpdateMusicInput!) {
    updateMusic(input: $input)
  }
`;

export const DELETE = gql`
  mutation deleteMusic($input: DeleteMusicInput!) {
    deleteMusic(input: $input)
  }
`;
