import { Input } from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import styled from 'styled-components';
import { Text } from '../Text';
import { AppColors } from 'helpers';
import { useTranslationText } from 'locale';

const { TextArea } = Input;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  .ant-select {
    border-color: ${AppColors.BORDER};
    z-index: 5000;
  }
  .ant-select-selector {
    border-color: ${AppColors.BORDER};
    z-index: 5000;
  }
  .ant-select-open {
    z-index: 5000;
  }
`;

const InputText = styled.div`
  margin-bottom: 5px;
  font-weight: 400;
`;

const MustLabel = styled.span`
  display: inline-block;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1;
  border-radius: 3px;
  color: red;
  margin-left: 2px;
`;

const ErrorMessage = styled.div`
  margin-top: 5px;
  color: red;
  font-size: 10px;
`;

const MaxLength = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 10px;
`;

const InputContent = styled.div<{ noBorder?: boolean }>`
  .ant-input {
    ${(props) => (props.noBorder ? 'border:0px;' : '')}
  }
`;

type TextAreaInputProps = {
  id?: string;
  isPreview?: boolean;
  name?: string;
  hide?: boolean;
  noBorder?: boolean;
  bolder?: boolean;
  value?: string | null;
  style?: React.CSSProperties;
  label?: string;
  height?: string;
  minHeight?: string;
  width?: string;
  required?: boolean;
  invalid?: boolean;
  placeholder?: string;
  hasFeedback?: boolean;
  disabled?: boolean;
  color?: string;
  size?: string;
  rows?: number;
  background?: string;
  marginBottom?: string;
  error?: string;
  maxLenght?: number;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: () => void;
  onPressEnter?: () => void;
};

export const TextAreaInput = (props: TextAreaInputProps) => {
  const { translation } = useTranslationText();
  if (props.hide) {
    return <></>;
  }

  const value = props.value ? props.value : '';

  return (
    <InputWrapper style={{ ...props.style, marginBottom: props.marginBottom || '0px' }}>
      {props.label && (
        <InputText>
          <Text fontWeight={500} color={props.color || 'black'} fontSize={props.size || '13px'}>
            {translation(props.label)} {props.required && <MustLabel>{'(*)'}</MustLabel>}
          </Text>
        </InputText>
      )}

      <InputContent noBorder={props.noBorder}>
        {props.isPreview && (
          <Text fontSize={props.size || '13px'} color={props.color || 'black'}>
            {props.value}
          </Text>
        )}
        {!props.isPreview && (
          <TextArea
            id={props.id || 'TextAreaId'}
            name={props.name}
            value={value}
            placeholder={props.placeholder ? translation(props.placeholder) : ''}
            onChange={(e) => {
              if (props.maxLenght) {
                if (e.target.value.length > props.maxLenght) {
                  return;
                }
              }
              props.onChange && props.onChange(e);
            }}
            onBlur={props.onBlur}
            disabled={props.disabled}
            rows={props.rows}
            color={props.color || 'black'}
            style={{
              width: props.width || '100%',
              color: props.color || 'black',
              fontSize: props.size || '13px',
              fontWeight: props.bolder ? 600 : 'normal',
              height: props.height || '75px',
              minHeight: props.minHeight,
              borderColor: AppColors.border.input,
              background: props.background,
            }}
          />
        )}
        {!props.isPreview && props.error && <ErrorMessage>{`※ ${translation(props.error)}`}</ErrorMessage>}
      </InputContent>
      {props.maxLenght && (
        <MaxLength>
          <Text color={props.color || 'black'} fontSize={'10px'}>
            ({value.length}/{props.maxLenght})
          </Text>
        </MaxLength>
      )}
    </InputWrapper>
  );
};
