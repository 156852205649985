import React from 'react';
import { Routes, Route } from 'react-router';
import { CmsArticleDetailsPage } from 'containers/cms-containers/articles/detail';
import { CmsMusicLibraryPage } from 'containers/cms-containers/music/library';

export const MusicRouter = () => {
  return (
    <Routes>
      <Route index element={<CmsMusicLibraryPage />} />
      <Route path="/:musicId" element={<CmsArticleDetailsPage />} />
    </Routes>
  );
};
