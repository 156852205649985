import gql from 'graphql-tag';

export const SEARCH = gql`
  query searchReports($input: SearchReportsInput!) {
    searchReports(input: $input) {
      totalItems
      items {
        id
        title
        type
        reasonType
        content
        reportVideoId
        reportUserId
        userId
        updatedTime
        createdTime
      }
    }
  }
`;

export const GET = gql`
  query getReport($input: GetReportInput!) {
    getReport(input: $input) {
      id
      title
      type
      reasonType
      content
      reportVideoId
      reportUserId
      reportUser {
        id
        identityId
        customerId
        snsId
        status
        name
        romajiName
        avatar
        role
      }
      reportUser {
        id
        identityId
        customerId
        snsId
        status
        name
        romajiName
        avatar
        role
      }
      video {
        id
        name
        employmentType
        description
        content
        videoUrl
        thumbnailSmall
        thumbnail
      }
      userId
      updatedTime
      createdTime
    }
  }
`;
