import { musicMutations } from 'api/graphql/music';
import { useBaseMutation } from 'api/hooks/base/useBaseMutation';
import { useTranslationText } from 'locale';
import { openNotification } from 'vendors/Notification';

export const useDeleteMusic = () => {
  const { translation } = useTranslationText();
  const { loading, mutation } = useBaseMutation<{ id: string }, boolean>({
    mutation: musicMutations.DELETE,
  });
  async function onDeleteMusic(params: { id: string }) {
    const result = await mutation(params);
    if (!result.error) {
      openNotification({
        type: 'success',
        message: translation('musicPage.deleteSuccess'),
      });
    } else {
      openNotification({
        type: 'error',
        message: translation('message.error'),
        description: result.error.code + (result.error?.title || ''),
      });
    }
    return result;
  }

  return {
    loading,
    onDeleteMusic,
  };
};
