import React from 'react';
import { BaseComponent } from 'components/common/components/BaseComponent';
import { Div, HorizontalView, VerticalView } from 'vendors/Wrapper';
import { useTranslationText } from 'locale';
import { useAppSetting } from 'hooks/useAppSetting';
import { IContact } from 'models/Contact';
import { Loading } from 'vendors/Loading';
import { NavigationBar } from 'components/common/components/NavigationBar';
import { IMessage } from 'models/Models';
import dayjs from 'dayjs';
import { Text } from 'vendors/Text';

type CMSContactDetailsProps = {
  loading: boolean;
  error?: IMessage;
  contact?: IContact;
};

export const CMSContactDetailsComponent = (props: CMSContactDetailsProps) => {
  const { translation } = useTranslationText();
  const { appSize } = useAppSetting();

  return (
    <BaseComponent error={props.error}>
      <Div fullWidth>
        <Loading loading={props.loading} />
        <NavigationBar header={'contactDetails'} path={'/cms/contacts'} background={'#EDEDED'} />
        {props.contact?.id && (
          <HorizontalView fullWidth padding={'20px'}>
            <VerticalView fullWidth>
              <VerticalView fullWidth overflow={'hidden'} alignContent={'centerLeft'}>
                <Div marginTop={'10px'} marginBottom={'10px'} alignContent={'centerLeft'}>
                  <Text fontSize="25px" fontWeight={'bold'}>
                    {props.contact.subject}
                  </Text>
                </Div>
                <HorizontalView marginBottom={'10px'} alignContent={'centerLeft'}>
                  <Text fontSize={appSize.h4} fontWeight={'bold'} marginRight={'5px'}>
                    {props.contact.name}
                  </Text>
                  {`<`}
                  <Text fontSize={appSize.h4}>{props.contact.email}</Text>
                  {`>`}
                </HorizontalView>
                <Text fontSize={appSize.h4} whiteSpace={'pre-line'}>
                  {props.contact.content}
                </Text>
                <Text fontSize={appSize.h4} marginTop={'25px'}>
                  {translation('public.contact.contactTime')}:{' '}
                  {props.contact.datetime ? props.contact.datetime : translation('no')}
                </Text>
                <Text fontSize={appSize.h4}>
                  {translation('public.contact.contactPhone')}: {props.contact.phone}
                </Text>
                <Text fontSize={appSize.h4}>
                  {translation('createdTime')}: {dayjs(props.contact.createdTime).format('YYYY/MM/DD HH:mm')}
                </Text>
              </VerticalView>
            </VerticalView>
          </HorizontalView>
        )}
      </Div>
    </BaseComponent>
  );
};
