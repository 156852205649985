import React from 'react';
import styled from 'styled-components';
import ReactAudioPlayer from 'react-audio-player';

const AudioWrapper = styled.div`
  overflow: hidden;
  width: 100%;
`;

export type AudioProps = {
  url: string;
};

export const AudioPlayer = React.memo((props: AudioProps) => {
  //   const [playing, toggle] = useAudio(props.url);

  return (
    <AudioWrapper>
      <ReactAudioPlayer
        style={{ width: '100%', borderRadius: '5px', background: '#F1F3F4', outline: 'none' }}
        src={props.url}
        controlsList={''}
        preload={'auto'}
        controls={true}
      />
    </AudioWrapper>
  );
});

AudioPlayer.displayName = 'AudioPlayer';
