import React from 'react';
import { VerticalView } from 'vendors/Wrapper';
import { Text } from '../Text';

export type TextViewProps = {
  value?: string | null;
  label?: string;
  fontSize?: string;
};

export const TextView = (props: TextViewProps) => {
  return (
    <VerticalView fullWidth alignContent={'centerLeft'}>
      {props.label && (
        <Text fontWeight={500} fontSize={props.fontSize || '13px'} marginBottom={'6px'}>
          {props.label}
        </Text>
      )}
      <Text fontSize={props.fontSize || '13px'}>{props.value}</Text>
    </VerticalView>
  );
};
