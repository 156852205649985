import { useDeleteDownloadRecord } from 'api/hooks/download/useDeleteDownloadRecord';
import { useSearchDownloadRecords } from 'api/hooks/download/useSearchDownloadRecords';
import { DocDownloadedComponent } from 'components/cms-interface/doc-downloaded';
import { useTranslationText } from 'locale';
import { IDownloadManager } from 'models/Download';
import React from 'react';
import { openNotification } from 'vendors/Notification';

export const DocDownloadedContainer = () => {
  const { translation } = useTranslationText();
  const {
    downloads,
    loading,
    searchDownloads,
    onDeleteItem,
    page,
    totalItems,
    totalPages,
    onChangePage,
  } = useSearchDownloadRecords();

  const { deleteDownload } = useDeleteDownloadRecord();

  return (
    <DocDownloadedComponent
      downloads={downloads}
      loading={loading}
      totalItems={totalItems}
      page={page}
      totalPages={totalPages}
      onSearch={(keyword: string) => {
        searchDownloads({
          keyword,
          page: 1,
          limit: 50,
        });
      }}
      onChangePage={onChangePage}
      onDelete={async (id) => {
        const result = await deleteDownload({ id });
        if (result.data) {
          onDeleteItem({ id } as IDownloadManager);
          openNotification({
            type: 'success',
            message: translation('message.delete'),
            description: translation('message.success'),
          });
        } else {
          openNotification({
            type: 'error',
            message: translation('message.delete'),
            description: translation('message.error'),
          });
        }
      }}
    />
  );
};
