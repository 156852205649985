import React from 'react';
import styled from 'styled-components';
import { useTranslationText } from 'locale';
import { BaseComponent } from 'components/common/components/BaseComponent';
import { MessageBox } from 'components/common/components/MessageBox';
import { VerticalView } from 'vendors/Wrapper';
import { Loading } from 'vendors/Loading';

type VerifyAccountProps = {
  isLoading: boolean;
  isSuccess: boolean;
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 320px;
  margin: auto;
`;

export const VerifyAccountComponent = (props: VerifyAccountProps) => {
  const { translation } = useTranslationText();

  return (
    <BaseComponent documentTitle={translation('verifyAccount.title')}>
      <VerticalView fullWidthHeight minHeight={'50vh'} alignContent={'center'} padding={'20px'}>
        <Loading loading={props.isLoading} />
        <ContentWrapper>
          {!props.isLoading && props.isSuccess && (
            <MessageBox title={translation('verifyAccount.title')} message={translation('verifyAccount.message')} />
          )}
          {!props.isLoading && !props.isSuccess && (
            <MessageBox title={translation('verifyAccount.title')} message={translation('verifyAccount.error')} />
          )}
        </ContentWrapper>
      </VerticalView>
    </BaseComponent>
  );
};
