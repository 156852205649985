import * as yup from 'yup';
export const contactValidatorSchema = yup.object().shape({
  name: yup.string().required('validator.requiredName').nullable(),
  email: yup.string().email('validator.invalidEmail').nullable().required('validator.requiredEmail'),
  phone: yup.string().required('validator.requiredPhone').nullable(),
  subject: yup.string().required('validator.requiredSubject').nullable(),
  content: yup.string().required('validator.requiredContent').nullable(),
  isAgree: yup.boolean().oneOf([true], 'validator.requiredAgree').required('validator.requiredAgree').nullable(),
  datetime: yup.string().when('isDirect', {
    is: true,
    then: yup.string().required('validator.requiredDatetime').nullable(),
  }),
});
