import { downloadQueries } from 'api/graphql/download';
import { IDownloadManager, ISearchDownloadRecordsInput } from 'models/Download';
import { useEffect } from 'react';
import { useBaseSearchQuery } from '../base/useBaseSearchQuery';

export const useSearchDownloadRecords = () => {
  const { onSearch, items, loading, page, totalItems, totalPages, onChangePage, onDeleteItem } = useBaseSearchQuery<
    ISearchDownloadRecordsInput,
    IDownloadManager
  >({
    fetchPolicy: 'cache-and-network',
    query: downloadQueries.SEARCH,
  });

  useEffect(() => {
    onSearch({
      page: 1,
      limit: 50,
    });
  }, []);

  return {
    searchDownloads: onSearch,
    downloads: items,
    loading,
    page,
    totalItems,
    totalPages,
    onChangePage,
    onDeleteItem,
  };
};
