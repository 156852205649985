import React from 'react';
import styled from 'styled-components';
import * as colors from '../../helpers/colors';
import { BackgroundWrapper, BodyWrapper } from '../CommonWrappers';

export const TableWrapper = styled.div<{ width?: string }>`
  margin: auto;
  overflow-x: scroll;
  width: ${(props) => (props.width ? `width:${props.width}` : '100%')};
  .react-bootstrap-table {
    background-color: white;
    .table td {
      overflow-wrap: break-word;
    }
  }

  .header-class {
    color: black;
    background-color: #f5f6f8;
    font-size: 16px;
    overflowwrap: break-word;
    tr {
      th {
        border: 1px solid #e3e4e6;
      }
    }
  }
  .table {
    width: 100%;
  }
  .selection-cell-header {
    display: flex;
    justify-content: center;
    input {
      margin-left: 5px;
      width: 20px;
      height: 20px;
      margin-top: 7px;
      border: 1px solid #e3e4e6;
    }
  }
  .selection-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    input {
      width: 20px;
      height: 20px;
    }
  }
  .custom-row-class {
    td {
      padding-left: 5px;
      padding-right: 5px;
      border: 1px solid #e3e4e6;
    }
  }
  .custom-row-class:nth-child(even) {
    background: #f5f5f5;
  }
  .custom-row-class:nth-child(odd) {
    background: #fafafa;
  }
`;

export const UserTableWrapper = styled.div<{ noShadow?: boolean }>`
  margin: auto;
  max-width: 1000px;
  .react-bootstrap-table {
    background-color: white;
    .table td {
      overflow-wrap: break-word;
    }
  }

  .header-class {
    color: black;
    background-color: #e8e8e8;
    font-size: 16px;
    overflowwrap: break-word;
  }
  .table {
    width: 100%;
    td {
      border-right: 1px solid white;
    }
    th {
      border-right: 1px solid white;
    }
    th:last {
      border-right: 0px solid white;
    }
  }
  .selection-cell-header {
    display: flex;
    justify-content: center;
    input {
      margin-left: 5px;
      width: 20px;
      height: 20px;
      margin-top: 7px;
    }
  }
  .selection-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    input {
      width: 20px;
      height: 20px;
    }
  }
  .custom-row-class {
    border-bottom: 1px solid white;
    td {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  .custom-row-class:nth-child(even) {
    background: #f5f5f5;
  }
  .custom-row-class:nth-child(odd) {
    background: #fafafa;
  }
`;

export const Hr = styled.div<{ height?: string; background?: string }>`
  height: ${(props) => props.height || '2px'};
  background: ${(props) => props.background || 'gray'};
  width: 100%;
  opacity: 0.1;
`;

type CommonPartsProps = {
  children?: React.ReactNode;
  textAlign?: string;
  marginTop?: string;
  marginBottom?: string;
  background?: string;
  width?: string;
  minHeight?: string;
};

export const QHCWrapper = (props: CommonPartsProps) => {
  return (
    <BackgroundWrapper
      style={{ background: props.background ? props.background : colors.WHITE, minHeight: props.minHeight }}
    >
      <BodyWrapper>{props.children}</BodyWrapper>
    </BackgroundWrapper>
  );
};
