import gql from 'graphql-tag';
export const REGIST_USER = gql`
  mutation registerUser($input: RegisterUserInput!) {
    registerUser(input: $input) {
      id
    }
  }
`;
export const LOG_IN = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      id
      email
      role
      email
      token
    }
  }
`;

export const RESET_USERPASSWORD = gql`
  mutation resetUserPassword($input: ResetUserPasswordInput!) {
    resetUserPassword(input: $input)
  }
`;

export const CREATE = gql`
  mutation operationCreateUser($input: OperationCreateUserInput!) {
    operationCreateUser(input: $input) {
      id
    }
  }
`;

export const LOG_OUT = gql`
  mutation logoutUser {
    logoutUser
  }
`;

export const UPDATE = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
    }
  }
`;

export const DELETE = gql`
  mutation operationDeleteUser($input: OperationDeleteUserInput!) {
    operationDeleteUser(input: $input)
  }
`;

export const RESET_PASS = gql`
  mutation operationResetUserPassword($input: OperationResetUserPasswordInput!) {
    operationResetUserPassword(input: $input) {
      id
    }
  }
`;

export const VERIFY = gql`
  mutation verifyAccount($input: VerifyAccountInput!) {
    verifyAccount(input: $input)
  }
`;

export const FORGOTPASSWORD = gql`
  mutation forgotUserPassword($input: ForgotUserPasswordInput!) {
    forgotUserPassword(input: $input)
  }
`;

export const CHANGE_IDENTITY = gql`
  mutation changeAccountIdentityId($input: ChangeAccountIdentityIdInput!) {
    changeAccountIdentityId(input: $input) {
      id
      identityId
      lastChangeIdentityTime
    }
  }
`;
export const REGIST_BUSINESS_USER = gql`
  mutation registerBusinessAccount($input: RegisterBusinessAccountInput!) {
    registerBusinessAccount(input: $input) {
      id
    }
  }
`;
export const UPDATE_USER = gql`
  # Write your query or mutation here
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      snsId
      status
      companyId
      company {
        id
        name
        status
        logo
        cover
        email
        description
        foundation
        representativeName
        postalCode
        stateOrCityName
        streetAddress
        buildingNameAndRoomName
        homephone
        telephone
        contact
        fax
        tax
        businessContent
        privacyPolicy
        termsOfService
        locationMapUrl
        iosAppUrl
        androidAppUrl
        createdBy
        updatedBy
        updatedTime
        createdTime
      }
      name
      token
      avatar
      role
      email
      gender
      birthday
      address
      provider
      isVerified
      isFollowed
      oldPassword
      password
      reason
      cover
      totalFollowers
      totalFollowing
      totalLikes
      createdBy
      updatedBy
      passwordAttemptTimes
      updatedTime
      createdTime
    }
  }
`;
