import * as mutations from './mutations';
import * as queries from './queries';

export const userMutations = {
  ...mutations,
};

export const userQueries = {
  ...queries,
};
