import gql from 'graphql-tag';

export const GET = gql`
  query getMusic($input: GetMusicInput!) {
    getMusic(input: $input) {
      id
      status
      name
      thumbnail
      authorName
      url
      updatedTime
      createdTime
    }
  }
`;

export const OPERATION_SEARCH = gql`
  query operationSearchMusics($input: OperationSearchMusicsInput!) {
    operationSearchMusics(input: $input) {
      totalItems
      items {
        id
        status
        name
        thumbnail
        authorName
        url
        createdTime
        updatedTime
      }
    }
  }
`;

export const USER_SEARCH = gql`
  query userSearchMusics($input: UserSearchMusicsInput!) {
    userSearchMusics(input: $input) {
      totalItems
      items {
        id
        status
        name
        thumbnail
        authorName
        url
        createdTime
        updatedTime
      }
    }
  }
`;
