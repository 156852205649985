import * as mutations from './mutations';
import * as queries from './queries';

export const mediaMutations = {
  ...mutations,
};

export const mediaQueries = {
  ...queries,
};
