import { useGetCompanyTermOfService } from 'api/hooks/company/useCompanyApi';
import { TermOfServiceComponent } from 'components/user-interface/term-of-service';
import React from 'react';

export const TermOfServiceContainer = () => {
  const { loading, company, onGetCompanyTOS } = useGetCompanyTermOfService();
  React.useEffect(() => {
    onGetCompanyTOS();
  }, []);

  return <TermOfServiceComponent isLoading={loading} content={company ? (company.termsOfService as string) : ''} />;
};

export default TermOfServiceContainer;
