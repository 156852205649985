export const vi = {
  code: 'en',
  toolbar: {
    default: 'Mặc định',
    save: 'Lưu',
    font: 'Phông chữ',
    formats: 'Định dạng',
    fontSize: 'Kích thước',
    bold: 'In đậm',
    underline: 'Gạch chân',
    italic: 'Italic',
    strike: 'Strike',
    subscript: 'Subscript',
    superscript: 'Superscript',
    removeFormat: 'Xóa Định dạng',
    fontColor: 'Màu Phông chữ',
    hiliteColor: 'Màu nổi bật',
    indent: 'Indent',
    outdent: 'Outdent',
    align: 'Căn chỉnh',
    alignLeft: 'Căn trái',
    alignRight: 'Căn phải',
    alignCenter: 'Căn giữa',
    alignJustify: 'Căn chỉnh cho đều',
    list: 'Danh sách',
    orderList: 'Danh sách có thứ tự',
    unorderList: 'Danh sách không có thứ tự',
    horizontalRule: 'Đường ngang',
    hr_solid: 'Rắn',
    hr_dotted: 'Chấm',
    hr_dashed: 'Dashed',
    table: 'Bảng',
    link: 'Liên kết',
    math: 'Toán học',
    image: 'Hình ảnh',
    video: 'Video',
    audio: 'Âm thanh',
    fullScreen: 'Toàn màn hình',
    showBlocks: 'Hiển thị khối',
    codeView: 'Chế độ xem mã',
    undo: 'Hoàn tác',
    redo: 'Làm lại',
    preview: 'Xem đầy đủ',
    print: 'print',
    tag_p: 'Đoạn văn',
    tag_div: 'Bình thường (DIV)',
    tag_h: 'Tiêu đề',
    tag_blockquote: 'Trích dẫn',
    tag_pre: 'Mã',
    template: 'Mẫu',
    lineHeight: 'Chiều cao dòng',
    paragraphStyle: 'Kiểu đoạn văn',
    textStyle: 'Kiểu văn bản',
    imageGallery: 'Thư viện hình ảnh',
    mention: 'Đề cập đến',
  },
  dialogBox: {
    linkBox: {
      title: 'Chèn liên kết',
      url: 'URL để liên kết',
      text: 'Văn bản để hiển thị',
      newWindowCheck: 'Mở trong cửa sổ mới',
    },
    mathBox: {
      title: 'Toán học',
      inputLabel: 'Ký hiệu Toán học',
      fontSizeLabel: 'Kích thước Phông chữ',
      previewLabel: 'Xem trước',
    },
    imageBox: {
      title: 'Chèn hình ảnh',
      file: 'Chọn từ tệp',
      url: 'URL hình ảnh',
      altText: 'Văn bản thay thế',
    },
    videoBox: {
      title: 'Chèn Video',
      file: 'Chọn từ tệp',
      url: 'URL nhúng phương tiện, YouTube / Vimeo',
    },
    audioBox: {
      title: 'Chèn âm thanh',
      file: 'Chọn từ tệp',
      url: 'URL âm thanh',
    },
    browser: {
      tags: 'Thẻ',
      search: ' tìm kiếm',
    },
    caption: 'Chèn mô tả',
    close: 'Đóng',
    submitButton: 'Gửi',
    revertButton: 'Hoàn nguyên',
    proportion: 'Tỷ lệ ràng buộc',
    basic: 'Cơ bản',
    left: 'Trái',
    right: 'Phải',
    center: 'Giữa',
    width: 'Chiều ngang',
    height: 'Chiều dọc',
    size: 'Kích thuước',
    ratio: 'Tỷ lệ',
  },
  controller: {
    edit: 'Chỉnh sửa',
    unlink: 'Hủy liên kết',
    remove: 'Xóa',
    insertRowAbove: 'Chèn hàng ở trên',
    insertRowBelow: 'Chèn hàng bên dưới',
    deleteRow: 'Xóa hàng',
    insertColumnBefore: 'Chèn cột trước',
    insertColumnAfter: 'Chèn cột sau',
    deleteColumn: 'Xóa cột',
    fixedColumnWidth: 'Chiều rộng cột cố định',
    resize100: 'Thay đổi kích thước 100%',
    resize75: 'Thay đổi kích thước 75%',
    resize50: 'Thay đổi kích thước 50%',
    resize25: 'Thay đổi kích thước 25%',
    autoSize: 'Tự động',
    mirrorHorizontal: 'Ngang',
    mirrorVertical: 'Dọc',
    rotateLeft: 'Xoay trái',
    rotateRight: 'Xoay phải',
    maxSize: 'Kích thước tối đa',
    minSize: 'Kích thước tối thiểu',
    tableHeader: 'Tiêu đề bảng',
    mergeCells: 'Hợp nhất các ô',
    splitCells: 'Chia ô',
    HorizontalSplit: 'Phân chia theo chiều ngang',
    VerticalSplit: 'Chia dọc',
  },
  menu: {
    spaced: 'khoảng cách',
    bordered: 'Giáp',
    neon: 'Neon',
    translucent: 'Mờ',
    shadow: 'Shadow',
    code: 'Code',
  },
};
