import React from 'react';
import { ConversationsComponent } from 'components/cms-interface/chat/room';
import { useNavigate } from 'react-router-dom';
import { useSession } from 'stores/userStore';
import { useChatManager } from '../../hooks/useChatManager';

import { MessageBox } from 'components/common/components/MessageBox';
import { HorizontalView } from 'vendors/Wrapper';
import { useTranslationText } from 'locale';

export const ConversationsContainer = () => {
  const navigative = useNavigate();
  const { state, dispatch, onLoadRooms } = useChatManager();
  const { session } = useSession();
  const { translation } = useTranslationText();

  React.useEffect(() => {
    if (state.roomList.page) {
      onLoadRooms({ limit: state.roomList.limit, page: state.roomList.page });
    }
  }, [state.roomList.page]);

  const onLoadMoreRooms = () => {
    dispatch({ type: 'load-more-rooms' });
  };

  if (state.roomList.isNoRoom && !state.currentRoom?.room) {
    return (
      <HorizontalView fullWidth alignContent="center" paddingTop="20px">
        <MessageBox message={translation('chat.noRoomChat')} />
      </HorizontalView>
    );
  }

  return (
    <ConversationsComponent
      loading={state.roomList.loading}
      auth={session.user}
      currentRoom={state.currentRoom?.room}
      rooms={state.roomList.rooms}
      onSelect={(conv) => {
        navigative(`/cms/chat/${conv.id}`);
      }}
      onLoadmoreRooms={onLoadMoreRooms}
    />
  );
};

export default ConversationsContainer;
