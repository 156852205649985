import React from 'react';
import styled from 'styled-components';
import { Formik, FormikProps } from 'formik';
import { AppColors, AppConst } from 'helpers';
import { useTranslationText } from 'locale';
import { useAppSetting } from 'hooks/useAppSetting';
import { Modal } from 'vendors/Modal';
import { HorizontalView, VerticalView } from 'vendors/Wrapper';
import { Text } from 'vendors/Text';
import { ImagePicker } from 'components/common/components/ImagePicker/ImagePicker';
import { Input } from 'vendors/Input';
import { TextAreaInput } from 'vendors/TextAreaInput';
import { Button } from 'vendors/Button';
import { IPageFeature } from 'models/HomePage';

export const ModalWrapper = styled.div``;
export const AlertModalWrapper = styled.div``;
export const ContentWrapper = styled.div``;
export const AddUserWrapper = styled.div``;

interface Props {
  open: boolean;
  category: IPageFeature | null;
  onChange: (feature: IPageFeature) => void;
  onClose: () => void;
}

const Wrapper = styled.div``;

export const FeatureModal = (props: Props) => {
  const { translation } = useTranslationText();
  const { appTheme, appLayout } = useAppSetting();
  const ref = React.useRef<FormikProps<IPageFeature>>(null);

  React.useEffect(() => {
    if (ref && ref.current) {
      if (props.category) {
        ref.current.setValues(props.category);
      } else {
        ref.current.setValues({ thumbnail: '', title: '', description: '' });
      }
    }
  }, [props.category]);

  return (
    <Wrapper>
      <Modal
        title={translation('home.category.header')}
        open={props.open}
        headerBackground={appTheme.sidebar.bg}
        height={appLayout.isMobile ? '100vh' : 'auto'}
        zIndex={AppConst.zIndex.header + 60}
        onClose={() => {
          props.onClose();
        }}
        closeOnBackDropClick
      >
        <HorizontalView
          width={appLayout.isMobile ? '100vw' : '400px'}
          fullHeight
          alignContent={'topCenter'}
          marginBottom={'10px'}
        >
          <Formik
            innerRef={ref}
            initialValues={{ thumbnail: '', title: '', description: '' }}
            onSubmit={(values: IPageFeature) => {
              props.onChange(values);
            }}
          >
            {({ values, handleChange, setFieldValue }) => {
              return (
                <VerticalView fullWidth alignContent={'centerLeft'} position={'relative'} padding={'20px'}>
                  <VerticalView paddingBottom={'20px'}>
                    <Text fontWeight={600}>{translation('setting.addFeature')}</Text>
                    <HorizontalView fullWidth paddingTop={'15px'}>
                      <ImagePicker
                        ratios={['1x1']}
                        width={'150px'}
                        height={'150px'}
                        value={values.thumbnail || ''}
                        onSelect={(url) => setFieldValue('thumbnail', url)}
                      />
                    </HorizontalView>
                  </VerticalView>
                  <VerticalView fullWidth paddingBottom={'20px'}>
                    <Input
                      id={'title'}
                      type={'text'}
                      value={values.title}
                      label={'setting.featureTitle'}
                      placeholder={'setting.featureTitle'}
                      onChange={handleChange}
                    />
                  </VerticalView>
                  <VerticalView fullWidth paddingBottom={'20px'}>
                    <TextAreaInput
                      id={'description'}
                      size={'15px'}
                      height={'100px'}
                      color={AppColors.BLACK}
                      label={'setting.featureDescription'}
                      placeholder={'setting.featureDescription'}
                      value={values.description || ''}
                      background={AppColors.TRANSPARENT}
                      onChange={handleChange}
                    />
                  </VerticalView>
                  <HorizontalView fullWidth paddingTop={'30px'}>
                    <Button
                      title={`${props.category ? 'save' : 'create'}`}
                      width={'100%'}
                      background={AppColors.CORAL_PINK}
                      color={AppColors.WHITE}
                      onClick={() => {
                        if (ref.current) {
                          ref.current.handleSubmit();
                        }
                      }}
                    />
                  </HorizontalView>
                </VerticalView>
              );
            }}
          </Formik>
        </HorizontalView>
      </Modal>
    </Wrapper>
  );
};
