export type ISearchTransactionsInput = {
  keyword?: string;
  from: string;
  to: string;
  device?: IDeviceType;
};

export enum IDeviceType {
  Ios = 'IOS',
  Android = 'ANDROID',
  Web = 'WEB',
}

export enum IPaymentServiceType {
  Apple = 'Apple',
  Google = 'Google',
  Stripe = 'Stripe',
  Manual = 'Manual',
}

export type ICreateInvoiceInput = {
  from?: string;
  to?: string;
  transactionId?: string;
  device?: IDeviceType;
  userIds?: string[];
};

export type IInvoice = {
  pdfLink?: string;
};

export type ITransaction = {
  id: string;
  description?: string;
  type?: ITransactionType;
  packageId?: string;
  purchaseDate?: string;
  device?: IDeviceType;
  price?: number;
  receipt?: string;
  invoice?: string;
  userId?: string;
  user?: IBuyer;
  createdBy?: string;
  updatedTime: string;
  createdTime: string;
};

export type IBuyer = {
  id?: string;
  avatar?: string;
  name?: string;
};

export enum ITransactionType {
  Subcription = 'Subcription',
}

export type ICreateTransactionInput = {
  type?: string;
  packageId?: string;
  description?: string;
  device?: IDeviceType;
  price?: number;
};

export type ISearchTransactionResult = {
  totalPrice?: number;
  items: ITransaction[];
};

export enum IProductType {
  Consumable = 'Consumable',
  NonConsumable = 'NonConsumable',
  AutoRenewableSubscription = 'AutoRenewableSubscription',
  NonRenewingSubscription = 'NonRenewingSubscription',
}

export type IProduct = {
  id: string;
  platform?: IDeviceType;
  type?: IProductType;
  name?: string;
  description?: string;
  packageId?: string;
  price?: number;
  createdTime?: string;
  updatedTime?: string;
};

export type ICreateProductInput = {
  name: string;
  description: string;
  platform: IDeviceType;
  type: IProductType;
  packageId: string;
  price: number;
};

export type IUpdateProductInput = {
  id: string;
  name: string;
  description: string;
  platform: IDeviceType;
  type: IProductType;
  price: number;
};

export type ISearchProductsInput = {
  keyword?: string;
  platform?: IDeviceType[];
  limit: number;
  page: number;
};
