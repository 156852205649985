import * as React from 'react';
export const useResize = (elementRef: any) => {
  const [size, setSize] = React.useState({ width: 0, height: 0 });

  React.useEffect(() => {
    if (elementRef && elementRef.current && size.width === 0) {
      setSize({ width: elementRef.current.clientWidth, height: elementRef.current.clientHeight });
    }
  }, [elementRef]);

  React.useEffect(() => {
    const handleResize = () => {
      if (elementRef && elementRef.current) {
        setSize({ width: elementRef.current.offsetWidth, height: elementRef.current.offsetHeight });
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [elementRef]);

  return size;
};
