import gql from 'graphql-tag';

export const CREATE = gql`
  mutation createBusinessAccount($input: CreateBusinessAccountInput!) {
    createBusinessAccount(input: $input) {
      id
    }
  }
`;
export const UPDATE_BUSINESS_USER = gql`
  mutation updateBusinessAccount($input: UpdateBusinessAccountInput!) {
    updateBusinessAccount(input: $input) {
      id
      snsId
      status
      companyId
      company {
        id
        name
        status
        logo
        cover
        email
        description
        foundation
        representativeName
        postalCode
        stateOrCityName
        streetAddress
        buildingNameAndRoomName
        homephone
        telephone
        contact
        fax
        tax
        businessContent
        privacyPolicy
        termsOfService
        locationMapUrl
        iosAppUrl
        androidAppUrl
        createdBy
        updatedBy
        updatedTime
        createdTime
      }
      name
      token
      avatar
      email
      gender
      birthday
      address
      provider
      isVerified
      isFollowed
      oldPassword
      password
      reason
      cover
      totalFollowers
      totalFollowing
      totalLikes
      createdBy
      updatedBy
      passwordAttemptTimes
    }
  }
`;
export const UPDATE = gql`
  mutation updateBusinessAccount($input: UpdateBusinessAccountInput!) {
    updateBusinessAccount(input: $input) {
      id
    }
  }
`;

export const DELETE = gql`
  mutation deleteBusinessAccount($input: DeleteBusinessAccount!) {
    deleteBusinessAccount(input: $input)
  }
`;

export const CHANGE_STATUS = gql`
  mutation changeAccountStatus($input: ChangeAccountStatusInput!) {
    changeAccountStatus(input: $input) {
      id
      status
    }
  }
`;
