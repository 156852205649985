import React from 'react';
import Table from 'components/common/components/Table';
import { Confirm } from 'components/common/components/Modals/ConfirmModal';
import { useTranslationText } from 'locale';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { AppColors } from 'helpers';
import { HorizontalView, VerticalView } from 'vendors/Wrapper';
import { TextLink } from 'vendors/TextLink';
import { Button } from 'vendors/Button';
import { IVideoShort } from 'models/Video';
import { Text } from 'vendors/Text';

export function columns(translation: any, onDelete: (id: string) => void, onDetails: (id: string) => void) {
  const mobileColumns: Array<ColumnDescription<IVideoShort>> = [
    {
      dataField: 'id',
      text: 'ID',
      headerStyle: () => {
        return {
          fontSize: '13px',
          width: '40px',
          textAlign: 'center',
          whiteSpace: 'unset',
          padding: '10px',
        };
      },
      // eslint-disable-next-line react/display-name
      formatter: (_, row: IVideoShort, index) => {
        return (
          <HorizontalView fullWidth alignContent={'center'} padding={'5px'}>
            <TextLink fontWeight={600} href={`/cms/videos/${row.id}`}>
              {index + 1}
            </TextLink>
          </HorizontalView>
        );
      },
    },
    {
      dataField: 'name',
      text: translation('usersSearch.information'),
      headerStyle: () => {
        return { fontSize: '13px', width: 'calc(100% - 60px)', textAlign: 'center', padding: '10px' };
      },
      // eslint-disable-next-line react/display-name
      formatter: (_, row: IVideoShort) => {
        return (
          <HorizontalView fullWidth alignContent={'center'} padding={'5px'}>
            <VerticalView fullWidth alignContent={'centerLeft'}>
              <HorizontalView>{row.name}</HorizontalView>
              <HorizontalView>{translation(`videoPage.type.${row.type?.toLowerCase()}`)}</HorizontalView>
              <Text
                fontWeight={500}
                color={
                  row.status === 'blocked'
                    ? AppColors.RED
                    : row.status === 'active'
                    ? AppColors.GRASSY_GREEN
                    : AppColors.ORANGE
                }
              >
                {translation(`videoPage.status.${row.status}`)}
              </Text>
              <HorizontalView fullWidth alignContent={'centerRight'}>
                <Button
                  borderRadius={'4px'}
                  preixIcon={'edit'}
                  color={AppColors.GRASSY_GREEN}
                  background={AppColors.TRANSPARENT}
                  onClick={() => {
                    onDetails(row.id);
                  }}
                />
                <Confirm
                  title={'userList.alertDelete.message'}
                  leftText={'userList.alertDelete.close'}
                  rightText={'userList.alertDelete.delete'}
                  onConfirm={() => {
                    onDelete(row.id);
                  }}
                >
                  <Button
                    preixIcon={'trash'}
                    borderRadius={'4px'}
                    color={AppColors.RED}
                    background={AppColors.TRANSPARENT}
                  />
                </Confirm>
              </HorizontalView>
            </VerticalView>
          </HorizontalView>
        );
      },
    },
  ];

  return mobileColumns;
}

interface MobileTableProps {
  loading: boolean;
  videos: IVideoShort[];
  onCreate?: () => void;
  onDetails: (id: string) => void;
  onDelete: (id: string) => void;
}

export const MobileTableView = (props: MobileTableProps) => {
  const { translation } = useTranslationText();

  return (
    <Table
      loading={props.loading}
      keyField={'id'}
      data={props.videos ? props.videos : []}
      bordered={false}
      striped={true}
      hover={true}
      condensed={true}
      // exportCSV={true}
      columns={columns(translation, props.onDelete, props.onDetails)}
    />
  );
};
