import React from 'react';
import { Composer, ComposerProps, SendProps } from 'react-gifted-chat';
export const ChatComposer = (
  props: ComposerProps & {
    onSend: SendProps['onSend'];
    text: SendProps['text'];
  },
) => (
  <Composer
    {...props}
    placeholder={'メッセジ入力'}
    textInputProps={{
      ...props.textInputProps,
      // for enabling the Return key to send a message only on web
      blurOnSubmit: true,
      onSubmitEditing: () => {
        if (props.text && props.onSend) {
          props.onSend({ text: props.text.trim() }, true);
          setTimeout(() => {
            document.querySelector('textarea')?.focus();
          }, 500);
        }
      },
    }}
  />
);
