import gql from 'graphql-tag';

export const EXTEND_MEMBERSHIP = gql`
  mutation extendMembership($input: ExtendMembershipInput!) {
    extendMembership(input: $input) {
      id
      deviceType
      userId
      packageId
      transactionId
      isMembership
      isTrialPeriod
      price
      planName
      planPrice
      planDesc
      localizedPlanName
      localizedPlanPrice
      localizedPlanDesc
      isAutoRenew
      purchaseDate
      expiresDate
      receipt
    }
  }
`;

export const CHANGE_MEMBERSHIP = gql`
  mutation changeMembership($input: ChangeMembershipInput!) {
    changeMembership(input: $input) {
      id
      deviceType
      paymentServiceType
      userId
      packageId
      transactionId
      isMembership
      isTrialPeriod
      price
      planName
      planPrice
      planDesc
      localizedPlanName
      localizedPlanPrice
      localizedPlanDesc
      isAutoRenew
      purchaseDate
      expiresDate
      receipt
    }
  }
`;

export const VERIFY_MEMBERSHIP = gql`
  mutation verifyMembership($input: VerifyMembershipInput!) {
    verifyMembership(input: $input) {
      id
      deviceType
      paymentServiceType
      userId
      packageId
      transactionId
      isMembership
      isTrialPeriod
      price
      planName
      planPrice
      planDesc
      localizedPlanName
      localizedPlanPrice
      localizedPlanDesc
      isAutoRenew
      purchaseDate
      expiresDate
      receipt
    }
  }
`;

export const CANCEL_MEMBERSHIP = gql`
  mutation cancelMembership($input: CancelMembershipInput!) {
    cancelMembership(input: $input) {
      id
      deviceType
      paymentServiceType
      userId
      packageId
      transactionId
      isMembership
      isTrialPeriod
      price
      planName
      planPrice
      planDesc
      localizedPlanName
      localizedPlanPrice
      localizedPlanDesc
      isAutoRenew
      purchaseDate
      expiresDate
      receipt
    }
  }
`;
