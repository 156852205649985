import config from 'config';
import React, { useEffect, useMemo } from 'react';
import io, { Socket } from 'socket.io-client';
import { useSession } from 'stores/userStore';

const SocketContext = React.createContext<Socket>({} as Socket);

export const SocketProvider = (props: { children: React.ReactNode }) => {
  const { session } = useSession();
  const socket = useMemo(() => {
    return io(config.API_HOST_URL, {
      transports: ['websocket', 'polling'],
      auth: {
        token: session?.token,
      },
    });
  }, [session.token]);

  useEffect(() => {
    if (!session?.token) {
      return;
    }
    socket?.emit('online');
    return () => {
      socket?.emit('offline');
    };
  }, [session?.token]);

  return <SocketContext.Provider value={socket}>{props.children}</SocketContext.Provider>;
};

export const useSocket = () => {
  const context = React.useContext(SocketContext);

  return { socket: context };
};
