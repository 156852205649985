import React from 'react';
import styled from 'styled-components';
import { Formik, FormikProps, Form } from 'formik';
import { AppColors } from 'helpers';
import { SelectDropdown, SelectItem } from 'vendors/Select';
import { IUser } from 'models/User';
import { Div, HorizontalView, VerticalView } from 'vendors/Wrapper';
import { useTranslationText } from 'locale';
import { useAppSetting } from 'hooks/useAppSetting';
import { ImagePicker } from 'components/common/components/ImagePicker/ImagePicker';
import { InputProps, Input } from 'vendors/Input';
import { PrimaryButton } from 'components/common/components/Button/PrimaryButton';
import { ButtonOutline } from '../../../common/components/Button/ButtonOutline';
import { updateUserValidatorSchema } from '../../../../validators/user';
import { ChangeStatusModal } from 'components/common/components/Modals/ChangeStatusModal';
import { IChangeAccountStatusInput } from 'models/BusinessAccount';
import { Button } from 'vendors/Button';

const FormEditWrapper = styled.div`
  width: 100%;
  padding-top: 20px;
`;

const i18key = 'userDetails';

export const InputField = (props: InputProps) => {
  return (
    <Input
      fontSize={props.fontSize || '30px'}
      background={'transparent'}
      style={{ color: AppColors.WHITE, background: 'transparent', width: '100%', minWidth: '320px' }}
      {...props}
    />
  );
};

interface UserUpdateFormProps {
  user?: IUser;
  onSave?: (user: IUser) => void;
  onChangePassword: () => void;
  onChangeStatus: (input: IChangeAccountStatusInput) => void;
  changeStatusLoading: boolean;
}
export const UserInformation = (props: UserUpdateFormProps) => {
  const { getTypes } = useTranslationText();
  const { appSize, appLayout } = useAppSetting();
  const ref = React.useRef<FormikProps<IUser | any>>(null);
  const [showModalChangeStatus, setShowModalChangeStatus] = React.useState(false);
  const statusTypes: SelectItem[] = getTypes('segmentPublic');

  React.useEffect(() => {
    if (props.user && ref && ref.current) {
      ref.current.setValues({ ...props.user });
    }
  }, [props.user]);

  return (
    <FormEditWrapper>
      <Formik
        innerRef={ref}
        enableReinitialize={true}
        initialValues={props.user || ({} as IUser)}
        validationSchema={updateUserValidatorSchema}
        onSubmit={(values: IUser) => {
          props.onSave && props.onSave(values);
        }}
      >
        {({ values, errors, submitCount, handleChange, setFieldValue }) => {
          return (
            <Form>
              <VerticalView width={'100%'} alignContent={'centerLeft'}>
                <VerticalView fullWidth alignContent={'centerLeft'}>
                  <ImagePicker
                    maxImageWidth={512}
                    label={`${i18key}.avatar`}
                    ratios={['1x1']}
                    width={'100%'}
                    height={'230px'}
                    value={values.avatar}
                    onSelect={(url) => setFieldValue('avatar', url)}
                  />
                </VerticalView>
                {/* <AccountIdentity user={props.user} /> */}
                <VerticalView minWidth={'320px'} fullWidth alignContent={'centerLeft'} marginTop={'20px'}>
                  <Input
                    label={`${i18key}.email`}
                    isPreview
                    placeholder={`${i18key}.email`}
                    name={'email'}
                    fontSize={appSize.md}
                    value={values.email}
                    onChange={handleChange}
                  />

                  {props.user?.totalFollowers && (
                    <Div marginTop="15px">
                      <Input
                        label={`${i18key}.totalFollowers`}
                        isPreview
                        placeholder={`${i18key}.totalFollowers`}
                        name={'totalFollowers'}
                        fontSize={appSize.md}
                        value={values.totalFollowers}
                        onChange={handleChange}
                      />
                    </Div>
                  )}
                  {props.user?.totalFollowing && (
                    <Div marginTop="15px">
                      <Input
                        label={`${i18key}.totalFollowing`}
                        isPreview
                        placeholder={`${i18key}.totalFollowing`}
                        name={'totalFollowing'}
                        fontSize={appSize.md}
                        value={values.totalFollowing}
                        onChange={handleChange}
                      />
                    </Div>
                  )}
                  <HorizontalView fullWidth paddingTop={'20px'}>
                    <Input
                      id={'name'}
                      name={'name'}
                      fontSize={appSize.md}
                      value={values.name}
                      label={`${i18key}.name`}
                      placeholder={`${i18key}.name`}
                      error={submitCount ? errors.name : undefined}
                      onChange={handleChange}
                    />
                  </HorizontalView>
                  <HorizontalView fullWidth paddingTop={'20px'}>
                    <SelectDropdown
                      width={'100%'}
                      size={appSize.md}
                      direction={'column'}
                      id={'isVerified'}
                      label={`${i18key}.status`}
                      dataType={'list'}
                      value={values.isVerified || false}
                      style={{ minWidth: '150px' }}
                      options={statusTypes}
                      onChange={(value) => {
                        setFieldValue('isVerified', value);
                      }}
                    />
                  </HorizontalView>
                </VerticalView>
              </VerticalView>
            </Form>
          );
        }}
      </Formik>
      <HorizontalView width={'100%'} marginTop={'40px'} alignContent={'centerRight'}>
        <Button
          width={appLayout.isMobile ? '100%' : 'auto'}
          marginRight={appLayout.isMobile ? '0px' : '20px'}
          title={'changeStatus.button'}
          color={AppColors.WHITE}
          borderRadius={'4px'}
          padding="10px 15px"
          fontSize={'15px'}
          fontWeight={600}
          background={AppColors.RED_PRIMARY}
          onClick={() => {
            setShowModalChangeStatus(!showModalChangeStatus);
          }}
        />

        <ButtonOutline
          width={appLayout.isMobile ? '100%' : 'auto'}
          icon={'key'}
          title={`${i18key}.changePass`}
          marginRight={appLayout.isMobile ? '0px' : '20px'}
          onClick={props.onChangePassword}
        />

        <PrimaryButton
          icon={'save'}
          marginTop={appLayout.isMobile ? '20px' : '0px'}
          width={appLayout.isMobile ? '100%' : 'auto'}
          title={`${i18key}.save`}
          onClick={() => {
            if (ref && ref.current) {
              ref.current.handleSubmit();
            }
          }}
        />
      </HorizontalView>
      {props.user && (
        <ChangeStatusModal
          loading={false}
          open={showModalChangeStatus}
          statusTypes={'userStatus'}
          status={props.user?.status || ''}
          onClose={() => setShowModalChangeStatus(false)}
          onUpdate={(input) => {
            props.onChangeStatus({
              id: props.user?.id,
              status: input.status,
              reason: input.reason,
            } as IChangeAccountStatusInput);
          }}
        />
      )}
    </FormEditWrapper>
  );
};
