import { ProductPageComponent } from 'components/cms-interface/product';
import { useCreateProduct } from 'api/hooks/product/useCreateProduct';
import { useDeleteProduct } from 'api/hooks/product/useDeleteProduct';
import { useSearchProducts } from 'api/hooks/product/useSearchProduct';
import { useUpdateProduct } from 'api/hooks/product/useUpdateProduct';
import { ICreateProductInput, IUpdateProductInput } from 'models/Transaction';
import React, { useEffect } from 'react';

export const ProductPage = () => {
  const { loading, onDeleteItem, searchProducts, packages } = useSearchProducts();
  const { createProduct } = useCreateProduct();
  const { updateProduct } = useUpdateProduct();
  const { deleteProduct } = useDeleteProduct();

  useEffect(() => {
    searchProducts({
      limit: 30,
      page: 1,
    });
  }, []);

  return (
    <ProductPageComponent
      loading={loading}
      packages={packages}
      onCreate={async (product) => {
        const result = await createProduct({
          name: product.name,
          type: product.type,
          description: product.description,
          platform: product.platform,
          packageId: product.packageId,
          price: product.price,
        } as ICreateProductInput);
        if (!result.error) {
          searchProducts({
            limit: 30,
            page: 1,
          });
        }
      }}
      onUpdate={async (product) => {
        const result = await updateProduct({
          id: product.id,
          name: product.name,
          type: product.type,
          description: product.description,
          platform: product.platform,
          price: product.price,
        } as IUpdateProductInput);
        if (!result.error) {
          searchProducts({
            limit: 20,
            page: 1,
          });
        }
      }}
      onDelete={async (product) => {
        const result = await deleteProduct({ id: product.id });
        if (!result.error) onDeleteItem(product);
      }}
    />
  );
};
