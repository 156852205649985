import React from 'react';
import { VerticalView } from 'vendors/Wrapper';

type PropsType = {
  children?: React.ReactNode;
  padding?: string;
  paddingLeft?: string;
  paddingRight?: string;
  paddingTop?: string;
  paddingBottom?: string;
  background?: string;
  zIndex?: number;
  minHeight?: string;
};
export const CommonSection = (props: PropsType) => {
  return (
    <VerticalView
      fullWidth
      position={'relative'}
      minHeight={props.minHeight}
      alignContent={'topCenter'}
      flexWrap={'nowrap'}
      background={props.background}
      paddingTop={props.paddingTop || '30px'}
      paddingBottom={props.paddingBottom || '30px'}
      paddingLeft={props.paddingLeft}
      paddingRight={props.paddingRight}
      padding={props.padding}
      zIndex={props.zIndex}
    >
      {props.children}
    </VerticalView>
  );
};

export default React.memo(CommonSection);
