import * as React from 'react';
import styled from 'styled-components';

const StyledLabel = styled.label<{ hasChildren?: boolean; disabled?: boolean }>`
  padding-right: ${(props) => (props.hasChildren ? 5 : 0)};
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
`;

const StyledSpan = styled.span`
  margin-left: 25px;
  position: relative;
  user-select: none;
`;

const StyledCheckbox = styled.input`
  opacity: 1;

  border: none;
  border-style: none;
  height: 20px;
  width: 20px;
  position: absolute;
  &::disabled {
    opacity: 0.6;
  }
`;

interface CheckboxProps {
  name?: string;
  checked?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  onChange?: (value: string) => void;
}
export function Checkbox(props: CheckboxProps) {
  return (
    <StyledLabel disabled={props.disabled} hasChildren={props.children ? true : false}>
      {props.checked && (
        <StyledCheckbox
          type={'checkbox'}
          onChange={(e) => {
            props.onChange && props.onChange(e.target.value);
          }}
          checked={true}
          disabled={props.disabled}
          name={props.name}
        />
      )}
      {!props.checked && (
        <StyledCheckbox
          type={'checkbox'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            props.onChange && props.onChange(e.target.value);
          }}
          checked={false}
          disabled={props.disabled}
          name={props.name}
        />
      )}
      {props.children ? <StyledSpan>{props.children}</StyledSpan> : <StyledSpan />}
    </StyledLabel>
  );
}
