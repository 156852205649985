import { productMutations } from 'api/graphql/product';
import { useBaseMutation } from 'api/hooks/base/useBaseMutation';
import { useTranslationText } from 'locale';
import { ICreateProductInput, IProduct } from 'models/Transaction';
import { openNotification } from 'vendors/Notification';

export const useCreateProduct = () => {
  const { translation } = useTranslationText();
  const { loading, mutation } = useBaseMutation<ICreateProductInput, IProduct>({
    mutation: productMutations.CREATE,
  });

  async function createProduct(params: ICreateProductInput) {
    const result = await mutation(params);
    if (!result.error) {
      openNotification({
        type: 'success',
        message: translation('productPage.createSuccess'),
      });
    } else {
      openNotification({
        type: 'error',
        message: translation('message.error'),
        description: result.error.code + (result.error?.title || ''),
      });
    }
    return result;
  }

  return {
    loading,
    createProduct,
  };
};
