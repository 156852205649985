import React from 'react';
import { useParams } from 'react-router-dom';
import { CMSCategoryCreateComponent } from 'components/cms-interface/categories/update-category';
import { openNotification } from 'vendors/Notification';
import { useTranslationText } from 'locale';
import { useGetCategory, useUpdateCategory } from 'api/hooks/category/useCategoryApi';
import { ICategory, IUpdateCategoryInput } from 'models/Category';

export const CmsCategoryCreatePage = () => {
  const { categoryId } = useParams();
  const { loading, category } = useGetCategory(categoryId);
  const { onUpdateCategory, loading: updateLoading } = useUpdateCategory();
  const { translation } = useTranslationText();

  const update = async (update: ICategory) => {
    const input = {
      id: update.id || '',
      status: update.status,
      locales: update.locales,
    } as IUpdateCategoryInput;
    const result = await onUpdateCategory(input);

    if (!result.error) {
      openNotification({
        type: 'success',
        message: translation('message.update'),
        description: translation('message.success'),
      });
    } else {
      openNotification({
        type: 'error',
        message: translation('message.update'),
        description: translation('message.error'),
      });
    }
  };

  return (
    <CMSCategoryCreateComponent
      isServicePage={false}
      loading={loading}
      saving={updateLoading}
      category={category}
      error={undefined}
      onUpdate={(updateData) => {
        update(updateData);
      }}
    />
  );
};
