import 'antd/dist/antd.css';
import React from 'react';
import styled from 'styled-components';
import { HorizontalView, VerticalView } from '../Wrapper';
import { Select } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { Text } from 'vendors/Text';
import { useTranslationText } from 'locale';
import { valueType } from 'antd/lib/statistic/utils';

const InputWrapper = styled.div<{ height?: string }>`
  width: 100%;
  position: relative;
  ${(props) => {
    if (props.height) {
      return `
      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: ${props.height};
      }
      .ant-select-single .ant-select-selector .ant-select-selection-item,
      .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
        line-height: ${props.height};
      }
      `;
    }
  }}
`;

const InputText = styled.div`
  font-weight: 400;
  min-width: 80px;
  margin-bottom: 5px;
`;

const MustLabel = styled.span`
  display: inline-block;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1;
  border-radius: 3px;
  color: red;
  margin-left: 2px;
`;

const ErrorMessage = styled.div`
  margin-top: 5px;
  color: red;
  font-size: 10px;
`;

const InputContent = styled.div<{ minWidth?: number | string; width?: string; borderBottom?: string }>`
  .ant-select-dropdown {
    min-width: auto;
    width: ${(props) => (props.width ? props.width : 'auto')};
  }
  min-width: ${(props) => (props.minWidth ? props.minWidth : 'auto')};
  width: ${(props) => (props.width ? props.width : 'auto')};
  border-bottom: ${(props) => (props.borderBottom ? props.borderBottom : '0px solid #d9d9d9')};
`;

function getStyle(props: SimpleSelectProps) {
  const style: React.CSSProperties = {};
  props.borderColor && (style.borderColor = props.borderColor);
  props.borderStyle && (style.borderStyle = props.borderStyle);
  props.width && (style.width = props.width);
  props.minWidth && (style.minWidth = props.minWidth);
  props.marginTop && (style.marginTop = props.marginTop);
  props.marginBottom && (style.marginBottom = props.marginBottom);
  props.marginLeft && (style.marginLeft = props.marginLeft);
  props.marginRight && (style.marginRight = props.marginRight);
  props.disable && (style.pointerEvents = 'none');

  return style;
}

export type SelectItem = {
  label: string;
  value: valueType;
};

interface SimpleSelectProps {
  label?: string;
  color?: string;
  isPreview?: boolean;
  bolder?: boolean;
  size?: string;
  disable?: boolean;
  borderColor?: string;
  borderStyle?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
  width?: string;
  height?: string;
  required?: boolean;
  id?: string;
  allowClear?: boolean;
  borderBottom?: boolean;
  direction?: 'row' | 'column';
  dataType?: 'object' | 'list';
  value: any;
  style?: React.CSSProperties;
  options: SelectItem[];
  minWidth?: string | number;
  borderRadius?: string;
  error?: string;
  onChange: (value: any) => void;
}

export const SelectDropdown = (props: SimpleSelectProps) => {
  const { translation } = useTranslationText();
  const icon = <CaretDownOutlined />;

  const itemList: Array<{ label: string; value: any }> = props.options;

  const FlexWrap = props.direction === 'column' ? VerticalView : HorizontalView;

  return (
    <InputWrapper key={props.id || ''} style={getStyle(props)} height={props.height}>
      <FlexWrap width={'100%'} alignContent={'centerLeft'} flexWrap={'nowrap'}>
        {props.label && (
          <InputText>
            <Text fontWeight={500} fontSize={props.size || '13px'}>
              {translation(props.label)} {props.required && <MustLabel>{'(*)'}</MustLabel>}
            </Text>
          </InputText>
        )}
        <InputContent
          minWidth={props.minWidth}
          width={props.label && props.direction !== 'column' ? `calc(100% - 100px)` : '100%'}
          borderBottom={props.borderBottom && props.direction !== 'column' ? '1px solid #d9d9d9' : undefined}
        >
          {props.isPreview && (
            <Text fontSize={props.size || '15px'}>
              {props.options.find((item) => item.value === props.value)?.label}
            </Text>
          )}
          {!props.isPreview && (
            <Select
              value={props.value}
              bordered={false}
              key={props.id}
              suffixIcon={icon}
              onChange={props.onChange}
              style={{
                color: props.color || 'black',
                borderStyle: 'none',
                border: '1px solid #d9d9d9',
                borderColor: props.borderColor || '#d9d9d9',
                width: props.width || '100%',
                fontSize: props.size || '15px',
                fontWeight: props.bolder ? 600 : 'normal',
                background: 'white',
                borderRadius: props.borderRadius,
              }}
              defaultValue={props.value}
              allowClear={props.allowClear}
              options={itemList}
            ></Select>
          )}
        </InputContent>

        {props.error && <ErrorMessage>{`※ ${translation(props.error)}`}</ErrorMessage>}
      </FlexWrap>
    </InputWrapper>
  );
};
