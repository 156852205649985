import { BaseComponent } from 'components/common/components/BaseComponent';
import MediaModal from 'components/common/components/Modals/MediaModal';
import { PageTitle } from 'components/common/components/PageTitle';
import { AppColors } from 'helpers';
import { useAppSetting } from 'hooks/useAppSetting';
import { useTranslationText } from 'locale';
import { IPageFeature } from 'models/HomePage';
import React from 'react';
import { Button } from 'vendors/Button';
import { Hr } from 'vendors/CommonParts';
import { Image } from 'vendors/Image';
import { Input } from 'vendors/Input';
import { Text } from 'vendors/Text';
import { Div, HorizontalView, VerticalView } from 'vendors/Wrapper';
import { FeatureList } from './parts/FeatureList';

type SettingHomeProps = {
  loading: boolean;
  saving: boolean;
  employerPagePictures?: string[];
  employeePagePictures?: string[];
  homePagePictures?: string[];
  documentLink?: string;
  features?: IPageFeature[];
  onSave: (
    homePics: string[],
    employerPics: string[],
    employeePics: string[],
    documentLink: string,
    feature: IPageFeature[],
  ) => void;
};

export const HomeSettingComponent = (props: SettingHomeProps) => {
  const [employerPagePictures, setEmployerPagePictures] = React.useState<string[]>(props.employerPagePictures || []);
  const [employeePagePictures, setEmployeePagePictures] = React.useState<string[]>(props.employeePagePictures || []);
  const [homePagePictures, setHomePagePictures] = React.useState<string[]>(props.homePagePictures || []);
  const [features, setFeatures] = React.useState<IPageFeature[]>(props.features || []);
  const [documentLink, setDocumentLink] = React.useState<string>(props.documentLink || '');
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const { appSize } = useAppSetting();
  const { translation } = useTranslationText();

  React.useEffect(() => {
    if (props.employerPagePictures) setEmployerPagePictures(props.employerPagePictures);
    if (props.employeePagePictures) setEmployeePagePictures(props.employeePagePictures);
    if (props.homePagePictures) setHomePagePictures(props.homePagePictures);
    if (props.features) setFeatures(props.features);
    if (props.documentLink) setDocumentLink(props.documentLink);
  }, [props]);

  return (
    <BaseComponent contentLoading={props.loading}>
      <VerticalView fullWidth>
        <PageTitle title={'setting.header'} />
        <VerticalView fullWidth alignContent="topLeft">
          <VerticalView fullWidth alignContent="topLeft" paddingBottom="20px" paddingTop="20px">
            <Div paddingBottom="30px">
              <Text fontSize={appSize.h2} fontWeight={600}>
                {translation('setting.homePage')}
              </Text>
            </Div>
            <Div>
              <Text fontSize={appSize.h4} fontWeight={500}>
                {translation('setting.images')}
              </Text>
            </Div>
            <HorizontalView width={'100%'} alignContent={'centerLeft'} paddingBottom="20px">
              {homePagePictures.map((item, index) => {
                return (
                  <Div
                    key={index}
                    width="200px"
                    height="100px"
                    margin="10px"
                    padding="3px"
                    border={`1px solid ${AppColors.PRIMARY}`}
                    position={'relative'}
                  >
                    <Image width="100%" height="100%" objectFit="contain" src={item} />
                    <Div position="absolute" bottom="10px" right="10px">
                      <Button
                        width="20px"
                        height="20px"
                        preixIcon="trash"
                        color={AppColors.RED}
                        onClick={() => {
                          const pics = [...homePagePictures];
                          pics.splice(index, 1);
                          setHomePagePictures(pics);
                        }}
                      />
                    </Div>
                  </Div>
                );
              })}
              <Div fullHeight alignContent="center">
                <Button
                  width="40px"
                  height="40px"
                  background={AppColors.GRASSY_GREEN}
                  preixIcon="plus"
                  color="white"
                  borderRadius="10px"
                  onClick={() => setOpen3(!open3)}
                />
              </Div>
            </HorizontalView>
          </VerticalView>
          <Hr />
          <VerticalView fullWidth alignContent="topLeft" paddingBottom="20px" paddingTop="20px">
            <Div paddingBottom="30px">
              <Text fontSize={appSize.h2} fontWeight={600}>
                {translation('setting.employerPage')}
              </Text>
            </Div>
            <Div>
              <Text fontSize={appSize.h4} fontWeight={500}>
                {translation('setting.images')}
              </Text>
            </Div>
            <HorizontalView width={'100%'} alignContent={'centerLeft'} paddingBottom="20px">
              {employerPagePictures.map((item, index) => {
                return (
                  <Div
                    key={index}
                    width="200px"
                    height="100px"
                    margin="10px"
                    padding="3px"
                    border={`1px solid ${AppColors.PRIMARY}`}
                    position="relative"
                  >
                    <Image width="100%" height="100%" objectFit="contain" src={item} />
                    <Div position="absolute" bottom="10px" right="10px">
                      <Button
                        width="20px"
                        height="20px"
                        preixIcon="trash"
                        color={AppColors.RED}
                        onClick={() => {
                          const pics = [...employerPagePictures];
                          pics.splice(index, 1);
                          setEmployerPagePictures(pics);
                        }}
                      />
                    </Div>
                  </Div>
                );
              })}
              <Div fullHeight alignContent="center">
                <Button
                  width="40px"
                  height="40px"
                  background={AppColors.GRASSY_GREEN}
                  preixIcon="plus"
                  color="white"
                  borderRadius="10px"
                  onClick={() => setOpen1(!open1)}
                />
              </Div>
            </HorizontalView>
            <Div paddingBottom="10px">
              <Text fontSize={appSize.h4} fontWeight={500}>
                {translation('setting.feature')}
              </Text>
            </Div>
            <VerticalView fullWidth paddingBottom="20px">
              <FeatureList features={features} onChange={(features) => setFeatures(features)} />
            </VerticalView>
            <Div fullWidth>
              <Input
                label="setting.downloadLink"
                value={documentLink}
                onChange={(e) => setDocumentLink(e.target.value)}
              />
            </Div>
          </VerticalView>
          <Hr />
          <VerticalView fullWidth alignContent="topLeft" paddingBottom="20px" paddingTop="20px">
            <Div paddingBottom="30px">
              <Text fontSize={appSize.h2} fontWeight={600}>
                {translation('setting.employeePage')}
              </Text>
            </Div>
            <Div>
              <Text fontSize={appSize.h4} fontWeight={500}>
                {translation('setting.images')}
              </Text>
            </Div>
            <HorizontalView width={'100%'} alignContent={'centerLeft'} paddingBottom="20px">
              {employeePagePictures.map((item, index) => {
                return (
                  <Div
                    key={index}
                    width="200px"
                    height="100px"
                    margin="10px"
                    padding="3px"
                    border={`1px solid ${AppColors.PRIMARY}`}
                    position={'relative'}
                  >
                    <Image width="100%" height="100%" objectFit="contain" src={item} />
                    <Div position="absolute" bottom="10px" right="10px">
                      <Button
                        width="20px"
                        height="20px"
                        preixIcon="trash"
                        color={AppColors.RED}
                        onClick={() => {
                          const pics = [...employeePagePictures];
                          pics.splice(index, 1);
                          setEmployeePagePictures(pics);
                        }}
                      />
                    </Div>
                  </Div>
                );
              })}
              <Div fullHeight alignContent="center">
                <Button
                  width="40px"
                  height="40px"
                  background={AppColors.GRASSY_GREEN}
                  preixIcon="plus"
                  color="white"
                  borderRadius="10px"
                  onClick={() => setOpen2(!open2)}
                />
              </Div>
            </HorizontalView>
          </VerticalView>
          <Div fullWidth alignContent={'centerRight'} marginTop={'20px'}>
            <Button
              loading={props.saving}
              minWidth={'40px'}
              height={'40px'}
              borderRadius={'5px'}
              preixIcon={'save'}
              title={'save'}
              color={AppColors.WHITE}
              background={AppColors.GRASSY_GREEN}
              onClick={() =>
                props.onSave(homePagePictures, employerPagePictures, employeePagePictures, documentLink, features)
              }
            />
          </Div>
        </VerticalView>
        <MediaModal
          open={open1}
          ratios={['2x1']}
          maxImageWidth={2048}
          accept={'image'}
          onClose={() => setOpen1(false)}
          onSelect={(url) => {
            setOpen1(false);
            const pics = [...employerPagePictures];
            pics.push(url);
            setEmployerPagePictures(pics);
          }}
        />
        <MediaModal
          open={open2}
          ratios={['2x1']}
          maxImageWidth={2048}
          accept={'image'}
          onClose={() => setOpen2(false)}
          onSelect={(url) => {
            setOpen2(false);
            const pics = [...employeePagePictures];
            pics.push(url);
            setEmployeePagePictures(pics);
          }}
        />
        <MediaModal
          open={open3}
          ratios={['2x1']}
          maxImageWidth={2048}
          accept={'image'}
          onClose={() => setOpen3(false)}
          onSelect={(url) => {
            setOpen3(false);
            const pics = [...homePagePictures];
            pics.push(url);
            setHomePagePictures(pics);
          }}
        />
      </VerticalView>
    </BaseComponent>
  );
};
