import React from 'react';
import { Div, HorizontalView, VerticalView } from 'vendors/Wrapper';
import { NavigationBar } from 'components/common/components/NavigationBar';
import { AppColors } from 'helpers';
import { AnimationView } from 'vendors/Animation';
import { SelectDropdown, SelectItem } from 'vendors/Select';
import { useTranslationText } from 'locale';
import { ImagePicker } from 'components/common/components/ImagePicker/ImagePicker';
import { useAppSetting } from 'hooks/useAppSetting';
import { Formik, FormikProps } from 'formik';
import { Input } from 'vendors/Input';
import { AlertModal } from 'components/common/components/Modals/AlertModal';
import { BaseComponent } from 'components/common/components/BaseComponent';
import { IError } from 'models/Models';
import { RadioSelect } from 'vendors/Radio';
import { IArticle } from 'models/Article';
import { ArticleEditorInput } from 'vendors/ArticleEditor';
import { TextAreaInput } from 'vendors/TextAreaInput';
import { updateArticleValidatorSchema } from '../../../validators/article';

interface NewsDetailsProps {
  loading: boolean;
  saving?: boolean;
  isServicePage: boolean;
  article?: IArticle;
  error?: IError;
  onUpdate: (news: IArticle) => void;
}

export const CMSNewsDetailsComponent = (props: NewsDetailsProps) => {
  const { getTypes, translation } = useTranslationText();
  const { loading, article } = props;
  const { appSize } = useAppSetting();
  const [openAlert, setOpenAlert] = React.useState(false);
  const ref = React.useRef<FormikProps<IArticle>>(null);
  const newsTypes = getTypes<SelectItem[]>('articleCategories');

  React.useEffect(() => {
    if (props.article) {
      if (ref && ref.current) {
        ref.current.setValues(JSON.parse(JSON.stringify(props.article)));
      }
    }
  }, [props.article]);

  const onSave = () => {
    if (ref && ref.current) {
      ref.current.submitForm();
    }
  };

  return (
    <BaseComponent error={props.error} contentLoading={loading}>
      <VerticalView>
        <NavigationBar
          header={'editArticle.header'}
          saving={props.saving}
          path={props.isServicePage ? '/cms/services' : '/cms/articles'}
          background={'#EDEDED'}
          onSave={props.article?.id ? onSave : undefined}
        />
        <VerticalView fullWidth alignContent={'center'}>
          {props.article?.id && (
            <AnimationView width={'100%'}>
              <VerticalView fullWidth>
                <Formik
                  innerRef={ref}
                  initialValues={props.article as IArticle}
                  validationSchema={updateArticleValidatorSchema}
                  onSubmit={(values: IArticle) => {
                    props.onUpdate(values);
                  }}
                >
                  {({ submitCount, setFieldValue, errors, values, handleChange }) => {
                    return (
                      <VerticalView fullWidth alignContent={'centerLeft'}>
                        <HorizontalView
                          fullWidth
                          alignContent={'topBetween'}
                          paddingTop={'40px'}
                          paddingBottom={'20px'}
                        >
                          <VerticalView width={'100%'} alignContent={'centerLeft'}>
                            <ImagePicker
                              required
                              maxImageWidth={512}
                              label={'editArticle.thumbnail'}
                              ratios={['3x2']}
                              width={'300px'}
                              height={'200px'}
                              value={values?.thumbnail || ''}
                              onSelect={(url) => setFieldValue('thumbnail', url)}
                              error={submitCount ? errors.thumbnail : undefined}
                            />
                          </VerticalView>
                          {props.isServicePage && (
                            <VerticalView width={'100%'} alignContent={'centerLeft'} paddingTop={'20px'}>
                              <ImagePicker
                                label={'editArticle.cover'}
                                ratios={['2x1']}
                                width={'100%'}
                                height={'300px'}
                                value={values?.cover || ''}
                                onSelect={(url) => setFieldValue('cover', url)}
                                error={submitCount ? errors.cover : undefined}
                              />
                            </VerticalView>
                          )}

                          <Div width={'100%'} alignContent={'centerLeft'} paddingTop={'20px'}>
                            <RadioSelect
                              fontSize={appSize.md}
                              defaultValue={{ value: values?.status === 'active' }}
                              label={`editArticle.status`}
                              options={getTypes('segmentPublic')}
                              onChange={async (e) => {
                                setFieldValue('status', e?.value ? 'active' : 'inactive');
                              }}
                            />
                          </Div>

                          <VerticalView width={'100%'} alignContent={'topLeft'} marginTop={'15px'}>
                            {!props.isServicePage && (
                              <SelectDropdown
                                required
                                width={'250px'}
                                size={appSize.md}
                                direction={'column'}
                                label={'editArticle.category'}
                                value={values?.category || ''}
                                options={newsTypes}
                                onChange={(category) => setFieldValue('category', category)}
                              />
                            )}

                            <Div fullWidth paddingTop={'15px'}>
                              <Input
                                required
                                label={'editArticle.title'}
                                fontSize={appSize.md}
                                name={'title'}
                                height={'auto'}
                                placeholder={'editArticle.title'}
                                value={values?.title}
                                background={'transparent'}
                                error={submitCount ? errors.title : undefined}
                                onChange={handleChange}
                              />
                            </Div>

                            <Div fullWidth paddingTop={'15px'}>
                              <TextAreaInput
                                required
                                id={'description'}
                                size={appSize.md}
                                color={AppColors.BLACK}
                                label={'editArticle.description'}
                                placeholder={'editArticle.description'}
                                value={values?.description || ''}
                                error={submitCount ? errors.description : undefined}
                                onChange={handleChange}
                              />
                            </Div>
                          </VerticalView>
                        </HorizontalView>

                        <Div fullWidth paddingBottom={'40px'}>
                          <ArticleEditorInput
                            required
                            isEditing
                            label={'editArticle.content'}
                            placeholder={'editArticle.content'}
                            defaultValue={values?.content}
                            name={'details'}
                            style={{ width: '100%' }}
                            error={submitCount ? errors.content : undefined}
                            onChange={(content) => {
                              setFieldValue('content', content);
                            }}
                          />
                        </Div>
                        <NavigationBar
                          saving={props.saving}
                          path={props.isServicePage ? '/cms/services' : '/cms/articles'}
                          error={Boolean(submitCount && Object.keys(errors).length > 0)}
                          onSave={() => {
                            if (ref && ref.current) {
                              ref.current.submitForm();
                            }
                          }}
                        />
                      </VerticalView>
                    );
                  }}
                </Formik>
              </VerticalView>
            </AnimationView>
          )}
          <AlertModal
            open={openAlert}
            message={translation('invalidDataAlert.message')}
            closeTitle={translation('invalidDataAlert.close')}
            onClose={() => setOpenAlert(false)}
          />
        </VerticalView>
      </VerticalView>
    </BaseComponent>
  );
};
