import React from 'react';
import { useAppSetting } from 'hooks/useAppSetting';
import { Carousel } from 'react-responsive-carousel';
import { HomeButtonAction } from './HomeButtonAction';
import { IArticleShort } from 'models/Article';
import { useNavigate } from 'react-router-dom';
import CommonSection from 'components/common/components/CommonSection';
import { Div, HorizontalView, VerticalView } from 'vendors/Wrapper';
import { Image } from 'vendors/Image';
import { BodyWrapper, DescriptionWrapper, TitleWrapper } from 'vendors/CommonWrappers';
import { FlatList } from 'vendors/FlatList';
import { Arrow } from 'components/common/components/Arrow';
import { Hr } from 'vendors/CommonParts';
import { PageTitle } from 'components/common/components/PageTitle';

type HomeArticleProps = {
  header: string;
  seeMore: string;
  article: IArticleShort[];
  color?: string;
  background?: string;
};

export const HomeArticlePreview = React.memo((props: HomeArticleProps) => {
  const { appSize, appLayout, appTheme } = useAppSetting();
  const carouselRef = React.useRef<Carousel | null>(null);
  const navigate = useNavigate();

  function groupCards(nodes: React.ReactChild[]) {
    const chunkSize = appLayout.isMobile ? 2 : 3;

    const allGroups: React.ReactChild[][] = [];
    let groups: React.ReactChild[] = [];
    let start = 1;
    nodes.forEach((node, index) => {
      groups.push(node);
      if (start === chunkSize || index === nodes.length - 1) {
        allGroups.push(groups);
        start = 1;
        groups = [];
      } else {
        start += 1;
      }
    });

    return allGroups;
  }

  React.useEffect(() => {
    if (carouselRef && carouselRef.current && props.article && props.article.length > 0) {
      carouselRef.current.moveTo(0);
    }
  }, [appLayout.isMobile]);

  const handleSeeMore = () => {
    navigate('/articles');
  };

  return (
    <CommonSection background={props.background} minHeight="350px" zIndex={2}>
      <BodyWrapper maxWidth={appLayout.isMobile ? '600px' : '1200px'}>
        <VerticalView fullWidth alignContent={'topCenter'} position={'relative'} zIndex={1}>
          <PageTitle color={props.color} title={props.header} />
          {!appLayout.isMobile && (
            <Div fullWidth paddingLeft={'20px'} paddingRight={'20px'}>
              <FlatList
                container={(nodes) => {
                  return (
                    <Carousel
                      ref={carouselRef}
                      showStatus={false}
                      showArrows={true}
                      showThumbs={false}
                      showIndicators={false}
                      renderArrowPrev={(clickHandler: () => void, hasPrev: boolean) => {
                        return (
                          <Arrow
                            type={'prev'}
                            show={
                              Boolean(!appLayout.isMobile && props.article && props.article.length > 3) ||
                              Boolean(appLayout.isMobile && props.article && props.article.length > 2)
                            }
                            disable={!hasPrev}
                            onClick={clickHandler}
                          />
                        );
                      }}
                      renderArrowNext={(clickHandler: () => void, hasNext: boolean) => {
                        return (
                          <Arrow
                            type={'next'}
                            show={
                              Boolean(!appLayout.isMobile && props.article && props.article.length > 3) ||
                              Boolean(appLayout.isMobile && props.article && props.article.length > 2)
                            }
                            disable={!hasNext}
                            onClick={clickHandler}
                          />
                        );
                      }}
                    >
                      {groupCards(nodes).map((childs, index) => {
                        return (
                          <HorizontalView flex={'1'} key={index} alignContent={'topCenter'}>
                            {childs}
                          </HorizontalView>
                        );
                      })}
                    </Carousel>
                  );
                }}
                values={props.article}
                renderItem={(co: IArticleShort, index: number) => {
                  return (
                    <VerticalView
                      width={'calc(33% - 20px)'}
                      alignContent={'topCenter'}
                      key={index}
                      marginTop={'20px'}
                      padding={'30px'}
                      paddingBottom={'0px'}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(`/articles/${co.id}`);
                      }}
                    >
                      <VerticalView fullWidth alignContent={'topLeft'}>
                        <Image width={'100%'} borderRadius={'5px'} src={co.thumbnail || ''} objectFit={'cover'} />
                        <VerticalView alignContent={'centerLeft'} fullWidth paddingTop={'15px'}>
                          <TitleWrapper color={props.color || appTheme.base.text} maxLine={2} size={appSize.h2}>
                            {co.title}
                          </TitleWrapper>
                          <DescriptionWrapper size={appSize.h4} maxLine={3} color={props.color || appTheme.base.text}>
                            {co.description}
                          </DescriptionWrapper>
                        </VerticalView>
                      </VerticalView>
                    </VerticalView>
                  );
                }}
              />
            </Div>
          )}

          {appLayout.isMobile && (
            <HorizontalView
              fullWidth
              alignContent={'topCenter'}
              paddingTop={'10px'}
              paddingLeft={'20px'}
              paddingRight={'20px'}
              zIndex={10}
            >
              {props.article &&
                props.article &&
                props.article.map((article, index) => {
                  const blockWidth = '100%';
                  const imageHeight = '250px';
                  return (
                    <VerticalView
                      minWidth={'300px'}
                      width={blockWidth}
                      alignContent={'topLeft'}
                      key={index}
                      paddingTop={'15px'}
                      paddingLeft={'15px'}
                      paddingRight={'15px'}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(`/articles/${article.id}`);
                      }}
                    >
                      <VerticalView fullWidth alignContent={'topLeft'}>
                        <Div paddingBottom={'5px'}>
                          <TitleWrapper
                            color={props.color || appTheme.base.text}
                            maxLine={2}
                            size={appLayout.isMobile ? appSize.h3 : appSize.h2}
                          >
                            {article!.title}
                          </TitleWrapper>
                        </Div>

                        <Image
                          width={'100%'}
                          borderRadius={'5px'}
                          height={imageHeight}
                          src={article!.thumbnail || ''}
                          objectFit={'cover'}
                        />
                        <VerticalView alignContent={'centerLeft'} fullWidth paddingTop={'5px'}>
                          <DescriptionWrapper size={appSize.h4} maxLine={3} color={props.color || appTheme.base.text}>
                            {article!.description}
                          </DescriptionWrapper>
                        </VerticalView>
                      </VerticalView>

                      {index < props.article!.length - 1 && (
                        <HorizontalView fullWidth paddingTop={'20px'}>
                          <Hr />
                        </HorizontalView>
                      )}
                    </VerticalView>
                  );
                })}
            </HorizontalView>
          )}

          <Div fullWidth alignContent={'center'} paddingTop={'40px'}>
            <HomeButtonAction title={props.seeMore} onClick={handleSeeMore} />
          </Div>
        </VerticalView>
      </BodyWrapper>
    </CommonSection>
  );
});
