export enum IRole {
  User = 'USER',
  Admin = 'SUPER_ADMIN',
  Customer = 'CUSTOMER',
  Guest = 'GUEST',
}

export enum ISocialProvider {
  Mail = 'MAIL',
  Facebook = 'FACEBOOK',
  Line = 'LINE',
  Google = 'GOOGLE',
  Twitter = 'TWITTER',
  Apple = 'APPLE',
}

export enum ISortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum AccountStatus {
  inactive = 'inactive',
  active = 'active',
  restrict = 'restrict',
  blocked = 'blocked',
  suspended = 'suspended',
  notApproved = 'not_approved',
}

export enum VideoStatus {
  pending = 'pending',
  reject = 'reject',
  inactive = 'inactive',
  blocked = 'blocked',
  active = 'active',
}

export type ISendMultipleEmailInput = {
  to?: string[];
  isAll?: boolean;
  subject: string;
  body: string;
};

export type ISendMultipleEmailOutput = {
  processed?: number;
  failedEmail?: string[];
};
