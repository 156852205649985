import gql from 'graphql-tag';

export const GET = gql`
  query getArticle($input: GetArticleInput!) {
    getArticle(input: $input) {
      id
      title
      thumbnail
      cover
      status
      seoname
      category
      description
      content
      createdTime
      updatedTime
    }
  }
`;

export const OPERATION_SEARCH_ARTICLE = gql`
  query operationSearchArticles($input: OperationSearchArticlesInput!) {
    operationSearchArticles(input: $input) {
      totalItems
      items {
        id
        title
        thumbnail
        cover
        status
        seoname
        category
        description
        createdTime
        updatedTime
      }
    }
  }
`;

export const USER_SEARCH_ARTICLE = gql`
  query userSearchArticles($input: UserSearchArticlesInput!) {
    userSearchArticles(input: $input) {
      totalItems
      items {
        id
        title
        thumbnail
        cover
        seoname
        category
        description
        createdTime
        updatedTime
      }
    }
  }
`;
