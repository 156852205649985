import gql from 'graphql-tag';

export const CREATE = gql`
  mutation createHelp {
    createHelp
  }
`;

export const DELETE = gql`
  mutation deleteHelp($input: DeleteHelpInput!) {
    deleteHelp(input: $input)
  }
`;

export const UPDATE = gql`
  mutation updateHelp($input: UpdateHelpInput!) {
    updateHelp(input: $input)
  }
`;
