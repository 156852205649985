import React from 'react';
import { QHCWrapper } from '../../../vendors/CommonParts';
import { H3 } from '../../../vendors/CommonWrappers';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslationText } from 'locale';
import { VerticalView } from 'vendors/Wrapper';
import { Image } from 'vendors/Image';
import { useAppSetting } from 'hooks/useAppSetting';

const GobackText = styled.span`
  margin-top: 30px;
  font-size: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: calc(100vh - 320px);
  min-height: 300px;
`;

export const NotFoundContainer = () => {
  const { translation } = useTranslationText();
  const { appLayout } = useAppSetting();
  return (
    <QHCWrapper>
      <ContentWrapper>
        <Image
          width={appLayout.isMobile ? '200px' : '300px'}
          height={appLayout.isMobile ? '200px' : '300px'}
          src={'/images/nodata.png'}
          objectFit={'contain'}
        />
        <VerticalView paddingTop={'30px'}>
          <H3>{translation('page404.description')}</H3>
          <Link to="/">
            <GobackText>{translation('page404.goHome')}</GobackText>
          </Link>
        </VerticalView>
      </ContentWrapper>
    </QHCWrapper>
  );
};

export default NotFoundContainer;
