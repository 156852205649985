import React from 'react';
import { useAppSetting } from 'hooks/useAppSetting';
import { Div } from 'vendors/Wrapper';
import { SVGIcon } from 'vendors/Icon';

export const Arrow = (props: {
  type: 'next' | 'prev';
  top?: string;
  disable: boolean;
  show?: boolean;
  onClick: () => void;
}) => {
  const { appLayout } = useAppSetting();

  if (!props.show) {
    return null;
  }

  return (
    <Div
      position={'absolute'}
      top={appLayout.isMobile ? '125px' : '175px'}
      left={props.type === 'prev' ? '0px' : undefined}
      right={props.type === 'next' ? '0px' : undefined}
      width={'30px'}
      height={'30px'}
      borderRadius={'15px'}
      overflow={'hidden'}
      alignContent={'center'}
      onClick={props.onClick}
    >
      <Div
        zIndex={1}
        fullWidthHeight
        left={'0'}
        top={'0'}
        position={'absolute'}
        background={'black'}
        opacity={!props.disable ? 0.8 : 0.3}
      />
      <Div zIndex={2}>
        <SVGIcon name={props.type === 'next' ? 'arrow-right' : 'arrow-left'} color={'white'} size={'15px'} />
      </Div>
    </Div>
  );
};
