import gql from 'graphql-tag';

export const GET = gql`
  query getBusinessAccount($input: GetBusinessAccountInput!) {
    getBusinessAccount(input: $input) {
      id
      identityId
      snsId
      status
      companyId
      company {
        id
        identityId
        name
        status
        logo
        cover
        email
        description
        foundation
        representativeName
        postalCode
        stateOrCityName
        streetAddress
        buildingNameAndRoomName
        homephone
        telephone
        contact
        fax
        tax
        businessContent
        privacyPolicy
        termsOfService
        locationMapUrl
        iosAppUrl
        androidAppUrl
        createdBy
        updatedBy
        updatedTime
        createdTime
      }
      membership {
        id
        deviceType
        userId
        packageId
        transactionId
        isMembership
        isTrialPeriod
        price
        isAutoRenew
        purchaseDate
        expiresDate
        planDesc
        planName
        planPrice
        paymentServiceType
        localizedPlanDesc
        localizedPlanName
        localizedPlanPrice
      }
      name
      token
      avatar
      role
      email
      gender
      birthday
      address
      provider
      isVerified
      isFollowed
      oldPassword
      password
      reason
      cover
      totalFollowers
      totalFollowing
      totalLikes
      createdBy
      updatedBy
      passwordAttemptTimes
      updatedTime
      createdTime
    }
  }
`;

export const SEARCH = gql`
  query searchBusinessAccounts($input: SearchBusinessAccountsInput!) {
    searchBusinessAccounts(input: $input) {
      totalItems
      items {
        id
        snsId
        status
        companyId
        company {
          id
          name
          logo
        }
        name
        token
        avatar
        role
        email
        gender
        birthday
        address
        provider
        isVerified
        oldPassword
        password
        cover
        createdBy
        updatedBy
        passwordAttemptTimes
        updatedTime
        createdTime
      }
    }
  }
`;
