import React from 'react';
import { Routes, Route } from 'react-router';
import LoginContainer from '../../containers/user-container/login/LoginPage';

export const LoginRouter = () => {
  return (
    <Routes>
      <Route index element={<LoginContainer />} />
    </Routes>
  );
};
