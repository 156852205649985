import React from 'react';
import styled from 'styled-components';
import { Form, Formik, FormikProps } from 'formik';
import { AppColors } from 'helpers';
import { useTranslationText } from 'locale';
import { useAppSetting } from 'hooks/useAppSetting';
import { HorizontalView, VerticalView } from 'vendors/Wrapper';
import { Loading } from 'vendors/Loading';
import { Text } from 'vendors/Text';
import { CardWrapper } from 'vendors/CommonWrappers';
import { Input } from 'vendors/Input';
import { Button } from 'vendors/Button';
import { resetPasswordValidatorSchema } from 'validators/user';

export type ResetFormik = {
  password: string;
  confirmPassword: string;
};

type LoginPros = {
  isLoading: boolean;
  status: string;
  handleSubmitForm: (values: ResetFormik) => void;
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 320px;
  margin: auto;
`;

export const ResetPasswordComponent = (props: LoginPros) => {
  const { translation } = useTranslationText();
  const ref = React.useRef<FormikProps<ResetFormik>>(null);
  const { appLayout, appSize } = useAppSetting();

  React.useEffect(() => {
    document.title = 'Wakwork - ' + translation('resetPassword.title');
  }, []);

  return (
    <VerticalView width={'100vw'} height={'100vh'} alignContent={'center'} padding={'20px'} background={'#7398a5'}>
      <Loading loading={props.isLoading} />
      <ContentWrapper>
        <VerticalView>
          <Text textAlign={'center'} fontSize={appSize.h2} color={AppColors.WHITE} marginBottom={'20px'}>
            {translation('resetPassword.title')}
          </Text>
          <HorizontalView>
            <CardWrapper
              paddingTop={'10px'}
              paddingBottom={'20px'}
              width={appLayout.isMobile ? '300px' : '350px'}
              noShadow={true}
            >
              {props.status.length > 0 && (
                <VerticalView width={'100%'} alignContent={'center'}>
                  <Text>{props.status}</Text>
                </VerticalView>
              )}
              {props.status.length === 0 && (
                <Formik
                  innerRef={ref}
                  initialValues={{ password: '', confirmPassword: '' }}
                  validationSchema={resetPasswordValidatorSchema}
                  onSubmit={(values: ResetFormik) => {
                    props.handleSubmitForm(values);
                  }}
                >
                  {({ submitCount, errors, values, handleChange }) => {
                    return (
                      <Form>
                        <VerticalView width={'100%'} alignContent={'center'} paddingTop={'20px'}>
                          <HorizontalView fullWidth marginBottom="20px">
                            <Input
                              id={'password'}
                              fontSize={'13px'}
                              height={'40px'}
                              borderRadius={'5px'}
                              value={values.password}
                              background={AppColors.GRAY_LIGHT2}
                              placeholder={'resetPassword.password'}
                              type={'password'}
                              onChange={handleChange}
                              error={submitCount && errors.password ? translation(errors.password) : ''}
                            />
                          </HorizontalView>
                          <HorizontalView fullWidth>
                            <Input
                              id={'confirmPassword'}
                              fontSize={'13px'}
                              height={'40px'}
                              borderRadius={'5px'}
                              value={values.confirmPassword}
                              type={'password'}
                              background={AppColors.GRAY_LIGHT2}
                              placeholder={'resetPassword.confirmPassword'}
                              onChange={handleChange}
                              error={submitCount && errors.password ? translation(errors.password) : ''}
                            />
                          </HorizontalView>

                          <HorizontalView fullWidth paddingTop={'35px'}>
                            <Button
                              disabled={props.isLoading}
                              width={'100%'}
                              borderRadius={'5px'}
                              title={'resetPassword.reset'}
                              preixIcon={'sign-in-alt'}
                              background={'#7398a5'}
                              color={AppColors.WHITE}
                              paddingTop={'10px'}
                              paddingBottom={'10px'}
                              onClick={() => {
                                if (ref.current) {
                                  ref.current.handleSubmit();
                                }
                              }}
                            />
                          </HorizontalView>
                        </VerticalView>
                      </Form>
                    );
                  }}
                </Formik>
              )}
            </CardWrapper>
          </HorizontalView>
        </VerticalView>
      </ContentWrapper>
    </VerticalView>
  );
};
