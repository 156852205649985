import React from 'react';
import { Button } from 'vendors/Button';
import { History } from 'history';
import { HorizontalView, VerticalView } from 'vendors/Wrapper';
import { AppColors } from 'helpers';
import { useNavigate } from 'react-router';
import { useTranslationText } from 'locale';
import { ErrorMessage } from 'vendors/CommonWrappers';

type BottomBarProp = {
  path: string;
  value?: string;
  history?: History;
  children?: React.ReactNode;
  saving?: boolean;
  error?: boolean;
  onSave?: () => void;
};
export const BottomBar = (props: BottomBarProp) => {
  const navigate = useNavigate();
  const { translation } = useTranslationText();

  return (
    <HorizontalView fullWidth alignContent={'bottomBetween'} marginTop={'30px'} padding={'20px'}>
      <Button
        title={'back'}
        fontSize={'20px'}
        preixIcon={'arrow-left'}
        borderRadius={'5px'}
        paddingLeft={'10px'}
        paddingRight={'10px'}
        height={'40px'}
        color={'white'}
        background={AppColors.BLUE_BOLD}
        onClick={() => {
          navigate(props.path);
        }}
      />
      <>
        {props.onSave && (
          <VerticalView alignContent={'centerRight'}>
            <Button
              disabled={props.error}
              loading={props.saving}
              minWidth={'40px'}
              height={'40px'}
              borderRadius={'5px'}
              preixIcon={'save'}
              title={translation('eventDetails.save')}
              color={AppColors.WHITE}
              background={AppColors.GRASSY_GREEN}
              onClick={() => {
                props.onSave && props.onSave();
              }}
            />
            {props.error ? <ErrorMessage>{translation('invalidDataAlert.message')}</ErrorMessage> : null}
          </VerticalView>
        )}
        {props.children}
      </>
    </HorizontalView>
  );
};
