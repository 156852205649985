import React from 'react';
import Chart from 'react-google-charts';
import { BaseComponent } from 'components/common/components/BaseComponent';
import { useAppSetting } from 'hooks/useAppSetting';
import { useTranslationText } from 'locale';
import { PageBodyHeader } from 'components/common/components/Header/PageBodyHeader';
import moment from 'moment';
import { DatePicker } from 'antd';
import { Body, Div, HorizontalView, VerticalView } from 'vendors/Wrapper';
import { CardWrapper } from 'vendors/CommonWrappers';
import { Text } from 'vendors/Text';
import { ICreateInvoiceInput, ITransaction } from 'models/Transaction';
import { dateUtil } from 'helpers/date.utils';
import { numberWithCommas } from 'helpers/common.utils';
import { Button } from 'vendors/Button';
import { Loading } from 'vendors/Loading';

type StatisticComponentProps = {
  loading?: boolean;
  loadingInvoice: boolean;
  totalRevenue?: number;
  transations?: ITransaction[];
  data: (string | number)[][];
  from: string;
  to: string;
  newInvoice: (input: ICreateInvoiceInput) => void;
  onChangeDate: (from: string, to: string) => void;
};

const { RangePicker } = DatePicker;

export const CompanyStatisticComponent = (props: StatisticComponentProps) => {
  const { appLayout, appTheme, appSize } = useAppSetting();
  const { translation } = useTranslationText();
  const isMobile = appLayout.type === 'mobile';
  const options = {
    title: `${translation('revenue.total')}: ${numberWithCommas(props.totalRevenue)}${translation('yen')} `,
    legend: { position: 'bottom' },
  };

  return (
    <BaseComponent contentLoading={props.loading} documentTitle={translation(`revenue.title`)}>
      <VerticalView fullWidth alignContent={'topCenter'}>
        <PageBodyHeader title={translation(`revenue.title`)} />
        <Body>
          <VerticalView fullWidth alignContent="center" padding="20px">
            <RangePicker
              allowClear={false}
              defaultValue={[moment(props.from, 'YYYY/MM/DD'), moment(props.to, 'YYYY/MM/DD')]}
              format={'YYYY/MM/DD'}
              onChange={(values, formatString: string[]) => {
                props.onChangeDate(formatString[0], formatString[1]);
              }}
            />
          </VerticalView>
          <Chart chartType="LineChart" width="100%" height="400px" data={props.data} options={options} />
          {/* </CardWrapper> */}

          <VerticalView fullWidth paddingTop={'20px'} alignContent={'centerAround'}>
            {props.transations && (
              <HorizontalView width={isMobile ? '100%' : '80%'} padding={'15px'}>
                <CardWrapper width={'100%'} borderTop borderColor={appTheme.header.bg} borderRadius={'4px'}>
                  <VerticalView>
                    <VerticalView width="100%">
                      <HorizontalView fullWidth padding="10px">
                        <Div width="40%">
                          <Text fontSize={appSize.h3} fontWeight={500}>
                            {translation('revenue.date')}
                          </Text>
                        </Div>
                        <Div width="20%">
                          <Text fontSize={appSize.h3} fontWeight={500}>
                            {translation('revenue.platform')}
                          </Text>
                        </Div>
                        <Div width="20%">
                          <Text fontSize={appSize.h3} fontWeight={500}>
                            {translation('revenue.price')}
                          </Text>
                        </Div>
                        <Div width="20%">
                          <Text fontSize={appSize.h3} fontWeight={500}>
                            {translation('revenue.receipt')}
                          </Text>
                        </Div>
                      </HorizontalView>
                      {props.transations.map((item, index) => {
                        return (
                          <HorizontalView key={index} fullWidth paddingBottom="10px">
                            <VerticalView width="40%" padding="5px" alignContent="centerLeft">
                              <Text fontSize={appSize.h4}>
                                {dateUtil.dateFormat(item?.purchaseDate || '', 'YYYY/MM/DD HH:mm')}
                              </Text>
                              <Text fontSize={appSize.h3} fontWeight={500}>
                                {item.user?.name}
                              </Text>
                            </VerticalView>
                            <Div width="20%" padding="5px">
                              <Text fontSize={appSize.h4}>{item.device}</Text>
                            </Div>
                            <Div width="20%" padding="5px">
                              <Text fontSize={appSize.h4}>{numberWithCommas(item.price) + translation('yen')}</Text>
                            </Div>
                            <Div width="20%" padding="5px">
                              <Button
                                preixIcon="download"
                                fontSize="18px"
                                onClick={() => props.newInvoice({ transactionId: item.id })}
                              />
                            </Div>
                          </HorizontalView>
                        );
                      })}
                    </VerticalView>
                  </VerticalView>
                </CardWrapper>
              </HorizontalView>
            )}
          </VerticalView>
          <Loading overlay loading={props.loadingInvoice} />
        </Body>
      </VerticalView>
    </BaseComponent>
  );
};

export default React.memo(CompanyStatisticComponent);
