import { useChangeVideoStatus, useGetVideo, useUpdateVideo } from 'api/hooks/video/useVideoApi';
import { CMSVideoDetailComponent } from 'components/cms-interface/videos/detail';
import { IUpdateVideoInput, IVideo } from 'models/Video';
import React from 'react';
import { useParams } from 'react-router-dom';

export const CMSVideoDetailContainer = () => {
  const { videoId } = useParams();
  const { video, loading, getVideo } = useGetVideo();
  const { updateVideo } = useUpdateVideo();
  const { changeVideoStatus, loading: changeStatusLoading } = useChangeVideoStatus();

  React.useEffect(() => {
    if (videoId) {
      getVideo({ id: videoId });
    }
  }, [videoId]);

  const update = (input: IVideo) => {
    const video = {
      id: input.id,
      name: input.name,
      description: input.description,
      content: input.content,
    } as IUpdateVideoInput;

    updateVideo(video);
  };

  return (
    <CMSVideoDetailComponent
      loading={loading}
      changeStatusLoading={changeStatusLoading}
      video={video}
      onUpdate={update}
      onChangeStatus={(input) => changeVideoStatus(input)}
    />
  );
};
