import CompanyStatisticContainer from 'containers/cms-containers/statistic';
import React from 'react';
import { Routes, Route } from 'react-router';

export const StatisticsRouter = () => {
  return (
    <Routes>
      <Route path="/*" element={<CompanyStatisticContainer />} />
    </Routes>
  );
};
