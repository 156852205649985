import { transactionMutations } from 'api/graphql/transaction';
import { useTranslationText } from 'locale';
import { IError } from 'models/Models';
import { ICreateInvoiceInput, IInvoice } from 'models/Transaction';

import { useBaseMutation } from '../base/useBaseMutation';

export const useCreateInvoice = () => {
  const { getError } = useTranslationText();
  const { mutation, loading } = useBaseMutation<ICreateInvoiceInput, { createInvoice: IInvoice }>({
    mutation: transactionMutations.CREATE_INVOICE,
  });

  async function onNewInvoice(input: ICreateInvoiceInput): Promise<{ error?: IError; createInvoice?: IInvoice }> {
    const { data, error } = await mutation(input);

    if (error) {
      switch (error.code) {
        default:
          return { error: getError('SYSTEM_ERROR') };
      }
    } else {
      return { createInvoice: data?.createInvoice };
    }
  }
  return {
    loading,
    onNewInvoice,
  };
};
