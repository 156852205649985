import React, { useEffect } from 'react';
import { chatReducer, ChatStore, LIMIT_ROOM } from 'containers/cms-containers/chat/state';
import { useSession } from 'stores/userStore';
import { useSocket } from 'context/SocketContext';
import { IChatMessage, IRoom, SocketEvent } from '../type/Chat';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';

export const ChatContext = React.createContext<ChatStore>({} as ChatStore);
const initialState = {
  roomList: {
    loading: false,
    isNoRoom: false,
    rooms: [],
    page: 1,
    limit: LIMIT_ROOM,
    totalRoms: 0,
  },
  newMessages: [],
};
export const ChatProvider = (props: { children: React.ReactNode }) => {
  const { session } = useSession();
  const { socket } = useSocket();
  const navigate = useNavigate();
  const [state, dispatch] = React.useReducer(chatReducer, initialState);

  /**
   * Reset when user change
   * Ex: Event Login or logout
   */
  useEffect(() => {
    dispatch({ type: 'reset' });
  }, [session.user?.id]);

  /**
   * On listener event for my self
   */
  useEffect(() => {
    if (!session.user?.id) {
      return;
    }
    socket.on(session.user?.id, (data: { message: IChatMessage; type: SocketEvent }) => {
      switch (data.type) {
        case SocketEvent.ReceiveMessage:
          dispatch({ type: 'new-message', message: data.message });
          break;
      }
    });
    return () => {
      socket.off(session.user?.id, () => {});
    };
  }, [session.user?.id]);

  /**
   * Listener new messages added queue
   */
  useEffect(() => {
    const [newMessage] = state.newMessages;

    if (newMessage) {
      const hasRoom = state.roomList.rooms.some((r) => r.id === newMessage.roomId);
      if (hasRoom) {
        dispatch({ type: 'add-message', message: newMessage });
      } else {
        socket.emit(SocketEvent.GetRoom, { id: newMessage.roomId }, (data: { room: IRoom }) => {
          if (data.room) {
            dispatch({ type: 'add-rooms', rooms: [data.room], totalRoms: state.roomList.totalRoms + 1 });
          }
        });
      }
      dispatch({ type: 'shift' });

      if (!window.location.pathname.includes('/cms/chat')) {
        notification.open({
          key: newMessage.id,
          message: newMessage.user?.name || 'Message',
          description: newMessage.text,
          onClick: () => {
            navigate('/cms/chat/' + newMessage.roomId);
            notification.close(newMessage.id);
          },
        });
      }
    }
  }, [state.newMessages]);

  return (
    <ChatContext.Provider value={{ state: state ? state : initialState, dispatch }}>
      {props.children}
    </ChatContext.Provider>
  );
};
