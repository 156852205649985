import React, { memo, useState } from 'react';
import { Loading } from 'vendors/Loading';
import { IUser } from 'models/User';
import { NavigationBar } from 'components/common/components/NavigationBar';
import { Body, VerticalView } from 'vendors/Wrapper';
import { BaseComponent } from 'components/common/components/BaseComponent';
import { ResetPasswordModal } from './parts/ResetPasswordModal';
import { UserInformation } from './parts/UserInformation';
import { IChangeAccountStatusInput } from 'models/BusinessAccount';
import { MessageBox } from 'components/common/components/MessageBox';
import { useTranslationText } from 'locale';
import { Text } from 'vendors/Text';
import { formatTimeAgo } from 'helpers/date.utils';
import { AppColors } from 'helpers';

interface UserDetailsProps {
  loading: boolean;
  user?: IUser;
  changeStatusLoading: boolean;
  onUpdate: (user: IUser) => void;
  onChangePassword: (password: string) => void;
  onChangeStatus: (input: IChangeAccountStatusInput) => void;
}

export const UserDetailsComponent = (props: UserDetailsProps) => {
  const [showModalChangePass, setShowModalChangePass] = useState(false);
  const { translation } = useTranslationText();

  return (
    <BaseComponent>
      <>
        <NavigationBar header={'usersSearch.header'} path={'/cms/users'} background={'#EDEDED'} />
        <Body>
          <Loading loading={props.loading} />
          {props.user?.isDeleted && props.user.deletedAt && (
            <MessageBox title={translation('accountDelete.title')} message={translation('accountDelete.message')}>
              <VerticalView fullWidth>
                <Text fontSize="22px" color={AppColors.RED} fontWeight={500}>
                  {translation('accountDelete.timeLeft') + ': ' + formatTimeAgo(props.user.deletedAt)}
                </Text>
              </VerticalView>
            </MessageBox>
          )}
          <UserInformation
            user={props.user}
            changeStatusLoading={props.changeStatusLoading}
            onChangePassword={() => {
              setShowModalChangePass(true);
            }}
            onSave={props.onUpdate}
            onChangeStatus={props.onChangeStatus}
          />
          <ResetPasswordModal
            loading={props.loading}
            show={showModalChangePass}
            onClose={() => {
              setShowModalChangePass(false);
            }}
            onChange={(password) => {
              setShowModalChangePass(false);
              props.onChangePassword(password);
            }}
          />
        </Body>
      </>
    </BaseComponent>
  );
};

export default memo(UserDetailsComponent);
