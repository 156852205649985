import React from 'react';
import { chunkArray } from 'helpers/common.utils';

export const usePagination = (nextPage: number, totalPages: number) => {
  const [pages, setPages] = React.useState<number[]>([]);
  const [currentPage, setCurrentPage] = React.useState(0);

  React.useEffect(() => {
    if (totalPages > 1) {
      const pageIndexs: number[] = [];
      if (totalPages < 5) {
        const max = totalPages;
        for (let i = 0; i < max; i++) {
          pageIndexs.push(i);
        }
        setCurrentPage(nextPage - 1);
        setPages(pageIndexs);
      } else {
        const max = totalPages;
        for (let i = 0; i < max; i++) {
          pageIndexs.push(i);
        }

        const groupPages: number[][] = chunkArray(pageIndexs, 5);

        groupPages.forEach((pages) => {
          if (pages.includes(nextPage - 1)) {
            setPages(pages);
          }
        });
        setCurrentPage(nextPage - 1);
      }
    } else {
      setPages([]);
      setCurrentPage(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPages, nextPage]);

  return {
    pages,
    currentPage,
  };
};
