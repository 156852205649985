import { useBaseGetQuery } from 'api/hooks/base/useBaseGetQuery';
import { useBaseMutation } from 'api/hooks/base/useBaseMutation';
import { useBaseSearchQuery } from 'api/hooks/base/useBaseSearchQuery';
import { useTranslationText } from 'locale';
import { IChangeAccountStatusInput } from 'models/BusinessAccount';
import { IError } from 'models/Models';
import { VideoStatus } from 'models/types';
import { IChangeVideoStatusInput, ISearchVideosInput, IUpdateVideoInput, IVideo, IVideoShort } from 'models/Video';
import React from 'react';
import { openNotification } from 'vendors/Notification';
import { videoMutations, videoQueries } from 'api/graphql/video';

export const useSearchVideo = () => {
  const {
    onSearch,
    items,
    loading,
    page,
    totalItems,
    totalPages,
    onLoadMore,
    onChangeLimit,
    onChangePage,
    onDeleteItem,
  } = useBaseSearchQuery<ISearchVideosInput, IVideoShort>({
    fetchPolicy: 'cache-and-network',
    query: videoQueries.SEARCH,
  });

  React.useEffect(() => {
    onSearch({
      status: [VideoStatus.pending],
      limit: 100,
      page: 1,
    });
  }, []);

  return {
    searchVideo: onSearch,
    videos: items,
    loading,
    page,
    totalItems,
    totalPages,
    onLoadMore,
    onChangeLimit,
    onChangePage,
    onDeleteItem,
  };
};

export const useGetVideo = () => {
  const { item, getQuery, loading } = useBaseGetQuery<{ id: string }, IVideo>({
    fetchPolicy: 'cache-and-network',
    query: videoQueries.GET,
  });

  return {
    getVideo: getQuery,
    loading,
    video: item,
  };
};

export const useUpdateVideo = () => {
  const { mutation, loading } = useBaseMutation<IUpdateVideoInput, IVideo>({
    mutation: videoMutations.UPDATE,
  });

  return {
    updateVideo: mutation,
    loading,
  };
};

export const useDeleteVideo = () => {
  const { getError } = useTranslationText();
  const { mutation, loading } = useBaseMutation<{ id: string }, boolean>({
    mutation: videoMutations.DELETE,
  });

  async function deleteVideo(input: string): Promise<{ error?: IError; success?: boolean }> {
    const { data, error } = await mutation({ id: input });
    if (error) return { error: getError('User_DELETE_ERROR') };

    return { success: data };
  }
  return {
    deleteVideo,
    loading,
  };
};

export const useChangeVideoStatus = () => {
  const { translation } = useTranslationText();
  const { mutation, loading } = useBaseMutation<IChangeVideoStatusInput, boolean>({
    mutation: videoMutations.CHANGE_STATUS,
  });

  async function changeVideoStatus(params: IChangeAccountStatusInput) {
    const result = await mutation(params);
    if (!result.error) {
      openNotification({
        type: 'success',
        message: translation('changeStatus.success'),
      });
    } else {
      openNotification({
        type: 'error',
        message: translation('changeStatus.error'),
        description: result.error.code + (result.error?.title || ''),
      });
    }
  }

  return {
    changeVideoStatus,
    loading,
  };
};
