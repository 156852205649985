import React from 'react';
import styled from 'styled-components';
import { Modal } from 'vendors/Modal';
import { HorizontalView, VerticalView } from 'vendors/Wrapper';
import { AppColors, AppConst } from 'helpers';
import { useWindowDimensions } from 'vendors/Hooks/useWindow/useWindowDimensions';
import { Button } from 'vendors/Button';
import { Hr } from 'vendors/CommonParts';
import { Text } from 'vendors/Text';
import { TextAreaInput } from 'vendors/TextAreaInput';
import { useTranslationText } from 'locale';

export const ModalWrapper = styled.div``;
export const AlertModalWrapper = styled.div``;
export const ContentWrapper = styled.div``;
export const AddUserWrapper = styled.div``;

const Content = styled.div<{ width: string; height: string }>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background: ${AppColors.TRANSPARENT};
`;

const ChildrenWrapper = styled.div<{ disabled?: boolean; opacity?: number }>`
  z-index: 0;
  pointer-event: ${(props) => (props.disabled ? 'none' : 'auto')};
  opacity: ${(props) => props.opacity || 1};
`;

export const Action = {
  tapButtonClose: 'Close',
  tapButtonLeft: 'Left',
  tapButtonRight: 'Right',
};

interface ConfirmProps {
  disabled?: boolean;
  title?: string;
  rightText?: string;
  leftText?: string;
  children: React.ReactNode;
  onConfirm?: () => void;
}

export const Confirm = (props: ConfirmProps) => {
  const [show, setShow] = React.useState(false);
  return (
    <>
      <ChildrenWrapper
        opacity={props.disabled ? 0.3 : 1}
        disabled={props.disabled}
        onClick={(e) => {
          e.preventDefault();
          !props.disabled && setShow(true);
        }}
      >
        {props.children}
      </ChildrenWrapper>
      <ConfirmModal
        open={show}
        title={props.title}
        cancelTitle={props.leftText}
        confirmTitle={props.rightText}
        onClose={() => setShow(false)}
        onConfirm={() => {
          setShow(false);
          props.onConfirm && props.onConfirm();
        }}
      />
    </>
  );
};

interface Props {
  open: boolean;
  hasComment?: boolean;
  loading?: boolean;
  title?: string;
  cancelTitle?: string;
  confirmTitle?: string;
  onConfirm?: (comment?: string) => void;
  onClose: () => void;
}

export const ConfirmModal = (props: Props) => {
  const { isMobile } = useWindowDimensions();
  const { translation } = useTranslationText();
  const [comment, setComment] = React.useState('');

  return (
    <Modal
      transparent
      zIndex={AppConst.zIndex.header + 3000}
      backdropColor={'rgb(1,1,1,0.3)'}
      open={props.open}
      onClose={() => {
        props.onClose();
      }}
      backdrop
      closeOnBackDropClick
      bottom={isMobile ? '5px' : undefined}
    >
      <Content width={'100vw'} height={'auto'}>
        <VerticalView fullWidthHeight alignContent={'bottomCenter'}>
          <HorizontalView
            marginBottom={'20px'}
            width={isMobile ? '300px' : props.hasComment ? '550px' : '400px'}
            background={AppColors.WHITE}
            borderRadius={'10px'}
            alignContent={'center'}
            overflow={'hidden'}
          >
            <HorizontalView
              paddingTop={isMobile ? '0px' : '20px'}
              paddingBottom={isMobile ? '0px' : props.hasComment ? '0px' : '20px'}
            >
              <Text padding={'20px'} fontSize={'20px'} marginLeft={'10px'} color={AppColors.BLACK} textAlign={'center'}>
                {props.title ? translation(props.title) : null}
              </Text>
            </HorizontalView>
            {props.hasComment && (
              <HorizontalView fullWidth padding={'20px'}>
                <TextAreaInput
                  value={comment}
                  placeholder={'reason'}
                  width={'100%'}
                  height={'120px'}
                  onChange={(e) => setComment(e.target.value)}
                />
              </HorizontalView>
            )}

            <Hr />
            <Button
              marginTop={'5px'}
              marginBottom={'5px'}
              title={props.confirmTitle || 'YES'}
              width={'100%'}
              height={'50px'}
              borderRadius={'5px'}
              fontWeight={500}
              color={props.hasComment && comment.trim().length === 0 ? AppColors.GRAY_LIGHT : AppColors.BLUE}
              background={AppColors.WHITE}
              onClick={() => {
                if (props.hasComment && comment.trim().length === 0) {
                  return;
                }
                props.onConfirm && props.onConfirm(comment);
              }}
            />

            {!isMobile && (
              <VerticalView fullWidth>
                <Hr />

                <Button
                  marginTop={'5px'}
                  marginBottom={'5px'}
                  title={props.cancelTitle || 'YES'}
                  width={'100%'}
                  height={'50px'}
                  fontWeight={500}
                  borderRadius={'5px'}
                  color={AppColors.RED}
                  background={AppColors.WHITE}
                  onClick={props.onClose}
                />
              </VerticalView>
            )}
          </HorizontalView>
          {isMobile && (
            <HorizontalView paddingBottom={'20px'}>
              <Button
                title={props.cancelTitle || 'NO'}
                width={'300px'}
                height={'50px'}
                fontWeight={500}
                borderRadius={'10px'}
                color={AppColors.RED}
                background={AppColors.WHITE}
                onClick={props.onClose}
              />
            </HorizontalView>
          )}
        </VerticalView>
      </Content>
    </Modal>
  );
};
