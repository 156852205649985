import { BaseComponent } from 'components/common/components/BaseComponent';
import React from 'react';
import { NavigationBar } from 'components/common/components/NavigationBar';
import { Body, Div, VerticalView } from 'vendors/Wrapper';
import { Loading } from 'vendors/Loading';
import { Formik, FormikProps } from 'formik';
import { Input } from 'vendors/Input';
import { TextAreaInput } from 'vendors/TextAreaInput';
import { PrimaryButton } from 'components/common/components/Button/PrimaryButton';
import { ICreateBusinessAccountInput } from 'models/BusinessAccount';

type PropsType = {
  loading: boolean;
  onCreate: (values: ICreateBusinessAccountInput) => void;
};

export const CreateCompanyComponent = (props: PropsType) => {
  const ref = React.useRef<FormikProps<ICreateBusinessAccountInput>>(null);

  const onSave = () => {
    if (ref && ref.current) {
      ref.current.submitForm();
    }
  };
  return (
    <BaseComponent>
      <>
        <NavigationBar header={'company.create'} path={'/cms/customer'} background={'#EDEDED'} />
        <Body>
          <Loading loading={props.loading} />
          <VerticalView fullWidth paddingTop="20px">
            <Formik
              innerRef={ref}
              initialValues={{} as ICreateBusinessAccountInput}
              onSubmit={(values: ICreateBusinessAccountInput) => {
                props.onCreate(values);
              }}
            >
              {({ values, handleChange }) => {
                return (
                  <VerticalView fullWidthHeight>
                    <Div fullWidth marginTop={'15px'}>
                      <Input
                        required
                        name={'email'}
                        label={'company.email'}
                        fontSize={'15px'}
                        placeholder={'company.email'}
                        value={values.email}
                        background={'transparent'}
                        onChange={handleChange}
                      />
                    </Div>
                    <Div fullWidth marginTop={'15px'}>
                      <Input
                        required
                        name={'password'}
                        label={'createUser.password'}
                        type={'password'}
                        fontSize={'15px'}
                        placeholder={'createUser.password'}
                        value={values.password}
                        background={'transparent'}
                        onChange={handleChange}
                      />
                    </Div>
                    <Div fullWidth marginTop={'15px'}>
                      <Input
                        required
                        name={'confirmPassword'}
                        label={'createUser.confirmPassword'}
                        type={'password'}
                        fontSize={'15px'}
                        placeholder={'createUser.confirmPassword'}
                        value={values.confirmPassword}
                        background={'transparent'}
                        onChange={handleChange}
                      />
                    </Div>
                    <Div fullWidth marginTop={'15px'}>
                      <Input
                        required
                        name={'companyName'}
                        label={'company.companyName'}
                        fontSize={'15px'}
                        placeholder={'company.companyName'}
                        value={values.companyName}
                        background={'transparent'}
                        onChange={handleChange}
                      />
                    </Div>
                    <Div fullWidth marginTop={'15px'}>
                      <Input
                        required
                        name={'companyFoundation'}
                        label={'company.foundation'}
                        fontSize={'15px'}
                        placeholder={'company.foundation'}
                        value={values.companyFoundation}
                        background={'transparent'}
                        onChange={handleChange}
                      />
                    </Div>
                    <Div fullWidth marginTop={'15px'}>
                      <Input
                        required
                        name={'companyRepresentativeName'}
                        label={'company.representatorName'}
                        fontSize={'15px'}
                        placeholder={'company.representatorName'}
                        value={values.companyRepresentativeName}
                        background={'transparent'}
                        onChange={handleChange}
                      />
                    </Div>

                    <Div fullWidth marginTop={'15px'}>
                      <Input
                        required
                        name={'postalCode'}
                        label={'company.postalCode'}
                        fontSize={'15px'}
                        placeholder={'company.postalCode'}
                        value={values.postalCode}
                        background={'transparent'}
                        onChange={handleChange}
                      />
                    </Div>
                    <Div fullWidth marginTop={'15px'}>
                      <Input
                        required
                        name={'stateOrCityName'}
                        label={'company.city'}
                        fontSize={'15px'}
                        placeholder={'company.city'}
                        value={values.stateOrCityName}
                        background={'transparent'}
                        onChange={handleChange}
                      />
                    </Div>
                    <Div fullWidth marginTop={'15px'}>
                      <Input
                        required
                        name={'streetAddress'}
                        label={'company.streetAddress'}
                        fontSize={'15px'}
                        placeholder={'company.streetAddress'}
                        value={values.streetAddress}
                        background={'transparent'}
                        onChange={handleChange}
                      />
                    </Div>
                    <Div fullWidth marginTop={'15px'}>
                      <Input
                        required
                        name={'buildingNameAndRoomName'}
                        label={'company.building'}
                        fontSize={'15px'}
                        placeholder={'company.building'}
                        value={values.buildingNameAndRoomName}
                        background={'transparent'}
                        onChange={handleChange}
                      />
                    </Div>
                    <Div fullWidth marginTop={'15px'}>
                      <Input
                        required
                        name={'telephone'}
                        label={'company.phone'}
                        fontSize={'15px'}
                        placeholder={'company.phone'}
                        value={values.telephone}
                        background={'transparent'}
                        onChange={handleChange}
                      />
                    </Div>
                    <Div fullWidth marginTop={'15px'}>
                      <Input
                        required
                        name={'homephone'}
                        label={'company.homePhone'}
                        fontSize={'15px'}
                        placeholder={'company.homePhone'}
                        value={values.homephone}
                        background={'transparent'}
                        onChange={handleChange}
                      />
                    </Div>
                    <Div fullWidth marginTop={'15px'}>
                      <TextAreaInput
                        required
                        name={'businessContent'}
                        label={'company.bussinessContent'}
                        size={'15px'}
                        placeholder={'company.bussinessContent'}
                        value={values.businessContent}
                        background={'transparent'}
                        onChange={handleChange}
                      />
                    </Div>
                    <Div fullWidth marginTop={'15px'}>
                      <TextAreaInput
                        required
                        name={'companyDescription'}
                        label={'company.description'}
                        size={'15px'}
                        placeholder={'company.description'}
                        value={values.companyDescription}
                        background={'transparent'}
                        onChange={handleChange}
                      />
                    </Div>
                    <Div fullWidth marginTop={'20px'} alignContent={'centerRight'}>
                      <PrimaryButton icon={'plus'} title={'new'} onClick={onSave} />
                    </Div>
                  </VerticalView>
                );
              }}
            </Formik>
          </VerticalView>
        </Body>
      </>
    </BaseComponent>
  );
};

export default React.memo(CreateCompanyComponent);
