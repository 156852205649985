import gql from 'graphql-tag';

export const GET = gql`
  query getVideo($input: GetVideoInput!) {
    getVideo(input: $input) {
      id
      categoryId
      type
      status
      name
      description
      content
      reason
      period
      videoUrl
      videoRatio
      thumbnail
      uploader {
        id
        name
        avatar
      }
      salary {
        type
        fixed
        min
        max
      }

      company {
        id
        logo
        name
        status
        email
      }
      category {
        id
        status
        thumbnail
        locales {
          lang
          name
        }
      }
      features
      userId
      createdBy
      updatedBy
      updatedTime
      createdTime
    }
  }
`;

export const SEARCH = gql`
  query searchVideos($input: SearchVideosInput!) {
    searchVideos(input: $input) {
      items {
        id
        categoryId
        category {
          id
          status
          thumbnail
          locales {
            lang
            name
          }
        }
        type
        status
        userId
        uploader {
          id
          name
          avatar
        }
        name
        description
        videoUrl
        videoRatio
        period
        thumbnail
        createdBy
        updatedBy
        updatedTime
        createdTime
      }
      totalItems
    }
  }
`;
