import { musicQueries } from 'api/graphql/music';
import { useBaseSearchQuery } from 'api/hooks/base/useBaseSearchQuery';
import { IMusic, IOperationSearchMusicsInput } from 'models/Music';
import { useEffect } from 'react';

export const useOpertionSearchMusic = () => {
  const query = useBaseSearchQuery<IOperationSearchMusicsInput, IMusic>({
    defaulInput: {
      page: 1,
      limit: 30,
    } as IOperationSearchMusicsInput,
    fetchPolicy: 'cache-and-network',
    query: musicQueries.OPERATION_SEARCH,
  });

  useEffect(() => {
    query.onSearch(query.input);
  }, []);

  return {
    loading: query.loading,
    error: query.error,
    query: query.input,
    page: query.page,
    hasMore: query.hasMore,
    totalItems: query.totalItems,
    totalPages: query.totalPages,
    limit: query.limit,
    musics: query.items,
    onChangePage: query.onChangePage,
    onChangeLimit: query.onChangeLimit,
    onSearchMusic: query.onSearch,
    onDeleteMusicInList: query.onDeleteItem,
    onUpdateMusicInList: query.onUpdateItem,
  };
};
