import React from 'react';
import styled from 'styled-components';
import { Modal } from 'vendors/Modal';
import { Formik, FormikProps } from 'formik';
import { HorizontalView, VerticalView } from 'vendors/Wrapper';
import { Input } from 'vendors/Input';
import { useTranslationText } from 'locale';
import { PrimaryButton } from 'components/common/components/Button/PrimaryButton';
import { resetPasswordValidatorSchema } from '../../../../validators/user';

export const ModalWrapper = styled.div``;
export const AlertModalWrapper = styled.div``;
export const ContentWrapper = styled.div``;
export const AddUserWrapper = styled.div``;

interface Props {
  show: boolean;
  loading: boolean;
  onChange: (password: string) => void;
  onClose: () => void;
}

export type ChangePassFormik = {
  password: string;
  confirmPassword: string;
};

const Wrapper = styled.div``;
const Content = styled.div`
  min-width: 450px;
  min-height: 150px;
  padding: 20px;
`;

export const ResetPasswordModal = (props: Props) => {
  const ref = React.useRef<FormikProps<ChangePassFormik>>(null);
  const { translation } = useTranslationText();
  return (
    <Wrapper>
      <Modal
        title={translation('changePassword.title')}
        open={props.show}
        onClose={() => {
          props.onClose();
        }}
        closeOnBackDropClick
      >
        <Content>
          <HorizontalView fullWidthHeight alignContent={'center'} marginBottom={'10px'}>
            <Formik
              innerRef={ref}
              initialValues={{ password: '', confirmPassword: '' }}
              validationSchema={resetPasswordValidatorSchema}
              onSubmit={(values: ChangePassFormik) => {
                props.onChange(values.password);
              }}
            >
              {({ errors, values, submitCount, handleChange }) => {
                return (
                  <VerticalView fullWidth minWidth={'320px'}>
                    <Input
                      id={'password'}
                      label={'changePassword.newPassword'}
                      type={'password'}
                      value={values.password}
                      onChange={handleChange}
                      placeholder={'changePassword.newPassword'}
                      error={submitCount ? errors.password : undefined}
                    />
                    <HorizontalView fullWidth paddingTop={'20px'}>
                      <Input
                        id={'confirmPassword'}
                        label={'changePassword.confirmNewPassword'}
                        type={'password'}
                        value={values.confirmPassword}
                        onChange={handleChange}
                        placeholder={'changePassword.confirmNewPassword'}
                        error={submitCount ? errors.confirmPassword : undefined}
                      />
                    </HorizontalView>

                    <PrimaryButton
                      marginTop={'20px'}
                      icon={'save'}
                      disabled={props.loading}
                      title={'changePassword.save'}
                      onClick={() => {
                        if (ref.current) {
                          ref.current.handleSubmit();
                        }
                      }}
                    />
                  </VerticalView>
                );
              }}
            </Formik>
          </HorizontalView>
        </Content>
      </Modal>
    </Wrapper>
  );
};
