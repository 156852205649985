import React from 'react';
import { Routes, Route } from 'react-router';
import HomeContainer from '../../containers/user-container/home/HomePage';

export const HomeRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<HomeContainer />} />
    </Routes>
  );
};
