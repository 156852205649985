import { articleMutations, articleQueries } from 'api/graphql/article/index';
import {
  ICreateArticleInput,
  IArticle,
  IGetArticleInput,
  ISearchArticlesInput,
  IUpdateArticleInput,
  IDeleteArticleInput,
  IArticleShort,
} from '../../../models/Article';

import { IError } from 'models/Models';
import { useTranslationText } from 'locale';
import { useBaseSearchQuery } from 'api/hooks/base/useBaseSearchQuery';
import { useBaseGetQuery } from 'api/hooks/base/useBaseGetQuery';
import { useEffect, useMemo } from 'react';
import { ErrorCodes, ArticleCategory } from 'helpers/const';
import { useBaseMutation } from 'api/hooks/base/useBaseMutation';
import { ISortDirection } from 'models/types';

export const useCreateArticle = () => {
  const { getError } = useTranslationText();

  const { mutation, loading } = useBaseMutation<ICreateArticleInput, { operationCreateArticle: IArticle }>({
    mutation: articleMutations.CREATE,
  });

  async function onCreateArticle(category: string): Promise<{ error?: IError; article?: IArticle }> {
    const { data, error } = await mutation({ category });
    if (error || !data?.operationCreateArticle) {
      return { error: getError('ARTICLE_CREATE_ERROR') };
    }
    return { article: data?.operationCreateArticle };
  }

  return {
    loading,
    onCreateArticle,
  };
};

export const useUpdateArticle = () => {
  const { getError } = useTranslationText();

  const { mutation, loading } = useBaseMutation<IUpdateArticleInput, IArticle>({
    mutation: articleMutations.UPDATE,
  });

  async function onUpdateArticle(input: IUpdateArticleInput): Promise<{ error?: IError; article?: IArticle }> {
    const { data, error } = await mutation(input);
    if (error) return { error: getError('ARTICLE_UPDATE_ERROR') };

    return { article: data };
  }

  return {
    loading,
    onUpdateArticle,
  };
};

export const useDeleteArticle = () => {
  const { getError } = useTranslationText();

  const { mutation, loading } = useBaseMutation<IDeleteArticleInput, IArticle>({
    mutation: articleMutations.DELETE,
  });

  async function onDeleteArticle(input: IDeleteArticleInput): Promise<{ error?: IError; article?: IArticle }> {
    const { data, error } = await mutation(input);
    if (error) return { error: getError('ARTICLE_DELETE_ERROR') };

    return { article: data };
  }

  return {
    loading,
    onDeleteArticle,
  };
};

export const useGetArticle = (articleId?: string) => {
  const { getError } = useTranslationText();

  const query = useBaseGetQuery<IGetArticleInput, IArticle>({
    fetchPolicy: 'cache-and-network',
    query: articleQueries.GET,
  });

  const errorHanle = useMemo(() => {
    if (!query.loading && query.error) {
      switch (query.error.code) {
        case ErrorCodes.ERROR_CODE_ITEM_NOT_EXIST:
          return { error: getError('ARTICLE_IS_NOT_EXIST') };
        default:
          return { error: getError('SYSTEM_ERROR') };
      }
    }
    return null;
  }, [query.error]);

  useEffect(() => {
    if (articleId) {
      query.getQuery({ id: articleId });
    }
  }, [articleId]);

  return {
    loading: query.loading,
    error: errorHanle,
    article: query.item,
  };
};

export const useOperationSearchArticles = () => {
  const query = useBaseSearchQuery<ISearchArticlesInput, IArticleShort>({
    defaulInput: {
      page: 1,
      sortDirection: ISortDirection.Asc,
      limit: 30,
    } as ISearchArticlesInput,
    fetchPolicy: 'cache-and-network',
    query: articleQueries.OPERATION_SEARCH_ARTICLE,
  });

  /**
   * Call api
   */
  useEffect(() => {
    query.onSearch(query.input);
  }, []);

  return {
    loading: query.loading,
    error: query.error,
    query: query.input,
    page: query.page,
    hasMore: query.hasMore,
    totalItems: query.totalItems,
    totalPages: query.totalPages,
    limit: query.limit,
    articles: query.items,
    onChangePage: query.onChangePage,
    onChangeLimit: query.onChangeLimit,
    onSearchArticles: query.onSearch,
    onLoadMoreArticles: query.onLoadMore,
    onDeleteArticleInList: query.onDeleteItem,
  };
};

export const useSearchArticles = () => {
  const query = useBaseSearchQuery<ISearchArticlesInput, IArticleShort>({
    defaulInput: {
      page: 1,
      limit: 10,
      categories: [ArticleCategory.Notification],
    } as ISearchArticlesInput,
    isLoadForever: true,
    fetchPolicy: 'cache-and-network',
    query: articleQueries.USER_SEARCH_ARTICLE,
  });

  useEffect(() => {
    query.onSearch(query.input);
  }, []);

  return {
    loading: query.loading,
    error: query.error,
    query: query.input,
    page: query.page,
    hasMore: query.hasMore,
    totalItems: query.totalItems,
    totalPages: query.totalPages,
    limit: query.limit,
    articles: query.items,
    onChangePage: query.onChangePage,
    onChangeLimit: query.onChangeLimit,
    onSearchArticles: query.onSearch,
    onLoadMoreArticles: query.onLoadMore,
    onDeleteArticleInList: query.onDeleteItem,
  };
};
