import { useAppSetting } from 'hooks/useAppSetting';
import { useResize } from 'vendors/Hooks/useResize';
import React from 'react';

export const useMediaWidth = () => {
  const mediaboxRef = React.useRef(null);
  const { appLayout } = useAppSetting();
  const { width } = useResize(mediaboxRef);

  const getCardWidth = () => {
    let mediaWidth = '150px';
    if (appLayout.isMobile && width > 0) {
      if (width > 400) {
        mediaWidth = `${(width - 120) / 3}px`;
      } else {
        mediaWidth = `${(width - 100) / 2}px`;
      }
    } else {
      if ((width - 140) / 4 > 200) {
        mediaWidth = '200px';
      } else {
        mediaWidth = `${(width - 140) / 4}px`;
      }
    }
    return mediaWidth;
  };
  return {
    mediaWidth: getCardWidth(),
    mediaboxRef,
  };
};
