import * as mutations from './mutations';
import * as queries from './queries';

export const reportMutations = {
  ...mutations,
};

export const reportQueries = {
  ...queries,
};
