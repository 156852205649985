import React from 'react';
import { useApolloClient } from '@apollo/client';
import { openNotification } from 'vendors/Notification';
import { LoginComponent, LoginFormik } from '../../../components/user-interface/login';
import { delay } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useSession } from 'stores/userStore';
import { IRole } from 'models/types';

import { useLoginUser } from 'api/hooks/user/useUserApi';

export const LoginContainer = () => {
  const { loginUser } = useLoginUser();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const client = useApolloClient();
  const { dispatch } = useSession();

  const onLogin = async (values: LoginFormik) => {
    try {
      await client.clearStore();
      setLoading(true);
      const loginData = await loginUser(values);

      if (loginData.user) {
        localStorage.setItem('SESSION_TOKEN', loginData.user?.token || '');
        dispatch({
          type: 'update',
          payload: { user: loginData.user, isAuthenticated: true, token: loginData.user?.token || null },
        });
      }

      await delay(() => {
        setLoading(false);
        if (!loginData.error) {
          if (loginData.user?.role === IRole.Admin) {
            navigate('/cms/users');
          } else {
            navigate('/user/me');
          }
        } else {
          openNotification({
            type: 'error',
            message: loginData.error?.title,
            description: loginData.error?.message,
          });
          client.resetStore();
        }
      }, 1000);
    } catch (error) {}
  };

  return <LoginComponent isLoading={loading} handleSubmitForm={onLogin} />;
};

export default LoginContainer;
