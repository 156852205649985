import { DocDownloadedContainer } from 'containers/cms-containers/doc-downloaded';
import React from 'react';
import { Routes, Route } from 'react-router';

export const DocDownloadedRoutes = () => {
  return (
    <Routes>
      <Route index element={<DocDownloadedContainer />} />
    </Routes>
  );
};
