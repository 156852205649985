import React from 'react';
import styled from 'styled-components';
import { AppColors, AppSizes } from 'helpers';
import { RowCardExplain, RowCardNormal, RowProps } from './Row';
import { Text } from 'vendors/Text';
import { HorizontalView, VerticalView } from 'vendors/Wrapper';
import { Hr } from 'vendors/CommonParts';
import { CssProps } from 'models/Help';
import { useNavigate } from 'react-router-dom';

export const CardWrapper = styled.div`
  ${(props: ExplainCardProps) => {
    const style = `
    ${props.width ? `width:${props.width};` : ''}
    ${props.minWidth ? `min-width:${props.minWidth};` : ''}
    ${props.height ? `height:${props.height};` : ''}
    ${props.color ? `color:${props.color};` : ''}
    ${props.background ? `background:${props.background};` : ''}
    ${props.margin ? `margin:${props.margin};` : ''}
    ${props.marginTop ? `margin-top:${props.marginTop};` : ''}
    ${props.marginLeft ? `margin-left:${props.marginLeft};` : ''}
    ${props.marginRight ? `margin-right:${props.marginRight};` : ''}
    ${props.marginBottom ? `margin-bottom:${props.marginBottom};` : ''}
    ${props.padding ? `padding:${props.padding};` : ''}
    ${props.paddingLeft ? `padding-left:${props.paddingLeft};` : ''}
    ${props.paddingRight ? `padding-right:${props.paddingRight};` : ''}
    ${props.paddingTop ? `padding-top:${props.paddingTop};` : ''}
    ${props.paddingBottom ? `padding-bottom:${props.paddingBottom};` : ''}
`;
    return style;
  }};
`;

const ExplainHeaderWrapper = styled.div`
  width: 100%;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: bold;
  background: ${AppColors.GRAY_LIGHT};
  font-size: ${AppSizes.font.size18}px;
`;

const RowWrapper = styled.div`
  width: 100%;
`;

type ExplainCardProps = CssProps & {
  title?: string;
  menus?: Array<RowProps>;
};
export const ExplainCard = (props: ExplainCardProps) => {
  return (
    <CardWrapper {...props}>
      <ExplainHeaderWrapper>
        <Text>{props.title}</Text>
      </ExplainHeaderWrapper>
      <VerticalView width={'100%'}>
        {props.menus &&
          props.menus.map((row, index) => {
            return (
              <RowWrapper key={`${index}`}>
                <RowCardExplain title={row.title} subRows={row.subRows} isBorder={true} />
              </RowWrapper>
            );
          })}
      </VerticalView>
    </CardWrapper>
  );
};

type BasicCardProps = CssProps & {
  icon?: string;
  title?: string;
  menus?: Array<RowProps>;
};

export const BasicCard = (props: BasicCardProps) => {
  const navigate = useNavigate();
  return (
    <CardWrapper {...props}>
      {/* <ExplainHeaderWrapper> */}
      <HorizontalView width="100%" alignContent={'centerLeft'}>
        <Text fontSize={'16px'}>{props.title}</Text>
      </HorizontalView>
      {/* </ExplainHeaderWrapper> */}
      <Hr />
      <VerticalView width={'100%'} padding={'5px'}>
        {props.menus &&
          props.menus.map((row, index: number) => {
            return (
              <RowWrapper key={`${index}`}>
                <RowCardNormal
                  url={row.url}
                  index={index + 1}
                  title={row.title}
                  icon={'arrow-right'}
                  onClick={() => {
                    navigate(row.url!);
                  }}
                />
              </RowWrapper>
            );
          })}
      </VerticalView>
    </CardWrapper>
  );
};

export default ExplainCard;
