import { membershipMutations } from 'api/graphql/membership';
import { useBaseMutation } from 'api/hooks/base/useBaseMutation';
import { useTranslationText } from 'locale';
import { IExtendMembershipInput, IMembership } from 'models/Membership';
import { openNotification } from 'vendors/Notification';

export const useExtendMembership = () => {
  const { translation } = useTranslationText();
  const { mutation, loading } = useBaseMutation<IExtendMembershipInput, IMembership>({
    mutation: membershipMutations.EXTEND_MEMBERSHIP,
  });

  async function onExtend(params: IExtendMembershipInput) {
    const result = await mutation(params);
    if (!result.error) {
      openNotification({
        type: 'success',
        message: translation('membership.extendSuccess'),
      });
    } else {
      openNotification({
        type: 'error',
        message: translation('membership.extendError'),
      });
    }
    return result;
  }

  return {
    loading,
    onExtendMembership: onExtend,
  };
};
