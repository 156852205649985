import React, { useEffect, useMemo, useRef, useState } from 'react';
import { GiftedChat, Send } from 'react-gifted-chat';
import { IUser } from 'models/User';
import { IRoom, IChatMessage } from 'containers/cms-containers/chat/type/Chat';
import { useSession } from 'stores/userStore';
import { ChatComposer } from './parts/ChatComposer';
import { Spinner } from 'vendors/Spinner';
import { Div, HorizontalView } from 'vendors/Wrapper';
import { Label } from 'vendors/Label';
import { RenderMessageText } from './parts/RenderMessageText';
import { RenderDay } from './parts/RenderDay';
import { RenderBubble } from './parts/RenderBubble';
import { RenderAvatar } from './parts/RenderAvatar';
import { useTranslationText } from 'locale';

type ChattingComponentProps = {
  loading?: boolean;
  isLoadMore?: boolean;
  hasMore?: boolean;
  auth: IUser;
  conversation?: IRoom;
  messages: IChatMessage[];
  onVisiableInView: (message: IChatMessage) => void;
  onSend: (messages: IChatMessage[]) => void;
  onLoadEarlier: () => void;
};

export const ChattingComponent = (props: ChattingComponentProps) => {
  const { session } = useSession();
  const { getLanguage, translation } = useTranslationText();
  const [isSroll, setIsScroll] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const topMessage = useMemo(() => {
    if (props.messages?.length) {
      return props.messages[props.messages?.length - 1];
    }
    return null;
  }, [props.messages?.length]);

  useEffect(() => {
    if (!props.hasMore || props.isLoadMore) {
      return;
    }
    try {
      const el = document.querySelector(`[id="${topMessage?.id}"]`);
      if (el) {
        const top = el.getBoundingClientRect().top;
        if (top >= 0) {
          props.onLoadEarlier();
        }
      }
    } catch (error) {}
  }, [isSroll, topMessage, props.hasMore, props.isLoadMore]);

  if (props.loading) {
    return (
      <HorizontalView fullWidthHeight alignContent="topCenter" paddingTop="80px">
        <Div position="relative" height="60px">
          <Spinner loading size="40px" />
        </Div>
      </HorizontalView>
    );
  }

  return (
    <>
      {!props.messages?.length && (
        <HorizontalView fullWidth alignContent="topCenter" position="absolute" top="20px">
          <Label label="chat.noMessage" />
        </HorizontalView>
      )}
      <div
        ref={ref}
        style={{ flex: 1, flexGrow: 1, width: '100%', height: '100%' }}
        onScroll={() => setIsScroll(!isSroll)}
        id={'Main'}
      >
        <GiftedChat
          locale={getLanguage()}
          loadEarlier={props.hasMore}
          renderLoading={() => (
            <HorizontalView fullWidth position="relative" alignContent="center" height="60px">
              <Spinner loading />
            </HorizontalView>
          )}
          renderSend={(props) => {
            return <Send {...props} label={translation('chat.send')} />;
          }}
          isLoadingEarlier={props.isLoadMore}
          messages={props.messages}
          onSend={(messages) => props.onSend(messages as IChatMessage[])}
          user={{
            _id: session.user.id,
          }}
          maxComposerHeight={0}
          minComposerHeight={0}
          minInputToolbarHeight={0}
          renderComposer={ChatComposer}
          onLoadEarlier={props.onLoadEarlier}
          renderMessageText={RenderMessageText}
          renderDay={RenderDay}
          renderBubble={RenderBubble}
          renderAvatar={RenderAvatar}
        />
      </div>
    </>
  );
};
