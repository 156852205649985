import React from 'react';
import styled from 'styled-components';
import { MediaModal } from '../Modals/MediaModal';
import { Image } from 'vendors/Image';
import { CropRatio } from '../Modals/CropImageModal';
import { useTranslationText } from 'locale';
import { VerticalView } from '../../../../vendors/Wrapper/VerticalView';
import { Text } from 'vendors/Text';
import { Div } from '../../../../vendors/Wrapper/Wrapper';
import { ErrorMessage, MustLabel } from 'vendors/CommonWrappers';

const ImageWrapper = styled.div<{ borderRadius?: string; hideBorder?: boolean; width?: string; height?: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${(props) =>
    !props.hideBorder
      ? `
    padding: 10px;
    border-style: dashed;
    border-width: '3px';
    border-color: black;
  `
      : ''};
  width: ${(props) => props.width || '150px'};
  height: ${(props) => props.height || '150px'};
  border-radius: ${(props) => props.borderRadius || '0px'};
  overflow: hidden;
  cursor: pointer;
  opacity: 0.05;
  background: center / contain no-repeat url('/plus.png');
`;

export const ImagePicker2 = (props: {
  disable?: boolean;
  isPreview?: boolean;
  ratios?: CropRatio[];
  maxImageWidth?: number;
  value?: string;
  label?: string;
  width?: string;
  height?: string;
  objectFit?: 'contain' | 'cover';
  hideBorder?: boolean;
  required?: boolean;
  borderRadius?: string;
  error?: string;
  onSelect: (sound: string) => void;
}) => {
  const { translation } = useTranslationText();
  const [open, setOpen] = React.useState(false);

  return (
    <VerticalView fullWidth position={'relative'} alignContent={'centerLeft'} flexWrap={'nowrap'}>
      {props.label && (
        <Div marginBottom={'10px'}>
          <Text color={'black'} fontWeight={500} fontSize={'13px'}>
            {translation(props.label)} {props.required && <MustLabel>{'(*)'}</MustLabel>}
          </Text>
        </Div>
      )}
      {props.isPreview && (
        <Image
          width={props.width || '150px'}
          height={'100%'}
          src={props.value || ''}
          objectFit={props.objectFit || 'contain'}
        />
      )}
      {!props.isPreview && (
        <ImageWrapper
          hideBorder={props.hideBorder}
          borderRadius={props.borderRadius}
          width={props.width}
          height={props.height}
          onClick={(e) => {
            e.preventDefault();
            !props.disable && setOpen(true);
          }}
        >
          {props.value && props.value.length > 0 && (
            <Image width={'100%'} height={'100%'} src={props.value || ''} objectFit={props.objectFit || 'contain'} />
          )}
        </ImageWrapper>
      )}
      {!props.isPreview && props.error && <ErrorMessage>{`※ ${translation(props.error)}`}</ErrorMessage>}
      {!props.isPreview && open && (
        <MediaModal
          open={open}
          ratios={props.ratios}
          maxImageWidth={props.maxImageWidth}
          accept={'image'}
          onClose={() => setOpen(false)}
          onSelect={(url) => {
            setOpen(false);
            props.onSelect(url);
          }}
        />
      )}
    </VerticalView>
  );
};
