import React from 'react';
import { useAppSetting } from 'hooks/useAppSetting';
import { Button } from 'vendors/Button';
import { Div } from 'vendors/Wrapper';
import { AppColors } from 'helpers';

interface HeaderTitleProps {
  title: string;
  onClick: () => void;
}

export const HomeButtonAction = (props: HeaderTitleProps) => {
  const { appSize } = useAppSetting();
  return (
    <Div position={'relative'} alignContent={'center'}>
      <Button
        suffixIcon={'arrow-right'}
        title={props.title}
        fontSize={appSize.h2}
        paddingLeft={'55px'}
        paddingRight={'35px'}
        paddingTop={'10px'}
        paddingBottom={'10px'}
        background={AppColors.WHITE}
        color={AppColors.PRIMARY}
        border={`2px solid ${AppColors.PRIMARY}`}
        borderRadius={'20px'}
        onClick={props.onClick}
        css={`
          &: hover {
            background-color: ${AppColors.PRIMARY};
            color: ${AppColors.WHITE};
            opacity: 1;
          }
        `}
      />
    </Div>
  );
};
