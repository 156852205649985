import React from 'react';
import dayjs from 'dayjs';
import { useSearchTransaction } from 'api/hooks/transaction/useSearchTransaction';
import { useLogoutUser, useUpdateUser } from 'api/hooks/user/useUserApi';
import { UserProfileComponent } from 'components/user-interface/user-profile';
import { useNavigate } from 'react-router-dom';
import { useSession } from 'stores/userStore';
import { useCreateInvoice } from 'api/hooks/transaction/useCreateInvoice';
import { ICreateInvoiceInput } from 'models/Transaction';
import { openNotification } from 'vendors/Notification';
import { useCancelMembership } from 'api/hooks/membership/useCancelMembership';
import { IMembership } from 'models/Membership';
import { useUpdateBusinessUser } from 'api/hooks/business/useBusinessApi';
import { ICompany } from 'models/Company';
import { IUpdateBusinessAccountInput } from 'models/BusinessAccount';
import { IUpdateUserInput } from 'models/User';
import { useSearchLocation } from 'api/hooks/location/useSearchLocation';

export const UserProfileContainer = () => {
  const { loading, onCancelMembership } = useCancelMembership();
  const { session, dispatch } = useSession();
  const { onLogout } = useLogoutUser();
  const navigate = useNavigate();
  const { transactions, searchTransactions, total, loading: loadingTransaction } = useSearchTransaction();
  const { onNewInvoice, loading: loadingInvoice } = useCreateInvoice();
  const [startDate, setStartDate] = React.useState(dayjs().startOf('month').format('YYYY/MM/DD'));
  const [endDate, setEndDate] = React.useState(dayjs().endOf('month').format('YYYY/MM/DD'));
  const { onUpdateUserBusiness, loading: bussinessLoading } = useUpdateBusinessUser();
  const { onUpdateUser } = useUpdateUser();
  const { locations, searchLocation, loading: locationLoading } = useSearchLocation();

  const newInvoice = async (input: ICreateInvoiceInput) => {
    const result = await onNewInvoice(input);
    if (!result.error) {
      if (result.createInvoice) {
        window.open(result.createInvoice?.pdfLink);
      }
    } else if (result.error) {
      openNotification({
        type: 'error',
        description: 'error',
      });
    }
  };
  const getLocation = (code?: string) => {
    searchLocation({
      code,
      limit: 1,
      page: 1,
    });
  };
  const onUpdateUserBussiness = async (company: ICompany) => {
    const updateData = {
      id: session.profile?.id || '',
      // logo: company.logo,
      // cover: company.cover,
      companyName: company.name,
      companyDescription: company.description,
      companyFoundation: company.foundation,
      companyRepresentativeName: company.representativeName,
      postalCode: company.postalCode,
      stateOrCityName: company.stateOrCityName,
      streetAddress: '',
      buildingNameAndRoomName: company.buildingNameAndRoomName,
      homephone: company.homephone,
      telephone: company.telephone,
      businessContent: company.businessContent,
    } as IUpdateBusinessAccountInput;

    const result = await onUpdateUserBusiness(updateData);
    if (!result.error) {
      // dispatch({ type: 'update-profile', payload: { user: result.user! } });
      openNotification({
        type: 'success',
        message: '更新',
        description: '完成しました',
      });
    } else {
      openNotification({
        type: 'error',
        message: 'エラー',
        description: 'Error',
      });
    }
  };
  const updateUser = async (input: IUpdateUserInput) => {
    const result = await onUpdateUser(input);
    if (!result.error) {
      // dispatch({ type: 'update-profile', payload: { user: result.user! } });
      openNotification({
        type: 'success',
        message: '更新',
        description: '完成しました',
      });
    } else {
      openNotification({
        type: 'error',
        message: 'エラー',
        description: 'Error',
      });
    }
  };
  const onLogoutUser = async () => {
    await onLogout();
    navigate('/');
    dispatch({ type: 'logout' });
  };

  function getTransactions() {
    searchTransactions({
      from: startDate,
      to: endDate,
    });
  }

  React.useEffect(() => {
    getTransactions();
  }, [startDate, endDate]);

  function changeDate(from: string, to: string): void {
    setStartDate(from);
    setEndDate(to);
  }

  const onCancel = async (membership: IMembership) => {
    const result = await onCancelMembership({ id: membership.id });
    if (result.data) {
      dispatch({ type: 'update-membership', payload: { membership: result.data.cancelMembership } });
    }
  };

  return (
    <UserProfileComponent
      getLocation={getLocation}
      locations={locations}
      searchLocationLoading={locationLoading}
      updateUser={updateUser}
      cancelLoading={loading}
      onUpdateUserBussiness={onUpdateUserBussiness}
      bussinessLoading={bussinessLoading}
      newInvoice={newInvoice}
      loading={!session.profile}
      user={session.profile}
      onLogout={onLogoutUser}
      totalRevenue={total}
      from={startDate}
      loadingInvoice={loadingInvoice}
      to={endDate}
      loadingTransaction={loadingTransaction}
      transations={transactions}
      onChangeDate={changeDate}
      onCancelMembership={onCancel}
    />
  );
};
