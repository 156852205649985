import React, { useState } from 'react';
import { Div, HorizontalView, VerticalView } from 'vendors/Wrapper';
import { Text } from 'vendors/Text';
import { Button } from 'vendors/Button';
import { AppColors } from 'helpers';
import { useNavigate } from 'react-router-dom';
import { useAppSetting } from 'hooks/useAppSetting';
import { useTranslationText } from 'locale';
import { useSession } from 'stores/userStore';
import { Image } from 'vendors/Image';

type HeaderSearchBarProps = {
  isMobile: boolean;
};

export const MobileHeaderBar = (props: HeaderSearchBarProps) => {
  const [openMenu, setOpenMenu] = useState(false);
  const { appTheme, appSize } = useAppSetting();
  const navigate = useNavigate();
  const { translation } = useTranslationText();
  const { session } = useSession();
  if (!props.isMobile) {
    return null;
  }

  return (
    <VerticalView fullWidth>
      <HorizontalView fullWidth height="60px" alignContent="centerLeft">
        <Div fullHeight onClick={() => navigate('/')} paddingLeft={'10px'}>
          {/* <Image height="100%" src="/logo.png" objectFit="contain" /> */}
          <Text fontSize={appSize.h2} fontWeight={600} color={AppColors.PRIMARY}>
            {'WAKWORK'}
          </Text>
        </Div>
        <Div alignContent="centerRight" width="calc(100% - 136px)">
          <Button
            preixIcon={'menu'}
            fontSize={'30px'}
            color={appTheme.header.text}
            background={AppColors.TRANSPARENT}
            onClick={() => {
              setOpenMenu(!openMenu);
            }}
          />
          {session.isAuthenticated && session.profile && (
            <Div marginLeft={'10px'} paddingRight={'10px'} onClick={() => navigate('/user/me')}>
              <Image
                width="30px"
                height="30px"
                borderRadius="15px"
                objectFit="cover"
                src={session.profile.avatar || '/images/avatar.png'}
              />
            </Div>
          )}
        </Div>
      </HorizontalView>

      <VerticalView
        width="100%"
        background={AppColors.GRAY_BGR}
        display={openMenu ? 'flex' : 'none'}
        position="relative"
        zIndex={10}
      >
        <Div
          width="100%"
          height="40px"
          alignContent="center"
          onClick={() => {
            navigate('/employer');
            setOpenMenu(false);
          }}
        >
          <Text color={AppColors.TEXT} fontWeight={500} fontSize={appSize.h4}>
            {translation('forEmployer')}
          </Text>
        </Div>
        <Div fullWidth height="1px" background="#ddd" />
        <Div
          width="100%"
          height="40px"
          alignContent="center"
          onClick={() => {
            navigate('/employee');
            setOpenMenu(false);
          }}
        >
          <Text color={AppColors.TEXT} fontWeight={500} fontSize={appSize.h4}>
            {translation('forEmployee')}
          </Text>
        </Div>
        <Div fullWidth height="1px" background="#ddd" />
        {session.isLoaded && !session.isAuthenticated && (
          <>
            <Div
              width="100%"
              height="40px"
              alignContent="center"
              onClick={() => {
                navigate('login');
                setOpenMenu(false);
              }}
            >
              <Text color={AppColors.TEXT} fontWeight={500} fontSize={appSize.h4}>
                {translation('signIn')}
              </Text>
            </Div>
            <Div fullWidth height="1px" background="#ddd" />
          </>
        )}

        <Button
          width="100%"
          height="40px"
          fontWeight="normal"
          title={'close'}
          onClick={() => {
            setOpenMenu(false);
          }}
        />
      </VerticalView>
    </VerticalView>
  );
};
