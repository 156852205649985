import { downloadMutations } from 'api/graphql/download';
import { useBaseMutation } from '../base/useBaseMutation';

export const useDeleteDownloadRecord = () => {
  const { mutation, loading } = useBaseMutation<{ id: string }, boolean>({
    mutation: downloadMutations.DELETE,
  });

  return {
    deleteDownload: mutation,
    loading,
  };
};
