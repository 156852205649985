import React from 'react';
import { Routes, Route } from 'react-router';
import { CmsArticleDetailsPage } from 'containers/cms-containers/articles/detail';
import { CmsArticleSearchPage } from 'containers/cms-containers/articles/search';

export const ArticleRouter = () => {
  return (
    <Routes>
      <Route index element={<CmsArticleSearchPage />} />
      <Route path="/:articleId" element={<CmsArticleDetailsPage />} />
    </Routes>
  );
};
