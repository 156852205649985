import { useGetUserContent } from 'api/hooks/home/useHomeApi';
import { EmployeeComponent } from 'components/user-interface/employee';
import React from 'react';

export const EmployeeContainer = () => {
  const { userPage, loading } = useGetUserContent();
  return (
    <EmployeeComponent
      loading={loading}
      videos={userPage?.videos || []}
      news={userPage?.articles || []}
      slideImages={userPage?.slideImages}
    />
  );
};

export default EmployeeContainer;
