import { reportQueries } from 'api/graphql/report';
import { IReport, ISearchReportsInput } from 'models/Report';
import { useEffect } from 'react';
import { useBaseSearchQuery } from '../base/useBaseSearchQuery';

export const useSearchReports = () => {
  const {
    onSearch,
    items,
    totalItems,
    loading,
    onChangePage,
    onDeleteItem,
    page,
    limit,
    totalPages,
  } = useBaseSearchQuery<ISearchReportsInput, IReport>({
    query: reportQueries.SEARCH,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    onSearch({
      limit: 30,
      page: 1,
    });
  }, []);

  return {
    searchReports: onSearch,
    loading,
    reports: items,
    totalItems,
    totalPages,
    onChangePage,
    onDeleteItem,
    page,
    limit,
  };
};
