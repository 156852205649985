import React from 'react';
import styled from 'styled-components';
import { CmsSidebar } from './part/CmsSidebar';
import { useAppSetting } from 'hooks/useAppSetting';
import { HorizontalView } from 'vendors/Wrapper';
import CmsHeader from './part/CmsHeader';
import { CardWrapper } from 'vendors/CommonWrappers';
import { useLocation } from 'react-router-dom';

const MainWrapper = styled.div<{
  left: string;
  paddingTop: string;
  width: string;
  paddingBottom: string;
}>`
  paddingtop-top: ${(props) => props.paddingTop};
  margin-left: ${(props) => props.left};
  width: ${(props) => props.width};
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  padding-bottom: ${(props) => props.paddingBottom};
  position: relative;
`;

const IndexWrapper = styled.div`
  top: 0px;
  left: 0px;
  z-index: 999;
  width: 100vw;
`;

interface CMSIndexProps {
  children?: React.ReactNode;
}
export const CMSLayout = (props: CMSIndexProps) => {
  const { appLayout } = useAppSetting();
  const location = useLocation();
  const [isExplain, setExplain] = React.useState(true);
  const [isChat, setIsChat] = React.useState(false);

  React.useEffect(() => {
    setExplain(appLayout.type === 'mobile' ? false : true);
  }, [appLayout.type]);

  React.useEffect(() => {
    if (location.pathname.includes('/cms/chat')) {
      setIsChat(true);
    } else {
      setIsChat(false);
    }
  }, [location.pathname]);

  const isMobile = appLayout.isMobile;

  return (
    <IndexWrapper>
      {isMobile && <CmsHeader />}
      {!isMobile && (
        <CmsSidebar
          hide={isMobile}
          onExplain={(_isExplain) => {
            setExplain(_isExplain);
          }}
        />
      )}
      <MainWrapper
        left={appLayout.isMobile ? '0px' : isExplain ? appLayout.sidebar.maxWidth : appLayout.sidebar.minWidth}
        width={appLayout.isMobile ? '100vw' : isExplain ? appLayout.body.minWidth : appLayout.body.maxWidth}
        paddingTop={isMobile ? '190px' : '0px'}
        paddingBottom={isChat ? '0px' : '20px'}
      >
        <HorizontalView fullWidthHeight alignContent={'topCenter'} padding={isChat ? '0px' : '20px'}>
          <CardWrapper
            minHeight={!isMobile ? 'calc(100vh - 100px)' : 'calc(100vh - 80px)'}
            noShadow={isChat}
            padding={isChat ? '0px' : '20px'}
            paddingTop={isMobile ? '60px' : isChat ? '0px' : '20px'}
          >
            {props.children}
          </CardWrapper>
        </HorizontalView>
      </MainWrapper>
    </IndexWrapper>
  );
};
