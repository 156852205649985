import React from 'react';
import styled from 'styled-components';
import { HorizontalView } from 'vendors/Wrapper';
import { useWindowDimensions } from 'vendors/Hooks/useWindow/useWindowDimensions';
import { AppColors } from 'helpers';
import { IUser } from 'models/User';
import { IRoom } from 'containers/cms-containers/chat/type/Chat';
import { Routes, Route } from 'react-router';
import ChattingContainer from 'containers/cms-containers/chat/pages/chatting';
import { RoomList } from './parts/RoomList';

const ChatWrapper = styled.div`
  width: 100%;
  height: 100vh;
`;

type ComponentProps = {
  auth: IUser;
  loading: boolean;
  currentRoom?: IRoom;
  rooms: IRoom[];
  onSelect: (room: IRoom) => void;
  onLoadmoreRooms: () => void;
};

export const ConversationsComponent = (props: ComponentProps) => {
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.isMobile || windowDimensions.isTablet;

  return (
    <HorizontalView fullWidthHeight alignContent={'topCenter'}>
      <ChatWrapper>
        <HorizontalView fullWidthHeight alignContent={'topBetween'} flexWrap={'nowrap'}>
          <RoomList
            auth={props.auth}
            loading={props.loading}
            currentRoom={props.currentRoom}
            rooms={props.rooms}
            onSelect={props.onSelect}
            onLoadmore={props.onLoadmoreRooms}
          />
          <HorizontalView
            width={isMobile ? '100%' : 'calc(100vw - 250px)'}
            height={'calc(100vh - 70px)'}
            overflowY={'scroll'}
            alignContent={'topCenter'}
            background={AppColors.WHITE}
            position={'relative'}
          >
            <HorizontalView fullWidthHeight>
              <HorizontalView fullHeight width={'100%'}>
                <Routes>
                  <Route path="/:roomId" element={<ChattingContainer />} />
                </Routes>
              </HorizontalView>
            </HorizontalView>
          </HorizontalView>
        </HorizontalView>
      </ChatWrapper>
    </HorizontalView>
  );
};
