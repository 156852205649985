import React from 'react';
import styled from 'styled-components';
import { AppColors } from 'helpers';
import { HorizontalView, VerticalView } from 'vendors/Wrapper';
import { FlatList } from 'vendors/FlatList';
import { Image } from 'vendors/Image';
import { Hr } from 'vendors/CommonParts';
import { IConType, SVGIcon } from 'vendors/Icon';
import { Text } from 'vendors/Text';
import { Button } from 'vendors/Button';
import { FeatureModal } from './FeatureModal';
import { IPageFeature } from 'models/HomePage';

const CategoryWrapper = styled.div<{ isPreview?: boolean }>`
  width: 100%;
  min-width: 200px;
  cursor: ${(props) => (props.isPreview ? 'auto' : 'pointer')};
`;

type FeatureListProps = {
  isPreview?: boolean;
  features: IPageFeature[];
  onChange?: (features: IPageFeature[]) => void;
};

export const FeatureList = (props: FeatureListProps) => {
  const [open, setOpen] = React.useState(false);
  const [indexSelect, setIndexSelect] = React.useState(-1);
  const [features, setFeatures] = React.useState<IPageFeature[]>([]);

  React.useEffect(() => {
    setFeatures([...props.features]);
  }, [props.features]);

  return (
    <VerticalView fullWidth position={'relative'} alignContent={'centerLeft'} flexWrap={'nowrap'}>
      <FlatList
        values={features}
        direction={'row'}
        renderItem={(_feature: IPageFeature, index: number) => {
          return (
            <CategoryWrapper
              isPreview={props.isPreview}
              key={index}
              onClick={(e) => {
                e.preventDefault();
                setOpen(true);
                setIndexSelect(index);
              }}
            >
              <VerticalView fullWidth alignContent={'centerLeft'} padding={'10px'} paddingBottom={'15px'}>
                {_feature.thumbnail && _feature.thumbnail.includes('http') && (
                  <Image width={'150px'} objectFit={'contain'} src={_feature.thumbnail} />
                )}
                {_feature.thumbnail && !_feature.thumbnail.includes('http') && (
                  <SVGIcon color={AppColors.BLACK} size={'40px'} name={_feature.thumbnail as IConType} />
                )}

                <HorizontalView paddingTop={'10px'}>
                  <Text fontWeight={600} fontSize={'15px'}>
                    {_feature.title}
                  </Text>
                </HorizontalView>
                <HorizontalView fullWidth alignContent={'centerLeft'}>
                  <Text fontSize={'13px'}>{_feature.description}</Text>
                </HorizontalView>
              </VerticalView>
              {index !== features.length - 1 && <Hr />}
            </CategoryWrapper>
          );
        }}
      />

      <HorizontalView fullWidth alignContent={'centerLeft'}>
        <HorizontalView alignContent={'center'}>
          <Button
            preixIcon={'plus'}
            title={'new'}
            fontSize={'18px'}
            background={AppColors.RED2}
            color={AppColors.WHITE}
            paddingLeft={'10px'}
            paddingRight={'10px'}
            height={'40px'}
            onClick={() => {
              setIndexSelect(-1);
              setOpen(true);
            }}
          />
        </HorizontalView>
      </HorizontalView>

      <FeatureModal
        open={open}
        category={indexSelect !== -1 ? features[indexSelect] : null}
        onClose={() => {
          setOpen(false);
          setTimeout(() => {
            setIndexSelect(-1);
          }, 300);
        }}
        onChange={(category) => {
          if (indexSelect !== -1) {
            const _categories = [...features];
            _categories[indexSelect] = category;
            setFeatures(_categories);
            props.onChange && props.onChange(_categories);
          } else {
            const categoriesList = [...features, category];
            setFeatures([...features, category]);
            props.onChange && props.onChange(categoriesList);
          }
          setOpen(false);
          setTimeout(() => {
            setIndexSelect(-1);
          }, 300);
        }}
      />
    </VerticalView>
  );
};
