import React, { useEffect, useState } from 'react';
import { CheckoutSuccessComponent } from 'components/user-interface/checkout-success';
import { useVerifyMembership } from 'api/hooks/membership/useVerifyMembership';
import { IMessage } from 'models/Models';
import { IMembership } from 'models/Membership';
import { useSession } from 'stores/userStore';

export const CheckoutSuccessContainer = () => {
  const { dispatch } = useSession();
  const [mounted, setMounted] = useState(false);
  const { loading, onVerifyMembership } = useVerifyMembership();
  const [error, setError] = useState<IMessage | undefined>(undefined);
  const [membership, setMemberShip] = useState<IMembership | undefined>(undefined);

  const onVerify = async () => {
    const query = new URLSearchParams(window.location.search);
    const result = await onVerifyMembership({
      sessionId: query.get('sessionId') as string,
      packageId: query.get('packageId') as string,
    });
    setError(result.error);

    setMounted(true);

    if (result.data) {
      setMemberShip(result.data.verifyMembership);
      dispatch({ type: 'update-membership', payload: { membership: result.data.verifyMembership } });
    }
  };

  useEffect(() => {
    onVerify();
  }, []);

  return <CheckoutSuccessComponent loading={loading || !mounted} error={error} membership={membership} />;
};
