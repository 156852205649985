import React from 'react';
import { useTranslationText } from 'locale';
import { delay } from 'lodash';
import { ResetFormik, ResetPasswordComponent } from 'components/user-interface/reset-password';
import { openNotification } from 'vendors/Notification';
import { useResetPassword } from 'api/hooks/user/useUserApi';
import { IResetPasswordInput } from 'models/User';
import { useParams } from 'react-router-dom';

export const ResetPasswordContainer = () => {
  const { resetPassword, loading: resetLoading } = useResetPassword();
  const { translation } = useTranslationText();
  const [status, setStatus] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const { token } = useParams();

  return (
    <ResetPasswordComponent
      isLoading={resetLoading || loading}
      status={status}
      handleSubmitForm={async (values: ResetFormik) => {
        setLoading(true);
        const input = {
          token,
          password: values.password,
          confirmPassword: values.confirmPassword,
        } as IResetPasswordInput;
        const result = await resetPassword(input);
        if (!result.error) {
          await delay(() => {
            setLoading(false);
            setStatus(translation('resetPassword.completed'));
          }, 1000);
        } else {
          openNotification({
            type: 'error',
            message: '',
            description: result.error?.message || 'Reset password error',
          });
        }
        setLoading(false);
      }}
    />
  );
};

export default ResetPasswordContainer;
