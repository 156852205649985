import { HomeSettingContainer } from 'containers/cms-containers/setting';
import React from 'react';
import { Routes, Route } from 'react-router';
import { NotFoundContainer } from '../../containers/user-container/not-found/index';

export const HomeSettingRouter = () => {
  return (
    <Routes>
      <Route index element={<HomeSettingContainer />} />
      <Route path="*" element={<NotFoundContainer />} />
    </Routes>
  );
};
