import { useGetCompany } from 'api/hooks/company/useCompanyApi';
import React from 'react';
import { AboutComponent } from '../../../components/user-interface/about';

export const AboutContainer = () => {
  const { loading, company, onGetCompany } = useGetCompany();

  React.useEffect(() => {
    onGetCompany();
  }, []);

  return <AboutComponent loading={loading} company={company} />;
};

export default AboutContainer;
