import { BannerPreview } from 'components/common/components/Banner';
import { BaseComponent } from 'components/common/components/BaseComponent';
import { AppColors } from 'helpers';
import { useAppSetting } from 'hooks/useAppSetting';
import { useTranslationText } from 'locale';
import _ from 'lodash';
import { IGroupHelp, IGroupHelpItem } from 'models/Help';
import { IOptionType } from 'models/IOptionType';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Hr } from 'vendors/CommonParts';
import { Text } from 'vendors/Text';
import { Div, HorizontalView, VerticalView } from 'vendors/Wrapper';
import { RowCardExplain } from './part/Row';
import { SelectDropdown, SelectItem } from 'vendors/Select';
import { HelpSearchBar } from './part/HelpSearchBar';

const HelperContentWrapper = styled.div`
  width: 100%;
  border-radius: 5px;

  border: 1px solid ${AppColors.GRAY_LIGHT};
`;
type Props = {
  loading: boolean;
  groups: IGroupHelp[];
  onGetGroup: (lang: string) => void;
};
export const HelpComponent = (props: Props) => {
  const [lang, setLang] = useState('ja');
  const { appLayout, appSize } = useAppSetting();
  const { translation, getTypes } = useTranslationText();
  const helpCategories: IOptionType[] = getTypes('helpCategories');
  const userRoles: IOptionType[] = getTypes('userRoles');
  const langList = getTypes<SelectItem[]>('langStatus');

  return (
    <BaseComponent contentLoading={props.loading}>
      <Div fullWidth alignContent="center">
        <BannerPreview
          height="400px"
          banner={'/images/helps.jpeg'}
          title={appLayout.isMobile ? '' : translation(`helpTitle`)}
          description={translation('helpMessage')}
        >
          <HorizontalView fullWidth marginTop={'20px'} alignContent="center">
            <HelpSearchBar lang={lang} />
          </HorizontalView>
        </BannerPreview>
        <VerticalView fullWidth maxWidth={'1000px'} alignContent="center">
          <HorizontalView fullWidth alignContent="centerLeft" paddingRight="100px" paddingTop="50px">
            {langList && (
              <SelectDropdown
                width={'250px'}
                direction={'column'}
                value={lang}
                options={langList}
                onChange={(value) => {
                  setLang(value);
                  props.onGetGroup(value || 'vi');
                }}
              />
            )}
          </HorizontalView>

          <VerticalView fullWidthHeight alignContent="center">
            <VerticalView
              alignContent="center"
              fullWidth
              paddingTop={'40px'}
              padding={appLayout.isMobile ? '20px' : '0px'}
            >
              {props.groups &&
                props.groups.map((_group, index) => {
                  const roleType = userRoles.find((type) => type.value === _group.role);

                  const groupByCategory: { [key: string]: string } = {};
                  _group.helps?.forEach((help) => {
                    const category = help?.category || 'other';
                    groupByCategory[category] = category;
                  });

                  const groupDatas: { category: string; helps: IGroupHelpItem[] }[] = Object.keys(groupByCategory).map(
                    (category) => {
                      let helps = _group.helps?.filter((help) => help?.category === category) || [];
                      helps = _.orderBy(helps, ['createdTime'], ['asc']);

                      return {
                        category: category,
                        helps: helps as IGroupHelpItem[],
                      };
                    },
                  );

                  return (
                    <VerticalView fullWidth paddingBottom={'20px'} key={index} alignContent={'center'}>
                      <Text fontSize={appSize.h3} fontWeight={600} color={AppColors.DARK} marginBottom={'10px'}>
                        {roleType?.label}
                      </Text>

                      <HelperContentWrapper>
                        <VerticalView fullWidth>
                          {groupDatas.map((subGroup, _index) => {
                            const category = helpCategories.find((category) => category.value === subGroup.category);
                            const menus = subGroup.helps?.map((help) => {
                              const id = help.id;

                              return {
                                title: help?.title || '',
                                url: `/helps/${id}?lang=${lang}`,
                              };
                            });
                            return (
                              <HorizontalView fullWidth key={_index}>
                                <RowCardExplain
                                  title={category ? category?.label : helpCategories[helpCategories.length - 1].label}
                                  subRows={menus as any}
                                  isBorder={true}
                                />
                                {_index !== groupDatas.length - 1 && <Hr background={AppColors.GRAY_LIGHT} />}
                              </HorizontalView>
                            );
                          })}
                        </VerticalView>
                      </HelperContentWrapper>
                    </VerticalView>
                  );
                })}
            </VerticalView>
          </VerticalView>
        </VerticalView>
      </Div>
    </BaseComponent>
  );
};
