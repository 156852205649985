import React, { memo, ReactNode, useState } from 'react';
import styled from 'styled-components';
import * as colors from '../../helpers/colors';
import { SVGIcon } from '../Icon';

const DropZoneContent = styled.div`
  width: 100%;
  height: 100%;
`;

const DropZoneWrapper = styled.div<{
  isError?: boolean;
  isDrag?: boolean;
  hideBoder?: boolean;
  minHeight?: string;
  isLoading?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  min-height: ${(props) => (props.minHeight ? props.minHeight : 'auto')};
  background-color: ${(props) => (props.isDrag ? colors.GRAY_LIGHT : 'transparent')};
  border-style: dashed;
  border-width: ${(props) => (props.hideBoder ? '0px' : '3px')};
  border-color: ${(props) => {
    if (props.isDrag) return colors.GRAY_LIGHT;
    return props.isError ? colors.GRAY_DARK : colors.GRAY_LIGHT;
  }};
`;
const RemoveButton = styled.div`
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 30px;
  top: 30;
  width: 40px;
  height: 40px;
  border-radius: 50vh;
  cursor: pointer;
  &:hover {
    background: ${colors.GRAY_LIGHT};
  }
`;

export type DropZoneProps = {
  maxWidth?: string;
  maxHeight?: string;
  minHeight?: string;
  isLoading?: boolean;
  isError?: boolean;
  hideBorder?: boolean;
  hasRemove?: boolean;
  onDrop: (files: FileList) => void;
  onRemove?: () => void;
  children?: ReactNode;
};

export const DropZone = (props: DropZoneProps) => {
  const [isDrag, setIsDrag] = useState(false);
  return (
    <DropZoneWrapper
      isError={props.isError}
      isDrag={isDrag}
      hideBoder={props.hideBorder}
      isLoading={props.isLoading}
      minHeight={props.minHeight}
      onDragOver={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDrag(true);
      }}
      onDrop={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDrag(false);
        if (e.dataTransfer && e.dataTransfer.files) {
          props.onDrop(e.dataTransfer.files);
        }
      }}
      onDragLeave={() => {
        setIsDrag(false);
      }}
      onDragEnd={() => {
        setIsDrag(false);
      }}
    >
      {props.hasRemove && props.onRemove && (
        <RemoveButton
          onClick={(e) => {
            e.preventDefault();
            if (props.onRemove) {
              props.onRemove();
            }
          }}
        >
          <SVGIcon name={'close'} size={'25px'} color={'red'} />
        </RemoveButton>
      )}

      <DropZoneContent>{props.children}</DropZoneContent>
    </DropZoneWrapper>
  );
};

export default memo(DropZone);
