import React from 'react';
import { AppRoutes } from './routes';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { initI18next } from 'locale';

initI18next();

function App() {
  return <AppRoutes />;
}

export default App;
