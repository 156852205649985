import { global } from './global';
import { typeList } from './typeList';
import { ERROR_MESSAGE } from './errors';
import { success } from './success';
export const ja = {
  global: global,
  errors: ERROR_MESSAGE,
  types: typeList,
  success: success,
};
