import React from 'react';
import { Routes, Route } from 'react-router';
import CMSMediaListContainer from '../../containers/cms-containers/media';
import { NotFoundContainer } from '../../containers/user-container/not-found/index';

export const MediaRouter = () => {
  return (
    <Routes>
      <Route index element={<CMSMediaListContainer />} />
      <Route path="*" element={<NotFoundContainer />} />
    </Routes>
  );
};
