import * as React from 'react';
import styled from 'styled-components';

export type TextProps = {
  /**
   * Attribute
   */
  key?: string | number;
  id?: string;
  lineHeight?: string;

  /**
   * Text font
   */
  fontSize?: string;
  fontFamily?: string;
  fontWeight?: React.CSSProperties['fontWeight'];

  textAlign?: React.CSSProperties['textAlign'];

  /**
   * Text color background
   */
  color?: string;
  background?: string;

  /**
   * Text layout
   */
  width?: string;
  height?: string;
  borderRadius?: string;
  margin?: string;
  marginTop?: string;
  marginLeft?: string;
  marginRight?: string;
  marginBottom?: string;
  padding?: string;
  paddingLeft?: string;
  paddingRight?: string;
  paddingTop?: string;
  paddingBottom?: string;
  whiteSpace?: React.CSSProperties['whiteSpace'];
  textDecoration?: React.CSSProperties['textDecoration'];
  overflow?: React.CSSProperties['overflow'];
  textOverflow?: React.CSSProperties['textOverflow'];
  display?: React.CSSProperties['display'];
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  /**
   * Default style
   */
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

const Wrapper = styled.span<TextProps>`
  ${(props) => (props.lineHeight ? `line-height:${props.lineHeight}` : null)};
  ${(props) => (props.fontSize ? `font-size:${props.fontSize}` : null)};
  ${(props) => (props.fontFamily ? `font-family:${props.fontFamily}` : null)};
  ${(props) => (props.fontWeight ? `font-weight:${props.fontWeight}` : null)};
  ${(props) => (props.textAlign ? `text-align:${props.textAlign}` : null)};

  color: ${(props) => props.color || 'black'};
  background: ${(props) => props.background || 'transparent'};

  overflow: ${(props) => props.overflow || 'auto'};
  width: ${(props) => props.width || 'auto'};
  height: ${(props) => props.height || 'auto'};
  border-radius: ${(props) => props.borderRadius || 0};
  ${(props) => (props.margin ? `margin:${props.margin}` : null)};
  ${(props) => (props.marginTop ? `margin-top:${props.marginTop}` : null)};
  ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}` : null)};
  ${(props) => (props.marginRight ? `margin-right:${props.marginRight}` : null)};
  ${(props) => (props.marginBottom ? `margin-bottom:${props.marginBottom}` : null)};

  ${(props) => (props.padding ? `padding:${props.padding}` : null)};
  ${(props) => (props.paddingTop ? `padding-top:${props.paddingTop}` : null)};
  ${(props) => (props.paddingLeft ? `padding-left:${props.paddingLeft}` : null)};
  ${(props) => (props.paddingRight ? `padding-right:${props.paddingRight}` : null)};
  ${(props) => (props.paddingBottom ? `padding-bottom:${props.paddingBottom}` : null)};
  ${(props) => (props.textDecoration ? `text-decoration:${props.textDecoration}` : null)};
  ${(props) => (props.whiteSpace ? `white-space:${props.whiteSpace}` : null)};
  ${(props) => (props.whiteSpace ? `white-space:${props.whiteSpace}` : null)};
  ${(props) => (props.display ? `display:${props.display}` : null)};
  ${(props) => (props.textOverflow ? `text-overflow:${props.textOverflow}` : null)};
`;

export const Text = React.memo((props: TextProps) => {
  return (
    <Wrapper {...props} style={props.style} key={props.key} id={props.id} onClick={props.onClick}>
      {props.children}
    </Wrapper>
  );
});
