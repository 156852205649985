import React from 'react';
import { useSpring, animated } from 'react-spring';

type AnimationType = 'fadeIn' | 'fadeOut';
export type AnimationViewProps = {
  isLoading?: boolean;
  id?: string;
  width?: string;
  height?: string;
  duration?: number;
  animation?: AnimationType;
  children: React.ReactNode;
};

export const AnimationView = React.memo((props: AnimationViewProps) => {
  let style: any;
  if (props.isLoading) {
    return <></>;
  }
  const duration = props.duration === 0 ? props.duration : props.duration || 200;

  switch (props.animation) {
    case 'fadeIn':
      style = useSpring({ config: { duration }, opacity: 1, from: { opacity: 0 } });
      break;
    case 'fadeOut':
      style = useSpring({ config: { duration }, opacity: 0, from: { opacity: 1 } });
      break;
    default:
      style = useSpring({ config: { duration }, opacity: 1, from: { opacity: 0 } });
  }
  return (
    <animated.div id={props.id} style={{ ...style, width: props.width, height: props.height }}>
      {props.children}
    </animated.div>
  );
});
