import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslationText } from 'locale';
import { CmsHelpCenterDetail } from 'components/cms-interface/help-center/detail';
import { useGetHelp } from 'api/hooks/help-center/useGetHelp';
import { IHelp, IUpdateHelpInput } from 'models/Help';
import { useUpdateHelp } from 'api/hooks/help-center/useUpdateHelp';
import { openNotification } from 'vendors/Notification';

export const CMSHelpCenterDetailsContainer = () => {
  const { helpId } = useParams();
  const { translation } = useTranslationText();
  const [updateLoading, setUpdateLoading] = React.useState(false);
  const { help, loading } = useGetHelp(helpId);
  const { onUpdateHelp } = useUpdateHelp();

  return (
    <CmsHelpCenterDetail
      loading={loading}
      updateLoading={updateLoading}
      help={help || ({} as IHelp)}
      onUpdate={async (updateData) => {
        setUpdateLoading(true);
        const result = await onUpdateHelp({
          id: updateData.id,
          forRole: updateData.forRole,
          status: updateData.status,
          category: updateData.category,
          translations: updateData.translations,
        } as IUpdateHelpInput);
        if (result.error) {
          openNotification({ type: 'error', message: translation('error') });
        } else {
          setTimeout(() => {
            setUpdateLoading(false);
            openNotification({ type: 'success', message: translation('success') });
          }, 300);
        }
      }}
    />
  );
};

export default CMSHelpCenterDetailsContainer;
