import { helpQueries } from 'api/graphql/help-center';
import { IHelpItem, IOperationSearchHelpsInput } from 'models/Help';
import { useBaseSearchQuery } from '../base/useBaseSearchQuery';

export const useOperatorSearchHelp = () => {
  const { onSearch, loading, items, onDeleteItem } = useBaseSearchQuery<IOperationSearchHelpsInput, IHelpItem>({
    query: helpQueries.OPERATOR_SEARCH,
    fetchPolicy: 'cache-and-network',
  });

  return {
    onDeleteItem,
    searchHelp: onSearch,
    loading,
    helps: items,
  };
};
