import gql from 'graphql-tag';

export const GET = gql`
  query operationGetContact($input: OperationGetContactInput!) {
    operationGetContact(input: $input) {
      id
      name
      datetime
      phone
      email
      subject
      content
      createdTime
    }
  }
`;

export const SEARCH = gql`
  query operationSearchContacts($input: OperationSearchContactsInput!) {
    operationSearchContacts(input: $input) {
      totalItems
      items {
        id
        name
        email
        phone
        subject
        content
        datetime
        createdTime
        updatedTime
      }
    }
  }
`;

export const SEARCH_SCHEDULE_LIST = gql`
  query searchScheduleList($input: SearchScheduleListInput!) {
    searchScheduleList(input: $input) {
      totalItems
      items {
        id
        datetime
      }
    }
  }
`;
