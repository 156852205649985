import React from 'react';
import { mediaMutations } from 'api/graphql/media';
import { useBaseMutation } from 'api/hooks/base/useBaseMutation';

export const useDeleteMedia = () => {
  const [reloadAfterDeleted, setReloadAfterDeleted] = React.useState(false);
  const { mutation, loading } = useBaseMutation<{ id: string }, { deleteMedia: { id: string } }>({
    mutation: mediaMutations.DELETE_MEDIA,
  });

  const mediaDelete = async (id: string) => {
    try {
      await mutation({ id });
      setReloadAfterDeleted(true);
    } catch (error) {}
  };

  React.useEffect(() => {
    if (reloadAfterDeleted) {
      setReloadAfterDeleted(false);
    }
  }, [reloadAfterDeleted]);

  return { mediaDelete, loading, reloadAfterDeleted };
};
