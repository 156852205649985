import * as queries from './queries';
import * as mutations from './mutations';

export const transactionQueries = {
  ...queries,
};

export const transactionMutations = {
  ...mutations,
};
