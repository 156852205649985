import { AppColors, AppConst } from 'helpers';
import { useAppSetting } from 'hooks/useAppSetting';
import { useTranslationText } from 'locale';
import React from 'react';
import { Button } from 'vendors/Button';
import { Checkbox } from 'vendors/CheckBox';
import { Input } from 'vendors/Input';
import { Modal } from 'vendors/Modal';
import { Text } from 'vendors/Text';
import { TextLink } from 'vendors/TextLink';
import { Div, HorizontalView, VerticalView } from 'vendors/Wrapper';
import * as yup from 'yup';

type PropsType = {
  loading: boolean;
  success?: boolean;
  open: boolean;
  onClose: () => void;
  onSend: (name: string, email: string) => void;
};

export const DownloadDocModal = React.memo((props: PropsType) => {
  const [mail, setMail] = React.useState('');
  const [name, setName] = React.useState('');
  const [agree, setAgree] = React.useState(false);
  const [invalidEmail, setInvalidEmail] = React.useState(false);
  const { translation } = useTranslationText();
  const { appLayout, appSize } = useAppSetting();
  const schema = yup.object().shape({
    email: yup.string().email(),
  });

  React.useEffect(() => {
    if (mail.length > 0) {
      schema.isValid({ email: mail }).then((valid) => {
        setInvalidEmail(!valid);
      });
    } else setInvalidEmail(false);
  }, [mail]);

  return (
    <Div>
      <Modal
        title={translation('downloadDocModal.title')}
        open={props.open}
        zIndex={AppConst.zIndex.header + 60}
        onClose={() => {
          props.onClose();
        }}
        headerBackground={AppColors.PRIMARY}
        closeOnBackDropClick
      >
        <VerticalView width={appLayout.isMobile ? '300px' : '500px'} alignContent={'topCenter'} padding="30px">
          {props.success && (
            <Div fullWidth alignContent="center" marginBottom="20px">
              <Text fontSize={appSize.h3} textAlign={'center'}>
                {translation('downloadDocModal.successMessage')}
              </Text>
            </Div>
          )}
          {!props.success && (
            <Div fullWidth alignContent="center" marginBottom="20px">
              <Text fontSize={appSize.h3} textAlign={'center'} whiteSpace={'pre-wrap'}>
                {translation('downloadDocModal.description')}
              </Text>
            </Div>
          )}
          {!props.success && (
            <VerticalView fullWidth>
              <Div fullWidth marginBottom="20px">
                <Input
                  height="50px"
                  placeholder={'public.contact.contactName'}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  paddingLeft={'10px'}
                  paddingRight={'10px'}
                />
              </Div>
              <Div fullWidth>
                <Input
                  height="50px"
                  placeholder={'public.contact.contactEmail'}
                  value={mail}
                  onChange={(e) => setMail(e.target.value)}
                  paddingLeft={'10px'}
                  paddingRight={'10px'}
                  error={invalidEmail ? 'validator.emailInvalid' : undefined}
                />
              </Div>
            </VerticalView>
          )}
          {!props.success && (
            <VerticalView>
              <HorizontalView marginTop={'20px'} flexWrap={'nowrap'}>
                <Checkbox checked={agree} onChange={() => setAgree(!agree)} />
                <Text marginLeft={'5px'} fontSize={appSize.h5} fontWeight={600}>
                  {translation('public.contact.contactPolicyMessage')}
                </Text>
              </HorizontalView>

              <Div marginTop={'10px'}>
                <TextLink fontSize={appSize.h5} href={'/privacy'}>
                  {translation('privacyPolicy')}
                </TextLink>
              </Div>
              <Button
                loading={props.loading}
                marginTop={appLayout.isMobile ? '15px' : '25px'}
                title={'downloadDocModal.btnDownload'}
                padding={'10px 20px'}
                fontSize={appSize.h3}
                disabled={!(mail.length > 0 && name.length > 0 && !invalidEmail && agree)}
                fontWeight={600}
                background={AppColors.BLUE}
                color={AppColors.WHITE}
                borderRadius={'12px'}
                onClick={() => {
                  props.onSend(name, mail);
                }}
              />
            </VerticalView>
          )}
          {props.success && (
            <Button
              marginTop={appLayout.isMobile ? '15px' : '25px'}
              title={'close'}
              padding={'10px 20px'}
              fontSize={appSize.h3}
              fontWeight={600}
              background={AppColors.BLUE}
              color={AppColors.WHITE}
              borderRadius={'12px'}
              onClick={props.onClose}
            />
          )}
        </VerticalView>
      </Modal>
    </Div>
  );
});
