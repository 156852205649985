import { useLazyQuery, WatchQueryFetchPolicy } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { ISortDirection } from 'models/types';
import { useState, useEffect, useCallback } from 'react';
import { getTotalPages } from 'helpers/common.utils';
import { IError } from 'models/Models';

export function useBaseSearchQuery<TQuery = any, TData = any>(props: {
  defaulInput?: TQuery;
  isLoadForever?: boolean;
  sortDirection?: ISortDirection.Asc;
  fetchPolicy?: WatchQueryFetchPolicy;
  query: DocumentNode;
}) {
  const [items, setItems] = useState<Record<string, unknown>[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);

  const [input, setInput] = useState<Record<string, unknown>>(
    (props.defaulInput || {
      page: 1,
      sortDirection: props.sortDirection || ISortDirection.Asc,
      limit: 30,
      filter: {},
    }) as Record<string, unknown>,
  );

  const [searchQuery, { data, loading, error }] = useLazyQuery(props.query, { fetchPolicy: props.fetchPolicy });

  function onChangePage(page: number) {
    if (props.isLoadForever) {
      console.error(
        'Cannot call onChangePage when isLoadForever is true, Please set isLoadForever = false when load by page',
      );
      return;
    }
    setInput({ ...input, page: page });
    searchQuery({ variables: { input: { ...input, page: page } } });
  }

  function onChangeLimit(limit: number) {
    setInput({ ...input, page: 1, limit: limit });
    if (props.isLoadForever) {
      setItems([]);
    }
    searchQuery({ variables: { input: { ...input, page: 1, limit: limit } } });
  }

  const onSearch = useCallback(
    (input: Record<string, unknown>) => {
      setInput(input);
      searchQuery({ variables: { input } });
    },
    [input],
  );

  const getPages = () => {
    return getTotalPages(totalItems, input.limit as number);
  };

  const hasMoreData = getPages() > (input.page as number);

  const onLoadMore = useCallback(() => {
    if (!props.isLoadForever) {
      console.error(
        'Cannot call onLoadMore when isLoadForever is false, Please set isLoadForever = true when load forever tiem',
      );
      return;
    }
    if (hasMoreData) {
      const nextPage = (input.page as number) + 1;
      setInput({ ...input, page: nextPage });
      searchQuery({ variables: { input: { ...input, page: nextPage } } });
    }
  }, [input, items, hasMoreData]);

  const onDeleteItem = (item: Record<string, unknown>) => {
    setItems((items) => {
      return items.filter((it: Record<string, unknown>) => it.id !== item.id) || [];
    });
  };

  const onUpdateItem = (item: Record<string, unknown>) => {
    setItems((items) => {
      return (
        items.map((it: Record<string, unknown>) => {
          if (it.id === item.id) {
            return { ...it, ...item };
          }
          return it;
        }) || []
      );
    });
  };

  useEffect(() => {
    if (loading) {
      return;
    }
    if (data) {
      const keys = Object.keys(data);
      if (keys?.length) {
        const totalItems = data[keys[0]]?.totalItems || 0;
        const itemList = (data[keys[0]]?.items || []) as Record<string, unknown>[];
        setTotalItems(totalItems);

        if (!props.isLoadForever) {
          setItems(itemList);
        } else {
          const newItems = itemList.filter((item) => !items.some((it) => item.id === it.id)) || [];
          setItems([...items, ...newItems]);
        }
      } else {
        setTotalItems(0);
        if (!props.isLoadForever) {
          setItems([]);
        }
      }
    } else {
      setTotalItems(0);
      if (!props.isLoadForever) {
        setItems([]);
      }
    }
  }, [data, loading]);

  return {
    /**
     * Loading api
     */
    loading,
    /**
     * Error response
     */
    error: (error as Record<string, any>) as IError,
    /**
     * Input quey
     */
    input: input as TQuery,
    /**
     * Page
     */
    page: input.page as number,
    /**
     * Has more data
     */
    hasMore: hasMoreData,
    /**
     * Total all items
     */
    totalItems: totalItems,
    /**
     * Total all pages data
     */
    totalPages: getPages(),
    /**
     * Limit data (number item per page)
     */
    limit: input.limit as number,
    /**
     * Results items list
     */
    items: items as TData[],
    /**
     * Set Input query
     */
    setInput: setInput as (value: TQuery) => void,
    /**
     * Fn Change page
     * Call Fn when change limit ( item per page )
     */
    onChangePage,
    /**
     * Fn Change limit
     * Call Fn when change other page
     */
    onChangeLimit,
    /**
     * Fn Search items
     * Call Fn when fetch data from service
     * Note: If not call this fn the data allway is undefined or null
     */
    onSearch: onSearch as (value: TQuery) => void,
    /**
     * Fn Loadmore data.
     * Note: Check condition hasMore true or false before call this fn
     */
    onLoadMore,
    /**
     * Fn Delete item in list
     */
    onDeleteItem: onDeleteItem as (item: TData) => void,
    /**
     * Fn Delete item in list
     */
    onUpdateItem: onUpdateItem as (item: TData) => void,
  };
}
