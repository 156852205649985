import ResetPasswordContainer from 'containers/user-container/reset-password';
import React from 'react';
import { Routes, Route } from 'react-router';

export const ResetPasswordRoute = () => {
  return (
    <Routes>
      <Route path="/:token" element={<ResetPasswordContainer />} />
    </Routes>
  );
};
