import React from 'react';
import { Div, HorizontalView, VerticalView } from 'vendors/Wrapper';
import { useAppSetting } from 'hooks/useAppSetting';
import { Image } from 'vendors/Image';
import { AppColors } from 'helpers';
import { SVGIcon } from 'vendors/Icon';
import { PageTitle } from '../PageTitle';
import { IVideoShort } from 'models/Video';
import { DownloadAppModal } from '../Modals/DownloadAppModal';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

type PropsType = {
  title: string;
  videos: IVideoShort[];
  maxWidth?: string;
  padding?: string;
  paddingLeft?: string;
  paddingRight?: string;
  paddingTop?: string;
  paddingBottom?: string;
};

const TitleWrapper = styled.div<{ size?: string; color?: string }>`
  font-weight: 600;
  font-size: ${(props) => props.size || '24px'};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => props.color || 'black'};
`;

const DescriptionWrapper = styled.div<{ size?: string; color?: string }>`
  margin-top: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${(props) => props.size || '16px'};
  font-weight: 400;
  color: ${(props) => props.color || 'black'};
`;

export const VideoPreviewTab = (props: PropsType) => {
  const { appSize, appLayout } = useAppSetting();
  const isMobile = appLayout.width < 760;
  const width = isMobile ? appLayout.width / 3.1 : appLayout.width > 1200 ? 220 : appLayout.width / 5.55;
  const height = (width / 9) * 16;
  const [open, setOpen] = React.useState(false);
  const [videoId, setVideoId] = React.useState('');
  const navigate = useNavigate();

  return (
    <VerticalView
      fullWidth
      maxWidth={props.maxWidth}
      padding={props.padding}
      paddingTop={props.paddingTop || '30px'}
      paddingBottom={props.paddingBottom || '30px'}
      paddingLeft={props.paddingLeft || '10px'}
      paddingRight={props.paddingRight || '10px'}
      alignContent="topCenter"
    >
      <Div paddingBottom="20px">
        <PageTitle title={props.title} size={appSize.h1} />
      </Div>
      <HorizontalView
        fullWidth
        overflowX="scroll"
        flexWrap="nowrap"
        alignContent={props.videos.length > 3 ? 'centerLeft' : appLayout.isMobile ? 'centerBetween' : 'centerEvenly'}
      >
        {props.videos.map((item, index) => {
          return (
            <Div
              key={index}
              width={`${width}px`}
              minWidth={`${width}px`}
              height={`${height}px`}
              onClick={
                appLayout.isMobile
                  ? () => {
                      setOpen(true);
                      setVideoId(item.id);
                    }
                  : () => {
                      navigate(`/videos/${item.id}`);
                    }
              }
              position="relative"
              borderRadius="15px"
              overflow={'hidden'}
              marginRight="20px"
            >
              <Div
                position="absolute"
                fullWidthHeight
                right="0"
                top="0"
                background={AppColors.BLACK}
                zIndex={1}
                opacity={0.4}
              />
              <Div
                position="absolute"
                fullWidthHeight
                right="0"
                top="0"
                background="transparent"
                alignContent="center"
                zIndex={2}
              >
                <SVGIcon name="play" width="50px" height="50px" color={'white'} />
                <VerticalView
                  fullWidth
                  padding="10px"
                  position="absolute"
                  bottom="0"
                  zIndex={2}
                  flexWrap="nowrap"
                  overflowX="hidden"
                >
                  {item.name && (
                    <TitleWrapper size={appSize.h3} color={AppColors.WHITE}>
                      {item.name}
                    </TitleWrapper>
                  )}
                  {item.description && (
                    <DescriptionWrapper size={appSize.h4} color={AppColors.WHITE}>
                      {item.description}
                    </DescriptionWrapper>
                  )}
                </VerticalView>
              </Div>
              <Div position="absolute" fullWidthHeight right="0" top="0" zIndex={0}>
                <Image width="100%" height="100%" src={item.thumbnail} objectFit="cover" />
              </Div>
            </Div>
          );
        })}
      </HorizontalView>
      <DownloadAppModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onDetail={() => {
          navigate(`/videos/${videoId}`);
        }}
      />
    </VerticalView>
  );
};
