import React from 'react';
import Table from 'components/common/components/Table';
import { Confirm } from 'components/common/components/Modals/ConfirmModal';
import { useTranslationText } from 'locale';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { AppColors } from 'helpers';
import { Div, HorizontalView, VerticalView } from 'vendors/Wrapper';
import { Button } from 'vendors/Button';
import { Text } from 'vendors/Text';
import { SVGIcon } from 'vendors/Icon';
import { IVideoShort, IVideoType } from 'models/Video';
import { dateUtil } from 'helpers/date.utils';

export function columns(
  translation: (path: string) => string,
  onDelete: (id: string) => void,
  onDetails: (id: string) => void,
) {
  const tableColumns: Array<ColumnDescription<IVideoShort>> = [
    {
      dataField: 'id',
      text: 'ID',
      headerStyle: () => {
        return { width: '15%', textAlign: 'left', whiteSpace: 'unset', padding: '10px' };
      },
      formatter: (_, row: IVideoShort) => {
        return (
          <a
            href={`/cms/videos/${row.id}`}
            color={AppColors.PRIMARY}
            onClick={(event) => {
              event.preventDefault();
              onDetails(row.id);
            }}
          >
            <HorizontalView fullWidth alignContent={'centerBetween'} padding={'10px'} flexWrap={'nowrap'}>
              {row.id}
              <Div width={'30px'}>
                <SVGIcon size={'20px'} name={'arrow-right'} color={AppColors.PRIMARY} />
              </Div>
            </HorizontalView>
          </a>
        );
      },
    },

    {
      dataField: 'name',
      text: translation('usersSearch.name'),
      headerStyle: () => {
        return { fontSize: '13px', width: '25%', textAlign: 'left', padding: '10px' };
      },
      // eslint-disable-next-line react/display-name
      formatter: (_, row: IVideoShort) => {
        return (
          <HorizontalView fullWidth alignContent={'centerLeft'} padding={'5px'}>
            <Text color={AppColors.BLACK}> {row.name} </Text>
          </HorizontalView>
        );
      },
    },
    {
      dataField: 'uploader.name',
      text: translation('videoPage.userName'),
      headerStyle: () => {
        return { fontSize: '13px', width: '15%', textAlign: 'left', padding: '10px' };
      },
      formatter: (_, row: IVideoShort) => {
        return (
          <a
            href={
              row.type === IVideoType.Job
                ? `/cms/customer/detail/${row.uploader?.id}`
                : `/cms/users/${row.uploader?.id}`
            }
            color={AppColors.PRIMARY}
          >
            <HorizontalView fullWidth alignContent={'centerBetween'} padding={'10px'} flexWrap={'nowrap'}>
              {row.uploader?.name}
            </HorizontalView>
          </a>
        );
      },
    },
    {
      dataField: 'type',
      text: translation('videoPage.type.title'),
      headerStyle: () => {
        return { fontSize: '13px', width: '10%', textAlign: 'left', padding: '10px' };
      },
      formatter: (_, row: IVideoShort) => {
        return (
          <Text fontWeight={500} color={AppColors.DARK}>
            {translation(`videoPage.type.${row.type?.toLowerCase()}`)}
          </Text>
        );
      },
    },
    {
      dataField: 'createdTime',
      text: translation('videoPage.createdTime'),
      headerStyle: () => {
        return { fontSize: '13px', width: '15%', textAlign: 'left', padding: '10px' };
      },
      formatter: (_, row: IVideoShort) => {
        return (
          <Text fontWeight={500} color={AppColors.DARK}>
            {dateUtil.dateFormat(row.createdTime || '', 'YYYY/MM/DD HH:mm')}
          </Text>
        );
      },
    },
    {
      dataField: 'status',
      text: translation('videoPage.status.title'),
      headerStyle: () => {
        return { fontSize: '13px', width: '10%', textAlign: 'left', padding: '10px' };
      },
      // eslint-disable-next-line react/display-name
      formatter: (_, row: IVideoShort) => {
        return (
          <Text
            fontWeight={500}
            color={
              row.status === 'blocked'
                ? AppColors.RED
                : row.status === 'active'
                ? AppColors.GRASSY_GREEN
                : AppColors.ORANGE
            }
          >
            {translation(`videoPage.status.${row.status}`)}
          </Text>
        );
      },
    },
    {
      dataField: 'menu',
      csvExport: false,
      text: '',
      headerStyle: () => {
        return { fontSize: '13px', width: '100px', textAlign: 'right', padding: '10px' };
      },
      // eslint-disable-next-line react/display-name
      formatter: (_, row: IVideoShort) => {
        return (
          <HorizontalView width={'100px'} alignContent={'center'} padding={'5px'}>
            <Confirm
              title={'userList.alertDelete.message'}
              leftText={'userList.alertDelete.close'}
              rightText={'userList.alertDelete.delete'}
              onConfirm={() => {
                onDelete(row.id);
              }}
            >
              <Button
                width={'35px'}
                height={'35px'}
                preixIcon={'trash'}
                borderRadius={'4px'}
                color={AppColors.WHITE}
                background={AppColors.RED}
              />
            </Confirm>
          </HorizontalView>
        );
      },
    },
  ];

  return tableColumns;
}

interface TableProps {
  loading: boolean;
  videos: IVideoShort[];
  onCreate?: () => void;
  onDelete: (id: string) => void;
  onDetails: (id: string) => void;
}

export const WebTableView = (props: TableProps) => {
  const { translation } = useTranslationText();
  return (
    <VerticalView fullWidth minWidth={'1200px'}>
      <Table
        loading={props.loading}
        keyField={'id'}
        data={props.videos ? props.videos : []}
        bordered={false}
        striped={true}
        hover={true}
        condensed={true}
        columns={columns(translation, props.onDelete, props.onDetails)}
      />
    </VerticalView>
  );
};
