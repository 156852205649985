import { reportQueries } from 'api/graphql/report';
import { IReport } from 'models/Report';
import { useBaseGetQuery } from '../base/useBaseGetQuery';

export const useGetReport = () => {
  const { getQuery, loading, item } = useBaseGetQuery<{ id: string }, IReport>({
    query: reportQueries.GET,
    fetchPolicy: 'cache-and-network',
  });

  return {
    getReport: getQuery,
    loading,
    report: item,
  };
};
