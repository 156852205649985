import gql from 'graphql-tag';

export const UPDATE = gql`
  mutation updateCompany($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      name
      status
      logo
      cover
      email
      description
      foundation
      representativeName
      postalCode
      stateOrCityName
      streetAddress
      buildingNameAndRoomName
      homephone
      telephone
      contact
      fax
      tax
      businessContent
      privacyPolicy
      termsOfService
      locationMapUrl
      iosAppUrl
      androidAppUrl
      createdBy
      updatedBy
      updatedTime
      createdTime
    }
  }
`;
