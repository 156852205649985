import React from 'react';
import { useParams } from 'react-router-dom';
import { useOperationGetUser, useOperationResetUserPassword } from 'api/hooks/user/useUserApi';
import { openNotification } from 'vendors/Notification';
import { IUser } from 'models/User';
import { UserDetailsComponent } from '../../../components/cms-interface/users/UserDetailsComponent';
import { useOperationUpdateUser } from 'api/hooks/user/useUserApi';
import { useTranslationText } from 'locale';
import { useChangeAccountStatus } from 'api/hooks/business/useBusinessApi';

export const CMSUserDetailsContainer = () => {
  const { userId } = useParams();
  const { translation } = useTranslationText();
  const { onGetUser, user, loading } = useOperationGetUser();
  const { onUpdateUser, loading: updateLoading } = useOperationUpdateUser();
  const { onResetUserPassword, loading: resetLoading } = useOperationResetUserPassword();
  const { changeStatus, loading: changeStatusLoading } = useChangeAccountStatus();
  React.useEffect(() => {
    if (userId) {
      onGetUser({ id: userId });
    }
  }, [userId]);

  const onUpdate = async (user: IUser) => {
    const updateData = {
      id: user.id,
      isVerified: user.isVerified,
      name: user.name,
      avatar: user.avatar || '',
    };
    const result = await onUpdateUser(updateData);
    if (!result.error) {
      openNotification({
        type: 'success',
        message: translation('message.update'),
        description: translation('message.success'),
      });
    } else {
      openNotification({
        type: 'error',
        message: result.error?.title,
        description: result.error?.message,
      });
    }
  };

  const onResetPassword = async (password: string) => {
    const result = await onResetUserPassword({
      id: userId!,
      newPassword: password,
      confirmNewPassword: password,
    });
    if (!result.error) {
      openNotification({
        type: 'success',
        message: translation('message.update'),
        description: translation('message.success'),
      });
    } else {
      openNotification({
        type: 'error',
        message: result.error?.title,
        description: result.error?.message,
      });
    }
  };

  return (
    <UserDetailsComponent
      loading={loading || updateLoading || resetLoading}
      user={user || ({} as IUser)}
      onUpdate={onUpdate}
      onChangePassword={onResetPassword}
      changeStatusLoading={changeStatusLoading}
      onChangeStatus={(input) => changeStatus(input)}
    />
  );
};

export default CMSUserDetailsContainer;
