import { BaseComponent } from 'components/common/components/BaseComponent';
import { PageTitle } from 'components/common/components/PageTitle';
import { useAppSetting } from 'hooks/useAppSetting';
import { IProduct } from 'models/Transaction';
import React from 'react';
import { CardWrapper } from 'vendors/CommonWrappers';
import { Div, HorizontalView, VerticalView } from 'vendors/Wrapper';
import { Text } from 'vendors/Text';
import { useTranslationText } from 'locale';
import { Button } from 'vendors/Button';
import { AppColors } from 'helpers';
import { ProductModal } from './parts/ProductModal';
import { Confirm } from 'components/common/components/Modals/ConfirmModal';
import { numberWithCommas } from 'helpers/common.utils';

type PropsType = {
  loading: boolean;
  packages?: IProduct[];
  onCreate: (product: IProduct) => void;
  onUpdate: (product: IProduct) => void;
  onDelete: (product: IProduct) => void;
};

const PackageItem = (props: { product: IProduct; onClick: () => void; onDelete: () => void }) => {
  const { appSize } = useAppSetting();
  const { translation } = useTranslationText();
  return (
    <Div width={'100%'} marginBottom={'10px'}>
      <CardWrapper width="100%">
        <VerticalView fullWidth>
          <HorizontalView fullWidth marginBottom="10px">
            <Text fontSize={appSize.h3} fontWeight={600} marginRight={'20px'}>
              {translation('productPage.packageId')}
            </Text>
            <Text fontSize={appSize.h3}>{props.product.packageId}</Text>
          </HorizontalView>
          <HorizontalView fullWidth marginBottom="10px">
            <Text fontSize={appSize.h3} fontWeight={600} marginRight={'20px'}>
              {translation('productPage.platform')}
            </Text>
            <Text fontSize={appSize.h3}>{props.product.platform}</Text>
          </HorizontalView>
          <HorizontalView fullWidth marginBottom="10px">
            <Text fontSize={appSize.h3} fontWeight={600} marginRight={'20px'}>
              {translation('productPage.type')}
            </Text>
            <Text fontSize={appSize.h3}>{props.product.type}</Text>
          </HorizontalView>
          <HorizontalView fullWidth marginBottom="10px">
            <Text fontSize={appSize.h3} fontWeight={600} marginRight={'20px'}>
              {translation('productPage.name')}
            </Text>
            <Text fontSize={appSize.h3}>{props.product.name}</Text>
          </HorizontalView>
          <HorizontalView fullWidth marginBottom="10px">
            <Text fontSize={appSize.h3} fontWeight={600} marginRight={'20px'}>
              {translation('productPage.description')}
            </Text>
            <Text fontSize={appSize.h3}>{props.product.description}</Text>
          </HorizontalView>
          <HorizontalView fullWidth marginBottom="10px">
            <Text fontSize={appSize.h3} fontWeight={600} marginRight={'20px'}>
              {translation('productPage.price')}
            </Text>
            <Text fontSize={appSize.h3}>{numberWithCommas(props.product?.price)}</Text>
          </HorizontalView>
          <HorizontalView fullWidth alignContent="centerRight">
            <Button
              preixIcon={'edit'}
              title={'edit'}
              fontSize={'18px'}
              background={AppColors.PRIMARY}
              color={AppColors.WHITE}
              paddingLeft={'10px'}
              paddingRight={'10px'}
              marginRight={'20px'}
              height={'40px'}
              onClick={props.onClick}
            />
            <Confirm
              title={'productPage.alertDelete'}
              leftText={'close'}
              rightText={'delete'}
              onConfirm={() => {
                props.onDelete();
              }}
            >
              <Button
                preixIcon={'trash'}
                title={'delete'}
                fontSize={'18px'}
                background={AppColors.RED}
                color={AppColors.WHITE}
                paddingLeft={'10px'}
                paddingRight={'10px'}
                height={'40px'}
              />
            </Confirm>
          </HorizontalView>
        </VerticalView>
      </CardWrapper>
    </Div>
  );
};

export const ProductPageComponent = (props: PropsType) => {
  const [open, setOpen] = React.useState(false);
  const [indexSelect, setIndexSelect] = React.useState(-1);

  return (
    <BaseComponent contentLoading={props.loading}>
      <VerticalView fullWidth minHeight="500px" alignContent="topCenter" position="relative">
        <PageTitle title={'productPage.header'} />
        {props.packages && (
          <VerticalView fullWidth alignContent="topLeft" paddingBottom="50px">
            {props.packages.map((item, index) => {
              return (
                <PackageItem
                  key={index}
                  product={item}
                  onDelete={() => {
                    props.onDelete(item);
                  }}
                  onClick={() => {
                    setOpen(true);
                    setIndexSelect(index);
                  }}
                />
              );
            })}
          </VerticalView>
        )}
        <Div fullWidth alignContent={'centerRight'} position="absolute" bottom={'0px'}>
          <Button
            preixIcon={'plus'}
            title={'new'}
            fontSize={'18px'}
            background={AppColors.GRASSY_GREEN}
            color={AppColors.WHITE}
            paddingLeft={'10px'}
            paddingRight={'10px'}
            height={'40px'}
            onClick={() => {
              setIndexSelect(-1);
              setOpen(true);
            }}
          />
        </Div>
        <ProductModal
          open={open}
          product={indexSelect !== -1 && props.packages ? props.packages[indexSelect] : null}
          onChange={(product) => {
            if (indexSelect !== -1) {
              props.onUpdate(product);
            } else {
              props.onCreate(product);
            }
            setOpen(false);
            setTimeout(() => {
              setIndexSelect(-1);
            }, 300);
          }}
          onClose={() => {
            setOpen(false);
            setTimeout(() => {
              setIndexSelect(-1);
            }, 300);
          }}
        />
      </VerticalView>
    </BaseComponent>
  );
};
