import { useBaseGetQuery } from 'api/hooks/base/useBaseGetQuery';
import { useBaseMutation } from 'api/hooks/base/useBaseMutation';
import { useTranslationText } from 'locale';
import { ICompany, IUpdateCompanyInput } from 'models/Company';
import { IError } from 'models/Models';
import { companyMutations, companyQueries } from 'api/graphql/company/index';

export const useGetCompany = () => {
  const getCompanyResults = useBaseGetQuery<null, ICompany>({
    fetchPolicy: 'cache-and-network',
    query: companyQueries.GET,
  });

  return {
    loading: getCompanyResults.loading,
    company: getCompanyResults.item,
    onGetCompany: getCompanyResults.getQuery,
  };
};

export const useGetCompanyPrivacy = () => {
  const getCompanyResults = useBaseGetQuery<null, ICompany>({
    fetchPolicy: 'cache-and-network',
    query: companyQueries.GETPRIVACY,
  });

  return {
    loading: getCompanyResults.loading,
    company: getCompanyResults.item,
    onGetCompanyPrivacy: getCompanyResults.getQuery,
  };
};

export const useGetCompanyTermOfService = () => {
  const getCompanyResults = useBaseGetQuery<null, ICompany>({
    fetchPolicy: 'cache-and-network',
    query: companyQueries.GETTOS,
  });

  return {
    loading: getCompanyResults.loading,
    company: getCompanyResults.item,
    onGetCompanyTOS: getCompanyResults.getQuery,
  };
};

export const useGetCompanyComercialLaw = () => {
  const getCompanyResults = useBaseGetQuery<null, ICompany>({
    fetchPolicy: 'cache-and-network',
    query: companyQueries.GET_C_LAW,
  });

  return {
    loading: getCompanyResults.loading,
    company: getCompanyResults.item,
    onGetComLaw: getCompanyResults.getQuery,
  };
};

export const useGetFooterInfo = () => {
  const getCompanyResults = useBaseGetQuery<null, ICompany>({
    fetchPolicy: 'cache-and-network',
    query: companyQueries.GET_FOOTER,
  });

  return {
    company: getCompanyResults.item,
    onGetFooterInfo: getCompanyResults.getQuery,
  };
};

export const useUpdateCompany = () => {
  const { getError } = useTranslationText();
  const { mutation, loading } = useBaseMutation<IUpdateCompanyInput, ICompany>({
    mutation: companyMutations.UPDATE,
  });

  async function onUpdateCompany(input: IUpdateCompanyInput): Promise<{ error?: IError; company?: ICompany }> {
    const { error, data } = await mutation(input);
    if (error) return { error: getError('COMPANY_UPDATE_ERROR') };
    return { company: data };
  }

  return {
    loading,
    onUpdateCompany,
  };
};
