import React from 'react';
import { Div, HorizontalView, VerticalView } from 'vendors/Wrapper';
import { Text } from 'vendors/Text';
import { AppColors } from 'helpers';
import { useTranslationText } from 'locale';
import { useNavigate } from 'react-router-dom';
import { useAppSetting } from 'hooks/useAppSetting';
import { useSession } from 'stores/userStore';
import { Image } from 'vendors/Image';

type HeaderSearchBarProps = {
  isMobile: boolean;
};

export const WebHeaderBar = (props: HeaderSearchBarProps) => {
  const { appSize } = useAppSetting();
  const navigate = useNavigate();
  const { translation } = useTranslationText();
  const { session } = useSession();
  if (props.isMobile) {
    return null;
  }

  return (
    <Div fullWidth alignContent="center" background="white">
      <HorizontalView width="100%" maxWidth="1200px" height="60px" alignContent="centerBetween">
        {/* logo */}
        <VerticalView fullHeight onClick={() => navigate('/')} alignContent={'center'}>
          {/* <Image height="100%" src="/logo.png" objectFit="contain" /> */}
          <Text fontSize={appSize.h2} fontWeight={600} color={AppColors.PRIMARY}>
            {'WAKWORK'}
          </Text>
        </VerticalView>
        <HorizontalView>
          <Div onClick={() => navigate('/employer')}>
            <Text color={AppColors.TEXT} fontWeight={500} fontSize={appSize.h4}>
              {translation('forEmployer')}
            </Text>
          </Div>
          <Div marginLeft={'25px'} onClick={() => navigate('/employee')}>
            <Text color={AppColors.TEXT} fontWeight={500} fontSize={appSize.h4}>
              {translation('forEmployee')}
            </Text>
          </Div>
          {session.isAuthenticated && session.profile && (
            <Div marginLeft={'25px'} onClick={() => navigate('/user/me')}>
              <Image
                width="30px"
                height="30px"
                borderRadius="15px"
                objectFit="cover"
                src={session.profile.avatar || '/images/avatar.png'}
              />
            </Div>
          )}
          {session.isLoaded && !session.isAuthenticated && (
            <Div marginLeft={'25px'} onClick={() => navigate('/login')}>
              <Text color={AppColors.TEXT} fontWeight={500} fontSize={appSize.h4}>
                {translation('signIn')}
              </Text>
            </Div>
          )}
        </HorizontalView>
      </HorizontalView>
    </Div>
  );
};
