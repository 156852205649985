import React from 'react';
import { Stream } from '@cloudflare/stream-react';
import styled from 'styled-components';
import ReactPlayer from 'react-player/lazy';
import config from 'config';

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export type VideoProps = {
  src: string;
  height?: string;
};

export const VideoPlayer = (props: VideoProps) => {
  const ref = React.useRef<ReactPlayer | null>(null);
  const API_HOST_URL = config.API_HOST_URL;
  let src = props.src;
  if (src && !src.includes('http')) {
    src = API_HOST_URL + props.src;
  }

  return (
    <VideoWrapper>
      {src.includes('watch.videodelivery.net') && (
        <Stream
          width={'100%'}
          height={props.height || '350px'}
          responsive={false}
          controls
          src={src.split('/').pop()}
        />
      )}
      {!src.includes('watch.videodelivery.net') && (
        <ReactPlayer ref={ref} url={src} width="100%" height={props.height || '100%'} controls />
      )}
    </VideoWrapper>
  );
};
