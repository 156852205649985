import React from 'react';
import styled from 'styled-components';
import { Div, HorizontalView } from 'vendors/Wrapper';
import { Button } from 'vendors/Button';
import { Input } from 'vendors/Input';
import { AppColors } from 'helpers';
import { VerticalView } from 'vendors/CommonWrappers';
import { SVGIcon } from 'vendors/Icon';
import { useAppSetting } from 'hooks/useAppSetting';

export const SearchWrapper = styled.div`
  border: 1px solid ${AppColors.BORDER};
  border-radius: 4px;
  overflow: hidden;
`;

type SearchBarProps = {
  headerTitle?: string;
  placeholder: string;
  addTitle?: string;
  marginTop?: string;
  paddingLeft?: string;
  paddingRight?: string;
  marginBottom?: string;
  onSearch: (params: string) => void;
  onCreate?: () => void;
};

export const SearchBar = (props: SearchBarProps) => {
  const { appLayout } = useAppSetting();
  const [keyword, setKeyword] = React.useState('');

  return (
    <VerticalView
      fullWidth
      marginTop={props.marginTop || '20px'}
      marginBottom={props.marginBottom || '20px'}
      paddingLeft={props.paddingLeft}
      paddingRight={props.paddingRight}
      alignContent={'centerLeft'}
    >
      <Div fullWidth alignContent={'centerLeft'}>
        <h1>{props.headerTitle}</h1>
      </Div>
      <HorizontalView
        fullWidth
        marginTop={props.headerTitle ? (appLayout.isMobile ? '5px' : '15px') : '0px'}
        alignContent={'centerBetween'}
        paddingBottom={'10px'}
      >
        <SearchWrapper>
          <HorizontalView alignContent={'centerBetween'} fullWidthHeight>
            <Div width={'150px'}>
              <Input
                id={'keyword'}
                placeholder={props.placeholder}
                name={'keyword'}
                value={keyword}
                noBorder
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                onPressEnter={() => {
                  props.onSearch(keyword);
                }}
              />
            </Div>

            <Div
              height={'50px'}
              width={'60px'}
              background={AppColors.PRIMARY}
              alignContent="center"
              onClick={() => {
                props.onSearch(keyword);
              }}
            >
              <SVGIcon name={'search'} color={AppColors.WHITE} />
            </Div>
          </HorizontalView>
        </SearchWrapper>
        {props.onCreate && (
          <Div>
            <Button
              title={appLayout.isMobile ? undefined : props.addTitle}
              height={'50px'}
              width={appLayout.isMobile ? '50px' : 'auto'}
              preixIcon={'plus'}
              background={AppColors.RED2}
              color={AppColors.WHITE}
              borderRadius={'5px'}
              onClick={props.onCreate}
            />
          </Div>
        )}
      </HorizontalView>
    </VerticalView>
  );
};
