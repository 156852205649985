import { productQueries } from 'api/graphql/product';
import { useBaseSearchQuery } from 'api/hooks/base/useBaseSearchQuery';
import { IProduct, ISearchProductsInput } from 'models/Transaction';

export const useSearchProducts = () => {
  const { loading, onSearch, onDeleteItem, items } = useBaseSearchQuery<ISearchProductsInput, IProduct>({
    fetchPolicy: 'network-only',
    query: productQueries.SEARCH_PRODUCT,
  });

  return {
    packages: items,
    loading,
    searchProducts: onSearch,
    onDeleteItem,
  };
};
