import { useDeleteBusiness, useSearchBusiness } from 'api/hooks/business/useBusinessApi';
import { CompanyListComponent } from 'components/cms-interface/customer/list';
import { useTranslationText } from 'locale';
import { IUser } from 'models/User';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { openNotification } from 'vendors/Notification';

export const CompanyListContainer = () => {
  const { translation } = useTranslationText();
  const {
    input,
    loading,
    businesses,
    totalItems,
    totalPages,
    onDeleteItem,
    searchBusiness,
    onChangeLimit,
    onChangePage,
    page,
  } = useSearchBusiness();
  const { onDeleteBusiness, loading: deleteLoading } = useDeleteBusiness();
  const navigate = useNavigate();

  const onDelete = async (id: string) => {
    const result = await onDeleteBusiness(id);
    if (!result.error) {
      onDeleteItem({ id } as IUser);
      openNotification({
        type: 'success',
        message: translation('message.delete'),
        description: translation('message.success'),
      });
    } else {
      openNotification({
        type: 'error',
        message: translation('message.delete'),
        description: translation('message.error'),
      });
    }
  };

  const onSearchBy = (tags: string[]) => {
    searchBusiness({
      limit: 100,
      page: 1,
      status: tags,
    });
  };
  const onFilterMemberStatus = useCallback(
    (isMembership?: boolean) => {
      searchBusiness({
        ...input,
        limit: 100,
        page: 1,
        isMembership,
      });
    },
    [input],
  );

  return (
    <CompanyListComponent
      loading={loading}
      deleteLoading={deleteLoading}
      totalItems={totalItems}
      totalPages={totalPages}
      currentPage={page}
      companies={businesses}
      onDelete={onDelete}
      onChangeLimit={onChangeLimit}
      onChangePage={onChangePage}
      onDetails={(id) => {
        navigate(`/cms/customer/detail/${id}`);
      }}
      onSearch={(keyword) => {
        searchBusiness({
          limit: 100,
          page: 1,
          keyword,
        });
      }}
      onSelectTags={onSearchBy}
      sendingEmail={false}
      mailResult={undefined}
      onSendEmail={() => {}}
      onFilterMemberStatus={onFilterMemberStatus}
    />
  );
};

export default CompanyListContainer;
