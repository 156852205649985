import React, { memo } from 'react';
import styled from 'styled-components';
import { Modal } from 'vendors/Modal';
import { Formik, FormikProps } from 'formik';
import { Div, HorizontalView, VerticalView } from 'vendors/Wrapper';
import { AppConst } from 'helpers';
import { useTranslationText } from 'locale';
import { useAppSetting } from 'hooks/useAppSetting';
import { PrimaryButton } from 'components/common/components/Button/PrimaryButton';
import { Input } from 'vendors/Input';
import { ICreateCategoryInput } from 'models/Category';
import { RadioItem, RadioSelect } from 'vendors/Radio';
import { createCategoryValidatorSchema } from 'validators/category';

export const ModalWrapper = styled.div``;
export const AlertModalWrapper = styled.div``;
export const ContentWrapper = styled.div``;
export const AddUserWrapper = styled.div``;

interface Props {
  showCreate: boolean;
  createLoading: boolean;
  onCreate: (values: ICreateCategoryInput) => void;
  onClose: () => void;
}

const Wrapper = styled.div``;

export const CreateCategoryModal = (props: Props) => {
  const ref = React.useRef<FormikProps<ICreateCategoryInput>>(null);
  const { translation, getTypes } = useTranslationText();
  const langOption = getTypes('languages') as RadioItem[];
  const [currentLang, setCurrentLag] = React.useState(0);
  const { appLayout, appTheme, appSize } = useAppSetting();
  return (
    <Wrapper>
      <Modal
        title={translation('home.category.create')}
        open={props.showCreate}
        height={appLayout.isMobile ? '100vh' : 'auto'}
        zIndex={AppConst.zIndex.header + 60}
        onClose={() => {
          props.onClose();
        }}
        headerBackground={appTheme.sidebar.bg}
        closeOnBackDropClick
      >
        <HorizontalView minWidth={'300px'} fullWidthHeight alignContent={'topCenter'}>
          <Formik
            innerRef={ref}
            validationSchema={createCategoryValidatorSchema}
            initialValues={
              {
                id: '',
                locales: [
                  { lang: 'ja', name: '種類' },
                  { lang: 'vi', name: 'Loại' },
                  { lang: 'en', name: 'Type' },
                ],
              } as ICreateCategoryInput
            }
            onSubmit={(values: ICreateCategoryInput) => {
              props.onCreate(values);
            }}
          >
            {({ errors, values, submitCount, handleChange }) => {
              return (
                <VerticalView width={appLayout.isMobile ? '100vw' : '550px'} padding={'20px'}>
                  <Div width={'100%'} alignContent={'centerLeft'} paddingBottom="20px">
                    <RadioSelect
                      fontSize={appSize.md}
                      defaultValue={{
                        // label: values?.locales?.find((locale) => currentLang.value === locale?.lang)?.name,
                        value: values?.locales?.find((locale) => langOption[currentLang].value === locale?.lang)?.lang,
                      }}
                      label={'language'}
                      options={getTypes('languages')}
                      onChange={async (e) => {
                        langOption.map((lang, index) => {
                          if (lang.value === e?.value) setCurrentLag(index);
                        });
                      }}
                    />
                  </Div>
                  <Input
                    required
                    width={appLayout.isMobile ? '100%' : '50%'}
                    label={'home.category.name'}
                    fontSize={appSize.md}
                    name={`locales[${currentLang}].name`}
                    height={'auto'}
                    placeholder={'home.category.name'}
                    value={values.locales[currentLang].name}
                    background={'transparent'}
                    onChange={handleChange}
                  />
                  <HorizontalView fullWidth paddingTop={'15px'} marginBottom="50px">
                    <Input
                      required
                      name={'id'}
                      label={'home.category.id'}
                      width={'100%'}
                      value={values.id}
                      onChange={handleChange}
                      placeholder={'home.category.id'}
                      error={submitCount ? errors.id : undefined}
                    />
                  </HorizontalView>
                  <PrimaryButton
                    title={'createUser.create'}
                    icon={'plus'}
                    width={'80%'}
                    onClick={() => {
                      if (ref.current) {
                        ref.current.handleSubmit();
                      }
                    }}
                  />
                </VerticalView>
              );
            }}
          </Formik>
        </HorizontalView>
      </Modal>
    </Wrapper>
  );
};

export default memo(CreateCategoryModal);
