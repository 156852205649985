import gql from 'graphql-tag';

export const GET = gql`
  query getHelp($input: GetHelpInput!) {
    getHelp(input: $input) {
      id
      forRole
      seoname
      status
      category
      translations {
        lang
        title
        content
      }
      createdBy
      updatedTime
      createdTime
    }
  }
`;

export const SEARCH = gql`
  query searchHelps($input: SearchHelpsInput!) {
    searchHelps(input: $input) {
      totalItems
      items {
        id
        forRole
        title
        seoname
        status
        category
        createdBy
        updatedTime
        createdTime
      }
    }
  }
`;

export const OPERATOR_SEARCH = gql`
  query operationSearchHelps($input: OperationSearchHelpsInput!) {
    operationSearchHelps(input: $input) {
      totalItems
      items {
        id
        forRole
        title
        seoname
        status
        category
        createdBy
        updatedTime
        createdTime
      }
    }
  }
`;

export const GET_GROUP_HELP = gql`
  query getGroupHelps($input: GroupHelpInput!) {
    getGroupHelps(input: $input) {
      role
      helps {
        id
        seoname
        category
        title
      }
    }
  }
`;
