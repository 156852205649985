import React from 'react';
import { useGetCompanyComercialLaw, useUpdateCompany } from 'api/hooks/company/useCompanyApi';
import { IUpdateCompanyInput } from 'models/Company';
import { openNotification } from 'vendors/Notification';
import { useTranslationText } from 'locale';
import { CMSComercialLaw } from 'components/cms-interface/company/specified-commercial-law';

export const CMSComercialLawContainer = () => {
  const { translation } = useTranslationText();
  const { onUpdateCompany, loading: updateLoading } = useUpdateCompany();
  const { company, onGetComLaw, loading } = useGetCompanyComercialLaw();

  React.useEffect(() => {
    onGetComLaw();
  }, []);

  const update = async (data: string) => {
    const update = { id: 'wakjob', commercialLaw: data } as IUpdateCompanyInput;
    const result = await onUpdateCompany(update);
    if (!result.error) {
      openNotification({
        type: 'success',
        message: translation('message.update'),
        description: translation('message.success'),
      });
    } else {
      openNotification({
        type: 'error',
        message: translation('message.update'),
        description: translation('message.error'),
      });
    }
  };
  return (
    <CMSComercialLaw
      loading={loading}
      updateLoading={updateLoading}
      content={company ? (company.commercialLaw as string) : ''}
      onSave={(data) => update(data)}
    />
  );
};

export default CMSComercialLawContainer;
